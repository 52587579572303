import { PopoverProps } from 'antd';
import { useState } from 'react';
import { StyledProfilePopover } from './ProfilePopover.styles';

interface ProfilePopoverProps extends PopoverProps {
  profileUrl: string;
}

export const ProfilePopover = (props: ProfilePopoverProps): JSX.Element => {
  const [visible, setVisible] = useState(false);

  return (
    <StyledProfilePopover open={visible} onOpenChange={(): void => setVisible(!visible)} {...props}>
      <div>
        <div className={`header__profile-image${visible ? ' header__profile-image--clicked' : ''}`}>
          <img src={props.profileUrl} alt="profile" />
        </div>
      </div>
    </StyledProfilePopover>
  );
};
