import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Form } from 'antd';
import { selectIsEditUserModalLoading, selectIsEditUserModalVisible } from '../../state/userModalsSelectors';
import { PrimaryButton } from '../../../../../../lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../../../../../../lib/components/Button/SecondaryButton/SecondaryButton';
import { UserModalsActions } from '../../state/userModalsActions';
import { StyledEditUserModal } from './EditUserModal.styles';
import { EditUserForm } from './EditUserForm';
import { UpdateUserInput } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import {
  IUserUpdateRequestOptions,
  UserDetailsActions,
} from 'app/modules/user-management/user-details/state/userDetailsActions';
import { splitLangPrefix } from 'app/modules/user-management/utils/splitLangPrefix';

export const EditUserModal = (): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();

  const isVisible = useSelector(selectIsEditUserModalVisible);
  const isLoading = useSelector(selectIsEditUserModalLoading);

  const handleCancel = useCallback(() => dispatch(UserModalsActions.hideEditUserModal()), [dispatch]);

  const handleOk = useCallback(() => {
    formInstance.submit();
  }, [formInstance]);

  const onFinish = useCallback(
    (values: UpdateUserInput) => {
      const getPrefixLanguage = splitLangPrefix(values?.language || '');
      const input: IUserUpdateRequestOptions = {
        input: {
          ...values,
          language: getPrefixLanguage,
          id: id as string,
        },
      };
      dispatch(UserDetailsActions.userUpdateRequest(input));
    },
    [dispatch, id]
  );

  return (
    <StyledEditUserModal
      className="edit-user-modal"
      title={t('userDetails.editUserModal.editUser')}
      centered
      open={isVisible}
      closable={false}
      footer={[
        <SecondaryButton size="m" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleOk}
        >
          {t('userDetails.editUserModal.saveChanges')}
        </PrimaryButton>,
      ]}
    >
      <EditUserForm formInstance={formInstance} onFinish={onFinish} />
    </StyledEditUserModal>
  );
};
