import { CustomerDetailsTab } from '../../interfaces/Customer.types';
import { CustomerManagementEntity } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export class CustomerDetailsSelectors {
  public static selectData = (state: IState): Optional<CustomerManagementEntity> =>
    state.modules['customer-management']['customer-details'].data;

  public static selectLoading = (state: IState): Optional<boolean> =>
    state.modules['customer-management']['customer-details'].isLoading;

  public static selectActiveTab = (state: IState): Optional<CustomerDetailsTab> =>
    state.modules['customer-management']['customer-details'].activeTab;
}
