import classnames from 'classnames';
import { ButtonBaseProps } from '../ButtonBase';
import { StyledTextButton } from './TextButton.styles';

type TextButtonProps = ButtonBaseProps;

export const TextButton = ({ className, ...props }: TextButtonProps): JSX.Element => {
  const buttonClassName = classnames(className, 'button');

  return <StyledTextButton className={buttonClassName} {...props} />;
};
