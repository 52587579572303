import { isValidEnvVarValue } from '../isValidEnvVarValue';

export const isFeatureEnabled = (envVarValue: string | undefined): boolean => {
  const valuesAcceptedAsTrue = ['true', '1', 'yes', 'on', 'enabled'];

  if (!isValidEnvVarValue(envVarValue)) {
    return false;
  }

  return valuesAcceptedAsTrue.includes(envVarValue);
};
