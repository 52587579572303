import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { call, getContext, put, takeLatest } from 'typed-redux-saga';
import { UserCreateResponse } from '../../interfaces/User.types';
import {
  UserCreateActions,
  IUserCreateErrorAction,
  IUserCreateRequestAction,
  IUserCreateSuccessAction,
} from './userCreateActions';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { Optional } from 'lib/types/Optional';
import { RoutePaths } from 'config/route-paths';

export function* userCreate(
  action: IUserCreateRequestAction
): Generator<
  | IDependencies
  | GetContextEffect
  | CallEffect<Optional<UserCreateResponse>>
  | CallEffect<void>
  | PutEffect<IUserCreateSuccessAction>
  | PutEffect<IUserCreateErrorAction>
  | void
> {
  const { userService, toastService, t, history } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(userService.create, action.payload);

    yield* put(UserCreateActions.userCreateSuccess(response));

    if (response?.registerUser) {
      history.push(RoutePaths.CREATE_USER_SUCCESS);
    }
  } catch (error) {
    yield* put(UserCreateActions.userCreateError({ error }));

    yield* call(toastService.error, {
      message: t('userCreate.toasts.error.message'),
      description: (error as Error).message,
    });
  }
}

export function* userCreateSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(UserCreateActions.CREATE_USER_REQUEST, userCreate);
}
