import { Action } from 'redux';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { MachineClientListOptions } from '../../MachineClient';
import { MachineListResponse, PollMachineListRequest } from '../../interfaces/Machine.types';
import { Optional } from 'lib/types/Optional';
import { SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export type IPollMachineListRequest = PollMachineListRequest;
export type IPollMachineListRequestAction = Action<typeof MachineListActions.POLL_MACHINE_LIST_REQUEST> & {
  payload: IPollMachineListRequest;
};

export type IGetMachineListRequestOptions = MachineClientListOptions;
export type IGetMachineListRequestAction = Action<typeof MachineListActions.GET_MACHINE_LIST_REQUEST> & {
  payload: MachineClientListOptions;
};

export type IGetMachineListSuccessOptions = Optional<MachineListResponse>;
export type IGetMachineListSuccessAction = Action<typeof MachineListActions.GET_MACHINE_LIST_SUCCESS> & {
  payload: Optional<MachineListResponse>;
};

export type IGetMachineListErrorAction = IErrorAction<typeof MachineListActions.GET_MACHINE_LIST_ERROR>;

export type IChangeMachineListTablePage = Action<typeof MachineListActions.CHANGE_TABLE_PAGE> & {
  payload: {
    page: number;
  };
};

export type IChangeMachineListTablePageSize = Action<typeof MachineListActions.CHANGE_TABLE_PAGE_SIZE> & {
  payload: {
    pageSize: number;
  };
};

export type ISetActivePeriodFilter = Action<typeof MachineListActions.SET_ACTIVE_PERIOD_FILTER> & {
  payload: {
    period: {
      startDate: string;
      endDate: string;
    };
  };
};

export type ISetActiveSortField = Action<typeof MachineListActions.SET_ACTIVE_SORT_FIELD> & {
  payload: {
    field: Optional<string>;
  };
};

export type ISetActiveSortOrder = Action<typeof MachineListActions.SET_ACTIVE_SORT_ORDER> & {
  payload: {
    order: Optional<SortOrders>;
  };
};
export type ISetActiveSearchText = Action<typeof MachineListActions.SET_ACTIVE_SEARCH_TEXT> & {
  payload: {
    searchText: Optional<string>;
  };
};
export type ISetActiveAlgFilter = Action<typeof MachineListActions.SET_ACTIVE_ALG_FILTER> & {
  payload: {
    alg: Optional<string>;
  };
};

export type ISetActiveCustomerFIlter = Action<typeof MachineListActions.SET_ACTIVE_CUSTOMER_FILTER> & {
  payload: {
    customerId: Optional<string>;
  };
};

export class MachineListActions {
  public static GET_MACHINE_LIST_REQUEST = 'MACHINE_LIST::GET_MACHINE_LIST_REQUEST';
  public static GET_MACHINE_LIST_SUCCESS = 'MACHINE_LIST::GET_MACHINE_LIST_SUCCESS';
  public static GET_MACHINE_LIST_ERROR = 'MACHINE_LIST::GET_MACHINE_LIST_ERROR';

  public static CHANGE_TABLE_PAGE = 'MACHINE_LIST::CHANGE_TABLE_PAGE';
  public static CHANGE_TABLE_PAGE_SIZE = 'MACHINE_LIST::CHANGE_TABLE_PAGE_SIZE';
  public static RESET_STATE = 'MACHINE_LIST::RESET_STATE';

  public static SET_ACTIVE_PERIOD_FILTER = 'MACHINE_LIST::SET_ACTIVE_PERIOD_FILTER';
  public static SET_ACTIVE_ALG_FILTER = 'MACHINE_LIST::SET_ACTIVE_ALG_FILTER';

  public static SET_ACTIVE_SORT_FIELD = 'MACHINE_LIST::SET_ACTIVE_SORT_FIELD';
  public static SET_ACTIVE_SORT_ORDER = 'MACHINE_LIST::SET_ACTIVE_SORT_ORDER';

  public static SET_ACTIVE_SEARCH_TEXT = 'MACHINE_LIST::SET_ACTIVE_SEARCH_TEXT';

  public static SET_ACTIVE_CUSTOMER_FILTER = 'MACHINE_LIST::SET_ACTIVE_CUSTOMER_FILTER';

  public static POLL_MACHINE_LIST_REQUEST = 'MACHINE_LIST::POLL_MACHINE_LIST_REQUEST';

  public static getMachineListRequest({
    search,
    paginationOptions,
    filter,
    sortOptions,
  }: IGetMachineListRequestOptions): IGetMachineListRequestAction {
    return {
      type: MachineListActions.GET_MACHINE_LIST_REQUEST,
      payload: {
        search,
        paginationOptions,
        filter,
        sortOptions,
      },
    };
  }

  public static getMachineListSuccess(data: IGetMachineListSuccessOptions): IGetMachineListSuccessAction {
    return {
      type: MachineListActions.GET_MACHINE_LIST_SUCCESS,
      payload: data,
    };
  }

  public static getMachineListError({ error }: IActionCreatorErrorOptions): IGetMachineListErrorAction {
    return {
      type: MachineListActions.GET_MACHINE_LIST_ERROR,
      error,
    };
  }

  public static changeTablePage(data: { page: number }): IChangeMachineListTablePage {
    return {
      type: MachineListActions.CHANGE_TABLE_PAGE,
      payload: data,
    };
  }

  public static changeTablePageSize(data: { pageSize: number }): IChangeMachineListTablePageSize {
    return {
      type: MachineListActions.CHANGE_TABLE_PAGE_SIZE,
      payload: data,
    };
  }

  public static resetState(): Action<typeof MachineListActions.RESET_STATE> {
    return {
      type: MachineListActions.RESET_STATE,
    };
  }

  public static setActivePeriodFilter(period: { startDate: string; endDate: string }): ISetActivePeriodFilter {
    return {
      type: MachineListActions.SET_ACTIVE_PERIOD_FILTER,
      payload: {
        period,
      },
    };
  }

  public static setActiveAlgFilter(alg: Optional<string>): ISetActiveAlgFilter {
    return {
      type: MachineListActions.SET_ACTIVE_ALG_FILTER,
      payload: {
        alg,
      },
    };
  }

  public static setActiveSortField(field: Optional<string>): ISetActiveSortField {
    return {
      type: MachineListActions.SET_ACTIVE_SORT_FIELD,
      payload: {
        field,
      },
    };
  }

  public static setActiveSortOrder(order: Optional<SortOrders>): ISetActiveSortOrder {
    return {
      type: MachineListActions.SET_ACTIVE_SORT_ORDER,
      payload: {
        order,
      },
    };
  }

  public static setActiveSearchText(searchText: Optional<string>): ISetActiveSearchText {
    return {
      type: MachineListActions.SET_ACTIVE_SEARCH_TEXT,
      payload: {
        searchText,
      },
    };
  }

  public static setActiveCustomerFilter(customerId: Optional<string>): ISetActiveCustomerFIlter {
    return {
      type: MachineListActions.SET_ACTIVE_CUSTOMER_FILTER,
      payload: {
        customerId,
      },
    };
  }

  public static pollMachineListRequest(data: IPollMachineListRequest): IPollMachineListRequestAction {
    return {
      type: MachineListActions.POLL_MACHINE_LIST_REQUEST,
      payload: data,
    };
  }
}
