import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectIsDeleteUserModalLoading, selectIsDeleteUserModalVisible } from '../../state/userModalsSelectors';
import { PrimaryButton } from '../../../../../../lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../../../../../../lib/components/Button/SecondaryButton/SecondaryButton';
import { UserModalsActions } from '../../state/userModalsActions';
import { StyledDeleteUserModal } from './DeleteUserModal.styles';

interface DeleteUserModalProps {
  handleOk: () => void;
  title: string;
  submitButtonLabel: string;
  body: string;
}

export const DeleteUserModal = ({ handleOk, title, submitButtonLabel, body }: DeleteUserModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isVisible = useSelector(selectIsDeleteUserModalVisible);
  const isLoading = useSelector(selectIsDeleteUserModalLoading);

  const handleCancel = useCallback(() => dispatch(UserModalsActions.hideDeleteUserModal()), [dispatch]);

  return (
    <StyledDeleteUserModal
      className="delete-user-modal"
      title={t(title)}
      centered
      open={isVisible}
      closable={false}
      footer={[
        <SecondaryButton size="s" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton key="submit" className="submit-button" type="primary" loading={isLoading} onClick={handleOk}>
          {t(submitButtonLabel)}
        </PrimaryButton>,
      ]}
    >
      <p className="modal__body">{t(body)}</p>
    </StyledDeleteUserModal>
  );
};
