import styled from 'styled-components';

export const StyledAppBreadcrumbs = styled.div`
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 98;
  background-color: ${(props): string => props.theme.colors.primary};

  .header__breadcrumb {
    display: flex;
    align-items: flex-end;
    height: 48px;
    max-width: 1056px;
    margin-left: auto;
    margin-right: auto;
  }

  .ant-breadcrumb {
    font-family: ${(props): string => props.theme.fonts.breadcrumbs};
    font-size: ${(props): string => props.theme.fontSizes.breadcrumbs};
    padding: 0 24px 14px;

    & > span a {
      color: ${(props): string => props.theme.colors.text} !important;
    }

    .breadcrumb-link--disabled {
      color: rgba(${(props): string => props.theme.colors.textRgb}, 0.4);
    }
  }

  @media screen and (max-width: 1406px) {
    .header__breadcrumb {
      margin-left: 175px;
    }
  }
`;
