import { StyledCustomerDeleteDescription } from './CustomerDeleteDescription.styles';

export interface CustomerDeleteDescriptionProps {
  message1: string;
  note: string;
  message2: string;
}
export const CustomerDeleteDescription = (props: CustomerDeleteDescriptionProps): JSX.Element => (
  <StyledCustomerDeleteDescription>
    <div className="customer-delete-modal__description">
      <p>
        {props.message1}&nbsp;
        <b>{props.note}</b>&nbsp;{props.message2}
      </p>
    </div>
  </StyledCustomerDeleteDescription>
);
