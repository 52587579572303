import { Tabs } from 'antd';
import styled from 'styled-components';

export const StyledTabs = styled(Tabs)`
  &.ant-tabs {
    width: 100%;
  }

  .ant-tabs-nav:before {
    border-bottom: 1px solid ${(props): string => props.theme.colors.neutralWhiteColor} !important;
  }

  .ant-tabs-nav {
    .ant-tabs-nav-list {
      .ant-tabs-ink-bar {
        background: ${(props): string => props.theme.colors.black};
        height: 4px;
      }
    }

    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: ${(props): string => props.theme.colors.black};
        text-shadow: none;
      }
    }
  }

  .ant-tabs-tab {
    :hover {
      color: ${(props): string => props.theme.colors.textGray};
    }
  }

  .ant-tabs-tab-btn {
    :active {
      color: ${(props): string => props.theme.colors.black};
    }

    :hover {
      color: ${(props): string => props.theme.colors.textGray};
    }
  }
`;
