import styled from 'styled-components';

export const StyledCustomerDeleteHasDevicesTab = styled.div`
  ant-table-container table .ant-table-tbody tr > td {
    padding: 24px 4px;
  }

  .customer-delete-has-devices__unclaim,
  .customer-delete-has-devices__reclaim {
    text-decoration-line: underline;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    display: flex;
    span {
      font-family: ${(props): string => props.theme.fonts.pinPopupBody};
      text-transform: capitalize;
    }
  }

  .customer-delete-has-devices__reclaim {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .customer-delete-modal__device-options {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    padding-bottom: 24px;
  }

  .customer-delete-has-devices__success-icon,
  .customer-delete-has-devices__success-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .device-list__table {
    margin-bottom: 24px;
    .ant-table-footer {
      display: none;
    }
  }
`;
