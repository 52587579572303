import { combineReducers, Reducer } from 'redux';
import { authenticationReducer } from '../authentication/state/authenticationReducer';
import { customerListReducer } from '../../modules/customer-management/customer-list/state/customerListSlice';
import { customerModalsReducer } from '../../modules/customer-management/modals/state/customerModalsSlice';
import { customerDetailsReducer } from 'app/modules/customer-management/customer-details/state/customerDetailsSlice';
import { machineListReducer } from 'app/modules/machine-directory/machine-list/state/machineListReducer';
import { userListReducer } from 'app/modules/user-management/user-list/state/userListReducer';
import { userModalsReducer } from 'app/modules/user-management/modals/state/userModalsReducer';
import { machineModalReducer } from 'app/modules/machine-directory/modals/state/machineModalReducer';
import { userCreateReducer } from 'app/modules/user-management/user-create/state/userCreateReducer';
import { machineReducer } from 'app/modules/machine-directory/state/machineDirectoryReducer';
import { customerSearchReducer } from 'app/modules/user-management/customer-search/state/customerSearchReducer';
import { userInviteReducer } from 'app/modules/user-management/user-invite/state/userInviteReducer';
import { userDetailsReducer } from 'app/modules/user-management/user-details/state/userDetailsReducer';
import { machineClaimPairReducer } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairReducer';
import { customerDeleteReducer } from 'app/modules/customer-management/modals/components/CustomerDeleteModal/state/deleteCustomerSlice';

export const crossCuttingConcernsReducers = combineReducers({
  authentication: authenticationReducer,
});

const machineDirectoryReducers = combineReducers({
  'machine-list': machineListReducer,
  'machine-claim-pair': machineClaimPairReducer,
  machine: machineReducer,
  modals: machineModalReducer,
});

const userManagementReducers = combineReducers({
  modals: userModalsReducer,
  'user-list': userListReducer,
  'user-create': userCreateReducer,
  'user-invite': userInviteReducer,
  'user-details': userDetailsReducer,
  'customer-search': customerSearchReducer,
});

const customerManagementReducers = combineReducers({
  'customer-list': customerListReducer,
  modals: customerModalsReducer,
  'customer-details': customerDetailsReducer,
  'customer-delete': customerDeleteReducer,
});

export const modulesReducers = combineReducers({
  'machine-directory': machineDirectoryReducers,
  'user-management': userManagementReducers,
  'customer-management': customerManagementReducers,
});

export const reducers: Record<string, Reducer<any, any>> = {
  'cross-cutting-concerns': crossCuttingConcernsReducers,
  modules: modulesReducers,
};
