import styled from 'styled-components';

export const StyledProfile = styled.div`
  padding: 15px 0px 12px 12px;
  font-size: 11px;

  .profile__logout-button {
    margin-top: 20px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .profile__user-name {
    color: ${(props): string => props.theme.colors.headerGray};
    font-family: ${(props): string => props.theme.fonts.body};
    font-size: 11px;
    text-transform: uppercase;
  }
`;
