import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/lib/form/Form';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Radio } from 'lib/components/Radio/Radio';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { Input } from 'lib/components/Input/Input';
import { ValidateMachineInput } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { MachineClaimPairSelectors } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairSelectors';
import { MachineClaimPairActions } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairActions';
import { MachineClassification } from 'app/modules/machine-directory/enum/MachineClassification';
import { MachineManufacturerType } from 'app/modules/machine-directory/enum/MachineManufacturerType';
import { MaterialNumberAutoComplete } from 'app/modules/machine-directory/machine-claiming-pairing/components/MaterialNumberAutoComplete/MaterialNumberAutoComplete';

export const CustomerClaimMachineForm = (): JSX.Element => {
  const [machineType, setMachineType] = useState(MachineManufacturerType.KARCHER_MACHINE);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formSearch] = useForm();
  const [materialNumberInput, setMaterialNumberInput] = useState('');
  const [serialNumberInput, setSerialNumberInput] = useState('');

  const handleOnFieldsChanged = (): void => {
    setMaterialNumberInput(formSearch.getFieldValue('materialNumber'));
    setSerialNumberInput(formSearch.getFieldValue('serialNumber'));
  };

  const selectMachineFindData = useSelector(MachineClaimPairSelectors.selectMachineFindData);
  const selectIsLoadingSearchMachine = useSelector(MachineClaimPairSelectors.selectIsMachineFindLoading);
  const selectMaterialNumberData = useSelector(MachineClaimPairSelectors.selectMaterialNumberData);

  const isMaterialNumberFound = selectMaterialNumberData?.some(
    materialNumberInfo => materialNumberInfo?.materialNumber === materialNumberInput
  );

  const isMachineInputValid = useMemo(
    () => materialNumberInput && serialNumberInput && isMaterialNumberFound,
    [materialNumberInput, serialNumberInput, isMaterialNumberFound]
  );

  const handleChangeMachineType = (e: RadioChangeEvent): void => {
    setMachineType(e.target.value);
    formSearch.resetFields();
    dispatch(MachineClaimPairActions.searchMaterialNumberResetState());
  };

  const handleSearch = useCallback(
    ({ materialNumber, serialNumber }: ValidateMachineInput) => {
      dispatch(MachineClaimPairActions.findMachineRequest({ materialNumber, serialNumber }));
    },
    [dispatch]
  );

  const handleSearchButtonClick = useCallback(() => {
    formSearch.submit();
  }, [formSearch]);

  const handleDeleteSelectionClick = useCallback(() => {
    formSearch.resetFields();
    setMachineType(MachineManufacturerType.KARCHER_MACHINE);
    dispatch(MachineClaimPairActions.findMachineResetState());
    dispatch(MachineClaimPairActions.searchMaterialNumberResetState());
  }, [dispatch, formSearch]);

  useEffect(() => {
    formSearch.resetFields();
    setMachineType(MachineManufacturerType.KARCHER_MACHINE);
    dispatch(MachineClaimPairActions.findMachineResetState());
    dispatch(MachineClaimPairActions.searchMaterialNumberResetState());
  }, [dispatch, formSearch, setMachineType]);

  return (
    <div className="machine-find">
      <div className="machine-find__body">
        <Form
          form={formSearch}
          layout="vertical"
          name="machine-find"
          onFinish={handleSearch}
          validateTrigger="onChange"
          autoComplete="false"
          onFieldsChange={handleOnFieldsChanged}
        >
          {!selectMachineFindData && (
            <Radio.Group
              className="machine-find__radio-group"
              defaultValue={machineType}
              onChange={handleChangeMachineType}
            >
              <Radio value={MachineManufacturerType.KARCHER_MACHINE}>
                {t('findMachine.typeOfMachine.karcherMachine')}
              </Radio>
              <Radio value={MachineManufacturerType.THIRD_PARTY_MANUFACTURER}>
                {t('findMachine.typeOfMachine.thirdPartyManufacturer')}
              </Radio>
            </Radio.Group>
          )}
          <Row gutter={24} style={{ alignItems: 'center' }}>
            <Col>
              <Form.Item
                name="materialNumber"
                label={
                  machineType === MachineManufacturerType.KARCHER_MACHINE
                    ? t('machineClaiming.forms.materialNumber')
                    : t('findMachine.forms.manufacturer')
                }
                className="machine-find__form-item"
                rules={[
                  {
                    required: true,
                    message: t('findMachine.forms.errors.materialNumberRequired'),
                  },
                ]}
              >
                <MaterialNumberAutoComplete
                  className="machine-find__input"
                  placeholder={
                    machineType === MachineManufacturerType.THIRD_PARTY_MANUFACTURER
                      ? t('findMachine.forms.placeholderManufacturer')
                      : t('machineClaiming.forms.placeholderMaterialNumber')
                  }
                  classifications={[MachineClassification.STAND_ALONE, MachineClassification.GATEWAY_CONNECTED_DEVICE]}
                  isNonKaercherDevice={machineType === MachineManufacturerType.THIRD_PARTY_MANUFACTURER}
                  disabled={!!selectMachineFindData}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="serialNumber"
                label={t('machineClaiming.forms.serialNumber')}
                className="machine-find__form-item"
                rules={[
                  {
                    required: true,
                    message: t('findMachine.forms.errors.serialNumberRequired'),
                  },
                  {
                    min: 6,
                    message: t('findMachine.forms.errors.serialNumberMinLength'),
                  },
                ]}
              >
                <Input
                  className="machine-find__input"
                  placeholder={t('machineClaiming.forms.placeholderSerialNumber')}
                  disabled={!!selectMachineFindData}
                />
              </Form.Item>
            </Col>
            <Col>
              {!selectMachineFindData ? (
                <PrimaryButton
                  size="m"
                  className="machine-find__button"
                  onClick={handleSearchButtonClick}
                  loading={selectIsLoadingSearchMachine}
                  disabled={!isMachineInputValid}
                >
                  {t('machineClaiming.searchButton')}
                </PrimaryButton>
              ) : (
                <SecondaryButton
                  size="m"
                  className="machine-find__delete-selection-button"
                  key="delete-button"
                  onClick={handleDeleteSelectionClick}
                >
                  {t('findMachine.deleteSelectionButton')}
                </SecondaryButton>
              )}
            </Col>
          </Row>
        </Form>

        {!selectMachineFindData ? (
          <></>
        ) : (
          <div className="machine-find__result">
            <div className="machine-find__table-result">
              <Row gutter={24} justify="space-between">
                <Col className="machine-find__result">
                  <h4 className="machine-find__title">{t('findMachine.table.machineType')}</h4>
                  <p className="machine-find__info">{selectMachineFindData?.type || '-'}</p>
                </Col>
                <Col className="machine-find__result">
                  <h4 className="machine-find__title">{t('findMachine.table.materialNo')}</h4>
                  <p className="machine-find__info">{selectMachineFindData?.materialNumber}</p>
                </Col>
                <Col className="machine-find__result">
                  <h4 className="machine-find__title">{t('findMachine.table.serialNo')}</h4>
                  <p className="machine-find__info">{selectMachineFindData?.serialNumber}</p>
                </Col>
                <Col className="machine-find__result">
                  <h4 className="machine-find__title">{t('findMachine.table.alg')}</h4>
                  <p className="machine-find__info">{selectMachineFindData?.algNumber || '-'}</p>
                </Col>
                <Col className="machine-find__result">
                  <h4 className="machine-find__title">{t('findMachine.table.customer')}</h4>
                  <p className="machine-find__info">{selectMachineFindData?.customerName || '-'}</p>
                  <p className="machine-find__info">{selectMachineFindData?.customerNumber}</p>
                </Col>
              </Row>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
