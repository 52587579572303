import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';

export const selectIsMachineReclaimingModalVisible = (state: IState): boolean =>
  state.modules['machine-directory'].modals.machineReclaiming.visible;

export const selectIsMachinePairingModalVisible = (state: IState): boolean =>
  state.modules['machine-directory'].modals.machinePairing.visible;

export const selectIsMachinePairReclaimModalVisible = (state: IState): boolean =>
  state.modules['machine-directory'].modals.machinePairReclaim.visible;

export const selectMachinePairModalMessage = (state: IState): string =>
  state.modules['machine-directory'].modals.machinePairing.pairedMsg;

export const selectIsMachineUnclaimModalVisible = (state: IState): boolean =>
  state.modules['machine-directory'].modals.machineUnclaim.visible;
