import React from 'react';
import { StyledLoginPage } from './LoginPage.styles';
import { Login } from 'app/cross-cutting-concerns/authentication/components/Login/Login';
import { LoginHeader } from 'app/cross-cutting-concerns/authentication/components/LoginHeader/LoginHeader';
import { AppFooter } from 'app/components/app-layout/AppFooter/AppFooter';

export const LoginPage = (): JSX.Element => (
  <StyledLoginPage>
    <LoginHeader />
    <Login />
    <AppFooter className="footer--full-width" />
  </StyledLoginPage>
);
