import styled from 'styled-components';

export const StyledUserInvite = styled.div`
  font-family: ${(props): string => props.theme.fonts.body};
  font-size: ${(props): string => props.theme.fontSizes.pageDescription};
  min-height: inherit;

  .invite-user__header {
    background-color: ${(props): string => props.theme.colors.lightGray};
    position: sticky;
    top: -128px; // header - breadcrumb = 176 - 48
    z-index: 97;
  }

  .invite-user__header-content {
    max-width: 1056px;
    margin: auto;
    padding: 38px 24px 15px;
  }

  .invite-user__title {
    font-size: ${(props): string => props.theme.fontSizes.pageTitle};
    color: ${(props): string => props.theme.colors.text};
    font-family: ${(props): string => props.theme.fonts.pageTitle};
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .invite-user__sub-title {
    font-size: ${(props): string => props.theme.fontSizes.formSubheading};
    color: ${(props): string => props.theme.colors.text};
    font-family: ${(props): string => props.theme.fonts.pageTitle};
    text-transform: uppercase;
    margin: 20px 0;
  }

  .invite-user__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  .invite-user__submit-button,
  .invite-user__back-button {
    text-transform: uppercase;
  }

  .invite-user__input {
    width: 100%;
    &::placeholder {
      font-size: ${(props): string => props.theme.fontSizes.formBody};
      font-family: ${(props): string => props.theme.fonts.modalSubtitle};
      color: ${(props): string => props.theme.colors.border};
    }
  }

  .invite-user-modal__select {
    width: 100%;

    .ant-select-selection-placeholder {
      font-size: ${(props): string => props.theme.fontSizes.formBody};
      font-family: ${(props): string => props.theme.fonts.modalSubtitle};
      color: ${(props): string => props.theme.colors.border};

      // Set placeholder center
      position: absolute;
      top: 50%;
      right: 11px;
      left: 11px;
      transform: translateY(-50%);
      transition: all 0.3s;
    }
  }

  .invite-user__body-content {
    font-family: ${(props): string => props.theme.fonts.formBody};
    color: ${(props): string => props.theme.colors.text};
    max-width: 1056px;
    margin: 26px auto 0;
    padding: 0 24px;
  }

  .ant-form-item-label {
    padding-bottom: 0;

    > label {
      text-transform: uppercase;
      font-size: ${(props): string => props.theme.fontSizes.formTitle};
      font-family: ${(props): string => props.theme.fonts.formTitle};
      color: ${(props): string => props.theme.colors.text};
    }
  }

  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${(props): string => props.theme.colors.red};
    box-shadow: none;
    border-right-width: 2px !important;
  }

  .ant-form-item-explain-error {
    color: ${(props): string => props.theme.colors.red};
  }

  @media screen and (max-width: 1406px) {
    .invite-user__header-content,
    .invite-user__body-content {
      margin-left: 175px;
    }
  }
`;
