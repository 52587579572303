import styled from 'styled-components';
import { Modal } from '../../../../../../lib/components/Modal/Modal';

export const StyledCustomerReassignUserModal = styled(Modal)`
  .ant-modal-content {
    width: 600px;
    padding: 32px;
  }

  .ant-modal-header {
    padding: 0px 0px 24px 0px;
  }
  .ant-modal-body {
    padding: 24px 0;
  }

  .ant-form-item-label {
    padding-bottom: 0px;
  }

  .ant-form-item-required {
    text-transform: uppercase;
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    line-height: ${(props): string => props.theme.lineHeights.caption};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
  }

  .ant-input {
    width: 256px;
  }

  .ant-select.ant-select-single .ant-select-selector {
    border: 2px solid ${(props): string => props.theme.colors.gray};
  }

  .ant-select-selection-placeholder {
    font-family: ${(props): string => props.theme.fonts.captionMedium};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: ${(props): number => props.theme.fontWeights.p1Medium};
  }

  .ant-modal-footer {
    padding: 0;
    .ant-row {
      width: 100%;
    }
  }

  .delete-customer-reassign-user-modal__footer {
    display: flex;
    justify-content: space-between;
  }

  .delete-customer-reassign-user-modal__customer-selection-table {
    .ant-table-tbody > tr.ant-table-row-selected > td {
      background: unset;
    }
    .ant-table-footer {
      padding: 10px;
      .pagination {
        .ant-select-selector {
          border: none;
        }
        .pagination__body {
          .pagination__items {
            margin-left: 35px;
            margin-right: 35px;
          }
        }
      }
    }
  }
`;
