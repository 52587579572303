import classnames from 'classnames';
import { ButtonBaseProps } from '../ButtonBase';
import { StyledSecondaryButton } from './SecondaryButton.styles';

type OutlinedButtonProps = ButtonBaseProps;

export const SecondaryButton = ({ className, ...props }: OutlinedButtonProps): JSX.Element => {
  const buttonClassName = classnames(className, 'secondary-button');

  return <StyledSecondaryButton className={buttonClassName} size="m" {...props} />;
};
