import {
  ResponseValidateMachineData,
  MachineOwnership,
  MachinePairingInfo,
  MachinePairingSearchInfo,
  SearchMaterialNumberResponseData,
} from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export class MachineClaimPairSelectors {
  public static selectStepData = (state: IState): number =>
    state.modules['machine-directory']['machine-claim-pair'].stepData.step;

  public static selectIsPairingStep = (state: IState): boolean =>
    state.modules['machine-directory']['machine-claim-pair'].stepData.isPairingStep;

  public static selectSearchMaterialNumberLoading = (state: IState): boolean =>
    state.modules['machine-directory']['machine-claim-pair'].materialNumber.isLoading;

  public static selectMaterialNumberData = (state: IState): Optional<SearchMaterialNumberResponseData[]> =>
    state.modules['machine-directory']['machine-claim-pair'].materialNumber.data;

  public static selectMachineFindData = (state: IState): Optional<ResponseValidateMachineData> =>
    state.modules['machine-directory']['machine-claim-pair'].machineFind.data;

  public static selectIsMachineFindLoading = (state: IState): boolean =>
    state.modules['machine-directory']['machine-claim-pair'].machineFind.isLoading;

  public static selectMachineClaimData = (state: IState): Optional<MachineOwnership> =>
    state.modules['machine-directory']['machine-claim-pair'].machineClaim.data;

  public static selectMachineClaimLoading = (state: IState): boolean =>
    state.modules['machine-directory']['machine-claim-pair'].machineClaim.isLoading;

  public static selectMachinePairingSearchData = (state: IState): Optional<MachinePairingSearchInfo> =>
    state.modules['machine-directory']['machine-claim-pair'].machinePairingSearch.data;

  public static selectMachinePairSearchLoading = (state: IState): boolean =>
    state.modules['machine-directory']['machine-claim-pair'].machinePairingSearch.isLoading;

  public static selectMachinePairingData = (state: IState): Optional<MachinePairingInfo> =>
    state.modules['machine-directory']['machine-claim-pair'].machinePairing.data;

  public static selectMachinePairLoading = (state: IState): boolean =>
    state.modules['machine-directory']['machine-claim-pair'].machinePairing.isLoading;

  public static selectMachinePairLoadingWithSearch = (state: IState): boolean =>
    state.modules['machine-directory']['machine-claim-pair'].machinePairing.isLoadingWithSearch;
}
