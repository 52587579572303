import { createGlobalStyle } from 'styled-components';

export const PopConfirmGlobalStyles = createGlobalStyle`
  .ant-popconfirm {
    .ant-popover-inner {
      padding: 24px;
      border-radius: 8px;
    }
    
    .ant-popconfirm-message-icon {
      margin-right: 12px;
      
      svg {
        height: 24px;
        width: 24px;
      }
    }
    
    .ant-popconfirm-title {
      font-family: ${(props): string => props.theme.fonts.captionBold};
      font-size: ${(props): string => props.theme.fontSizes.pinTitle};
      line-height: ${(props): string => props.theme.lineHeights.formSubheading};
      font-weight:${(props): number => props.theme.fontWeights.captionSmall};
    }  
    
    .ant-popconfirm-message {
      .ant-popconfirm-description {
        margin-top: 8px;
        margin-bottom: 8px;
        font-family: ${(props): string => props.theme.fonts.pinPopupBody};
        font-size: ${(props): string => props.theme.fontSizes.p0};
        line-height: ${(props): string => props.theme.lineHeights.formSubheading};
        font-weight:${(props): number => props.theme.fontWeights.captionSmall};
      }  
    }
    
    .popconfirm__paragraph {
      margin-bottom: 16px;
    }

    .checkbox {
      font-family: ${(props): string => props.theme.fonts.pinPopupBody};
      font-size: ${(props): string => props.theme.fontSizes.p0};
      line-height: ${(props): string => props.theme.lineHeights.formSubheading};
      font-weight:${(props): number => props.theme.fontWeights.captionSmall};

    }

    .popconfirm__checkbox-label {
      // Fix font baseline
      margin-top: 2px;
      margin-bottom: -2px;
    }
    
    .ant-btn-default {
      width: fit-content;
      height: 40px;
      font-family: ${(props): string => props.theme.fonts.captionBold};
      font-size: ${(props): string => props.theme.fontSizes.pinTitle};
      line-height: ${(props): string => props.theme.lineHeights.formSubheading};
      font-weight:${(props): number => props.theme.fontWeights.captionSmall};
      padding: 8px 16px;
    }
    
    .ant-btn-primary {
      width: fit-content;
      height: 40px;
      font-family: ${(props): string => props.theme.fonts.captionBold};
      font-size: ${(props): string => props.theme.fontSizes.pinTitle};
      line-height: ${(props): string => props.theme.lineHeights.formSubheading};
      font-weight:${(props): number => props.theme.fontWeights.captionSmall};
      padding: 8px 16px;
    }
  }
`;
