import styled from 'styled-components';
import { Layout } from 'antd';

const { Header } = Layout;

export const StyledAppHeader = styled(Header)`
  background-color: #fff;
  height: auto;
  padding: 0;

  .header__top {
    display: flex;
    align-items: center;
    height: 94px;
    padding: 0 72px;
    justify-content: space-between;
    border-bottom: 6px solid;
    border-color: ${(props): string => props.theme.colors.gray};
  }
`;
