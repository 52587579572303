import React from 'react';
import classnames from 'classnames';
import { ReactComponent as arrowIcon } from '../../assets/images/icons/Arrow_Icon.svg';
import { ReactComponent as deleteIcon } from '../../assets/images/icons/Delete_icon.svg';
import { ReactComponent as editIcon } from '../../assets/images/icons/Edit_icon.svg';
import { ReactComponent as homeIcon } from '../../assets/images/icons/Home_icon.svg';
import { ReactComponent as notificationsIcon } from '../../assets/images/icons/Notifications_icon.svg';
import { ReactComponent as permissionsIcon } from '../../assets/images/icons/Permissions_icon.svg';
import { ReactComponent as reportsIcon } from '../../assets/images/icons/Reports_icon.svg';
import { ReactComponent as sitesIcon } from '../../assets/images/icons/Sites_icon.svg';
import { ReactComponent as infoIcon } from '../../assets/images/icons/Info_icon.svg';
import { ReactComponent as forbiddenIcon } from '../../assets/images/icons/Forbidden_icon.svg';
import { ReactComponent as plusIcon } from '../../assets/images/icons/Plus_icon.svg';
import { ReactComponent as warningInformationIcon } from '../../assets/images/icons/WarningInformation_icon.svg';
import { ReactComponent as successIcon } from '../../assets/images/icons/Success_icon.svg';
import { ReactComponent as errorIcon } from '../../assets/images/icons/Error_icon.svg';
import '../../assets/fonts/icons/css/kaercher_system_icons.css';
import '../../assets/fonts/icons/css/kaercher_system_icons-codes.css';
import { ISvgIconName } from './interfaces/SvgIconNames';
import { StyledIcon } from './SvgIcon.styles';

export interface ISvgIconProps {
  name: ISvgIconName;
  className?: string;
  verticalAlign?: number;
}

/**
 * SvgIcon component
 *
 * @param props - Icon props
 * @param props.name - Icon name
 * @param props.className - Additional class name to add to the icon
 * @param props.verticalAlign - Vertical align offset in px to apply to the icon
 * @constructor
 */
export const SvgIcon: React.FC<ISvgIconProps> = ({ name, className, verticalAlign = 0 }) => {
  const iconClassName = classnames('svg-icon', className, name);

  let IconComponent;

  switch (name) {
    case 'arrow': {
      IconComponent = arrowIcon;
      break;
    }

    case 'delete': {
      IconComponent = deleteIcon;
      break;
    }

    case 'edit': {
      IconComponent = editIcon;
      break;
    }

    case 'home': {
      IconComponent = homeIcon;
      break;
    }

    case 'notifications': {
      IconComponent = notificationsIcon;
      break;
    }

    case 'permissions': {
      IconComponent = permissionsIcon;
      break;
    }

    case 'reports': {
      IconComponent = reportsIcon;
      break;
    }

    case 'sites': {
      IconComponent = sitesIcon;
      break;
    }

    case 'info': {
      IconComponent = infoIcon;
      break;
    }

    case 'forbidden': {
      IconComponent = forbiddenIcon;
      break;
    }

    case 'plus': {
      IconComponent = plusIcon;
      break;
    }

    case 'warningInformation': {
      IconComponent = warningInformationIcon;
      break;
    }

    case 'success': {
      IconComponent = successIcon;
      break;
    }

    case 'error': {
      IconComponent = errorIcon;
      break;
    }

    default: {
      throw new Error(`Icon identifier ${name} is not supported.`);
    }
  }

  return (
    <StyledIcon className={iconClassName} verticalAlign={verticalAlign}>
      <IconComponent />
    </StyledIcon>
  );
};
