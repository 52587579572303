import { User } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export class userDetailsSelectors {
  public static selectUserDetails = (state: IState): Optional<User> =>
    state.modules['user-management']['user-details'].data;

  public static selectIsUserDetailLoading = (state: IState): boolean =>
    state.modules['user-management']['user-details'].isLoading;

  public static selectResendInvitationData = (state: IState): Optional<string> =>
    state.modules['user-management']['user-details'].invitationRevokedOn;
}
