import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { inject, injectable } from 'tsyringe';
import {
  MachineClaimResponse,
  MachineListResponse,
  MachinePairingSearchResponse,
  MachinePairingResponse,
  MachineSearchMaterialNumberResponse,
  MachineFindResponse,
  MachineUnclaimResponse,
} from './interfaces/Machine.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  InputMachinePairingSearch,
  ListAlgNumberResponse,
  MachineClaimMutation,
  MachinePairingMutation,
  MutationAdminMachineClaimArgs,
  MutationUnclaimMachineArgs,
  QueryListClaimedMachinesArgs,
  QuerySearchMaterialNumberArgs,
  ValidateMachineInput,
} from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export type MachineClientClaimOptions = MachineClaimMutation;
export type MachineClientReclaimOptions = MutationAdminMachineClaimArgs;
export type MachineClientListOptions = QueryListClaimedMachinesArgs;
export type MachineClientSearchMaterialOptions = QuerySearchMaterialNumberArgs;
export type MachineClientPairSearchOptions = InputMachinePairingSearch;
export type MachineClientPairOptions = MachinePairingMutation;
export type MachineClientFindOptions = ValidateMachineInput;
export type MachineClientUnclaimOptions = MutationUnclaimMachineArgs;

@injectable()
export class MachineClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}

  public claim = async ({
    algNumber,
    customerId,
    materialNumber,
    serialNumber,
  }: MachineClientClaimOptions): Promise<FetchResult<MachineClaimResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation adminMachineClaim($input: MachineClaimMutation) {
          adminMachineClaim(input: $input) {
            data {
              materialNumber
              serialNumber
              customerNumber
              customerName
              customerId
              algNumber
              dateOfClaim
              type
            }
          }
        }
      `,
      variables: {
        input: {
          algNumber,
          customerId,
          materialNumber,
          serialNumber,
        },
      },
    });

  public pair = async ({
    gatewayMaterialNumber,
    gatewaySerialNumber,
    machineMaterialNumber,
    machineSerialNumber,
    operatingHours,
  }: MachineClientPairOptions): Promise<FetchResult<MachinePairingResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation machinePairing($input: MachinePairingMutation) {
          machinePairing(input: $input) {
            data {
              gatewayMaterialNumber
              gatewaySerialNumber
              machineMaterialNumber
              machineSerialNumber
              customerName
              algNumber
              customerNumber
              gatewayType
              machineType
            }
          }
        }
      `,
      variables: {
        input: {
          gatewayMaterialNumber,
          gatewaySerialNumber,
          machineMaterialNumber,
          machineSerialNumber,
          operatingHours,
        },
      },
    });

  public pairSearch = async ({
    gatewayMaterialNumber,
    gatewaySerialNumber,
    machineMaterialNumber,
    machineSerialNumber,
  }: MachineClientPairSearchOptions): Promise<ApolloQueryResult<MachinePairingSearchResponse>> =>
    this.client.query({
      query: gql`
        query MachinePairingSearch($input: InputMachinePairingSearch) {
          machinePairingSearch(input: $input) {
            data {
              gatewayPairedMaterialNumber
              gatewayPairedSerialNumber
              machinePairedMaterialNumber
              machinePairedSerialNumber
            }
          }
        }
      `,
      variables: {
        input: {
          gatewayMaterialNumber,
          gatewaySerialNumber,
          machineMaterialNumber,
          machineSerialNumber,
        },
      },
    });

  public listAlgs = async (): Promise<ApolloQueryResult<ListAlgNumberResponse>> =>
    this.client.query({
      query: gql`
        query ListAlgNumber {
          listAlgNumber {
            data
          }
        }
      `,
    });

  public list = async ({
    search,
    paginationOptions,
    sortOptions,
    filter,
  }: MachineClientListOptions): Promise<ApolloQueryResult<MachineListResponse>> =>
    this.client.query({
      query: gql`
        query listClaimedMachines(
          $search: String
          $filter: ClaimedMachineFilter
          $sortOptions: InputSortOptions
          $paginationOptions: InputPagingOptions
        ) {
          listClaimedMachines(
            search: $search
            filter: $filter
            sortOptions: $sortOptions
            paginationOptions: $paginationOptions
          ) {
            data {
              id
              materialNumber
              serialNumber
              algNumber
              customerNumber
              dateOfClaim
              type
              customerId
              customerName
              machineName
            }
            metadata {
              totalCount
              paginationToken
            }
          }
        }
      `,
      variables: {
        search,
        filter,
        sortOptions,
        paginationOptions,
      },
    });

  // TODO: later change to use new api for reclaim => curr using admin claim
  public reClaim = async ({ input }: MachineClientReclaimOptions): Promise<FetchResult<MachineClaimResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation adminMachineClaim($input: MachineClaimMutation) {
          adminMachineClaim(input: $input) {
            data {
              materialNumber
              serialNumber
              customerNumber
              customerId
              algNumber
              dateOfClaim
              type
            }
          }
        }
      `,
      variables: {
        input,
      },
    });

  public searchMaterialNumber = async ({
    search,
    filter,
    paginationOptions,
  }: MachineClientSearchMaterialOptions): Promise<ApolloQueryResult<MachineSearchMaterialNumberResponse>> =>
    this.client.query({
      query: gql`
        query searchMaterialNumber(
          $search: String!
          $filter: SearchMaterialNumberFilterInput
          $paginationOptions: InputPagingOptions
        ) {
          searchMaterialNumber(search: $search, filter: $filter, paginationOptions: $paginationOptions) {
            data {
              materialNumber
              classification
              isPairable
            }
          }
        }
      `,
      variables: {
        search,
        filter,
        paginationOptions,
      },
    });

  public find = async ({
    materialNumber,
    serialNumber,
  }: MachineClientFindOptions): Promise<ApolloQueryResult<MachineFindResponse>> =>
    this.client.query({
      query: gql`
        query findMachine($input: ValidateMachineInput!) {
          validateMachine(input: $input) {
            data {
              algNumber
              customerName
              customerNumber
              machineIsClaimed
              materialNumber
              serialNumber
              type
            }
          }
        }
      `,
      variables: {
        input: {
          materialNumber,
          serialNumber,
        },
      },
    });

  public unClaim = async ({ input }: MachineClientUnclaimOptions): Promise<FetchResult<MachineUnclaimResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation UnclaimMachine($input: UnclaimMachineInput) {
          unclaimMachine(input: $input) {
            data {
              materialNumber
              serialNumber
              customerNumber
              customerId
              algNumber
              dateOfClaim
              type
            }
          }
        }
      `,
      variables: {
        input,
      },
    });
}
