import styled from 'styled-components';
import { Layout } from 'antd';

const { Footer } = Layout;

export const StyledAppFooter = styled(Footer)`
  padding: 0;
  background-color: ${(props): string => props.theme.colors.white};
  font-size: 11px;

  .footer__banner {
    padding: 1rem;
    background-color: ${(props): string => props.theme.colors.semiGray};
    text-align: center;
    & .footer__product-name {
      font-family: ${(props): string => props.theme.fonts.pageTitle};
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .footer__copyright {
    text-align: center;
    padding: 1rem;
    background-color: ${(props): string => props.theme.colors.white};
  }

  @media screen and (max-width: 1406px) {
    &.ant-layout-footer:not(.footer--full-width) {
      margin-left: 175px;
    }
  }
`;
