import { createSlice } from '@reduxjs/toolkit';
import { CustomerDetailsTab } from '../../interfaces/Customer.types';
import {
  CustomerDetailsGetErrorAction,
  CustomerDetailsGetRequestAction,
  CustomerDetailsGetSuccessAction,
  CustomerDetailsSetActiveTabAction,
} from './customerDetailsActions.types';
import { Optional } from 'lib/types/Optional';
import { CustomerManagementEntity } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export interface CustomerDetailsState {
  data: Optional<CustomerManagementEntity>;
  isLoading: Optional<boolean>;
  activeTab: Optional<CustomerDetailsTab>;
}

export const initialState: CustomerDetailsState = {
  data: null,
  isLoading: null,
  activeTab: CustomerDetailsTab.DETAILS,
};

export const customerDetailsSlice = createSlice({
  name: 'customer-details',
  initialState,
  reducers: {
    getCustomerDetailsRequest: (state, _action: CustomerDetailsGetRequestAction) => {
      state.isLoading = true;

      return state;
    },
    getCustomerDetailsSuccess: (state, action: CustomerDetailsGetSuccessAction) => {
      state.data = action.payload?.getCustomer.data || initialState.data;
      state.isLoading = false;

      return state;
    },
    getCustomerDetailsError: (state, _action: CustomerDetailsGetErrorAction) => {
      state.isLoading = false;

      return state;
    },

    getCustomerDetailsActiveTab: (state, action: CustomerDetailsSetActiveTabAction) => {
      state.activeTab = action.payload.activeTab;

      return state;
    },
  },
});

export const customerDetailsActions = customerDetailsSlice.actions;
export const customerDetailsReducer = customerDetailsSlice.reducer;
