import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { inject, injectable } from 'tsyringe';
import { ReassignUserToCustomerResponse, UserDeleteResponse } from '../user-management/interfaces/User.types';
import { UserClientDeleteOptions, UserClientReassignOptions } from '../user-management/UserClient';
import { MachineClientReclaimOptions, MachineClientUnclaimOptions } from '../machine-directory/MachineClient';
import { MachineClaimResponse, MachineUnclaimResponse } from '../machine-directory/interfaces/Machine.types';
import {
  CustomerListAlgNumbersResponse,
  CustomerListResponse,
  CustomerCreateResponse,
  CustomerValidateCreateResponse,
  CustomerGetResponse,
  CustomerUpdateResponse,
  CustomerDeleteResponse,
} from './interfaces/Customer.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  QuerySearchCustomersArgs,
  CreateCustomerInput,
  QueryValidateCreateArgs,
  QueryGetCustomerArgs,
  UpdateCustomerInput,
  DeleteCustomerInput,
} from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export type CustomerClientListOptions = QuerySearchCustomersArgs;
export type CustomerClientCreateOptions = CreateCustomerInput;
export type CustomerClientValidateOptions = QueryValidateCreateArgs;
export type CustomerClientGetOptions = QueryGetCustomerArgs;
export type CustomerClientUpdateOptions = UpdateCustomerInput;
export type CustomerClientDeleteOptions = DeleteCustomerInput;

@injectable()
export class CustomerClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}

  public list = async ({
    filter,
    sortOptions,
    paginationOptions,
    search,
  }: CustomerClientListOptions): Promise<ApolloQueryResult<CustomerListResponse>> =>
    this.client.query({
      query: gql`
        query searchCustomers(
          $filter: CustomerFilter
          $paginationOptions: InputPagingOptions
          $sortOptions: InputSortOptions
          $search: String
        ) {
          searchCustomers(
            filter: $filter
            paginationOptions: $paginationOptions
            sortOptions: $sortOptions
            search: $search
          ) {
            metadata {
              paginationToken
              totalCount
            }
            data {
              alg
              city
              country
              createdAt
              customerId
              customerName
              district
              houseNumber
              region
              sapNumber
              street
              updatedAt
              zipCode
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        paginationOptions,
        search,
      },
    });

  public listAlgs = async (): Promise<ApolloQueryResult<CustomerListAlgNumbersResponse>> =>
    this.client.query({
      query: gql`
        query listAlgNumber {
          listAlgNumber {
            data
          }
        }
      `,
    });

  public create = async ({
    alg,
    city,
    customerName,
    district,
    houseNumber,
    region,
    sapNumber,
    street,
    zipCode,
  }: CustomerClientCreateOptions): Promise<FetchResult<CustomerCreateResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation createCustomer($input: CreateCustomerInput!) {
          createCustomer(input: $input) {
            data {
              alg
              city
              country
              createdAt
              customerId
              customerName
              district
              houseNumber
              region
              sapNumber
              street
              updatedAt
              zipCode
            }
          }
        }
      `,
      variables: {
        input: {
          alg,
          city,
          customerName,
          district,
          houseNumber,
          region,
          sapNumber,
          street,
          zipCode,
        },
      },
    });

  public validateCreate = async ({
    customerName,
    sapNumber,
  }: CustomerClientValidateOptions): Promise<ApolloQueryResult<CustomerValidateCreateResponse>> =>
    this.client.query({
      query: gql`
        query validateCreate($customerName: String!, $sapNumber: String!) {
          validateCreate(customerName: $customerName, sapNumber: $sapNumber) {
            data {
              isDuplicate
            }
          }
        }
      `,
      variables: {
        customerName,
        sapNumber,
      },
    });

  public get = async ({ id }: CustomerClientGetOptions): Promise<ApolloQueryResult<CustomerGetResponse>> =>
    this.client.query({
      query: gql`
        query GetCustomer($id: ID!) {
          getCustomer(id: $id) {
            data {
              alg
              city
              country
              createdAt
              customerId
              customerName
              district
              houseNumber
              region
              sapNumber
              street
              updatedAt
              zipCode
            }
          }
        }
      `,
      variables: { id },
    });

  public update = async ({
    alg,
    city,
    customerId,
    customerName,
    district,
    houseNumber,
    region,
    sapNumber,
    street,
    zipCode,
  }: UpdateCustomerInput): Promise<FetchResult<CustomerUpdateResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation UpdateCustomer($input: UpdateCustomerInput!) {
          updateCustomer(input: $input) {
            data {
              alg
              city
              country
              createdAt
              customerId
              customerName
              district
              houseNumber
              region
              sapNumber
              street
              updatedAt
              zipCode
            }
          }
        }
      `,
      variables: {
        input: {
          alg,
          city,
          customerId,
          customerName,
          district,
          houseNumber,
          region,
          sapNumber,
          street,
          zipCode,
        },
      },
    });

  public delete = async ({ customerId }: CustomerClientDeleteOptions): Promise<FetchResult<CustomerDeleteResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation DeleteCustomer($input: DeleteCustomerInput!) {
          deleteCustomer(input: $input) {
            data
          }
        }
      `,
      variables: {
        input: {
          customerId,
        },
      },
    });

  public reassignUser = async ({
    input,
  }: UserClientReassignOptions): Promise<FetchResult<ReassignUserToCustomerResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation CustomerReassignUser($input: ReassignUserToCustomerInput) {
          reassignUserToCustomer(input: $input) {
            data {
              country
              customerId
              customerName
              customerNumber
              userId
            }
          }
        }
      `,
      variables: {
        input,
      },
    });

  public deleteUser = async ({ input }: UserClientDeleteOptions): Promise<FetchResult<UserDeleteResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation CustomerDeleteUser($input: DeleteUserInput) {
          deleteUser(input: $input)
        }
      `,
      variables: {
        input,
      },
    });

  public reClaim = async ({ input }: MachineClientReclaimOptions): Promise<FetchResult<MachineClaimResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation CustomerReClaimMachines($input: MachineClaimMutation) {
          adminMachineClaim(input: $input) {
            data {
              materialNumber
              serialNumber
              customerNumber
              customerId
              algNumber
              dateOfClaim
              type
            }
          }
        }
      `,
      variables: {
        input,
      },
    });

  public unClaim = async ({ input }: MachineClientUnclaimOptions): Promise<FetchResult<MachineUnclaimResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation CustomerUnclaimMachines($input: UnclaimMachineInput) {
          unclaimMachine(input: $input) {
            data {
              materialNumber
              serialNumber
              customerNumber
              customerId
              algNumber
              dateOfClaim
              type
            }
          }
        }
      `,
      variables: {
        input,
      },
    });
}
