import { singleton } from 'tsyringe';
import { Optional } from '../../../lib/types/Optional';
import {
  MachineClaimResponse,
  MachineListResponse,
  MachinePairingResponse,
  MachinePairingSearchResponse,
  MachineSearchMaterialNumberResponse,
  MachineFindResponse,
  MachineUnclaimResponse,
} from './interfaces/Machine.types';
import {
  MachineClient,
  MachineClientClaimOptions,
  MachineClientFindOptions,
  MachineClientListOptions,
  MachineClientPairOptions,
  MachineClientPairSearchOptions,
  MachineClientReclaimOptions,
  MachineClientSearchMaterialOptions,
  MachineClientUnclaimOptions,
} from './MachineClient';
import { ListAlgNumberResponse } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export type MachineServiceClaimOptions = MachineClientClaimOptions;
export type MachineServiceListOptions = MachineClientListOptions;
export type MachineServiceReclaimOptions = MachineClientReclaimOptions;
export type MachineServiceSearchMaterialNumberOptions = MachineClientSearchMaterialOptions;
export type MachineServiceFindOptions = MachineClientFindOptions;
export type MachineServicePairSearchOptions = MachineClientPairSearchOptions;
export type MachineServicePairOptions = MachineClientPairOptions;
export type MachineServiceUnclaimOptions = MachineClientUnclaimOptions;

@singleton()
export class MachineService {
  constructor(private machineClient: MachineClient) {}

  public claim = async ({
    algNumber,
    customerId,
    materialNumber,
    serialNumber,
  }: MachineServiceClaimOptions): Promise<Optional<MachineClaimResponse>> => {
    const { data } = await this.machineClient.claim({
      algNumber,
      customerId,
      materialNumber,
      serialNumber,
    });
    return data;
  };

  public list = async ({
    search,
    paginationOptions,
    sortOptions,
    filter,
  }: MachineServiceListOptions): Promise<MachineListResponse> => {
    const { data } = await this.machineClient.list({ search, paginationOptions, sortOptions, filter });

    return data;
  };

  public listAlgs = async (): Promise<ListAlgNumberResponse> => {
    const { data } = await this.machineClient.listAlgs();

    return data;
  };

  public reClaim = async ({ input }: MachineServiceReclaimOptions): Promise<Optional<MachineClaimResponse>> => {
    const { data } = await this.machineClient.reClaim({
      input,
    });
    return data;
  };

  public searchMaterialNumber = async ({
    search,
    filter,
    paginationOptions,
  }: MachineServiceSearchMaterialNumberOptions): Promise<Optional<MachineSearchMaterialNumberResponse>> => {
    const { data } = await this.machineClient.searchMaterialNumber({
      search,
      filter,
      paginationOptions,
    });
    return data;
  };

  public pairSearch = async ({
    gatewayMaterialNumber,
    gatewaySerialNumber,
    machineMaterialNumber,
    machineSerialNumber,
  }: MachineServicePairSearchOptions): Promise<Optional<MachinePairingSearchResponse>> => {
    const { data } = await this.machineClient.pairSearch({
      gatewayMaterialNumber,
      gatewaySerialNumber,
      machineMaterialNumber,
      machineSerialNumber,
    });

    return data;
  };

  public pair = async ({
    gatewayMaterialNumber,
    gatewaySerialNumber,
    machineMaterialNumber,
    machineSerialNumber,
    operatingHours,
  }: MachineServicePairOptions): Promise<Optional<MachinePairingResponse>> => {
    const { data } = await this.machineClient.pair({
      gatewayMaterialNumber,
      gatewaySerialNumber,
      machineMaterialNumber,
      machineSerialNumber,
      operatingHours,
    });
    return data;
  };

  public find = async ({
    materialNumber,
    serialNumber,
  }: MachineServiceFindOptions): Promise<Optional<MachineFindResponse>> => {
    const { data } = await this.machineClient.find({
      materialNumber,
      serialNumber,
    });

    return data;
  };

  public unClaim = async ({ input }: MachineServiceUnclaimOptions): Promise<Optional<MachineUnclaimResponse>> => {
    const { data } = await this.machineClient.unClaim({
      input,
    });

    return data;
  };
}
