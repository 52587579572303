import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export class deleteCustomerSelector {
  public static selectDeleteUser = (
    state: IState
  ): {
    [id: string]: {
      loading: boolean;
      error: Optional<string>;
      completed: boolean;
    };
  } => state.modules['customer-management']['customer-delete'].deleteUsers;

  public static selectReassignUser = (
    state: IState
  ): {
    [id: string]: {
      loading: boolean;
      error: Optional<string>;
      completed: boolean;
    };
  } => state.modules['customer-management']['customer-delete'].reassignUsers;

  public static selectReclaimDevices = (
    state: IState
  ): {
    [id: string]: {
      loading: boolean;
      error: Optional<string>;
      completed: boolean;
    };
  } => state.modules['customer-management']['customer-delete'].reclaimDevices;

  public static selectUnclaimDevices = (
    state: IState
  ): {
    [id: string]: {
      loading: boolean;
      error: Optional<string>;
      completed: boolean;
    };
  } => state.modules['customer-management']['customer-delete'].unclaimDevices;
}
