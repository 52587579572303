import { createGlobalStyle } from 'styled-components';

export const MaterialNumberAutoCompleteGlobalStyles = createGlobalStyle`
    .auto-complete__dropdown-text {
      font-size: ${(props): string => props.theme.fontSizes.formBody};
      margin: 5px 0;

      .auto-complete__words-match {
        color: ${(props): string => props.theme.colors.black};
      }
      
      .auto-complete__words-not-match {
        color: ${(props): string => props.theme.colors.textGray};
      }
    }
`;
