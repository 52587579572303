import { DefaultTheme } from 'styled-components';

export const theme: DefaultTheme = {
  fonts: {
    pageTitle: 'ClanWebPro-NarrBlack',
    body: 'ClanWebPro-Medium',
    menu: 'ClanWebPro-News',
    heading: 'ClanWebPro-Bold',
    formTitle: 'ClanWebPro-Bold',
    formBody: 'ClanWebPro-News',
    formSubheading: 'ClanWebPro-Bold',
    formLabel: 'ClanWebPro-Bold',
    filterLabel: 'ClanWebPro-Bold',
    button: 'ClanWebPro-NarrBold',
    pagination: 'ClanWebPro-News',
    breadcrumbs: 'ClanWebPro-Medium',
    modalTitle: 'ClanWebPro-NarrBlack',
    modalSubtitle: 'ClanWebPro-Medium',
    modalBody: 'ClanWebPro-News',
    notificationErrorTitle: 'ClanWebPro-Bold',
    notificationErrorBody: 'ClanWebPro-News',
    operatingHoursNumber: 'ClanWebPro-Bold-NarrBlack',
    pinPopupTitle: 'ClanWebPro-Medium',
    pinPopupBody: 'ClanWebPro-News',
    caption: 'ClanWebPro-Medium',
    captionBold: 'ClanWebPro-Bold',
    captionMedium: 'ClanWebPro-Medium',
    buttonM: 'ClanWebPro-Bold',
  },
  fontSizes: {
    pageTitle: '30px',
    pageDescription: '13px',
    formTitle: '11px',
    formBody: '13px',
    formSubheading: '16px',
    formLabel: '11px',
    filterLabel: '11px',
    pinTitle: '16px',
    pinDescription: '13px',
    pagination: '11px',
    breadcrumbs: '11px',
    modalTitle: '30px',
    modalSubtitle: '16px',
    modalBody: '13px',
    notificationErrorTitle: '16px',
    notificationErrorBody: '13px',
    pinPopupTitle: '11px',
    pinPopupBody: '11px',
    buttonTitle: '15px',
    caption: '11px',
    p0: '14px',
    p1: '12px',
    stepTitle: '18px',
    stepNumber: '14px',
    buttonM: '16px',
  },
  colors: {
    primary: 'var(--primary-color)',
    primaryHover: 'var(--primary-hover-color)',
    text: 'var(--text-color)',
    textGray: 'var(--text-color-gray)',
    textRgb: 'var(--text-rgb-color)',
    gray: 'var(--gray-color)',
    semiGray: 'var(--semi-gray-color)',
    lightGray: 'var(--light-gray-color)',
    border: 'var(--border-color)',
    white: 'var(--white-color)',
    buttonHover: 'var(--button-hover-color)',
    black: 'var(--black-color)',
    red: 'var(--red-color)',
    green: 'var(--green-color)',
    celadonGreen: 'var(--celadon-green-color)',
    lightCeladonGreen: 'var(--light-celadon-green-color)',
    orange: 'var(--orange-color)',
    apple: 'var(--apple-color)',
    focusBorder: 'var(--focus-border-color)',
    buttonBorder: 'var(--button-border-color)',
    headerGray: 'var(--header-gray-color)',
    paginationGray: 'var(--pagination-gray-color)',
    inputBg: 'var(--input-bg-color)',
    circleGeofenceBgColor: 'var(--circle-geofence-bg-color)',
    circleGeofenceBorderColor: 'var(--circle-geofence-border-color)',
    userStatusColor: 'var(--user-status-color)',
    darkGrey: 'var(--dark-grey)',
    brightGrey: 'var(--bright-grey)',
    neutralWhiteColor: 'var(--neutral-white-color)',
    stepsBackgroundColor: 'var(--steps-background-color)',
    errorExplainColor: 'var(--error-explain-color)',
    green100: 'var(--green-100-color)',
    gray100: 'var(--gray-100-color)',
    tertiary: 'var(--tertiary-color)',
  },
  fontWeights: {
    captionBold: 700,
    p1Medium: 500,
    captionSmall: 475,
    pageTitle: 900,
    buttonM: 700,
  },
  lineHeights: {
    caption: '14px',
    p1: '16px',
    pageTitle: '38px',
    formSubheading: '24px',
    buttonM: '24px',
    p: '20px',
    heading: '36px',
  },
  mixins: {
    truncateText: `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    `,
  },
};
