export const getDecodedFromURL = (queryParamKey: string, splitKeyword: string): string[] => {
  const queryString = window.location.search;
  const encodedUserInfo = new URLSearchParams(queryString).get(queryParamKey);
  if (queryString && encodedUserInfo) {
    const decodedUserInfo = Buffer.from(encodedUserInfo as string, 'base64').toString('utf8');
    return decodedUserInfo.split(splitKeyword);
  }

  return [];
};
