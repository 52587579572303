import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledCustomerSelectedTable } from './CustomerSelectedTable.styles';
import { getCustomerListSelectedColumns } from './column/CustomerSelected.column';
import { Table } from 'lib/components/Table/Table';
import { Customer } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export const CustomerSelectedTable = ({
  customerList,
  onSelectCustomer,
}: {
  customerList: Customer[];
  onSelectCustomer: (selectedRowKeys: React.Key[]) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const tableColumns = getCustomerListSelectedColumns(t);

  return (
    <StyledCustomerSelectedTable>
      <div className="machine-selection__body">
        <div className="machine-selection__body-content">
          <Table
            className="machine-selection__table"
            loading={false}
            columns={tableColumns}
            dataSource={customerList}
            rowKey="customerId"
            rowSelection={{
              columnTitle: t('machineList.table.selected'),
              onChange: onSelectCustomer,
              type: 'radio',
            }}
          />
        </div>
      </div>
    </StyledCustomerSelectedTable>
  );
};
