import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Customer } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export class userInviteSelectors {
  public static selectIsUserInviteLoading = (state: IState): boolean =>
    state.modules['user-management']['user-invite'].isLoading;

  public static selectCustomerSearchList = (state: IState): Customer[] =>
    state.modules['user-management']['customer-search'].customer.list;

  public static selectIsCustomerSearchLoading = (state: IState): boolean =>
    state.modules['user-management']['customer-search'].customer.isLoading;
}
