import React, { useEffect } from 'react';
import { Col, Row, TablePaginationConfig } from 'antd';
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash-es';
import { SelectValue } from 'antd/lib/select';
import { SearchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { Select } from '../../../../../../lib/components/Select/Select';
import { Permission } from '../../../../../../config/permissions';
import { PermissionGuard } from '../../../../../cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import {
  CustomerEntity,
  CustomerManagementEntity,
  SortOrders,
} from '../../../../../cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { customerListSelector } from '../../state/customerListSelectors';
import { CustomerListActions } from '../../state/customerListSlice';
import { customerModalsActions } from '../../../modals/state/customerModalsSlice';
import { CustomerCreateModal } from '../../../modals/components/CustomerCreateModal/CustomerCreateModal';
import { Optional } from '../../../../../../lib/types/Optional';
import { CustomerConfirmModal } from '../../../modals/components/CustomerConfirmModal/CustomerConfirmModal';
import { customerModalSelector } from '../../../modals/state/customerModalsSelectors';
import { StyledCustomerList } from './CustomerList.styles';
import { getCustomerListColumns } from './columns/CustomerList.columns';
import { RoutePaths } from 'config/route-paths';
import { Table } from 'lib/components/Table/Table';
import { AutoCompleteInput } from 'lib/components/AutoCompleteInput/AutoCompleteInput';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { ReactComponent as PlusIcon } from 'lib/assets/images/icons/Plus_icon.svg';

const { Option } = Select;

export const CustomerList = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data = useSelector(customerListSelector.selectData) || [];
  const totalCount = useSelector(customerListSelector.selectTotalCount) || 0;
  const paginationTokens = useSelector(customerListSelector.selectPaginationTokens);
  const page = useSelector(customerListSelector.selectPage);
  const pageSize = useSelector(customerListSelector.selectPageSize);
  const algs = useSelector(customerListSelector.selectAlgsFilters);
  const isLoading = !!useSelector(customerListSelector.selectIsLoading);
  const isFilterLoading = useSelector(customerListSelector.selectIsFiltersLoading);
  const activeAlgFilter = useSelector(customerListSelector.selectActiveAlgFilter);
  const sortField = useSelector(customerListSelector.selectSortField) || 'createdAt';
  const sortOrder = useSelector(customerListSelector.selectSortOrder) || SortOrders.Desc;
  const searchText = useSelector(customerListSelector.selectSearchText);
  const isDeleteData = useSelector(customerModalSelector.selectIsDeleteCustomerModalData);

  useEffect(() => {
    dispatch(CustomerListActions.customerListAlgsRequest());
  }, [dispatch]);

  useEffect(
    () => (): void => {
      dispatch(CustomerListActions.resetState());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!isDeleteData) {
      dispatch(
        CustomerListActions.customerListRequest({
          filter: {
            alg: activeAlgFilter || '',
          },
          search: searchText || '',
          sortOptions: {
            field: sortField,
            order: sortOrder,
          },
          paginationOptions: {
            limit: pageSize || 50,
            paginationToken: paginationTokens[page] || '',
          },
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, sortOrder, sortField, searchText, activeAlgFilter, isDeleteData]);

  const onPageChange = (value: number): void => {
    dispatch(CustomerListActions.setChangeTablePage(value));
  };

  const onPageLimitChange = (value: number): void => {
    dispatch(CustomerListActions.setChangeTablePageSize(value));
  };

  const handleAlgFilterChange = (value: SelectValue): void => {
    dispatch(CustomerListActions.setAlgFilter(value as string));
  };

  const handleSearchTextChange = debounce((e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(CustomerListActions.setActiveSearchText(e.target.value));
  }, 500);

  const handleOpenCustomerCreateModal = (): void => {
    dispatch(customerModalsActions.openCreateCustomerModal());
    dispatch(customerModalsActions.closeConfirmCustomerModal());
  };

  const onChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<CustomerEntity> | SorterResult<CustomerEntity>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    let sorterOrder = SortOrders.Asc;
    if (sorter.order === 'descend') sorterOrder = SortOrders.Desc;
    dispatch(CustomerListActions.setChangeSortField(sorter.field as Optional<string>));
    dispatch(CustomerListActions.setChangeSortOrder(sorterOrder as Optional<SortOrders>));
  };

  const tableColumns = getCustomerListColumns({ t });

  const handleClickTableRow = ({ id }: { id: Optional<string> }): void => {
    if (!id) return;
    navigate(RoutePaths.CUSTOMERS_DETAILS.replace(':id', id));
  };

  return (
    <StyledCustomerList>
      <div className="customer-list__header">
        <div className="customer-list__header-content">
          <div className="customer-list__page-info">
            <h1 className="customer-list__title">{t('customerList.title').toLocaleUpperCase()}</h1>
          </div>

          <div className="customer-list__filter-wrapper">
            <div className="customer-list__filter">
              <div className="customer-list__select-wrapper">
                <span className="customer-list__filter-label">{t('customerList.filter.ALG')}</span>
                <Select
                  defaultValue={activeAlgFilter || ''}
                  className="customer-list__select"
                  loading={isFilterLoading}
                  onChange={handleAlgFilterChange}
                  showSearch
                >
                  <Option value="">{t('common.all')}</Option>
                  {algs?.map(alg => (
                    <Option value={alg} key={`${alg} `}>
                      {alg}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="customer-list__action-buttons-wrapper">
              <PermissionGuard requiredPermissions={[Permission.Customer.User.SEND_INVITATION]}>
                <PrimaryButton
                  size="m"
                  className="customer-list__invitation-btn"
                  icon={<PlusIcon />}
                  onClick={handleOpenCustomerCreateModal}
                >
                  {t('customerList.createNewCustomer')}
                </PrimaryButton>
              </PermissionGuard>
            </div>
          </div>
        </div>
      </div>
      <div className="customer-list__body">
        <div className="customer-list__body-content">
          <Row gutter={[26, 26]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className="customer-list__search-input">
                <AutoCompleteInput
                  placeholder={t('customerList.searchInputHolder')}
                  className="customer-list__search-customer-input"
                  prefix={<SearchOutlined />}
                  onChange={handleSearchTextChange}
                />
              </div>
              <Table
                pagination={{
                  total: totalCount,
                  pageSize,
                  page,
                  onPageChange,
                  onPageLimitChange,
                  isLoading,
                }}
                onRow={(customerRowData: CustomerManagementEntity): { onClick(): void } => ({
                  onClick: (): void => {
                    handleClickTableRow({ id: customerRowData.customerId });
                  },
                })}
                rowKey="customerId"
                dataSource={data}
                loading={isLoading}
                className="customer-list__table"
                columns={tableColumns}
                onChange={onChange}
                sortDirections={['ascend', 'descend', 'ascend']}
              />
            </Col>
          </Row>
        </div>
      </div>
      <CustomerCreateModal />
      <CustomerConfirmModal />
    </StyledCustomerList>
  );
};
