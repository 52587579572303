import { MachinePairingSearchInfo } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { Optional } from 'lib/types/Optional';

export enum PairConditionEnum {
  BOTH_PAIRED = 'BOTH_PAIRED',
  BOTH_NOT_PAIRED = 'BOTH_NOT_PAIRED',
  GATEWAY_PAIRED = 'GATEWAY_PAIRED',
  MACHINE_PAIRED = 'MACHINE_PAIRED',
}

export const pairMachineGatewayCondition = ({
  gatewayPairedMaterialNumber,
  gatewayPairedSerialNumber,
  machinePairedMaterialNumber,
  machinePairedSerialNumber,
}: MachinePairingSearchInfo): Optional<PairConditionEnum> => {
  const isGatewayPaired = machinePairedMaterialNumber && machinePairedSerialNumber;
  const isMachinePaired = gatewayPairedMaterialNumber && gatewayPairedSerialNumber;

  if (isGatewayPaired && isMachinePaired) {
    return PairConditionEnum.BOTH_PAIRED;
  }

  if (isGatewayPaired) {
    return PairConditionEnum.GATEWAY_PAIRED;
  }

  if (isMachinePaired) {
    return PairConditionEnum.MACHINE_PAIRED;
  }

  return PairConditionEnum.BOTH_NOT_PAIRED;
};

export const getPairingMessage = (pairingSearchResult: MachinePairingSearchInfo): Optional<string> => {
  const machineGatewayCondition = pairMachineGatewayCondition(pairingSearchResult || {});

  switch (machineGatewayCondition) {
    case PairConditionEnum.BOTH_PAIRED: {
      return 'pairMachine.modal.descriptionBothIsAlreadyPaired';
    }
    case PairConditionEnum.MACHINE_PAIRED: {
      return 'pairMachine.modal.descriptionMachineIsAlreadyPaired';
    }
    case PairConditionEnum.GATEWAY_PAIRED: {
      return 'pairMachine.modal.descriptionModuleIsAlreadyPaired';
    }

    default:
      return null;
  }
};
