import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { UserStatus } from '../user-status-icon/UserStatus';
import { User } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { SortableColumnTitle } from 'lib/components/Table/SortableColumnTitle/SortableColumnTitle';

interface GetUserListColumnsOptions {
  t: TFunction;
  isSortingAndFilteringEnabled: boolean;
}

export const getUserListColumns = ({
  t,
  isSortingAndFilteringEnabled,
}: GetUserListColumnsOptions): ColumnsType<User> => [
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<User>(t('userList.table.name'), isSortingAndFilteringEnabled, 'fullName', sortColumns),
    dataIndex: 'fullName',
    key: 'fullName',
    width: '17%',
    sorter: isSortingAndFilteringEnabled,
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<User>(t('userList.table.email'), isSortingAndFilteringEnabled, 'email', sortColumns),
    dataIndex: 'email',
    key: 'email',
    width: '17%',
    sorter: isSortingAndFilteringEnabled,
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<User>(
        t('userList.table.customerName'),
        isSortingAndFilteringEnabled,
        'customerName',
        sortColumns
      ),
    dataIndex: 'customerName',
    key: 'customerName',
    width: '30%',
    sorter: isSortingAndFilteringEnabled,
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<User>(t('userList.table.status'), isSortingAndFilteringEnabled, 'status', sortColumns),
    dataIndex: 'status',
    key: 'status',
    width: '5%',
    sorter: isSortingAndFilteringEnabled,
    render: (status: string): JSX.Element | null =>
      status ? (
        <div className="user-list__user-status-icon">
          <UserStatus status={status} />
        </div>
      ) : null,
  },
];
