import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from '@redux-devtools/extension';
import { applyMiddleware, combineReducers, compose, createStore, Store as IStore } from 'redux';
import { ICreateReduxStoreOptions } from './interfaces/StateManagement.types';
import { reducers } from './reducers';
import { sagas } from './sagas';
import { isFeatureEnabled } from 'lib/utils/feature-flags/isFeatureEnabled';

export class StateManagement {
  public static createReduxStore = ({ state = {}, dependencies }: ICreateReduxStoreOptions): IStore => {
    const sagaMiddleware = createSagaMiddleware({
      context: {
        dependencies,
      },
    });

    const composeEnhancers = isFeatureEnabled(process.env.REACT_APP_ENABLE_REDUX_DEV_TOOL)
      ? (composeWithDevTools({ trace: true, traceLimit: 25 }) as typeof compose)
      : compose;

    const rootReducer = combineReducers(reducers);

    const store = createStore(rootReducer, state, composeEnhancers(applyMiddleware(sagaMiddleware)));

    sagaMiddleware.run(sagas);

    return store;
  };
}
