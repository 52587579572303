import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row, FormInstance } from 'antd';
import { CreateCustomerInput } from '../../../../../cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { Select } from '../../../../../../lib/components/Select/Select';
import { customerListSelector } from '../../../customer-list/state/customerListSelectors';
import { CustomerListActions } from '../../../customer-list/state/customerListSlice';
import { Input } from 'lib/components/Input/Input';

interface CreateCustomerFormProps {
  formInstance: FormInstance<any>;
  onFinish: (values: CreateCustomerInput) => void;
}

const { Option } = Select;

export const CustomerCreateForm = ({ formInstance, onFinish }: CreateCustomerFormProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const algs = useSelector(customerListSelector.selectAlgsFilters);
  const isListAlgsLoading = useSelector(customerListSelector.selectIsFiltersLoading);

  useEffect(() => {
    dispatch(CustomerListActions.customerListAlgsRequest());
  }, [dispatch]);

  return (
    <Form form={formInstance} name="customer-create" layout="vertical" onFinish={onFinish} autoComplete="off">
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="customerName"
            label={t('customerCreate.form.customerName')}
            className="customer-create__input-group"
            required
            rules={[{ required: true, message: t('customerCreate.form.errors.customerNameRequired') }]}
          >
            <Input className="customer-create__input" placeholder={t('customerCreate.form.placeholder.common')} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="alg"
            label={t('customerCreate.form.alg')}
            className="customer-create__input-group"
            required
            rules={[{ required: true, message: t('customerCreate.form.errors.algRequired') }]}
          >
            <Select
              className="customer-create__select"
              placeholder={t('customerCreate.form.placeholder.alg')}
              loading={isListAlgsLoading}
              showArrow
              showSearch
            >
              {algs?.map(alg => (
                <Option value={alg} key={alg}>
                  {alg}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="sapNumber"
            label={t('customerCreate.form.sapNumber')}
            className="customer-create__input-group"
          >
            <Input className="customer-create__input" placeholder={t('customerCreate.form.placeholder.common')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item name="street" label={t('customerCreate.form.street')} className="customer-create__input-group">
            <Input className="customer-create__input" placeholder={t('customerCreate.form.placeholder.street')} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="houseNumber"
            label={t('customerCreate.form.houseNumber')}
            className="customer-create__input-group"
          >
            <Input className="customer-create__input" placeholder={t('customerCreate.form.placeholder.houseNumber')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item name="zipCode" label={t('customerCreate.form.zipCode')} className="customer-create__input-group">
            <Input className="customer-create__input" placeholder={t('customerCreate.form.placeholder.zipCode')} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="city" label={t('customerCreate.form.city')} className="customer-create__input-group">
            <Input className="customer-create__input" placeholder={t('customerCreate.form.placeholder.city')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item name="region" label={t('customerCreate.form.region')} className="customer-create__input-group">
            <Input className="customer-create__input" placeholder={t('customerCreate.form.placeholder.region')} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name="district" label={t('customerCreate.form.district')} className="customer-create__input-group">
            <Input className="customer-create__input" placeholder={t('customerCreate.form.placeholder.district')} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
