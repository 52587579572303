import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { CustomerDetailsSelectors } from '../state/customerDetailsSelectors';
import { StyledCustomerDetails } from './CustomerDetails.styles';
import { CustomerDetailsHeader } from './CustomerDetailsHeader/CustomerDetailsHeader';
import { CustomerDetailsBody } from './CustomerDetailsBody/CustomerDetailsBody';

export const CustomerDetails = (): JSX.Element => {
  const isCustomerDetailsLoading = !!useSelector(CustomerDetailsSelectors.selectLoading);

  return (
    <StyledCustomerDetails>
      <Spin spinning={isCustomerDetailsLoading}>
        <CustomerDetailsHeader />
        <CustomerDetailsBody />
      </Spin>
    </StyledCustomerDetails>
  );
};
