import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RoutePaths } from '../../../../../config/route-paths';
import { LoadingPage } from '../../../../../lib/components/LoadingPage/LoadingPage';
import { IRoute } from '../../../routing/interfaces/Routing.types';
import { AuthenticationActions } from '../../state/authenticationActions';
import {
  selectAuthenticationTokens,
  selectIsCheckingAuthentication,
  selectIsLoggedIn,
  selectPermissions,
} from '../../state/authenticationSelectors';
import { isUserPermitted } from '../../utils/isUserPermitted';

export interface AuthorizationGuardProps {
  route: IRoute;
  children?: React.ReactNode;
}

export const AuthorizationGuard: React.FC<AuthorizationGuardProps> = ({ route, children }) => {
  const dispatch = useDispatch();
  const isCheckingAuthentication = useSelector(selectIsCheckingAuthentication);
  const authenticationTokens = useSelector(selectAuthenticationTokens);
  const userPermissions = useSelector(selectPermissions);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const isPermittedToAccessRoute = isUserPermitted(userPermissions, route.permissions);

  useEffect(() => {
    if (isLoggedIn && authenticationTokens) {
      dispatch(
        AuthenticationActions.setUserInfo({
          decodedIdToken: authenticationTokens.decoded.idToken,
        })
      );
    }
  }, [isLoggedIn, authenticationTokens, dispatch]);

  if (isCheckingAuthentication) {
    return <LoadingPage />;
  }

  if (!authenticationTokens) {
    return <Navigate to={RoutePaths.LOGIN} />;
  }

  if (!authenticationTokens?.decoded.accessToken['cognito:groups'].includes('SUPER_ADMIN')) {
    return <Navigate to={RoutePaths.ACCESS_DENIED} />;
  }

  if (!isPermittedToAccessRoute) {
    return <Navigate to={RoutePaths.ROOT} />;
  }

  return <>{children}</>;
};
