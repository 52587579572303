import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppHeader } from '../AppHeader/AppHeader';
import { AppFooter } from '../AppFooter/AppFooter';
import { AppSider } from '../AppSider/AppSider';
import { StyledLayout } from './AppLayout.styles';
import { ScrollToTopButton } from 'lib/components/ScrollToTopButton/ScrollToTopButton';
import { AppBreadcrumbs } from 'app/components/AppBreadcrumbs/AppBreadcrumbs';
import { ScrollToTop } from 'lib/components/ScrollToTop/ScrollToTop';
import { AuthenticationActions } from 'app/cross-cutting-concerns/authentication/state/authenticationActions';
import { selectIsCheckingAuthentication } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { LoadingPage } from 'lib/components/LoadingPage/LoadingPage';

const { Content } = Layout;
export const AppLayout = (): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const isCheckingAuthentication = useSelector(selectIsCheckingAuthentication);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AuthenticationActions.checkAuthentication());
  }, [dispatch]);

  if (isCheckingAuthentication) {
    return <LoadingPage />;
  }

  return (
    <StyledLayout className="layout" hasSider={false}>
      <ScrollToTop containerRef={containerRef} />
      <AppHeader />
      <Content>
        <AppSider />
        <div ref={containerRef} className="outlet-wrapper">
          <AppBreadcrumbs />
          <Outlet />
          <AppFooter>
            <ScrollToTopButton containerRef={containerRef} />
          </AppFooter>
        </div>
      </Content>
    </StyledLayout>
  );
};
