import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';
import { MachineOwnership, SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { PaginationTokens } from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';

export const selectData = (state: IState): Optional<MachineOwnership[]> =>
  state.modules['machine-directory']['machine-list'].data;

export const selectTotalCount = (state: IState): Optional<number> =>
  state.modules['machine-directory']['machine-list'].totalCount;

export const selectIsLoading = (state: IState): Optional<boolean> =>
  state.modules['machine-directory']['machine-list'].isLoading;

export const selectPaginationTokens = (state: IState): PaginationTokens =>
  state.modules['machine-directory']['machine-list'].paginationTokens;

export const selectPage = (state: IState): number => state.modules['machine-directory']['machine-list'].page;

export const selectPageSize = (state: IState): number => state.modules['machine-directory']['machine-list'].pageSize;

export const selectPeriodStartDate = (state: IState): Optional<string> =>
  state.modules['machine-directory']['machine-list'].filters.active.period.startDate;

export const selectPeriodEndDate = (state: IState): Optional<string> =>
  state.modules['machine-directory']['machine-list'].filters.active.period.endDate;

export const selectSortField = (state: IState): Optional<string> =>
  state.modules['machine-directory']['machine-list'].sortOptions.field;

export const selectSortOrder = (state: IState): Optional<SortOrders> =>
  state.modules['machine-directory']['machine-list'].sortOptions.order;

export const selectSearchText = (state: IState): Optional<string> =>
  state.modules['machine-directory']['machine-list'].search;

export const selectAlg = (state: IState): Optional<string> =>
  state.modules['machine-directory']['machine-list'].filters.active.alg;

export const selectCustomerIdFilter = (state: IState): Optional<string> =>
  state.modules['machine-directory']['machine-list'].filters.active.customerId;
