import styled from 'styled-components';

export const StyledPagination = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: ${(props): string => props.theme.fontSizes.pagination};
  line-height: 14px;
  font-family: ${(props): string => props.theme.fonts.pagination};

  .ant-row {
    width: 100%;
    align-items: center;

    .pagination__body-col {
      .pagination__body {
        justify-content: center;
      }
    }
  }

  .pagination__body {
    display: flex;
    align-items: center;

    .pagination__items-default {
      margin-left: 45px;
      margin-right: 45px;
    }

    .pagination__items-small {
      margin-left: 30px;
      margin-right: 30px;
    }
  }

  .pagination__prev-button,
  .pagination__next-button {
    display: flex;
    justify-content: center;
    align-items: center;
    path {
      stroke: ${(props): string => props.theme.colors.black};
    }

    :hover {
      path {
        stroke: ${(props): string => props.theme.colors.black};
      }
    }

    &.button-base.button__size-s {
      padding: 0;
      height: 18px;
      width: 18px;
    }

    &[disabled],
    &[disabled]:hover {
      path {
        // TODO: propose an existing kind of grey color
        stroke: ${(props): string => props.theme.colors.paginationGray};
      }
    }

    & > span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .pagination__prev-button svg {
    transform: rotate(180deg);
  }
`;
