import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest } from 'typed-redux-saga';
import {
  MachineClaimResponse,
  MachineFindResponse,
  MachinePairingResponse,
  MachinePairingSearchResponse,
  MachineSearchMaterialNumberResponse,
} from '../../interfaces/Machine.types';
import { convertedOperatingHours } from '../../utils/getOperatingHoursConverted';
import { customerModalsActions } from '../../../customer-management/modals/state/customerModalsSlice';
import {
  IClaimMachineErrorAction,
  IClaimMachineRequestAction,
  IClaimMachineSuccessAction,
  IMachineFindErrorAction,
  IMachineFindRequestAction,
  IMachineFindSuccessAction,
  IPairMachineErrorAction,
  IPairMachineRequestAction,
  IPairMachineSearchErrorAction,
  IPairMachineSearchRequestAction,
  IPairMachineSearchSuccessAction,
  IPairMachineSuccessAction,
  ISearchMaterialNumberErrorAction,
  ISearchMaterialNumberRequestAction,
  ISearchMaterialNumberSuccessAction,
  MachineClaimPairActions,
} from './machineClaimPairActions';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { Optional } from 'lib/types/Optional';

export function* searchMaterialNumberSaga(
  action: ISearchMaterialNumberRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<MachineSearchMaterialNumberResponse>>
  | PutEffect<ISearchMaterialNumberSuccessAction>
  | PutEffect<ISearchMaterialNumberErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.searchMaterialNumber, action.payload);

    yield* put(MachineClaimPairActions.searchMaterialNumberSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      MachineClaimPairActions.searchMaterialNumberError({
        error,
      })
    );
  }
}

export function* findMachineSaga(
  action: IMachineFindRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<MachineFindResponse> | void>
  | PutEffect<IMachineFindSuccessAction>
  | PutEffect<IMachineFindErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { materialNumber, serialNumber } = action.payload;

    const response = yield* call(machineService.find, {
      materialNumber: materialNumber.trim(),
      serialNumber: serialNumber.trim(),
    });

    if (response?.validateMachine?.data) {
      yield* put(MachineClaimPairActions.findMachineSuccess(response));
    } else {
      // Message for dev, do not have to translate
      yield* put(
        MachineClaimPairActions.findMachineError({
          error: `Could not find machine type matched material number ${action.payload.materialNumber}`,
        })
      );
    }
  } catch (error) {
    console.error(error);
    yield* put(
      MachineClaimPairActions.findMachineError({
        error,
      })
    );
  }
}

export function* claimMachineSaga(
  action: IClaimMachineRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<MachineClaimResponse>>
  | CallEffect<void>
  | PutEffect<IClaimMachineSuccessAction>
  | PutEffect<IClaimMachineErrorAction>,
  void,
  IDependencies
> {
  const { machineService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const { materialNumber, serialNumber } = action.payload;

    const response = yield* call(machineService.claim, {
      ...action.payload,
      materialNumber: materialNumber.trim(),
      serialNumber: serialNumber.trim(),
    });
    yield* put(MachineClaimPairActions.claimMachineSuccess(response));

    if (response?.adminMachineClaim) {
      yield* call(toastService.success, {
        message: t('machineClaiming.toasts.claimMachine.success.message'),
        description: t('machineClaiming.toasts.claimMachine.success.description'),
      });
    }
  } catch (error) {
    yield* put(
      MachineClaimPairActions.claimMachineError({
        error: (error as Error).message,
      })
    );

    yield* call(toastService.error, {
      message: t('machineClaiming.toasts.claimMachine.error.message'),
      description: (error as Error).message,
    });
  } finally {
    yield* put(customerModalsActions.closeClaimNewDeviceModal());
  }
}

export function* pairMachineSearchSaga(
  action: IPairMachineSearchRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<MachinePairingSearchResponse>>
  | CallEffect<void>
  | PutEffect<IPairMachineSearchSuccessAction>
  | PutEffect<IPairMachineSearchErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const { gatewayMaterialNumber, gatewaySerialNumber, machineMaterialNumber, machineSerialNumber } = action.payload;

    const response = yield* call(machineService.pairSearch, {
      gatewayMaterialNumber: gatewayMaterialNumber?.trim(),
      gatewaySerialNumber: gatewaySerialNumber?.trim(),
      machineMaterialNumber: machineMaterialNumber?.trim(),
      machineSerialNumber: machineSerialNumber?.trim(),
    });
    yield* put(MachineClaimPairActions.pairMachineSearchSuccess(response));
  } catch (error) {
    yield* put(
      MachineClaimPairActions.pairMachineSearchError({
        error: (error as Error).message,
      })
    );
  }
}

export function* pairMachineSaga(
  action: IPairMachineRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<MachinePairingResponse>>
  | CallEffect<void>
  | PutEffect<IPairMachineSuccessAction>
  | PutEffect<IPairMachineErrorAction>,
  void,
  IDependencies
> {
  const { machineService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const { gatewayMaterialNumber, gatewaySerialNumber, machineMaterialNumber, machineSerialNumber, operatingHours } =
      action.payload;

    const convertedOperatingHoursData = convertedOperatingHours(operatingHours)?.toString();

    const response = yield* call(machineService.pair, {
      gatewayMaterialNumber: gatewayMaterialNumber.trim(),
      gatewaySerialNumber: gatewaySerialNumber.trim(),
      machineMaterialNumber: machineMaterialNumber.trim(),
      machineSerialNumber: machineSerialNumber.trim(),
      ...(convertedOperatingHoursData && { operatingHours: convertedOperatingHoursData }),
    });
    yield* put(MachineClaimPairActions.pairMachineSuccess(response));

    if (response?.machinePairing.data) {
      yield* call(toastService.success, {
        message: t('pairMachine.toasts.success.message'),
        description: t('pairMachine.toasts.success.description'),
      });
    }
  } catch (error) {
    yield* put(
      MachineClaimPairActions.pairMachineError({
        error: (error as Error).message,
      })
    );
  }
}

export function* machineClaimPairSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(MachineClaimPairActions.SEARCH_MATERIAL_NUMBER_REQUEST, searchMaterialNumberSaga);
  yield* takeLatest(MachineClaimPairActions.FIND_MACHINE_REQUEST, findMachineSaga);
  yield* takeLatest(MachineClaimPairActions.CLAIM_MACHINE_REQUEST, claimMachineSaga);
  yield* takeLatest(MachineClaimPairActions.PAIR_MACHINE_SEARCH_REQUEST, pairMachineSearchSaga);
  yield* takeLatest(MachineClaimPairActions.PAIR_MACHINE_REQUEST, pairMachineSaga);
}
