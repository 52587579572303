import React from 'react';
import { IRoute } from '../app/cross-cutting-concerns/routing/interfaces/Routing.types';
import { UserListPage } from '../app/pages/UserListPage/UserListPage';
import { CustomerListPage } from '../app/pages/CustomerListPage/CustomerListPage';
import { RoutePaths } from './route-paths';
import { Permission } from './permissions';
import { NoRouteFoundPage } from 'app/pages/NoRouteFoundPage/NoRouteFoundPage';
import { LoginPage } from 'app/pages/LoginPage/LoginPage';
import { UserInvitePage } from 'app/pages/UserInvitePage/UserInvitePage';
import { MachineListPage } from 'app/pages/MachineListPage/MachineListPage';
import { OverviewPage } from 'app/pages/OverviewPage/OverviewPage';
import { UserDetailsPage } from 'app/pages/UserDetailsPage/UserDetailsPage';
import { MachineClaimingPage } from 'app/pages/MachineClaimingPage/MachineClaimingPage';
import { UserCreatePage } from 'app/pages/UserCreatePage/UserCreatePage';
import { MachineClaimingPairingPage } from 'app/pages/MachineClaimingPairingPage/MachineClaimingPairingPage';
import { UserCreateSuccessPage } from 'app/pages/UserCreateSuccessPage/UserCreateSuccessPage';
import { UserInviteSuccessPage } from 'app/pages/UserInviteSuccessPage/UserInviteSuccessPage';
import { AccessDeniedPage } from 'app/pages/AccessDenied/AccessDeniedPage';
import { CustomerDetailsPage } from 'app/pages/CustomerDetailsPage/CustomerDetailsPage';

export const privateRoutes: IRoute[] = [
  {
    index: true,
    element: <OverviewPage />,
    permissions: [],
  },
  {
    path: RoutePaths.CLAIM_MACHINE,
    element: <MachineClaimingPage />,
    permissions: [Permission.Machine.CLAIM],
  },
  {
    path: RoutePaths.MACHINE_CLAIM_PAIR,
    element: <MachineClaimingPairingPage />,
    permissions: [Permission.Machine.CLAIM],
  },
  {
    path: RoutePaths.MACHINES,
    element: <MachineListPage />,
    permissions: [],
  },
  {
    path: RoutePaths.INVITE_USER,
    element: <UserInvitePage />,
    permissions: [Permission.Customer.User.SEND_INVITATION],
  },
  {
    path: RoutePaths.USER_DETAILS,
    element: <UserDetailsPage />,
    permissions: [],
  },
  {
    path: RoutePaths.USERS,
    element: <UserListPage />,
    permissions: [],
  },
  {
    path: RoutePaths.INVITE_USER_SUCCESS,
    element: <UserInviteSuccessPage />,
    permissions: [],
  },
  {
    path: RoutePaths.CUSTOMERS,
    element: <CustomerListPage />,
    permissions: [],
  },
  {
    path: RoutePaths.CUSTOMERS_DETAILS,
    element: <CustomerDetailsPage />,
    permissions: [],
  },
];

export const publicRoutes: IRoute[] = [
  {
    path: RoutePaths.LOGIN,
    element: <LoginPage />,
    permissions: [],
  },
  {
    path: RoutePaths.CREATE_USER,
    element: <UserCreatePage />,
    permissions: [],
  },
  {
    path: RoutePaths.CREATE_USER_SUCCESS,
    element: <UserCreateSuccessPage />,
    permissions: [],
  },
  {
    path: RoutePaths.ACCESS_DENIED,
    element: <AccessDeniedPage />,
    permissions: [],
  },

  // NoRouteFoundPage handles routes /logout, /callback and any route, that is not defined
  {
    path: '*',
    element: <NoRouteFoundPage />,
    permissions: [],
  },
];
