import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'react-i18next';
import { ReactNode } from 'react';
import { Table, Skeleton } from 'antd';
import moment from 'moment';
import { SortableColumnTitle } from 'lib/components/Table/SortableColumnTitle/SortableColumnTitle';
import { MachineOwnership } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export const getCustomerDetailsMachineColumns = ({
  t,
  isSortingAndFilteringEnabled,
  hasPermissionMachineEditClaim,
}: {
  t: TFunction;
  isSortingAndFilteringEnabled: boolean;
  hasPermissionMachineEditClaim: boolean;
}): ColumnsType<MachineOwnership> => [
  {
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    width: '9%',
    sorter: isSortingAndFilteringEnabled,
    render: (): JSX.Element => (
      <div className="machine-list__avatar-column">
        <Skeleton.Image className="machine-list__avatar-image" />
      </div>
    ),
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<MachineOwnership>(
        t('machineList.table.machine').toLocaleUpperCase(),
        isSortingAndFilteringEnabled,
        'type',
        sortColumns
      ),
    dataIndex: 'type',
    width: '20%',
    sorter: isSortingAndFilteringEnabled,
    key: 'type',
    render: (value): JSX.Element => (
      <div>
        <p>{value}</p>
      </div>
    ),
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<MachineOwnership>(
        t('machineList.table.serialNo'),
        isSortingAndFilteringEnabled,
        'serialNumber',
        sortColumns
      ),
    dataIndex: 'serialNumber',
    width: '25%',
    sorter: isSortingAndFilteringEnabled,
    key: 'serialNumber',
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<MachineOwnership>(
        t('machineList.table.materialNo'),
        isSortingAndFilteringEnabled,
        'materialNumber',
        sortColumns
      ),
    dataIndex: 'materialNumber',
    width: '15%',
    sorter: isSortingAndFilteringEnabled,
    key: 'materialNumber',
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<MachineOwnership>(t('common.alg'), isSortingAndFilteringEnabled, 'algNumber', sortColumns),
    dataIndex: 'algNumber',
    width: '7%',
    sorter: isSortingAndFilteringEnabled,
    key: 'algNumber',
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<MachineOwnership>(
        t('machineList.table.dateOfClaim'),
        isSortingAndFilteringEnabled,
        'dateOfClaim',
        sortColumns
      ),
    dataIndex: 'dateOfClaim',
    width: '15%',
    sorter: isSortingAndFilteringEnabled,
    defaultSortOrder: 'descend',
    key: 'dateOfClaim',
    render: (dateOfClaim: string): JSX.Element => <p>{moment(new Date(dateOfClaim)).format('YYYY-MM-DD')}</p>,
  },
  ...(hasPermissionMachineEditClaim ? [Table.SELECTION_COLUMN] : []),
];
