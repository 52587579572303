import styled from 'styled-components';
import { Modal } from '../../../../../../lib/components/Modal/Modal';

export const StyledCustomerConfirmModal = styled(Modal)`
  .ant-modal-title {
    text-transform: uppercase;
    font-family: ${(props): string => props.theme.fonts.modalTitle};
    font-size: 20px;
    padding-top: 12px;
  }

  .ant-form-item-label {
    padding-bottom: 0;

    > label {
      text-transform: uppercase;
      font-size: ${(props): string => props.theme.fontSizes.formTitle};
      font-family: ${(props): string => props.theme.fonts.formTitle};
      color: ${(props): string => props.theme.colors.text};
    }
  }

  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${(props): string => props.theme.colors.red};
    box-shadow: none;
    border-right-width: 2px !important;
  }

  .ant-form-item-explain-error {
    color: ${(props): string => props.theme.colors.red};
  }
`;
