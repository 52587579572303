import styled from 'styled-components';

export const StyledCustomerSelectionForm = styled.div`
  width: 100%;

  .customer-selection__header-content {
    .customer-selection__title {
      font-size: ${(props): string => props.theme.fontSizes.formSubheading};
      font-family: ${(props): string => props.theme.fonts.heading};
      color: ${(props): string => props.theme.colors.text};
      text-transform: uppercase;
    }
  }

  .customer-selection__body-content {
    margin-bottom: 12px;
    margin-top: 12px;

    font-family: ${(props): string => props.theme.fonts.formBody};
    color: ${(props): string => props.theme.colors.text};

    .customer-selection__form {
      .customer-selection__col {
        padding-top: 22px;
      }

      .customer-selection__row {
        align-items: flex-start;
      }
    }

    .customer-selection__modal-select {
      width: 100%;

      .ant-select-selection__placeholder {
        font-size: ${(props): string => props.theme.fontSizes.formBody};
        font-family: ${(props): string => props.theme.fonts.modalSubtitle};
        color: ${(props): string => props.theme.colors.border};

        // Set placeholder center
        position: absolute;
        top: 50%;
        right: 11px;
        left: 11px;
        transform: translateY(-50%);
        transition: all 0.3s;
      }
    }

    .customer-selection__input {
      width: 100%;

      &::placeholder {
        font-size: ${(props): string => props.theme.fontSizes.formBody};
        font-family: ${(props): string => props.theme.fonts.modalSubtitle};
        color: ${(props): string => props.theme.colors.border};
      }
    }
  }

  .ant-form-item-label {
    padding-bottom: 0;
    > label {
      font-size: ${(props): string => props.theme.fontSizes.formLabel};
      font-family: ${(props): string => props.theme.fonts.formLabel};
      color: ${(props): string => props.theme.colors.text};
      text-transform: uppercase;
    }
  }

  .customer-selection__submit-button {
    text-transform: uppercase;
    width: 100%;
  }
`;
