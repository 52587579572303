import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { call, getContext, put, takeLatest } from 'typed-redux-saga';
import { customerModalsActions } from '../../../state/customerModalsSlice';
import * as customerModalActionTypes from '../../../state/customerModalsActions.types';
import { deleteCustomerActions } from './deleteCustomerSlice';
import {
  CustomerDeleteMultiUsersRequestAction,
  CustomerDeleteUserErrorAction,
  CustomerDeleteUserRequestAction,
  CustomerDeleteUserSuccessAction,
  CustomerReassignMultiUsersRequestAction,
  CustomerReassignUserErrorAction,
  CustomerReassignUserRequestAction,
  CustomerReassignUserSuccessAction,
  CustomerReclaimDeviceErrorAction,
  CustomerReclaimDeviceRequestAction,
  CustomerReclaimDeviceSuccessAction,
  CustomerReclaimMultiDevicesRequestAction,
  CustomerUnclaimDeviceErrorAction,
  CustomerUnclaimDeviceRequestAction,
  CustomerUnclaimDeviceSuccessAction,
  CustomerUnclaimMultiDevicesRequestAction,
} from './deleteCustomerActions.types';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { ReassignUserToCustomerResponse, UserDeleteResponse } from 'app/modules/user-management/interfaces/User.types';
import {
  IHideChangeCustomerModalAction,
  IIsChangeCustomerModalLoadingAction,
  IIsChangeCustomerModalNotLoadingAction,
  UserModalsActions,
} from 'app/modules/user-management/modals/state/userModalsActions';
import {
  IUserDetailsRequestAction,
  UserDetailsActions,
} from 'app/modules/user-management/user-details/state/userDetailsActions';
import { Optional } from 'lib/types/Optional';
import {
  ICustomerSearchClearListAction,
  CustomerSearchActions,
} from 'app/modules/user-management/customer-search/state/customerSearchAction';
import { MachineClaimResponse, MachineUnclaimResponse } from 'app/modules/machine-directory/interfaces/Machine.types';
import { IGetMachineListRequestAction } from 'app/modules/machine-directory/machine-list/state/machineListActions';

export function* userDelete(
  action: CustomerDeleteUserRequestAction
): Generator<
  | IDependencies
  | GetContextEffect
  | CallEffect<Optional<UserDeleteResponse>>
  | CallEffect<void>
  | PutEffect<CustomerDeleteUserSuccessAction>
  | PutEffect<CustomerDeleteUserErrorAction>
  | void
> {
  const { customerService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(customerService.deleteUsers, action.payload);

    yield* put(
      deleteCustomerActions.deleteUserSuccess({
        id: action.payload.input?.id,
        deleteUser: response?.deleteUser,
      })
    );
  } catch (error: unknown) {
    if (error instanceof Error) {
      yield* put(deleteCustomerActions.deleteUserError({ error: error.message, id: action.payload.input?.id }));
    }
  }
}

export function* userReassign(
  action: CustomerReassignUserRequestAction
): Generator<
  | IDependencies
  | GetContextEffect
  | CallEffect<Optional<ReassignUserToCustomerResponse>>
  | CallEffect<void>
  | PutEffect<CustomerReassignUserSuccessAction>
  | PutEffect<CustomerReassignUserErrorAction>
  | PutEffect<IHideChangeCustomerModalAction>
  | PutEffect<IIsChangeCustomerModalLoadingAction>
  | PutEffect<IIsChangeCustomerModalNotLoadingAction>
  | PutEffect<IUserDetailsRequestAction>
  | PutEffect<ICustomerSearchClearListAction>
  | void
> {
  const { customerService } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(UserModalsActions.hideChangeCustomerModal());

    const response = yield* call(customerService.reassignUser, action.payload);

    if (action.payload.input?.userId) {
      yield* put(CustomerSearchActions.customerSearchClearList());
      yield* put(UserDetailsActions.userDetailsRequest(action.payload.input.userId));
    } else {
      throw new Error('User ID is missing');
    }

    if (response?.reassignUserToCustomer) {
      yield* put(CustomerSearchActions.customerSearchClearList());
      yield* put(deleteCustomerActions.reassignUserRequest(response));
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      yield* put(deleteCustomerActions.reassignUserError({ error }));
    }
  }
}

export function* userMultiDelete(
  action: CustomerDeleteMultiUsersRequestAction
): Generator<
  | IDependencies
  | GetContextEffect
  | CallEffect<Optional<UserDeleteResponse>>
  | CallEffect<void>
  | PutEffect<CustomerDeleteUserSuccessAction>
  | PutEffect<CustomerDeleteUserErrorAction>
  | void
> {
  const { customerService } = yield* getContext<IDependencies>('dependencies');

  try {
    const listInput = action.payload;
    if (listInput.length > 0) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < listInput.length; i += 1) {
        const { input } = listInput[i];
        try {
          const response = yield* call(customerService.deleteUsers, { input });
          yield* put(
            deleteCustomerActions.deleteUserSuccess({
              id: input?.id,
              deleteUser: response?.deleteUser,
            })
          );
        } catch (error: unknown) {
          if (error instanceof Error) {
            yield* put(deleteCustomerActions.deleteUserError({ error: error.message, id: input?.id }));
          }
        }
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }
}

export function* userReassignMulti(
  action: CustomerReassignMultiUsersRequestAction
): Generator<
  | IDependencies
  | GetContextEffect
  | CallEffect<Optional<ReassignUserToCustomerResponse>>
  | CallEffect<void>
  | PutEffect<CustomerReassignUserSuccessAction>
  | PutEffect<CustomerReassignUserErrorAction>
  | PutEffect<IHideChangeCustomerModalAction>
  | PutEffect<IUserDetailsRequestAction>
  | PutEffect<ICustomerSearchClearListAction>
  | void
> {
  const { customerService } = yield* getContext<IDependencies>('dependencies');
  yield* put(UserModalsActions.hideChangeCustomerModal());
  yield* put(CustomerSearchActions.customerSearchClearList());
  try {
    const listInput = action.payload;
    if (listInput.length > 0) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < listInput.length; i += 1) {
        const { input } = listInput[i];
        try {
          const response = yield* call(customerService.reassignUser, { input });
          yield* put(
            deleteCustomerActions.reassignUserSuccess({
              id: input?.userId,
              reassignUser: response?.reassignUserToCustomer as ReassignUserToCustomerResponse,
            })
          );
        } catch (error: unknown) {
          if (error instanceof Error) {
            yield* put(deleteCustomerActions.reassignUserError({ error: error.message, id: input?.userId }));
          }
        }
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }
}

export function* reClaimDevice(
  action: CustomerReclaimDeviceRequestAction
): Generator<
  | GetContextEffect
  | IDependencies
  | CallEffect<Optional<MachineClaimResponse>>
  | CallEffect<void>
  | PutEffect<CustomerReclaimDeviceSuccessAction>
  | PutEffect<IGetMachineListRequestAction>
  | PutEffect<CustomerReclaimDeviceErrorAction>
  | PutEffect<customerModalActionTypes.CustomerReclaimMachineModalCloseAction>
  | void
> {
  const { customerService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(customerModalsActions.closeReclaimMachineModal());
    const response = yield* call(customerService.reClaim, action.payload);

    yield* put(deleteCustomerActions.reclaimDevicesSuccess(response));

    if (response?.adminMachineClaim) {
      yield* call(toastService.success, {
        message: t('machineReclaiming.toasts.machineReclaiming.success.message'),
        description: t('machineReclaiming.toasts.machineReclaiming.success.description'),
      });
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      yield* put(deleteCustomerActions.reclaimDevicesError({ error }));

      yield* call(toastService.error, {
        message: t('machineReclaiming.toasts.machineReclaiming.error.message'),
        description: error.message,
      });
    }
  }
}

export function* unClaimDevice(
  action: CustomerUnclaimDeviceRequestAction
): Generator<
  | GetContextEffect
  | IDependencies
  | CallEffect<Optional<MachineUnclaimResponse>>
  | CallEffect<void>
  | PutEffect<CustomerUnclaimDeviceSuccessAction>
  | PutEffect<IGetMachineListRequestAction>
  | PutEffect<CustomerUnclaimDeviceErrorAction>
  | void
> {
  const { customerService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(customerService.unClaim, action.payload);

    yield* put(deleteCustomerActions.unclaimDevicesSuccess(response));

    if (response?.unclaimMachine) {
      yield* call(toastService.success, {
        message: t('machineUnclaim.toasts.success.message'),
        description: t('machineUnclaim.toasts.success.description'),
      });
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      yield* put(deleteCustomerActions.unclaimDevicesError({ error }));

      yield* call(toastService.error, {
        message: t('machineUnclaim.toasts.error.message'),
        description: error.message,
      });
    }
  }
}

export function* devicesReClaimMulti(
  action: CustomerReclaimMultiDevicesRequestAction
): Generator<
  | IDependencies
  | GetContextEffect
  | CallEffect<Optional<MachineClaimResponse>>
  | CallEffect<void>
  | PutEffect<CustomerReclaimDeviceSuccessAction>
  | PutEffect<CustomerReclaimDeviceErrorAction>
  | PutEffect<IGetMachineListRequestAction>
  | PutEffect<customerModalActionTypes.CustomerReclaimMachineModalCloseAction>
  | void
> {
  const { customerService } = yield* getContext<IDependencies>('dependencies');
  yield* put(customerModalsActions.closeReclaimMachineModal());
  try {
    const listInput = action.payload;
    if (listInput.length > 0) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < listInput.length; i += 1) {
        const { input } = listInput[i];
        try {
          const response = yield* call(customerService.reClaim, { input });
          yield* put(
            deleteCustomerActions.reclaimDevicesSuccess({
              id: `${input?.materialNumber}-${input?.serialNumber}`,
              adminMachineClaim: response?.adminMachineClaim,
            })
          );
        } catch (error: unknown) {
          if (error instanceof Error) {
            yield* put(
              deleteCustomerActions.reclaimDevicesError({
                error: error.message,
                id: `${input?.materialNumber}-${input?.serialNumber}`,
              })
            );
          }
        }
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }
}

export function* devicesUnClaimMulti(
  action: CustomerUnclaimMultiDevicesRequestAction
): Generator<
  | IDependencies
  | GetContextEffect
  | CallEffect<Optional<MachineUnclaimResponse>>
  | CallEffect<void>
  | PutEffect<CustomerUnclaimDeviceSuccessAction>
  | PutEffect<CustomerUnclaimDeviceErrorAction>
  | PutEffect<IGetMachineListRequestAction>
  | void
> {
  const { customerService } = yield* getContext<IDependencies>('dependencies');
  try {
    const listInput = action.payload;
    if (listInput.length > 0) {
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < listInput.length; i += 1) {
        const { input } = listInput[i];
        try {
          const response = yield* call(customerService.unClaim, { input });
          yield* put(
            deleteCustomerActions.unclaimDevicesSuccess({
              id: `${input?.materialNumber}-${input?.serialNumber}`,
              unclaimMachine: response?.unclaimMachine,
            })
          );
        } catch (error: unknown) {
          if (error instanceof Error) {
            yield* put(
              deleteCustomerActions.unclaimDevicesError({
                error: error.message,
                id: `${input?.materialNumber}-${input?.serialNumber}`,
              })
            );
          }
        }
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('error', error);
  }
}

export function* deleteCustomerSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(deleteCustomerActions.deleteUserRequest, userDelete);
  yield* takeLatest(deleteCustomerActions.reassignUserRequest, userReassign);
  yield* takeLatest(deleteCustomerActions.deleteMultiUsersRequest, userMultiDelete);
  yield* takeLatest(deleteCustomerActions.reassignMultiUsersRequest, userReassignMulti);
  yield* takeLatest(deleteCustomerActions.reclaimDevicesRequest, reClaimDevice);
  yield* takeLatest(deleteCustomerActions.unclaimDevicesRequest, unClaimDevice);
  yield* takeLatest(deleteCustomerActions.reclaimMultiDevicesRequest, devicesReClaimMulti);
  yield* takeLatest(deleteCustomerActions.unclaimMultiDevicesRequest, devicesUnClaimMulti);
}
