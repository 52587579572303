import { createSlice } from '@reduxjs/toolkit';
import * as customerModalActionTypes from './customerModalsActions.types';
import { Optional } from 'lib/types/Optional';
import {
  CreateCustomerInput,
  CustomerManagementEntity,
  MachineOwnership,
  User,
} from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export interface CustomerCreateModal {
  data: Optional<CustomerManagementEntity>;
  customerRequest: Optional<CreateCustomerInput>;
  isOpen: boolean;
  isLoading: boolean;
}

export interface CustomerConfirmModal {
  isOpen: boolean;
  isConfirm: boolean;
  isDuplicate: boolean;
}

export interface CustomerEditModal {
  data: Optional<CustomerManagementEntity>;
  isOpen: Optional<boolean>;
  isLoading: Optional<boolean>;
}

export interface CustomerDeleteModal {
  data: Optional<boolean>;
  isOpen: Optional<boolean>;
  isLoading: Optional<boolean>;
}

export interface CustomerInviteUserModal {
  data: Optional<User>;
  isOpen: Optional<boolean>;
  isLoading: Optional<boolean>;
}

export interface CustomerReclaimMachineModal {
  data: Optional<MachineOwnership>;
  isOpen: Optional<boolean>;
  isLoading: Optional<boolean>;
}

export interface CustomerUnclaimMachineModal {
  data: Optional<MachineOwnership>;
  isOpen: Optional<boolean>;
  isLoading: Optional<boolean>;
}

export interface CustomerClaimNewDeviceModal {
  data: Optional<MachineOwnership>;
  isOpen: Optional<boolean>;
  isLoading: Optional<boolean>;
}

export interface CustomerModalsState {
  createModal: CustomerCreateModal;
  confirmModal: CustomerConfirmModal;
  editModal: CustomerEditModal;
  deleteModal: CustomerDeleteModal;
  inviteUserModal: CustomerInviteUserModal;
  reclaimMachineModal: CustomerReclaimMachineModal;
  unclaimMachineModal: CustomerUnclaimMachineModal;
  claimNewDeviceModal: CustomerClaimNewDeviceModal;
}

export const initialState: CustomerModalsState = {
  createModal: {
    data: null,
    customerRequest: null,
    isOpen: false,
    isLoading: false,
  },
  confirmModal: {
    isOpen: false,
    isConfirm: false,
    isDuplicate: false,
  },
  editModal: {
    data: null,
    isOpen: null,
    isLoading: null,
  },
  deleteModal: {
    data: null,
    isOpen: null,
    isLoading: null,
  },
  inviteUserModal: {
    data: null,
    isOpen: null,
    isLoading: null,
  },
  reclaimMachineModal: {
    data: null,
    isOpen: null,
    isLoading: null,
  },
  unclaimMachineModal: {
    data: null,
    isOpen: null,
    isLoading: null,
  },
  claimNewDeviceModal: {
    data: null,
    isOpen: false,
    isLoading: null,
  },
};

const customerModalsSlice = createSlice({
  name: 'customer-modals',
  initialState,
  reducers: {
    openCreateCustomerModal: state => {
      state.createModal.isOpen = true;
      return state;
    },
    closeCreateCustomerModal: state => {
      state.createModal.isOpen = false;
      return state;
    },
    openConfirmCustomerModal: state => {
      state.confirmModal.isOpen = true;
      return state;
    },
    closeConfirmCustomerModal: state => {
      state.confirmModal.isOpen = false;
      state.confirmModal.isDuplicate = false;
      state.confirmModal.isConfirm = false;
      return state;
    },
    submitCreateCustomerModal: (state, action: customerModalActionTypes.CustomerCreateModalSubmitRequestAction) => {
      state.createModal.isLoading = true;
      state.createModal.customerRequest = action.payload;
      return state;
    },
    confirmCreateCustomerModal: (state, _action: customerModalActionTypes.CustomerConfirmModalConfirmAction) => {
      state.confirmModal.isConfirm = true;
    },
    createCustomerRequest: (state, _action: customerModalActionTypes.CustomerCreateModalCreateRequestAction) => {
      state.createModal.isLoading = true;
      state.confirmModal.isOpen = false;
      return state;
    },
    createCustomerSuccess: (state, action: customerModalActionTypes.CustomerCreateModalCreateSuccessAction) => {
      state.createModal.data = action.payload?.createCustomer.data;
      state.createModal.data = action.payload?.createCustomer.data;
      state.createModal.isLoading = false;
      return state;
    },
    createCustomerError: (state, _action: customerModalActionTypes.CustomerCreateModalCreateErrorAction) => {
      state.createModal.isLoading = false;
      return state;
    },
    validateCreateCustomerRequest: (
      state,
      _action: customerModalActionTypes.CustomerCreateModalValidateCreateRequestAction
    ) => {
      state.createModal.isLoading = true;
      return state;
    },
    validateCreateCustomerSuccess: (
      state,
      action: customerModalActionTypes.CustomerCreateModalValidateCreateSuccessAction
    ) => {
      state.createModal.isLoading = false;
      state.confirmModal.isDuplicate = action.payload?.validateCreate.data?.isDuplicate as boolean;
      if (!state.confirmModal.isDuplicate) {
        state.confirmModal.isConfirm = true;
        state.confirmModal.isOpen = false;
      }
      state.createModal.isOpen = true;
      return state;
    },
    validateCreateCustomerError: (
      state,
      _action: customerModalActionTypes.CustomerCreateModalValidateCreateErrorAction
    ) => {
      state.createModal.isLoading = false;
      return state;
    },
    resetCreateCustomerModal: (_state, _action: customerModalActionTypes.CustomerCreateModalResetFormAction) =>
      initialState,

    openDeleteCustomerModal: (state, _action: customerModalActionTypes.CustomerDeleteModalOpenAction) => {
      state.deleteModal.isOpen = true;

      return state;
    },
    closeDeleteCustomerModal: (state, _aciton: customerModalActionTypes.CustomerDeleteModalCloseAction) => {
      state.deleteModal.isOpen = false;

      return state;
    },
    deleteCustomerModalRequest: (state, _action: customerModalActionTypes.CustomerDeleteModalDeleteRequestAction) => {
      state.deleteModal.isLoading = true;

      return state;
    },
    deleteCustomerModalSuccess: (state, action: customerModalActionTypes.CustomerDeleteModalDeleteSuccessAction) => {
      state.deleteModal.isLoading = false;
      state.deleteModal.data = action.payload?.deleteCustomer.data || initialState.deleteModal.data;

      return state;
    },
    deleteCustomerModalError: (state, _action: customerModalActionTypes.CustomerDeleteModalDeleteErrorAction) => {
      state.deleteModal.isLoading = false;

      return state;
    },

    openEditCustomerModal: (state, _action: customerModalActionTypes.CustomerEditModalOpenAction) => {
      state.editModal.isOpen = true;

      return state;
    },
    closeEditCustomerModal: (state, _aciton: customerModalActionTypes.CustomerEditModalCloseAction) => {
      state.editModal.isOpen = false;

      return state;
    },
    editCustomerModalRequest: (state, _action: customerModalActionTypes.CustomerEditModalEditRequestAction) => {
      state.editModal.isLoading = true;

      return state;
    },
    editCustomerModalSuccess: (state, action: customerModalActionTypes.CustomerEditModalEditSuccessAction) => {
      state.editModal.isLoading = false;
      state.editModal.data = action.payload?.updateCustomer.data || initialState.editModal.data;

      return state;
    },
    editCustomerModalError: (state, _action: customerModalActionTypes.CustomerEditModalEditErrorAction) => {
      state.editModal.isLoading = false;

      return state;
    },
    resetDeleteCustomerModal: (state, _action: customerModalActionTypes.CustomerDeleteModalResetFormAction) => {
      state.deleteModal = initialState.deleteModal;

      return state;
    },

    openInviteUserModal: (state, _action: customerModalActionTypes.CustomerInviteUserModalOpenAction) => {
      state.inviteUserModal.isOpen = true;

      return state;
    },
    closeInviteUserModal: (state, _action: customerModalActionTypes.CustomerInviteUserModalCloseAction) => {
      state.inviteUserModal.isOpen = false;

      return state;
    },

    inviteUserModalRequest: (state, _action: customerModalActionTypes.CustomerInviteUserModalRequestAction) => {
      state.inviteUserModal.isLoading = true;

      return state;
    },
    inviteUserModalSuccess: (state, action: customerModalActionTypes.CustomerInviteUserModalSuccessAction) => {
      state.inviteUserModal.isLoading = false;
      state.inviteUserModal.data = action.payload?.inviteUser.data || initialState.inviteUserModal.data;

      return state;
    },
    inviteUserModalError: (state, _action: customerModalActionTypes.CustomerInviteUserModalErrorAction) => {
      state.inviteUserModal.isLoading = false;

      return state;
    },
    inviteUserModalResetState: state => {
      state.inviteUserModal = initialState.inviteUserModal;

      return state;
    },
    openReclaimMachineModal: (state, _action: customerModalActionTypes.CustomerReclaimMachineModalOpenAction) => {
      state.reclaimMachineModal.isOpen = true;

      return state;
    },
    closeReclaimMachineModal: (state, _action: customerModalActionTypes.CustomerReclaimMachineModalCloseAction) => {
      state.reclaimMachineModal.isOpen = false;

      return state;
    },

    reclaimMachineModalRequest: (state, _action: customerModalActionTypes.CustomerReclaimMachineModalRequestAction) => {
      state.reclaimMachineModal.isLoading = true;

      return state;
    },

    reclaimMachineModalSuccess: (state, action: customerModalActionTypes.CustomerReclaimMachineModalSuccessAction) => {
      state.reclaimMachineModal.isLoading = false;
      state.reclaimMachineModal.data = action.payload?.adminMachineClaim.data;

      return state;
    },

    reclaimMachineModalError: (state, _action: customerModalActionTypes.CustomerReclaimMachineModalErrorAction) => {
      state.reclaimMachineModal.isLoading = false;

      return state;
    },

    openUnclaimMachineModal: (state, _action: customerModalActionTypes.CustomerUnclaimMachineModalOpenAction) => {
      state.unclaimMachineModal.isOpen = true;

      return state;
    },

    closeUnclaimMachineModal: (state, _action: customerModalActionTypes.CustomerUnclaimMachineModalCloseAction) => {
      state.unclaimMachineModal.isOpen = false;

      return state;
    },

    unclaimMachineModalRequest: (state, _action: customerModalActionTypes.CustomerUnclaimMachineModalRequestAction) => {
      state.unclaimMachineModal.isLoading = true;

      return state;
    },

    unclaimMachineModalSuccess: (state, _action: customerModalActionTypes.CustomerUnclaimMachineModalSuccessAction) => {
      state.unclaimMachineModal.isLoading = false;
      state.unclaimMachineModal.data = _action.payload?.unclaimMachine.data;

      return state;
    },

    unclaimMachineModalError: (state, _action: customerModalActionTypes.CustomerUnclaimMachineModalErrorAction) => {
      state.unclaimMachineModal.isLoading = false;

      return state;
    },

    openClaimNewDeviceModal: (state, _action: customerModalActionTypes.CustomerClaimNewDeviceModalOpenAction) => {
      state.claimNewDeviceModal.isOpen = true;

      return state;
    },

    closeClaimNewDeviceModal: (state, _action: customerModalActionTypes.CustomerClaimNewDeviceModalOpenAction) => {
      state.claimNewDeviceModal.isOpen = false;

      return state;
    },

    claimNewDeviceModalRequest: (
      state,
      _action: customerModalActionTypes.CustomerClaimNewDeviceMachineModalRequestAction
    ) => {
      state.claimNewDeviceModal.isLoading = true;

      return state;
    },

    claimNewDeviceModalSuccess: (
      state,
      _action: customerModalActionTypes.CustomerClaimNewDeviceMachineModalSuccessAction
    ) => {
      state.claimNewDeviceModal.isLoading = false;
      state.claimNewDeviceModal.data = _action.payload?.adminMachineClaim.data;

      return state;
    },
    claimNewDeviceModalError: (
      state,
      _action: customerModalActionTypes.CustomerClaimNewDeviceMachineModalErrorAction
    ) => {
      state.claimNewDeviceModal.isLoading = false;

      return state;
    },
  },
});

export const customerModalsActions = customerModalsSlice.actions;
export const customerModalsReducer = customerModalsSlice.reducer;
