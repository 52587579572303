import { TFunction } from 'react-i18next';
import moment from 'moment';
import { Optional } from 'lib/types/Optional';

export const convertUTCToLocal = (dateUTC: Optional<string>, t: TFunction<'translation', undefined>): string => {
  if (dateUTC) {
    const dateLocal = moment(dateUTC).format('YYYY-MM-DD HH:mm:ss') as unknown as string;
    return dateLocal;
  }
  return dateUTC || t('userDetails.noneData');
};
