import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';
import { customerModalSelector } from '../../state/customerModalsSelectors';
import { PrimaryButton } from '../../../../../../lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../../../../../../lib/components/Button/SecondaryButton/SecondaryButton';
import { CreateCustomerInput } from '../../../../../cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { customerModalsActions } from '../../state/customerModalsSlice';
import { StyledCustomerCreateModal } from './CustomerCreateModal.styles';
import { CustomerCreateForm } from './CustomerCreateForm';

export const CustomerCreateModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [formInstance] = Form.useForm();
  const onFinish = (values: CreateCustomerInput): void => {
    dispatch(customerModalsActions.submitCreateCustomerModal(values));
    dispatch(
      customerModalsActions.validateCreateCustomerRequest({
        customerName: values.customerName,
        sapNumber: values.sapNumber || '',
      })
    );
  };

  const handleCancel = (): void => {
    dispatch(customerModalsActions.resetCreateCustomerModal());
  };

  const handleOk = useCallback(() => {
    formInstance.submit();
  }, [formInstance]);

  const isOpen = useSelector(customerModalSelector.selectIsCreateCustomerModalOpen);
  const isLoading = useSelector(customerModalSelector.selectIsCreateCustomerModalLoading);
  const isDuplicate = useSelector(customerModalSelector.selectIsConfirmCustomerModalDuplicate);
  const isConfirm = useSelector(customerModalSelector.selectIsConfirmCustomerModalConfirm);

  useEffect(() => {
    if (!isOpen) {
      formInstance.resetFields();
    }
  }, [isOpen, formInstance]);

  useEffect(() => {
    const formValue: CreateCustomerInput = formInstance.getFieldsValue();
    if (!isDuplicate && isOpen && isConfirm) {
      dispatch(customerModalsActions.createCustomerRequest(formValue));
    } else if (isDuplicate && isOpen) {
      dispatch(customerModalsActions.openConfirmCustomerModal());
    }
  }, [isDuplicate, isOpen, formInstance, dispatch, isConfirm]);

  return (
    <StyledCustomerCreateModal
      className="customer-create-modal"
      title={t('customerCreate.title')}
      centered
      open={isOpen}
      closable={false}
      width={600}
      footer={[
        <SecondaryButton size="m" className="cancel-button" key="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          onClick={handleOk}
          loading={isLoading}
        >
          {t('common.create')}
        </PrimaryButton>,
      ]}
    >
      <CustomerCreateForm formInstance={formInstance} onFinish={onFinish} />
    </StyledCustomerCreateModal>
  );
};
