import React from 'react';
import { useTranslation } from 'react-i18next';
import { Result } from 'antd';
import { useNavigate } from 'react-router-dom';
import { StyledUserInviteSuccess } from './UserInviteSuccess.styles';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { RoutePaths } from 'config/route-paths';

export const UserInviteSuccess = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleInviteUser = (): void => {
    navigate(RoutePaths.INVITE_USER);
  };
  return (
    <StyledUserInviteSuccess>
      <Result
        status="success"
        title={t('userInvite.userInviteSuccess.title')}
        subTitle={t('userInvite.userInviteSuccess.subTitle')}
        extra={[
          <PrimaryButton
            size="m"
            type="primary"
            key="success"
            onClick={handleInviteUser}
            className="user-invite-success__btn"
          >
            {t('userInvite.userInviteSuccess.button')}
          </PrimaryButton>,
        ]}
      />
    </StyledUserInviteSuccess>
  );
};
