export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type ClaimedMachineFilter = {
  algNumber?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  materialNumber?: InputMaybe<Scalars['String']>;
  periods?: InputMaybe<DateFilter>;
  serialNumber?: InputMaybe<Scalars['String']>;
};

export type ClaimedMachinesResults = {
  __typename?: 'ClaimedMachinesResults';
  data: Array<MachineOwnership>;
  metadata: ResponseMetadata;
};

export type CommonUpdateReponse = {
  __typename?: 'CommonUpdateReponse';
  updated?: Maybe<Scalars['Boolean']>;
};

export type CreateCustomerInput = {
  alg: Scalars['String'];
  city?: InputMaybe<Scalars['String']>;
  customerName: Scalars['String'];
  district?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  sapNumber?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type CreateCustomerResponse = {
  __typename?: 'CreateCustomerResponse';
  data?: Maybe<CustomerManagementEntity>;
};

export type Customer = {
  __typename?: 'Customer';
  SAPCustomerNumber?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
};

export type CustomerEntity = {
  __typename?: 'CustomerEntity';
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
};

export type CustomerFilter = {
  alg?: InputMaybe<Scalars['String']>;
};

export type CustomerManagementEntity = {
  __typename?: 'CustomerManagementEntity';
  alg?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  sapNumber?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type CustomerResults = {
  __typename?: 'CustomerResults';
  data?: Maybe<CustomerManagementEntity>;
};

export type CustomersResults = {
  __typename?: 'CustomersResults';
  data?: Maybe<Array<Maybe<CustomerManagementEntity>>>;
  metadata?: Maybe<ResponseMetadata>;
};

export type DateFilter = {
  from: Scalars['AWSDate'];
  to: Scalars['AWSDate'];
};

export type DeleteCustomerInput = {
  customerId: Scalars['String'];
};

export type DeleteCustomerResponse = {
  __typename?: 'DeleteCustomerResponse';
  data: Scalars['Boolean'];
};

export type DeleteUserInput = {
  id: Scalars['String'];
};

export type GetCustomerResponse = {
  __typename?: 'GetCustomerResponse';
  data?: Maybe<Array<Maybe<Customer>>>;
};

export type GetCustomersInput = {
  algNumber: Scalars['String'];
  customerName?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
};

export type GetUserInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type InputMachinePairingSearch = {
  gatewayMaterialNumber?: InputMaybe<Scalars['String']>;
  gatewaySerialNumber?: InputMaybe<Scalars['String']>;
  machineMaterialNumber?: InputMaybe<Scalars['String']>;
  machineSerialNumber?: InputMaybe<Scalars['String']>;
};

export type InputPagingOptions = {
  limit?: InputMaybe<Scalars['Int']>;
  paginationToken?: InputMaybe<Scalars['String']>;
};

export type InputSearchOptions = {
  keyword?: InputMaybe<Scalars['String']>;
};

export type InputSortOptions = {
  field: Scalars['String'];
  order: SortOrders;
};

export type InviteUserInput = {
  customerId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<RegisterUserTitleEnum>;
};

export type ListAlgNumberResponse = {
  __typename?: 'ListAlgNumberResponse';
  data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ListCustomerInput = {
  algNumber: Scalars['String'];
};

export type MachineClaimMutation = {
  algNumber: Scalars['String'];
  customerId: Scalars['String'];
  materialNumber: Scalars['String'];
  serialNumber: Scalars['String'];
};

export type MachineOwnership = {
  __typename?: 'MachineOwnership';
  algNumber?: Maybe<Scalars['String']>;
  classification?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  dateOfClaim?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isNonKaercherDevice?: Maybe<Scalars['Boolean']>;
  machineName?: Maybe<Scalars['String']>;
  materialNumber?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type MachinePairingInfo = {
  __typename?: 'MachinePairingInfo';
  algNumber?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  gatewayMaterialNumber: Scalars['String'];
  gatewaySerialNumber: Scalars['String'];
  gatewayType?: Maybe<Scalars['String']>;
  machineMaterialNumber: Scalars['String'];
  machineSerialNumber: Scalars['String'];
  machineType?: Maybe<Scalars['String']>;
  operatingHours?: Maybe<Scalars['String']>;
};

export type MachinePairingMutation = {
  gatewayMaterialNumber: Scalars['String'];
  gatewaySerialNumber: Scalars['String'];
  machineMaterialNumber: Scalars['String'];
  machineSerialNumber: Scalars['String'];
  operatingHours?: InputMaybe<Scalars['String']>;
};

export type MachinePairingSearchInfo = {
  __typename?: 'MachinePairingSearchInfo';
  gatewayPairedMaterialNumber?: Maybe<Scalars['String']>;
  gatewayPairedSerialNumber?: Maybe<Scalars['String']>;
  machinePairedMaterialNumber?: Maybe<Scalars['String']>;
  machinePairedSerialNumber?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  adminMachineClaim?: Maybe<ResponseMachineClaim>;
  createCustomer?: Maybe<CreateCustomerResponse>;
  deleteCustomer?: Maybe<DeleteCustomerResponse>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  inviteUser?: Maybe<UserResults>;
  machinePairing?: Maybe<ResponseMachinePairing>;
  reassignUserToCustomer?: Maybe<ResponseReassignUserToCustomer>;
  registerUser?: Maybe<ResponseRegisterUser>;
  resendInviteEmail?: Maybe<ResponseResendInviteEmail>;
  unclaimMachine?: Maybe<ResponseUnclaimMachine>;
  updateCustomer?: Maybe<UpdateCustomerResponse>;
  updateUser?: Maybe<ResponseUpdateUser>;
};

export type MutationAdminMachineClaimArgs = {
  input?: InputMaybe<MachineClaimMutation>;
};

export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};

export type MutationDeleteCustomerArgs = {
  input: DeleteCustomerInput;
};

export type MutationDeleteUserArgs = {
  input?: InputMaybe<DeleteUserInput>;
};

export type MutationInviteUserArgs = {
  input?: InputMaybe<InviteUserInput>;
};

export type MutationMachinePairingArgs = {
  input?: InputMaybe<MachinePairingMutation>;
};

export type MutationReassignUserToCustomerArgs = {
  input?: InputMaybe<ReassignUserToCustomerInput>;
};

export type MutationRegisterUserArgs = {
  input?: InputMaybe<RegisterUserInput>;
};

export type MutationResendInviteEmailArgs = {
  input?: InputMaybe<ResendInviteEmail>;
};

export type MutationUnclaimMachineArgs = {
  input?: InputMaybe<UnclaimMachineInput>;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUpdateUserArgs = {
  input?: InputMaybe<UpdateUserInput>;
};

export type Query = {
  __typename?: 'Query';
  getCustomer?: Maybe<CustomerResults>;
  getCustomers?: Maybe<GetCustomerResponse>;
  getUser?: Maybe<UserResults>;
  listAlgNumber?: Maybe<ListAlgNumberResponse>;
  listClaimedMachines?: Maybe<ClaimedMachinesResults>;
  listCountries?: Maybe<ResponseListCountries>;
  listCustomers?: Maybe<ResponseListCustomers>;
  listUsers?: Maybe<UsersResults>;
  machinePairingSearch?: Maybe<ResponseMachinePairingSearch>;
  ping?: Maybe<Scalars['Boolean']>;
  searchCustomers?: Maybe<CustomersResults>;
  searchMaterialNumber?: Maybe<SearchMaterialNumberResponse>;
  searchUsers?: Maybe<UsersResults>;
  validateCreate?: Maybe<ValidateCreateResponse>;
  validateMachine?: Maybe<ResponseValidateMachine>;
};

export type QueryGetCustomerArgs = {
  id: Scalars['ID'];
};

export type QueryGetCustomersArgs = {
  input?: InputMaybe<GetCustomersInput>;
};

export type QueryGetUserArgs = {
  input?: InputMaybe<GetUserInput>;
};

export type QueryListClaimedMachinesArgs = {
  filter?: InputMaybe<ClaimedMachineFilter>;
  paginationOptions?: InputMaybe<InputPagingOptions>;
  search?: InputMaybe<Scalars['String']>;
  sortOptions?: InputMaybe<InputSortOptions>;
};

export type QueryListCustomersArgs = {
  input?: InputMaybe<ListCustomerInput>;
};

export type QueryListUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  paginationOptions?: InputMaybe<InputPagingOptions>;
  search?: InputMaybe<Scalars['String']>;
  sortOptions?: InputMaybe<InputSortOptions>;
};

export type QueryMachinePairingSearchArgs = {
  input?: InputMaybe<InputMachinePairingSearch>;
};

export type QuerySearchCustomersArgs = {
  filter?: InputMaybe<CustomerFilter>;
  paginationOptions?: InputMaybe<InputPagingOptions>;
  search?: InputMaybe<Scalars['String']>;
  sortOptions?: InputMaybe<InputSortOptions>;
};

export type QuerySearchMaterialNumberArgs = {
  filter?: InputMaybe<SearchMaterialNumberFilterInput>;
  paginationOptions?: InputMaybe<InputPagingOptions>;
  search: Scalars['String'];
};

export type QuerySearchUsersArgs = {
  input?: InputMaybe<SearchUsersInput>;
};

export type QueryValidateCreateArgs = {
  customerName: Scalars['String'];
  sapNumber?: InputMaybe<Scalars['String']>;
};

export type QueryValidateMachineArgs = {
  input: ValidateMachineInput;
};

export type ReassignUserToCustomerInput = {
  customerId: Scalars['String'];
  userId: Scalars['String'];
};

export type RegisterUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<RegisterUserTitleEnum>;
};

export enum RegisterUserTitleEnum {
  Miss = 'Miss',
  Mr = 'Mr',
  Mrs = 'Mrs',
  Ms = 'Ms',
  None = 'None',
}

export type ResendInviteEmail = {
  internalUserId: Scalars['String'];
};

export type ResponseListCountries = {
  __typename?: 'ResponseListCountries';
  data?: Maybe<Array<Maybe<ResponseListCountriesData>>>;
};

export type ResponseListCountriesData = {
  __typename?: 'ResponseListCountriesData';
  country?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
};

export type ResponseListCustomer = {
  __typename?: 'ResponseListCustomer';
  data?: Maybe<ResponseListCustomerData>;
};

export type ResponseListCustomerData = {
  __typename?: 'ResponseListCustomerData';
  customerId?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
};

export type ResponseListCustomers = {
  __typename?: 'ResponseListCustomers';
  data?: Maybe<Array<Maybe<CustomerEntity>>>;
};

export type ResponseMachineClaim = {
  __typename?: 'ResponseMachineClaim';
  data?: Maybe<MachineOwnership>;
};

export type ResponseMachinePairing = {
  __typename?: 'ResponseMachinePairing';
  data?: Maybe<MachinePairingInfo>;
};

export type ResponseMachinePairingSearch = {
  __typename?: 'ResponseMachinePairingSearch';
  data?: Maybe<MachinePairingSearchInfo>;
};

export type ResponseMetadata = {
  __typename?: 'ResponseMetadata';
  paginationToken?: Maybe<Scalars['String']>;
  totalCount: Scalars['Int'];
};

export type ResponseReassignUserToCustomer = {
  __typename?: 'ResponseReassignUserToCustomer';
  data?: Maybe<User>;
};

export type ResponseRegisterUser = {
  __typename?: 'ResponseRegisterUser';
  userId?: Maybe<Scalars['String']>;
};

export type ResponseResendInviteEmail = {
  __typename?: 'ResponseResendInviteEmail';
  invitationRevokedOn?: Maybe<Scalars['String']>;
};

export type ResponseUnclaimMachine = {
  __typename?: 'ResponseUnclaimMachine';
  data?: Maybe<MachineOwnership>;
};

export type ResponseUpdateUser = {
  __typename?: 'ResponseUpdateUser';
  data?: Maybe<User>;
};

export type ResponseValidateMachine = {
  __typename?: 'ResponseValidateMachine';
  data?: Maybe<ResponseValidateMachineData>;
};

export type ResponseValidateMachineData = {
  __typename?: 'ResponseValidateMachineData';
  algNumber?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  machineIsClaimed: Scalars['Boolean'];
  materialNumber: Scalars['String'];
  serialNumber: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type SearchMaterialNumberFilterInput = {
  classifications?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isNonKaercherDevice?: InputMaybe<Scalars['Boolean']>;
};

export type SearchMaterialNumberResponse = {
  __typename?: 'SearchMaterialNumberResponse';
  data?: Maybe<Array<Maybe<SearchMaterialNumberResponseData>>>;
  metadata?: Maybe<ResponseMetadata>;
};

export type SearchMaterialNumberResponseData = {
  __typename?: 'SearchMaterialNumberResponseData';
  classification?: Maybe<Scalars['String']>;
  isNonKaercherDevice?: Maybe<Scalars['Boolean']>;
  isPairable?: Maybe<Scalars['Boolean']>;
  materialNumber?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SearchUsersInput = {
  paginationOptions?: InputMaybe<InputPagingOptions>;
  searchOptions?: InputMaybe<InputSearchOptions>;
};

export enum SortOrders {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type UnclaimMachineInput = {
  materialNumber: Scalars['String'];
  serialNumber: Scalars['String'];
};

export type UpdateCustomerInput = {
  alg?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  customerName?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  sapNumber?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerResponse = {
  __typename?: 'UpdateCustomerResponse';
  data?: Maybe<CustomerManagementEntity>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<RegisterUserTitleEnum>;
};

export type User = {
  __typename?: 'User';
  country: Scalars['String'];
  createdAt: Scalars['String'];
  customerId: Scalars['String'];
  customerName?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  internalUserId: Scalars['String'];
  invitationRevokedOn?: Maybe<Scalars['String']>;
  invitedOn?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleEnum>;
  status: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type UserFilter = {
  alg?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  customerNumber?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UserResults = {
  __typename?: 'UserResults';
  data?: Maybe<User>;
};

export enum UserRoleEnum {
  CustomerAdmin = 'CUSTOMER_ADMIN',
  CustomerStrategicManager = 'CUSTOMER_STRATEGIC_MANAGER',
}

export type UsersResults = {
  __typename?: 'UsersResults';
  data?: Maybe<Array<Maybe<User>>>;
  metadata?: Maybe<ResponseMetadata>;
};

export type ValidateCreate = {
  __typename?: 'ValidateCreate';
  isDuplicate: Scalars['Boolean'];
};

export type ValidateCreateResponse = {
  __typename?: 'ValidateCreateResponse';
  data?: Maybe<ValidateCreate>;
};

export type ValidateMachineInput = {
  materialNumber: Scalars['String'];
  serialNumber: Scalars['String'];
};
