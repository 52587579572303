import { singleton } from 'tsyringe';
import { MenuItem, menuItems as unfilteredMenuItems } from '../../../config/menu-items';
import { IMenuItemManager } from './interfaces/Routing.types';
import { Routing } from './Routing';

@singleton()
export class MenuItemManager implements IMenuItemManager {
  constructor(private routing: Routing) {}

  public async getMenuItems(): Promise<MenuItem[]> {
    const routes = await this.routing.getAllRoutesFilteredByPermission();
    const routePaths = routes.map(route => route.path);

    return unfilteredMenuItems.filter(menuItem => routePaths.includes(menuItem.path));
  }
}
