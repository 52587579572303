import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, FormInstance, Row } from 'antd';
import { StyledCustomerSelectionForm } from './CustomerSelectionForm.styles';
import { Input } from 'lib/components/Input/Input';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { Select } from 'lib/components/Select/Select';
import { CustomerSearchSelectors } from 'app/modules/user-management/customer-search/state/customerSearchSelectors';
import { MachineDirectorySelectors } from 'app/modules/machine-directory/state/machineDirectorySelectors';
import { CustomerSearchFormValues } from 'app/modules/user-management/interfaces/Customer.types';
import { CustomerSearchActions } from 'app/modules/user-management/customer-search/state/customerSearchAction';

const { Item } = Form;
const { Option } = Select;

interface CustomerSelectionFormProps {
  formCustomerSelection: FormInstance<any>;
  handleSearch: () => void;
  onSearchFinish: (values: CustomerSearchFormValues) => void;
}

export const CustomerSelectionForm = ({
  formCustomerSelection,
  handleSearch,
  onSearchFinish,
}: CustomerSelectionFormProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const algs = useSelector(MachineDirectorySelectors.selectAlgs);
  const isAlgsLoading = useSelector(MachineDirectorySelectors.selectAreAlgsLoading);
  const isCustomerSearchLoading = useSelector(CustomerSearchSelectors.selectIsCustomerSearchLoading);

  const onFinish = (values: CustomerSearchFormValues): void => {
    onSearchFinish(values);
  };

  const onChangeNameOrNumber = (): void => {
    if (!formCustomerSelection.getFieldValue('customerNameOrNumber')) {
      formCustomerSelection.resetFields(['customerNameOrNumber']);
    }
  };

  useEffect(() => () => formCustomerSelection.resetFields(), [formCustomerSelection]);

  useEffect(
    () => () => {
      dispatch(CustomerSearchActions.customerSearchClearList());
    },
    [dispatch]
  );

  return (
    <StyledCustomerSelectionForm>
      <div className="customer-selection__header">
        <div className="customer-selection__header-content">
          <h3 className="customer-selection__title">{t('machineReclaiming.form.subTitle')}</h3>
        </div>
      </div>
      <div className="customer-selection__body">
        <div className="customer-selection__body-content">
          <Form className="customer-selection__form" form={formCustomerSelection} onFinish={onFinish} layout="vertical">
            <Row gutter={24} className="customer-selection__row" justify="start">
              <Col span={6}>
                <Item
                  name="algNumber"
                  label={t('machineReclaiming.form.alg.label')}
                  className="customer-selection__input-group"
                  rules={[{ required: true, message: t('machineReclaiming.form.alg.error.required') }]}
                >
                  <Select
                    placeholder={t('machineReclaiming.form.alg.placeholder')}
                    className="customer-selection__modal-select"
                    showArrow
                    showSearch
                    loading={isAlgsLoading}
                  >
                    {algs.map(alg => (
                      <Option key={alg} value={alg}>
                        {alg}
                      </Option>
                    ))}
                  </Select>
                </Item>
              </Col>
              <Col span={6}>
                <Item
                  name="customerNameOrNumber"
                  label={t('machineClaiming.forms.customerNameOrNumber')}
                  className="customer-selection__input-group"
                  requiredMark={true}
                  rules={[
                    {
                      required: true,
                      message: t('machineClaiming.forms.errors.requiredCustomer'),
                    },
                  ]}
                >
                  <Input
                    placeholder={t('machineClaiming.forms.placeholderCustomerNameOrNumber')}
                    className="customer-selection__input"
                    onChange={onChangeNameOrNumber}
                  />
                </Item>
              </Col>
              <Col className="customer-selection__col">
                <PrimaryButton
                  key="submit"
                  className="customer-selection__submit-button"
                  type="primary"
                  onClick={handleSearch}
                  size="m"
                  loading={isCustomerSearchLoading}
                >
                  {t('machineReclaiming.search')}
                </PrimaryButton>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </StyledCustomerSelectionForm>
  );
};
