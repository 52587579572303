import {
  CreateCustomerResponse,
  CustomerResults,
  CustomersResults,
  DeleteCustomerResponse,
  ListAlgNumberResponse,
  ValidateCreateResponse,
  UpdateCustomerResponse,
} from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export interface CustomerListAlgNumbersResponse {
  listAlgNumber: ListAlgNumberResponse;
}

export interface CustomerListResponse {
  searchCustomers: CustomersResults;
}

export interface CustomerCreateResponse {
  createCustomer: CreateCustomerResponse;
}

export interface CustomerValidateCreateResponse {
  validateCreate: ValidateCreateResponse;
}

export enum PollCustomerListOperation {
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update',
}

export interface PollCustomerListRequest {
  customerId: string;
  operation: PollCustomerListOperation;
}

export interface CustomerGetResponse {
  getCustomer: CustomerResults;
}

export interface CustomerUpdateResponse {
  updateCustomer: UpdateCustomerResponse;
}

export interface CustomerDeleteResponse {
  deleteCustomer: DeleteCustomerResponse;
}

export enum CustomerDetailsTab {
  DETAILS = 'DETAILS',
  USERS = 'USERS',
  MACHINES = 'MACHINES',
}

export enum CustomerDeleteTab {
  USERS = 'USERS',
  MACHINES = 'MACHINES',
}
