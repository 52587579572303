import { Customer } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';

export class CustomerSearchSelectors {
  public static selectCustomerSearchList = (state: IState): Customer[] =>
    state.modules['user-management']['customer-search'].customer.list;

  public static selectIsCustomerSearchLoading = (state: IState): boolean =>
    state.modules['user-management']['customer-search'].customer.isLoading;
}
