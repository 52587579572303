import { StyledAvatarInitials } from './AvatarInitials.styles';
import { Optional } from 'lib/types/Optional';

export const AvatarInitials = ({ initialNames }: { initialNames: Optional<string> }): JSX.Element => {
  if (!initialNames) return <></>;

  return (
    <StyledAvatarInitials justify="center" align="middle" className="avatar-initials">
      <p>{initialNames}</p>
    </StyledAvatarInitials>
  );
};
