import { Modal } from 'antd';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
  .ant-select-dropdown {
    .machine-image {
      margin-right: 12px;
    }
  }

  .ant-select-selection-item-content {
    .machine-image {
      display: none;
    }
  }

  .ant-modal-header {
    border-bottom: unset;
  }

  .ant-modal-title {
    text-transform: uppercase;
    font-family: ${(props): string => props.theme.fonts.modalTitle};
    font-size: ${(props): string => props.theme.fontSizes.modalTitle};
    padding-top: 12px;
  }

  .modal__body {
    font-family: ${(props): string => props.theme.fonts.modalBody};
    font-size: ${(props): string => props.theme.fontSizes.modalBody};
  }

  .modal__sub-title {
    font-family: ${(props): string => props.theme.fonts.modalSubtitle};
    font-size: ${(props): string => props.theme.fontSizes.modalSubtitle};
  }

  .ant-modal-footer {
    text-align: unset;
    border-top: unset;
    padding: 0px 25px 21px 25px;
    display: flex;
    button {
      text-transform: uppercase;
    }
    .submit-button {
      margin-left: auto !important; //overwrite default margin
    }
  }

  .input-label {
    text-transform: uppercase;
    font-family: ${(props): string => props.theme.fonts.heading};
    font-size: ${(props): string => props.theme.fontSizes.pinDescription};
  }

  .modal-select-space {
    width: 100%;
  }
`;
