import { Action } from 'redux';
import {
  UserDetailsResponse,
  UserUpdateResponse,
  UserDeleteResponse,
  UserResendInvitationResponse,
  ReassignUserToCustomerResponse,
} from '../../interfaces/User.types';
import { UserClientDeleteOptions, UserClientReassignOptions, UserClientUpdateOptions } from '../../UserClient';
import { UserServiceResendInvitationOptions } from '../../UserService';
import { Optional } from 'lib/types/Optional';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';

export type IUserDetailsRequestAction = Action<typeof UserDetailsActions.USER_DETAILS_REQUEST> & {
  payload: string;
};

export type IUserDetailsSuccessOptions = Optional<UserDetailsResponse>;
export type IUserDetailsSuccessAction = Action<typeof UserDetailsActions.USER_DETAILS_SUCCESS> & {
  payload: IUserDetailsSuccessOptions;
};

export type IUserDetailsErrorAction = IErrorAction<typeof UserDetailsActions.USER_DETAILS_ERROR>;

export type IUserDeleteRequestOptions = UserClientDeleteOptions & {
  email: string;
};
export type IUserDeleteRequestAction = Action<typeof UserDetailsActions.USER_DELETE_REQUEST> & {
  payload: IUserDeleteRequestOptions;
};

export type IUserDeleteSuccessOptions = Optional<UserDeleteResponse> & {
  id: string;
};
export type IUserDeleteSuccessAction = Action<typeof UserDetailsActions.USER_DELETE_SUCCESS> & {
  payload: IUserDeleteSuccessOptions;
};

export type IUserDeleteErrorOptions = IActionCreatorErrorOptions & {
  id: string;
};

export type IUserDeleteErrorAction = IErrorAction<typeof UserDetailsActions.USER_DELETE_ERROR> & {
  id: string;
};

export type IUserResendInvitationRequestOptions = UserServiceResendInvitationOptions;
export type IUserResendInvitationRequestAction = Action<typeof UserDetailsActions.USER_RESEND_INVITATION_REQUEST> & {
  payload: IUserResendInvitationRequestOptions;
};

export type IUserResendInvitationSuccessOptions = Optional<UserResendInvitationResponse>;
export type IUserResendInvitationSuccessAction = Action<typeof UserDetailsActions.USER_RESEND_INVITATION_SUCCESS> & {
  payload: Optional<UserResendInvitationResponse>;
};

export type IUserResendInvitationErrorAction = IErrorAction<typeof UserDetailsActions.USER_RESEND_INVITATION_ERROR>;

export type IUserUpdateRequestOptions = UserClientUpdateOptions;
export type IUserUpdateRequestAction = Action<typeof UserDetailsActions.USER_UPDATE_REQUEST> & {
  payload: IUserUpdateRequestOptions;
};

export type IUserUpdateSuccessOptions = Optional<UserUpdateResponse>;
export type IUserUpdateSuccessAction = Action<typeof UserDetailsActions.USER_UPDATE_SUCCESS> & {
  payload: IUserUpdateSuccessOptions;
};

export type IUserUpdateErrorAction = IErrorAction<typeof UserDetailsActions.USER_UPDATE_ERROR>;

export type IUserReassignRequestOptions = UserClientReassignOptions;
export type IUserReassignRequestAction = Action<typeof UserDetailsActions.USER_REASSIGN_REQUEST> & {
  payload: IUserReassignRequestOptions;
};

export type IUserReassignSuccessOptions = Optional<ReassignUserToCustomerResponse>;
export type IUserReassignSuccessAction = Action<typeof UserDetailsActions.USER_REASSIGN_SUCCESS> & {
  payload: IUserReassignSuccessOptions;
};

export type IUserReassignErrorAction = IErrorAction<typeof UserDetailsActions.USER_REASSIGN_ERROR>;

export type IClearDeleteUserAction = Action<typeof UserDetailsActions.CLEAR_DELETE_USER>;
export class UserDetailsActions {
  public static USER_DETAILS_REQUEST = 'USER_DETAILS::USER_DETAILS_REQUEST';
  public static USER_DETAILS_SUCCESS = 'USER_DETAILS::USER_DETAILS_SUCCESS';
  public static USER_DETAILS_ERROR = 'USER_DETAILS::USER_DETAILS_ERROR';

  public static USER_DELETE_REQUEST = 'USER_DETAILS::USER_DELETE_REQUEST';
  public static USER_DELETE_SUCCESS = 'USER_DETAILS::USER_DELETE_SUCCESS';
  public static USER_DELETE_ERROR = 'USER_DETAILS::USER_DELETE_ERROR';

  public static USER_RESEND_INVITATION_REQUEST = 'USER_DETAILS::USER_RESEND_INVITATION_REQUEST';
  public static USER_RESEND_INVITATION_SUCCESS = 'USER_DETAILS::USER_RESEND_INVITATION_SUCCESS';
  public static USER_RESEND_INVITATION_ERROR = 'USER_DETAILS::USER_RESEND_INVITATION_ERROR';

  public static USER_UPDATE_REQUEST = 'USER_DETAILS::USER_UPDATE_REQUEST';
  public static USER_UPDATE_SUCCESS = 'USER_DETAILS::USER_UPDATE_SUCCESS';
  public static USER_UPDATE_ERROR = 'USER_DETAILS::USER_UPDATE_ERROR';

  public static USER_REASSIGN_REQUEST = 'USER_DETAILS::USER_REASSIGN_REQUEST';
  public static USER_REASSIGN_SUCCESS = 'USER_DETAILS::USER_REASSIGN_SUCCESS';
  public static USER_REASSIGN_ERROR = 'USER_DETAILS::USER_REASSIGN_ERROR';

  public static CLEAR_DELETE_USER = 'USER_DETAILS::CLEAR_DELETE_USER';

  public static userDetailsRequest = (userId: string): IUserDetailsRequestAction => ({
    type: UserDetailsActions.USER_DETAILS_REQUEST,
    payload: userId,
  });

  public static userDetailsSuccess = (userDetails: IUserDetailsSuccessOptions): IUserDetailsSuccessAction => ({
    type: UserDetailsActions.USER_DETAILS_SUCCESS,
    payload: userDetails,
  });

  public static userDetailsError = ({ error }: IActionCreatorErrorOptions): IUserDetailsErrorAction => ({
    type: UserDetailsActions.USER_DETAILS_ERROR,
    error,
  });

  public static userDeleteRequest = ({ input, email }: IUserDeleteRequestOptions): IUserDeleteRequestAction => ({
    type: UserDetailsActions.USER_DELETE_REQUEST,
    payload: { input, email },
  });

  public static userDeleteSuccess = (data: IUserDeleteSuccessOptions): IUserDeleteSuccessAction => ({
    type: UserDetailsActions.USER_DELETE_SUCCESS,
    payload: data,
  });

  public static userDeleteError = ({ error, id }: IUserDeleteErrorOptions): IUserDeleteErrorAction => ({
    type: UserDetailsActions.USER_DELETE_ERROR,
    error,
    id,
  });

  public static clearDeleteUser = (): IClearDeleteUserAction => ({
    type: UserDetailsActions.CLEAR_DELETE_USER,
  });

  public static userUpdateRequest = ({ input }: IUserUpdateRequestOptions): IUserUpdateRequestAction => ({
    type: UserDetailsActions.USER_UPDATE_REQUEST,
    payload: { input },
  });

  public static userUpdateSuccess = (data: IUserUpdateSuccessOptions): IUserUpdateSuccessAction => ({
    type: UserDetailsActions.USER_UPDATE_SUCCESS,
    payload: data,
  });

  public static userUpdateError = ({ error }: IActionCreatorErrorOptions): IUserUpdateErrorAction => ({
    type: UserDetailsActions.USER_UPDATE_ERROR,
    error,
  });

  public static userResendInvitationRequest = ({
    input,
  }: IUserResendInvitationRequestOptions): IUserResendInvitationRequestAction => ({
    type: UserDetailsActions.USER_RESEND_INVITATION_REQUEST,
    payload: { input },
  });

  public static userResendInvitationSuccess = (
    data: IUserResendInvitationSuccessOptions
  ): IUserResendInvitationSuccessAction => ({
    type: UserDetailsActions.USER_RESEND_INVITATION_SUCCESS,
    payload: data,
  });

  public static userResendInvitationError = ({
    error,
  }: IActionCreatorErrorOptions): IUserResendInvitationErrorAction => ({
    type: UserDetailsActions.USER_RESEND_INVITATION_ERROR,
    error,
  });

  public static userReassignRequest = ({ input }: IUserReassignRequestOptions): IUserReassignRequestAction => ({
    type: UserDetailsActions.USER_REASSIGN_REQUEST,
    payload: { input },
  });

  public static userReassignSuccess = (data: IUserReassignSuccessOptions): IUserReassignSuccessAction => ({
    type: UserDetailsActions.USER_REASSIGN_SUCCESS,
    payload: data,
  });

  public static userReassignError = ({ error }: IActionCreatorErrorOptions): IUserReassignErrorAction => ({
    type: UserDetailsActions.USER_REASSIGN_ERROR,
    error,
  });
}
