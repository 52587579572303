export class Scripts {
  public static addScriptTags(): void {
    Scripts.addUserSnapScriptTags();
  }

  /**
   * Adds a script tag to the document header containing the UserSnap script.
   * The script tag is only added if the REACT_APP_USER_SNAP_SCRIPT_URL environment variable is set.
   * The script tag is added to the header.
   */
  private static addUserSnapScriptTags(): void {
    const userSnapScriptUrl: string = process.env.REACT_APP_USER_SNAP_SCRIPT_URL ?? '';

    if (userSnapScriptUrl.trim().length <= 0) {
      return;
    }

    const headElement: HTMLHeadElement | null = document.querySelector('head');
    const headerScriptTag = document.createElement('script');
    headerScriptTag.setAttribute('src', userSnapScriptUrl);
    headerScriptTag.toggleAttribute('defer');
    headElement?.appendChild(headerScriptTag);
  }
}
