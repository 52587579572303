import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export const selectIsDeleteUserModalVisible = (state: IState): boolean =>
  state.modules['user-management'].modals.deleteUser.visible;

export const selectIsDeleteUserModalLoading = (state: IState): boolean =>
  state.modules['user-management'].modals.deleteUser.isLoading;

export const selectIsEditUserModalVisible = (state: IState): boolean =>
  state.modules['user-management'].modals.editUser.visible;

export const selectIsEditUserModalLoading = (state: IState): boolean =>
  state.modules['user-management'].modals.editUser.isLoading;

export const selectIsResendInvitationModalVisible = (state: IState): boolean =>
  state.modules['user-management'].modals.resendInvitation.visible;

export const selectIsResendInvitationModalLoading = (state: IState): boolean =>
  state.modules['user-management'].modals.resendInvitation.isLoading;

export const selectIsChangeCustomerModalVisible = (state: IState): boolean =>
  state.modules['user-management'].modals.changeCustomer.visible;

export const selectIsChangeCustomerModalLoading = (state: IState): boolean =>
  state.modules['user-management'].modals.changeCustomer.isLoading;

export const selectChangeCustomerModalUserId = (state: IState): Optional<string> | undefined =>
  state.modules['user-management'].modals.changeCustomer.userId;
