import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { customerModalSelector } from '../../state/customerModalsSelectors';
import { SecondaryButton } from '../../../../../../lib/components/Button/SecondaryButton/SecondaryButton';
import { PrimaryButton } from '../../../../../../lib/components/Button/PrimaryButton/PrimaryButton';
import { customerModalsActions } from '../../state/customerModalsSlice';
import { StyledCustomerConfirmModal } from './CustomerConfirmModal.styles';

export const CustomerConfirmModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isOpen = useSelector(customerModalSelector.selectIsConfirmCustomerModalOpen);
  const customerRequest = useSelector(customerModalSelector.selectCreateCustomerRequest);

  const handleCancel = (): void => {
    dispatch(customerModalsActions.closeConfirmCustomerModal());
  };

  const handleSubmit = (): void => {
    if (customerRequest) {
      dispatch(customerModalsActions.createCustomerRequest(customerRequest));
    }
  };

  return (
    <StyledCustomerConfirmModal
      className="customer-confirm-modal"
      title={t('customerCreate.confirm.duplicated.title')}
      centered
      open={isOpen}
      closable={false}
      width={600}
      footer={[
        <SecondaryButton size="m" className="cancel-button" key="cancel-button" onClick={handleCancel}>
          {t('common.back')}
        </SecondaryButton>,
        <PrimaryButton size="m" key="submit" className="submit-button" type="primary" onClick={handleSubmit}>
          {t('customerCreate.confirm.duplicated.stillCreateButton')}
        </PrimaryButton>,
      ]}
    >
      <p>{t('customerCreate.confirm.duplicated.description')}</p>
    </StyledCustomerConfirmModal>
  );
};
