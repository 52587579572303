import { Role as RoleEnum } from '../app/modules/user-management/interfaces/User.types';

export const Permission = {
  Customer: {
    Account: {
      UPDATE: 'mykb:customer:account:update',
    },
    Role: {
      UPDATE: 'mykb:customer:role:update',
    },
    User: {
      AUTHORIZE: 'am:customer:user:authorize',
      UNAUTHORIZE: 'am:customer:user:unauthorize',
      SEND_INVITATION: 'am:customer:user:send-invitation',
      DELETE: 'mykb:customer:user:delete',
    },
  },
  Internal: {
    Role: {
      UPDATE: 'mykb:internal:role:update',
    },
  },
  Machine: {
    CLAIM: 'am:machine:claim',
    UPDATE: 'am:machine:update',
    ActivationStatus: {
      UPDATE: 'am:machine:activation-status:update',
    },
    Comment: {
      CREATE: 'am:machine:comment:create',
    },
    UNCLAIM: 'am:machine:unclaim',
  },
  Site: {
    CREATE: 'am:site:create',
    READ: 'am:site:read',
    UPDATE: 'am:site:update',
    DELETE: 'am:site:delete',
    Machine: {
      UPDATE: 'am:site:machine:update',
    },
    StrategicManager: {
      UPDATE: 'am:site:strategic-manager:update',
    },
    WorkInterval: {
      UPDATE: 'am:site:machine:work-interval:update',
    },
  },
  Permissions: {
    READ: 'am:permissions:read',
  },
};

export const Role = {
  Internal: {
    ADMIN: RoleEnum.INTERNAL_ADMIN,
  },
  Customer: {
    ADMIN: RoleEnum.CUSTOMER_ADMIN,
    STRATEGIC_MANAGER: RoleEnum.CUSTOMER_STRATEGIC_MANAGER,
    OPERATOR: RoleEnum.CUSTOMER_OPERATOR,
  },
};

export const Roles = [Role.Internal.ADMIN, Role.Customer.ADMIN, Role.Customer.STRATEGIC_MANAGER];

export const PermissionsByRole = {
  [Role.Internal.ADMIN]: [
    Permission.Customer.Role.UPDATE,
    Permission.Customer.Account.UPDATE,
    Permission.Customer.User.SEND_INVITATION,
    Permission.Customer.User.AUTHORIZE,
    Permission.Machine.CLAIM,
    Permission.Machine.UNCLAIM,
  ],
  [Role.Customer.ADMIN]: [
    Permission.Customer.Role.UPDATE,
    Permission.Machine.ActivationStatus.UPDATE,
    Permission.Machine.UPDATE,
    Permission.Machine.Comment.CREATE,
    Permission.Customer.User.DELETE,
    Permission.Site.CREATE,
    Permission.Site.DELETE,
    Permission.Site.StrategicManager.UPDATE,
    Permission.Site.UPDATE,
    Permission.Site.WorkInterval.UPDATE,
    Permission.Site.Machine.UPDATE,
    Permission.Site.READ,
    Permission.Permissions.READ,
    Permission.Customer.User.UNAUTHORIZE,
  ],
  [Role.Customer.STRATEGIC_MANAGER]: [
    Permission.Machine.ActivationStatus.UPDATE,
    Permission.Machine.UPDATE,
    Permission.Machine.Comment.CREATE,
    Permission.Site.UPDATE,
    Permission.Site.WorkInterval.UPDATE,
    Permission.Site.Machine.UPDATE,
    Permission.Site.READ,
  ],
  [Role.Customer.OPERATOR]: [Permission.Site.READ],
};
