import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StyledProfile } from './Profile.styles';
import {
  AuthenticationActions,
  ISignOutAction,
} from 'app/cross-cutting-concerns/authentication/state/authenticationActions';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { selectUserInfo } from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';

export const Profile = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userInfo = useSelector(selectUserInfo);

  return (
    <StyledProfile>
      <div>
        <div className="profile__user-name">{t('profile.userName')}</div>
        <div className="profile__user-id">{userInfo.customerId}</div>
      </div>
      <div>
        <PrimaryButton
          size="m"
          className="profile__logout-button"
          onClick={async (): Promise<ISignOutAction> => dispatch(AuthenticationActions.signOut())}
        >
          {t('profile.logoutButton')}
        </PrimaryButton>
      </div>
    </StyledProfile>
  );
};
