import { Action } from 'redux';

export type IShowMachineReclaimingModalAction = Action<typeof MachineModalActions.SHOW_MACHINE_RECLAIMING_MODAL>;
export type IHideMachineReclaimingModalAction = Action<typeof MachineModalActions.HIDE_MACHINE_RECLAIMING_MODAL>;

export type IShowMachinePairingModalAction = Action<typeof MachineModalActions.SHOW_MACHINE_PAIRING_MODAL> & {
  payload: {
    pairedMsg: string;
  };
};
export type IHideMachinePairingModalAction = Action<typeof MachineModalActions.HIDE_MACHINE_PAIRING_MODAL>;

export type IShowMachinePairReclaimModalAction = Action<typeof MachineModalActions.SHOW_MACHINE_PAIR_RECLAIM_MODAL>;
export type IHideMachinePairReclaimModalAction = Action<typeof MachineModalActions.HIDE_MACHINE_PAIR_RECLAIM_MODAL>;

export type IShowMachineUnclaimModalAction = Action<typeof MachineModalActions.SHOW_MACHINE_UNCLAIM_MODAL>;
export type IHideMachineUnclaimModalAction = Action<typeof MachineModalActions.HIDE_MACHINE_UNCLAIM_MODAL>;

export class MachineModalActions {
  public static SHOW_MACHINE_RECLAIMING_MODAL = 'MACHINE_MODALS::SHOW_MACHINE_RECLAIMING_MODAL';
  public static HIDE_MACHINE_RECLAIMING_MODAL = 'MACHINE_MODALS::HIDE_MACHINE_RECLAIMING_MODAL';

  public static SHOW_MACHINE_PAIRING_MODAL = 'MACHINE_MODALS::SHOW_MACHINE_PAIRING_MODAL';
  public static HIDE_MACHINE_PAIRING_MODAL = 'MACHINE_MODALS::HIDE_MACHINE_PAIRING_MODAL';

  public static SHOW_MACHINE_PAIR_RECLAIM_MODAL = 'MACHINE_MODALS::SHOW_MACHINE_PAIR_RECLAIM_MODAL';
  public static HIDE_MACHINE_PAIR_RECLAIM_MODAL = 'MACHINE_MODALS::HIDE_MACHINE_PAIR_RECLAIM_MODAL';

  public static SHOW_MACHINE_UNCLAIM_MODAL = 'MACHINE_MODALS::SHOW_MACHINE_UNCLAIM_MODAL';
  public static HIDE_MACHINE_UNCLAIM_MODAL = 'MACHINE_MODALS::HIDE_MACHINE_UNCLAIM_MODAL';

  public static showMachineReclaimingModal(): IShowMachineReclaimingModalAction {
    return {
      type: MachineModalActions.SHOW_MACHINE_RECLAIMING_MODAL,
    };
  }

  public static hideMachineReclaimingModal(): IHideMachineReclaimingModalAction {
    return {
      type: MachineModalActions.HIDE_MACHINE_RECLAIMING_MODAL,
    };
  }

  public static showMachinePairingModal(pairedMsg: string): IShowMachinePairingModalAction {
    return {
      type: MachineModalActions.SHOW_MACHINE_PAIRING_MODAL,
      payload: { pairedMsg },
    };
  }

  public static hideMachinePairingModal(): IHideMachinePairingModalAction {
    return {
      type: MachineModalActions.HIDE_MACHINE_PAIRING_MODAL,
    };
  }

  public static showMachinePairReclaimModal(): IShowMachinePairReclaimModalAction {
    return {
      type: MachineModalActions.SHOW_MACHINE_PAIR_RECLAIM_MODAL,
    };
  }

  public static hideMachinePairReclaimModal(): IHideMachinePairReclaimModalAction {
    return {
      type: MachineModalActions.HIDE_MACHINE_PAIR_RECLAIM_MODAL,
    };
  }

  public static showMachineUnclaimModal(): IShowMachineUnclaimModalAction {
    return {
      type: MachineModalActions.SHOW_MACHINE_UNCLAIM_MODAL,
    };
  }

  public static hideMachineUnclaimModal(): IHideMachineUnclaimModalAction {
    return {
      type: MachineModalActions.HIDE_MACHINE_UNCLAIM_MODAL,
    };
  }
}
