import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import { IUserCreateRequestOptions, UserCreateActions } from '../../state/userCreateActions';
import { UserCreateForm } from './form/UserCreateForm';
import { StyledUserCreate } from './UserCreate.styles';
import { RegisterUserTitleEnum } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { splitLangPrefix } from 'app/modules/user-management/utils/splitLangPrefix';

export const UserCreate = (): JSX.Element => {
  const { t } = useTranslation();
  const [formInstance] = Form.useForm();
  const dispatch = useDispatch();

  const handleOk = useCallback(() => {
    formInstance.submit();
  }, [formInstance]);

  const onFinish = useCallback(
    (values: IUserCreateRequestOptions) => {
      const userInfoRegister: IUserCreateRequestOptions = {
        ...values,
        title: values.title === undefined ? RegisterUserTitleEnum.None : values.title,
        language: splitLangPrefix(values.language as string),
      };

      dispatch(UserCreateActions.userCreateRequest(userInfoRegister));
    },
    [dispatch]
  );

  return (
    <StyledUserCreate className="user-create">
      <div className="user-create__header">
        <div className="user-create__header-content">
          <h2 className="user-create__title">{t('userCreate.title')}</h2>
        </div>
      </div>
      <div className="user-create__body">
        <div className="user-create__body-content">
          <p className="user-create__mandatory-field">{t('userCreate.mandatoryField')}</p>
          <h3 className="user-create__sub-title">{t('userCreate.userInformation')}</h3>
          <UserCreateForm formInstance={formInstance} handleOk={handleOk} onFinish={onFinish} />
        </div>
      </div>
    </StyledUserCreate>
  );
};
