import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import { Content } from 'antd/lib/layout/layout';
import { AppFooter } from 'app/components/app-layout/AppFooter/AppFooter';
import { StyledLayout } from 'app/components/app-layout/AppLayout/AppLayout.styles';
import { AppBreadcrumbs } from 'app/components/AppBreadcrumbs/AppBreadcrumbs';
import { UserCreateSider } from 'app/modules/user-management/user-create/components/UserCreate/layout/UserCreateSider';
import { ScrollToTopButton } from 'lib/components/ScrollToTopButton/ScrollToTopButton';
import { UserCreateHeader } from 'app/modules/user-management/user-create/components/UserCreate/layout/UserCreateHeader';
import { splitLangPrefix } from 'app/modules/user-management/utils/splitLangPrefix';
import { getDecodedFromURL } from 'app/modules/user-management/utils/getDecodedFromURL';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import { IMomentIntl } from 'app/cross-cutting-concerns/translations/interfaces/Translations.types';
import { UserCreateSuccess } from 'app/modules/user-management/user-create/components/UserCreate/UserCreateSuccess';

export const UserCreateSuccessPage = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [locale, setLocale] = useState(Translations.getAntdLocale());

  useEffect(() => {
    const encodedData = getDecodedFromURL('user', '|');
    if (encodedData.length === 0) return;
    const language = encodedData.pop() || 'en';
    const getPrefixLanguage = splitLangPrefix(language);

    i18n.changeLanguage(getPrefixLanguage);
  }, [i18n]);

  useEffect(() => {
    if (i18n.language) {
      const momentIntl: IMomentIntl = t('momentIntl', { returnObjects: true });
      moment.locale(momentIntl.locale, momentIntl.localeSpecification);
      const antdLocale = Translations.getAntdLocale();
      setLocale(antdLocale);
    }
  }, [i18n.language, t]);

  return (
    <ConfigProvider locale={locale}>
      <StyledLayout className="layout" hasSider={false}>
        <UserCreateHeader />
        <Content>
          <UserCreateSider />
          <div className="outlet-wrapper">
            <AppBreadcrumbs />
            <UserCreateSuccess />
            <AppFooter>
              <ScrollToTopButton containerRef={containerRef} />
            </AppFooter>
          </div>
        </Content>
      </StyledLayout>
    </ConfigProvider>
  );
};
