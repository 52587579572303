import { Tag } from 'antd';
import { theme } from 'config/theme';
import { UserStatusValue } from 'app/modules/user-management/interfaces/User.types';

export const UsersStatusTag = ({ status }: { status: string }): JSX.Element => {
  const getStatusMapColor = (): string => {
    switch (status) {
      case UserStatusValue.AUTHORIZED:
        return theme.colors.green100;
      case UserStatusValue.UNAUTHORIZED:
        return theme.colors.tertiary;
      case UserStatusValue.IN_PROGRESS:
        return theme.colors.gray100;
      default:
        return theme.colors.gray100;
    }
  };

  return (
    <Tag color={getStatusMapColor()} className="customer-list__user-status-tag">
      {status}
    </Tag>
  );
};
