import {
  ListAlgNumberResponse,
  QueryListUsersArgs,
  ResponseListCountries,
  ResponseReassignUserToCustomer,
  ResponseRegisterUser,
  ResponseResendInviteEmail,
  ResponseUpdateUser,
  UserResults,
  UsersResults,
} from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export enum Role {
  INTERNAL_ADMIN = 'INTERNAL_ADMIN',
  CUSTOMER_ADMIN = 'CUSTOMER_ADMIN',
  CUSTOMER_STRATEGIC_MANAGER = 'CUSTOMER_STRATEGIC_MANAGER',
  CUSTOMER_OPERATOR = 'CUSTOMER_OPERATOR',
}

export enum UserStatusColors {
  RED = 'red',
  ORANGE = 'orange',
  GREEN = 'green',
}

export enum UserStatusValue {
  AUTHORIZED = 'Authorized',
  UNAUTHORIZED = 'Unauthorized',
  IN_PROGRESS = 'In Progress',
}

export interface IUserStatus {
  color: UserStatusColors;
  description: string;
}

export interface UserListResponse {
  listUsers: UsersResults;
}

export interface UserDetailsResponse {
  getUser: UserResults;
}

export interface UserCreateResponse {
  registerUser: ResponseRegisterUser;
}

export interface UserInviteResponse {
  inviteUser: UserResults;
}

export interface UserDeleteResponse {
  deleteUser: boolean;
}

export interface UserUpdateResponse {
  updateUser: ResponseUpdateUser;
}

export interface CountriesListResponse {
  listCountries: ResponseListCountries;
}

export interface UserResendInvitationResponse {
  resendInviteEmail: ResponseResendInviteEmail;
}

export interface UserListAlgNumbersResponse {
  listAlgNumber: ListAlgNumberResponse;
}

export interface ReassignUserToCustomerResponse {
  reassignUserToCustomer: ResponseReassignUserToCustomer;
}

export enum PollUserListOperation {
  INVITE_NEWUSER_IN_CUSTOMER_DETAIL = 'inviteNewUser',
  DELETE = 'delete',
}

export interface PollUserListRequestOptions {
  email: string;
  operation: PollUserListOperation;
  customerId?: string;
  filterOptions: QueryListUsersArgs;
}
