import { useEffect, useState } from 'react';
import { BrowserHistory } from 'history';
import { Store as IStore } from 'redux';
import { useRoutes } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import { AppConfigurationContext } from './app/cross-cutting-concerns/configuration/AppConfigurationContext';
import { DependenciesContext } from './app/cross-cutting-concerns/dependency-injection/DependenciesContext';
import { IDependencies } from './app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { IRoute } from './app/cross-cutting-concerns/routing/interfaces/Routing.types';
import { appConfig } from './config/app-config';
import { GlobalStyles } from './config/global-styles';
import { BrowserRouter } from './app/cross-cutting-concerns/routing/components/BrowserRouter/BrowserRouter';
import { Routing } from './app/cross-cutting-concerns/routing/Routing';
import './App.css';
import { IMomentIntl } from 'app/cross-cutting-concerns/translations/interfaces/Translations.types';
import { Translations } from 'app/cross-cutting-concerns/translations/Translations';
import { UsersnapProvider } from 'app/cross-cutting-concerns/usersnap/UsersnapContext';

interface AppProps {
  store: IStore;
  theme: DefaultTheme;
  routing: Routing;
  history: BrowserHistory;
  dependencies: IDependencies;
}

const RouteRenderer = ({ routes }: { routes: IRoute[] }): JSX.Element | null => {
  const routerElements = useRoutes(routes);
  return routerElements;
};

export const App = ({ store, theme, history, routing, dependencies }: AppProps): JSX.Element => {
  const { i18n, t } = useTranslation();
  const [locale, setLocale] = useState(Translations.getAntdLocale());
  const [userSnapLocale, setUserSnapLocale] = useState(Translations.getUserSnapLocaleSupported());
  const routes = routing.getAllRoutesWithAppLayout();

  useEffect(() => {
    if (i18n.language) {
      const momentIntl: IMomentIntl = t('momentIntl', { returnObjects: true });
      moment.locale(momentIntl.locale, momentIntl.localeSpecification);
      const antdLocale = Translations.getAntdLocale();
      const userSnapLocaleSupported = Translations.getUserSnapLocaleSupported();
      setLocale(antdLocale);
      setUserSnapLocale(userSnapLocaleSupported);
    }
  }, [i18n.language, t]);

  return (
    <StoreProvider store={store}>
      <AppConfigurationContext.Provider value={appConfig}>
        <DependenciesContext.Provider value={dependencies}>
          <AntdConfigProvider locale={locale}>
            <ThemeProvider theme={theme}>
              <UsersnapProvider initParams={{ locale: userSnapLocale, nativeScreenshot: true }}>
                <BrowserRouter history={history}>
                  <RouteRenderer routes={routes} />
                </BrowserRouter>
                <GlobalStyles />
              </UsersnapProvider>
            </ThemeProvider>
          </AntdConfigProvider>
        </DependenciesContext.Provider>
      </AppConfigurationContext.Provider>
    </StoreProvider>
  );
};

export default App;
