import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { call, getContext, put, retry, takeLatest } from 'typed-redux-saga';
import {
  IGetUserListErrorAction,
  IGetUserListFiltersErrorAction,
  IGetUserListFiltersSuccessAction,
  IGetUserListRequestAction,
  IGetUserListSuccessAction,
  IPollUserListRequestAction,
  UserListActions,
} from './userListActions';
import {
  UserListResponse,
  UserListAlgNumbersResponse,
  PollUserListOperation,
} from 'app/modules/user-management/interfaces/User.types';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { Optional } from 'lib/types/Optional';
import { User } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { POLL_INTERVAL, POLL_MAX_RETRIES } from 'config/constants';

export function* getUserListSaga(
  action: IGetUserListRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<UserListResponse>
  | PutEffect<IGetUserListSuccessAction>
  | PutEffect<IGetUserListErrorAction>,
  void,
  IDependencies
> {
  const { userService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(userService.list, action.payload);
    yield* put(UserListActions.getUserListSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.getUserListError({
        error,
      })
    );
  }
}

export function* getUserListFiltersSaga(): Generator<
  | GetContextEffect
  | CallEffect<Optional<UserListAlgNumbersResponse>>
  | PutEffect<IGetUserListFiltersSuccessAction>
  | PutEffect<IGetUserListFiltersErrorAction>,
  void,
  IDependencies
> {
  const { userService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(userService.listAlgs);
    yield* put(UserListActions.getUserListFiltersSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.getUserListFiltersError({
        error,
      })
    );
  }
}

export function* pollUserListSaga(
  action: IPollUserListRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<UserListResponse>
  | PutEffect<IGetUserListSuccessAction>
  | PutEffect<IGetUserListErrorAction>,
  void,
  IDependencies
> {
  const { userService } = yield* getContext<IDependencies>('dependencies');
  const { email, operation, customerId, filterOptions } = action.payload;

  const getUserListAfterMuitation = async (): Promise<UserListResponse> => {
    let predicate: () => boolean;

    const checkUser: UserListResponse = await userService.list({ search: email });

    const newUserList: User[] = (checkUser.listUsers.data as User[]) || [];

    switch (operation) {
      case PollUserListOperation.INVITE_NEWUSER_IN_CUSTOMER_DETAIL:
        predicate = (): boolean => newUserList.some(user => user?.email === email && user?.customerId === customerId);
        break;

      case PollUserListOperation.DELETE:
        predicate = (): boolean => newUserList.length === 0 || newUserList.every(user => user?.email !== email);
        break;

      default: {
        throw new Error(`Invalid operation: ${operation}`);
      }
    }

    if (!predicate()) {
      throw new Error('List User not updated');
    }

    const response = await userService.list(filterOptions);

    return response;
  };

  try {
    const response = yield* retry(POLL_MAX_RETRIES, POLL_INTERVAL, getUserListAfterMuitation);
    yield* put(UserListActions.getUserListSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      UserListActions.getUserListError({
        error,
      })
    );
  }
}

export function* userListSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(UserListActions.GET_USER_LIST_REQUEST, getUserListSaga);
  yield* takeLatest(UserListActions.GET_USER_LIST_FILTERS_REQUEST, getUserListFiltersSaga);
  yield* takeLatest(UserListActions.POLL_USER_LIST_REQUEST, pollUserListSaga);
}
