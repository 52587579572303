import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row, Form } from 'antd';
import {
  selectChangeCustomerModalUserId,
  selectIsChangeCustomerModalLoading,
  selectIsChangeCustomerModalVisible,
} from '../../state/userModalsSelectors';
import { PrimaryButton } from '../../../../../../lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from '../../../../../../lib/components/Button/SecondaryButton/SecondaryButton';
import { UserModalsActions } from '../../state/userModalsActions';
import { StyledChangeCustomerModal } from './ChangeCustomerModal.styles';
import { Select } from 'lib/components/Select/Select';
import { MachineDirectorySelectors } from 'app/modules/machine-directory/state/machineDirectorySelectors';
import { getCustomerListSearchColumns } from 'app/components/MachineSelectedTable/columns/CustomerSelected.columns';
import { CustomerSearchSelectors } from 'app/modules/user-management/customer-search/state/customerSearchSelectors';
import { Table } from 'lib/components/Table/Table';
import { CustomerSearchActions } from 'app/modules/user-management/customer-search/state/customerSearchAction';
import { AutoCompleteInput } from 'lib/components/AutoCompleteInput/AutoCompleteInput';
import { MachineDirectoryActions } from 'app/modules/machine-directory/state/machineDirectoryActions';
import { UserDetailsActions } from 'app/modules/user-management/user-details/state/userDetailsActions';
import { PopConfirm } from 'lib/components/PopConfirm/PopConfirm';

const { Item } = Form;
const { Option } = Select;

export const ChangeCustomerModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formChangeCustomer] = Form.useForm();
  const formValues = Form.useWatch([], formChangeCustomer);

  const [isPopupCancelBtnOpen, setIsPopupCancelBtnOpen] = useState(false);
  const [isPopupConfirmBtnOpen, setIsPopupConfirmBtnOpen] = useState(false);
  const [customerSelectedId, setCustomerSelectedId] = useState<string>('');

  const isVisible = useSelector(selectIsChangeCustomerModalVisible);
  const isLoading = useSelector(selectIsChangeCustomerModalLoading);
  const userId = useSelector(selectChangeCustomerModalUserId);
  const algs = useSelector(MachineDirectorySelectors.selectAlgs);
  const isAlgsLoading = useSelector(MachineDirectorySelectors.selectAreAlgsLoading);
  const customerList = useSelector(CustomerSearchSelectors.selectCustomerSearchList);
  const isCustomerSearchLoading = useSelector(CustomerSearchSelectors.selectIsCustomerSearchLoading);

  const onConfirmPopupCancelBtn = (): void => {
    dispatch(UserModalsActions.hideChangeCustomerModal());
    formChangeCustomer.resetFields();
  };
  const onCancelPopupCancelBtn = (): void => setIsPopupCancelBtnOpen(false);
  const handleCancel = useCallback(() => {
    const isFieldsTouched = formChangeCustomer.isFieldsTouched();
    if (isFieldsTouched) {
      setIsPopupCancelBtnOpen(true);
    } else {
      dispatch(UserModalsActions.hideChangeCustomerModal());
      formChangeCustomer.resetFields();
    }
  }, [dispatch, formChangeCustomer]);

  const onConfirmPopupSaveBtn = (): void => {
    formChangeCustomer.submit();
    setIsPopupConfirmBtnOpen(false);
  };
  const onCancelPopupSaveBtn = (): void => setIsPopupConfirmBtnOpen(false);

  const customerSearchListColumns = getCustomerListSearchColumns(t);
  const onSelectCustomer = (selectedRowKeys: React.Key[]): void => {
    setCustomerSelectedId(selectedRowKeys[0] as string);
  };

  useEffect(() => {
    dispatch(MachineDirectoryActions.getAlgsRequest());
  }, [dispatch, formChangeCustomer]);

  const handleOk = useCallback(() => {
    setIsPopupConfirmBtnOpen(true);
  }, []);

  useEffect(() => {
    if (formValues && formValues.algNumber && formValues.customerNameOrNumber) {
      dispatch(
        CustomerSearchActions.customerSearchRequest({
          algNumber: formValues.algNumber,
          customerName: formValues.customerNameOrNumber,
          customerNumber: formValues.customerNameOrNumber,
        })
      );
    }
  }, [dispatch, formChangeCustomer, formValues]);

  const onFinish = useCallback(() => {
    if (!userId) {
      return;
    }
    dispatch(
      UserDetailsActions.userReassignRequest({
        input: {
          userId,
          customerId: customerSelectedId,
        },
      })
    );
  }, [dispatch, customerSelectedId, userId]);

  return (
    <StyledChangeCustomerModal
      width={600}
      className="change-customer-modal"
      title={t('userDetails.reassignUser')}
      afterClose={(): void => {
        setIsPopupCancelBtnOpen(false);
        dispatch(CustomerSearchActions.customerSearchClearList());
      }}
      open={isVisible}
      destroyOnClose
      closable={false}
      footer={[
        <Row key="footer">
          <Col span={24} className="change-customer-modal__footer">
            <PopConfirm
              key="cancel-button"
              title={t('common.popConfirm.titleDiscard')}
              cancelText={t('common.popConfirm.cancelText')}
              okText={t('common.popConfirm.okTextDiscard')}
              open={isPopupCancelBtnOpen}
              onConfirm={onConfirmPopupCancelBtn}
              onCancel={onCancelPopupCancelBtn}
            >
              <SecondaryButton onClick={handleCancel} className="cancel-button" key="cancel-button">
                {t('common.cancel')}
              </SecondaryButton>
            </PopConfirm>
            <PopConfirm
              key="save-button"
              title={t('common.popConfirm.titleConfirm')}
              cancelText={t('common.popConfirm.cancelText')}
              okText={t('common.popConfirm.okTextConfirm')}
              open={isPopupConfirmBtnOpen}
              onConfirm={onConfirmPopupSaveBtn}
              onCancel={onCancelPopupSaveBtn}
            >
              <PrimaryButton
                key="submit"
                className="submit-button"
                type="primary"
                loading={isLoading}
                onClick={handleOk}
                disabled={customerSelectedId === ''}
              >
                {t('common.save')}
              </PrimaryButton>
            </PopConfirm>
          </Col>
        </Row>,
      ]}
    >
      <Form
        preserve={false}
        form={formChangeCustomer}
        onFinish={onFinish}
        className="change-customer-modal"
        layout="vertical"
      >
        <Row gutter={24} className="change-customer-modal__row" justify="start">
          <Col span={12}>
            <Item
              name="algNumber"
              label={t('userDetails.changeCustomerModal.alg')}
              className="change-customer-modal__input-group"
              rules={[{ required: true, message: t('userDetails.changeCustomerModal.algRequired') }]}
            >
              <Select
                placeholder={t('userDetails.changeCustomerModal.algPlaceholder')}
                className="change-customer-modal__alg-select"
                showArrow
                showSearch
                loading={isAlgsLoading}
              >
                {algs.map(alg => (
                  <Option key={alg} value={alg}>
                    {alg}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col span={12}>
            <Item
              name="customerNameOrNumber"
              label={t('userDetails.changeCustomerModal.customer')}
              requiredMark={true}
              rules={[
                {
                  required: true,
                  message: t('userDetails.changeCustomerModal.customerRequired'),
                },
              ]}
            >
              <AutoCompleteInput
                placeholder={t('userDetails.changeCustomerModal.customerPlaceholder')}
                className="change-customer-modal__customer-name-input"
              />
            </Item>
          </Col>
        </Row>
        <Table
          className="change-customer-modal__customer-selection-table"
          loading={isCustomerSearchLoading}
          columns={customerSearchListColumns}
          dataSource={customerList}
          rowKey="customerId"
          rowSelection={{
            columnTitle: t('common.selected'),
            onChange: onSelectCustomer,
            type: 'radio',
          }}
        />
      </Form>
    </StyledChangeCustomerModal>
  );
};
