import { Steps } from 'antd';
import styled from 'styled-components';

export const StyledSteps = styled(Steps)`
  .ant-steps-item {
    padding: 24px;

    .ant-steps-item-container {
      .ant-steps-item-tail {
        &::after {
          display: none;
        }
      }

      .ant-steps-item-icon {
        border-color: ${(props): string => props.theme.colors.neutralWhiteColor};
        font-family: ${(props): string => props.theme.fonts.heading};
        font-size: ${(props): string => props.theme.fontSizes.stepNumber};
        font-weight: 700;

        .ant-steps-icon {
          font-family: ${(props): string => props.theme.fonts.heading};
          font-size: ${(props): string => props.theme.fontSizes.stepNumber};
          font-weight: 700;
          left: 0px;
          top: 1px;

          span {
            display: none;
          }
        }
      }

      .ant-steps-item-content {
        overflow: visible;
        margin-left: 48px;

        .ant-steps-item-title {
          font-family: ${(props): string => props.theme.fonts.pageTitle};
          font-size: ${(props): string => props.theme.fontSizes.stepTitle};
          font-weight: 900;
          line-height: 24px;
          text-transform: uppercase;
          margin: 6px 0 16px 0;
        }
      }
    }
  }

  .ant-steps-item-wait {
    border-bottom: 1px solid ${(props): string => props.theme.colors.brightGrey};

    .ant-steps-item-icon {
      background: ${(props): string => props.theme.colors.neutralWhiteColor};

      .ant-steps-icon {
        color: ${(props): string => props.theme.colors.white};
      }
    }

    .ant-steps-item-content {
      display: none;
    }
  }

  .ant-steps-item-active {
    background: ${(props): string => props.theme.colors.stepsBackgroundColor};

    .ant-steps-item-icon {
      background: ${(props): string => props.theme.colors.black};

      .ant-steps-icon {
        color: ${(props): string => props.theme.colors.primary};
      }
    }
  }

  .ant-steps-item-finish {
    border-top: 1px solid ${(props): string => props.theme.colors.brightGrey};

    .ant-steps-item-icon {
      background: ${(props): string => props.theme.colors.neutralWhiteColor};

      .ant-steps-icon {
        color: ${(props): string => props.theme.colors.white};
      }
    }

    .ant-steps-item-content {
      .ant-steps-item-title {
        color: ${(props): string => props.theme.colors.neutralWhiteColor};
      }

      .ant-steps-item-description {
        display: none;
      }
    }
  }
`;
