import { SelectProps as AntdSelectProps } from 'antd';
import { SelectValue as AntdSelectValue } from 'antd/lib/select';
import { StyledOptGroup, StyledOption, StyledSelect } from './Select.styles';

type SelectProps = AntdSelectProps<AntdSelectValue>;

export const Select = (props: SelectProps): JSX.Element => (
  // @ts-ignore
  <StyledSelect getPopupContainer={(triggerNode): HTMLElement => triggerNode} {...props} />
);

Select.Option = StyledOption;
Select.OptGroup = StyledOptGroup;
