import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { RadioChangeEvent } from 'antd/lib/radio';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from '../../../../../../lib/components/Button/PrimaryButton/PrimaryButton';
import { Input } from '../../../../../../lib/components/Input/Input';
import { Select } from '../../../../../../lib/components/Select/Select';
import { MachineDirectoryActions } from '../../../state/machineDirectoryActions';
import { getCustomerListColumns } from '../../../machine-claiming-pairing/components/MachineClaim/CustomerList/columns/CustomerList.columns';
import { StyledMachineClaiming } from './MachineClaiming.styles';
import { MachineDirectorySelectors } from 'app/modules/machine-directory/state/machineDirectorySelectors';
import { MaterialNumberAutoComplete } from 'app/modules/machine-directory/machine-claiming-pairing/components/MaterialNumberAutoComplete/MaterialNumberAutoComplete';
import { Table } from 'lib/components/Table/Table';
import { CustomerSearchSelectors } from 'app/modules/user-management/customer-search/state/customerSearchSelectors';
import { CustomerSearchActions } from 'app/modules/user-management/customer-search/state/customerSearchAction';
import { MachineClaimPairSelectors } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairSelectors';
import { MachineClaimPairActions } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairActions';
import { CustomerSearchFormValues } from 'app/modules/user-management/interfaces/Customer.types';

const { Option } = Select;

export const MachineClaiming = (): JSX.Element => {
  const [selectedCustomer, setSelectCustomer] = useState<string>('');
  const [formSearch] = useForm();
  const [formClaim] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const algs = useSelector(MachineDirectorySelectors.selectAlgs);
  const areAlgsLoading = useSelector(MachineDirectorySelectors.selectAreAlgsLoading);
  const customerList = useSelector(CustomerSearchSelectors.selectCustomerSearchList);
  const isCustomerSearchLoading = useSelector(CustomerSearchSelectors.selectIsCustomerSearchLoading);
  const isMachineClaimLoading = useSelector(MachineClaimPairSelectors.selectMachineClaimLoading);

  const [materialNumber, setMaterialNumber] = useState<string>('');
  const [serialNumber, setSerialNumber] = useState<string>('');
  const [selectedAlg, setSelectedAlg] = useState<string>('');
  const isClaimButtonDisabled = useMemo(
    () => !selectedCustomer || !materialNumber || !serialNumber,
    [materialNumber, selectedCustomer, serialNumber]
  );

  const handleSetButtonDisable = (): void => {
    setMaterialNumber(formClaim.getFieldValue('materialNumber'));
    setSerialNumber(formClaim.getFieldValue('serialNumber'));
  };

  useEffect(() => {
    dispatch(MachineDirectoryActions.getAlgsRequest());
    return () => {
      dispatch(CustomerSearchActions.customerSearchClearList());
    };
  }, [dispatch]);

  const handleClaimButtonClick = useCallback(() => {
    formClaim.submit();
  }, [formClaim]);

  const handleSearchButtonClick = useCallback(() => {
    formSearch.submit();
  }, [formSearch]);

  const handleSearch = useCallback(
    (formValues: CustomerSearchFormValues) => {
      dispatch(
        CustomerSearchActions.customerSearchRequest({
          algNumber: formValues.algNumber,
          customerName: formValues.customerNameOrNumber,
          customerNumber: formValues.customerNameOrNumber,
        })
      );
      setSelectedAlg(formValues.algNumber);
    },
    [dispatch]
  );

  const handleFinishClaim = useCallback(
    (formValues: { materialNumber: string; serialNumber: string }) => {
      dispatch(
        MachineClaimPairActions.claimMachineRequest({
          materialNumber: formValues.materialNumber.trim(),
          serialNumber: formValues.serialNumber.trim(),
          customerId: selectedCustomer,
          algNumber: selectedAlg,
        })
      );
    },
    [dispatch, selectedAlg, selectedCustomer]
  );

  const handleCustomerNameOrNumberBlur = (_e: React.ChangeEvent<HTMLInputElement>): void => {
    formSearch.validateFields(['customerNameOrNumber']);
  };

  const handleSelectCustomer = (e: RadioChangeEvent): void => {
    setSelectCustomer(e.target.value);
  };

  const tableColumns = getCustomerListColumns({ t, selectedCustomer, handleSelectCustomer });

  return (
    <StyledMachineClaiming className="machine-list">
      <div className="machine-claiming__header">
        <div className="machine-claiming__header-content">
          <div className="machine-claiming__page-info">
            <h1 className="machine-claiming__title">{t('machineClaiming.title')}</h1>
            <pre className="machine-claiming__description">{t('machineClaiming.description')}</pre>
          </div>
        </div>
      </div>

      <div className=" machine-claiming__body">
        <div className="machine-claiming__body-content">
          <Form
            form={formSearch}
            layout="vertical"
            name="machine-claiming"
            onFinish={handleSearch}
            validateTrigger="onChange"
          >
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name="algNumber"
                  label={t('common.alg')}
                  required
                  className="machine-claiming__form-item"
                  rules={[
                    {
                      required: true,
                      message: t('forms.errors.required'),
                    },
                  ]}
                >
                  <Select className="machine-claiming__alg-select" loading={areAlgsLoading}>
                    {algs.map(alg => (
                      <Option value={alg} key={alg}>
                        {alg}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="customerNameOrNumber"
                  label={t('machineClaiming.forms.customerNameOrNumber')}
                  className="machine-claiming__form-item"
                  requiredMark={true}
                  rules={[
                    {
                      required: true,
                      message: t('machineClaiming.forms.errors.requiredCustomer'),
                    },
                  ]}
                  validateTrigger="onChange"
                >
                  <Input
                    placeholder={t('machineClaiming.forms.placeholderCustomerNameOrNumber')}
                    className="machine-claiming__customer-input"
                    onBlur={handleCustomerNameOrNumberBlur}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Form.Item>
              <PrimaryButton
                size="m"
                className="machine-claiming__search-button machine-claiming__button"
                onClick={handleSearchButtonClick}
                loading={isCustomerSearchLoading}
              >
                {t('machineClaiming.searchButton')}
              </PrimaryButton>
            </Form.Item>
          </Row>
          {customerList.length > 0 && (
            <Table
              rowKey={'customerId'}
              dataSource={customerList || []}
              columns={tableColumns}
              className="machine-claiming__customer-list-table"
            />
          )}
          <h3 className="machine-claiming__subheading">{t('machineClaiming.machinesToBeClaimed')}</h3>
          <Form
            form={formClaim}
            layout="vertical"
            name="machine-claiming"
            onFinish={handleFinishClaim}
            validateMessages={{ required: t('forms.errors.required') }}
            onFieldsChange={handleSetButtonDisable}
          >
            <Row gutter={24}>
              <Col>
                <Form.Item
                  name="materialNumber"
                  label={t('machineClaiming.forms.materialNumber')}
                  className="machine-claiming__form-item"
                  rules={[{ required: true }]}
                >
                  <MaterialNumberAutoComplete
                    className="machine-claiming__material-number-auto-complete machine-claiming__auto-complete"
                    placeholder={t('machineClaiming.forms.placeholderMaterialNumber')}
                    classifications={['Stand-Alone', 'Gateway-Connected-Device']}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="serialNumber"
                  label={t('machineClaiming.forms.serialNumber')}
                  className="machine-claiming__form-item"
                  rules={[{ required: true }]}
                >
                  <Input
                    className="machine-claiming__serial-number-input machine-claiming__input"
                    placeholder={t('machineClaiming.forms.placeholderSerialNumber')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <PrimaryButton
              size="m"
              className="machine-claiming__claim-button"
              onClick={handleClaimButtonClick}
              disabled={isClaimButtonDisabled}
              loading={isMachineClaimLoading}
            >
              {t('machineClaiming.claimButton')}
            </PrimaryButton>
          </Form>
        </div>
      </div>
    </StyledMachineClaiming>
  );
};
