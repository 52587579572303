import React, { ReactElement } from 'react';
import { ReactComponent as ClaimPair } from '../app/assets/images/claim-pair.svg';
import { ReactComponent as Machine } from '../app/assets/images/machine-directory.svg';
import { ReactComponent as UserSetting } from '../app/assets/images/user-setting.svg';
import { ReactComponent as UserProfile } from '../app/assets/images/user-profile.svg';
import { RoutePaths } from './route-paths';

export interface MenuItem {
  path: string;
  translationKey: string;
  icon: ReactElement;
}

export const menuItems = [
  { translationKey: 'pages.machineClaimingPairing', path: RoutePaths.MACHINE_CLAIM_PAIR, icon: <ClaimPair /> },
  { translationKey: 'pages.machineDirectory', path: RoutePaths.MACHINES, icon: <Machine /> },
  { translationKey: 'pages.customerManagement', path: RoutePaths.CUSTOMERS, icon: <UserProfile /> },
  { translationKey: 'pages.userManagement', path: RoutePaths.USERS, icon: <UserSetting /> },
];
