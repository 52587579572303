import { ReactNode } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { StyledAppFooter } from './AppFooter.styles';

interface AppFooterProps {
  children?: ReactNode;
  className?: string;
}

export const AppFooter = ({ children, className }: AppFooterProps): JSX.Element => {
  const { t } = useTranslation();
  const appFooterClassName = classnames(className, 'footer');

  return (
    <StyledAppFooter className={appFooterClassName}>
      {children}
      <div className="footer__banner">
        <span className="footer__product-name">{t('footer.productName')}</span>
        &nbsp;&ndash;&nbsp;
        <span className="footer__terms">{t('footer.terms')}</span>
      </div>
      <div className="footer__copyright">{t('footer.copyright')}</div>
    </StyledAppFooter>
  );
};
