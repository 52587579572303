import kiraB50Image from 'app/assets/images/machines/kira-b50.png';
import b4522cImage from 'app/assets/images/machines/b4522c.png';

export const MACHINE_IMAGE_BY_MATERIAL_NUMBER: Record<string, string> = {
  '1.533-001.0': kiraB50Image,
  '1.783-460.0': b4522cImage,
  '1.783-465.0': b4522cImage,
  '1.783-466.0': b4522cImage,
};

export const PAGE_SIZE_OPTIONS = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '30',
    value: 30,
  },
  {
    label: '50',
    value: 50,
  },
];

export const DEFAULT_PAGE_SIZE_VALUE = PAGE_SIZE_OPTIONS[PAGE_SIZE_OPTIONS.length - 1].value;

export const DEFAULT_PAGE_VALUE = 1;

// See https://spec.graphql.org/June2018/#sec-Int
export const GRAPHQL_MAX_INT_VALUE = 2 ** 31 - 1;
export const MAP_BOUNDING_BOX = {
  ne: {
    lat: 90,
    lng: 180,
  },
  sw: {
    lat: -90,
    lng: -180,
  },
};

export const DEFAULT_ADDRESSES_SUGGESTION_LIMIT_VALUE = 5;

export const POLL_MAX_RETRIES = 10;
export const POLL_INTERVAL = 2000;

export const AccessRequestSPLink = 'https://taskbox.kaercher.com/plugins/servlet/desk/portal/231/create/3817';
