import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledCustomerDeleteModal = styled(Modal)`
  .customer-delete-modal__description {
    font-family: ${(props): string => props.theme.fonts.modalBody};
    font-size: ${(props): string => props.theme.fontSizes.p0};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.p};
  }

  .customer-delete-modal__customer-name {
    font-family: ${(props): string => props.theme.fonts.formLabel};
    font-size: ${(props): string => props.theme.fontSizes.stepTitle};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
    line-height: ${(props): string => props.theme.lineHeights.formSubheading};
  }
`;
