import styled from 'styled-components';

export const StyledTable = styled.div`
  border: 1px solid ${(props): string => props.theme.colors.semiGray};
  border-radius: 0;

  .ant-table-container {
    border-radius: 0;

    table {
      border-collapse: collapse;

      .ant-table-thead {
        > tr:first-child th {
          border-radius: 0;
        }

        > tr > th {
          background-color: ${(props): string => props.theme.colors.semiGray};
          font-family: ${(props): string => props.theme.fonts.heading};
          font-size: 11px;
          line-height: 13px;
          color: ${(props): string => props.theme.colors.text};
          text-transform: uppercase;
          padding: 17px 24px;
          border: none;
          height: 48px;

          &:before {
            display: none;
          }

          .table-head--with-icon {
            display: flex;
            align-items: center;

            .table-head__icon {
              margin-left: 15px;
            }
          }
        }
      }

      .ant-table-tbody {
        tr:nth-child(even) {
          background-color: #f8f8f8; // TODO: propose an existing kind of grey color
        }

        tr > td {
          font-family: ${(props): string => props.theme.fonts.menu};
          word-break: break-word;
          border-bottom: none;
          font-size: 13px;
          line-height: 17px;
          color: ${(props): string => props.theme.colors.text};
          padding: 24px 24px 23px;
        }

        > tr.ant-table-row-selected:hover > td {
          background-color: inherit !important;
        }

        .ant-table-cell {
          background-color: inherit !important;
        }
      }
    }
  }

  .ant-table-footer {
    background-color: ${(props): string => props.theme.colors.white};
    padding: 20px 24px;
  }

  .table__link--no-styling {
    color: ${(props): string => props.theme.colors.text};
  }

  td.ant-table-column-sort {
    background: inherit;
  }

  .ant-table-column-sorters {
    display: block;
  }

  .ant-table-column-sorter {
    display: none;
  }

  .ant-radio-checked {
    .ant-radio-inner {
      background-color: ${(props): string => props.theme.colors.black};
      border-color: ${(props): string => props.theme.colors.text};
    }

    .ant-radio-inner:after {
      background-color: ${(props): string => props.theme.colors.primary};
    }

    &::after {
      border-color: ${(props): string => props.theme.colors.text};
    }
  }

  .ant-radio:hover {
    .ant-radio-inner {
      border-color: ${(props): string => props.theme.colors.text};
    }
  }
`;
