import {
  CustomerManagementEntity,
  SortOrders,
} from '../../../../cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { PaginationTokens } from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Optional } from 'lib/types/Optional';

export class customerListSelector {
  public static selectData = (state: IState): CustomerManagementEntity[] =>
    state.modules['customer-management']['customer-list'].data;

  public static selectTotalCount = (state: IState): Optional<number> =>
    state.modules['customer-management']['customer-list'].totalCount;

  public static selectIsLoading = (state: IState): Optional<boolean> =>
    state.modules['customer-management']['customer-list'].isLoading;

  public static selectPaginationTokens = (state: IState): PaginationTokens =>
    state.modules['customer-management']['customer-list'].paginationTokens;

  public static selectPage = (state: IState): number => state.modules['customer-management']['customer-list'].page;

  public static selectPageSize = (state: IState): number =>
    state.modules['customer-management']['customer-list'].pageSize;

  public static selectAlgsFilters = (state: IState): string[] =>
    state.modules['customer-management']['customer-list'].filters.available.algs;

  public static selectIsFiltersLoading = (state: IState): boolean =>
    state.modules['customer-management']['customer-list'].filters.isLoading;

  public static selectActiveAlgFilter = (state: IState): Optional<string> =>
    state.modules['customer-management']['customer-list'].filters.active.alg;

  public static selectSearchText = (state: IState): Optional<string> =>
    state.modules['customer-management']['customer-list'].searchText;

  public static selectSortField = (state: IState): Optional<string> =>
    state.modules['customer-management']['customer-list'].sortOptions.field;

  public static selectSortOrder = (state: IState): Optional<SortOrders> =>
    state.modules['customer-management']['customer-list'].sortOptions.order;
}
