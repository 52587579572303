import { createGlobalStyle } from 'styled-components';

export const StyledMachineIsPairableGlobalStyles = createGlobalStyle`
  .machine-pairable-tooltip-overlay {
    padding-top: 0px;
    
    .ant-tooltip-content {
      ${(props): string => props.theme.fonts.caption}
      color: ${(props): string => props.theme.colors.black};
    }

    .ant-tooltip-inner {
      background-color: ${(props): string => props.theme.colors.white};
      color: ${(props): string => props.theme.colors.black};
      float: right;
      width: 393px;
      padding: 16px;
    }

    .ant-tooltip-arrow {
      display: none;
    }
  }
`;
