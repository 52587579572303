import {
  CreateCustomerInput,
  CustomerManagementEntity,
  MachineOwnership,
  User,
} from '../../../../cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { IState } from '../../../../cross-cutting-concerns/state-management/interfaces/IState';
import { Optional } from 'lib/types/Optional';

export class customerModalSelector {
  public static selectIsCreateCustomerModalOpen = (state: IState): boolean =>
    state.modules['customer-management'].modals.createModal.isOpen;

  public static selectIsCreateCustomerModalLoading = (state: IState): boolean =>
    state.modules['customer-management'].modals.createModal.isLoading;

  public static selectCreateCustomerRequest = (state: IState): Optional<CreateCustomerInput> | null =>
    state.modules['customer-management'].modals.createModal.customerRequest;

  public static selectIsConfirmCustomerModalOpen = (state: IState): boolean =>
    state.modules['customer-management'].modals.confirmModal.isOpen;

  public static selectIsConfirmCustomerModalDuplicate = (state: IState): boolean =>
    state.modules['customer-management'].modals.confirmModal.isDuplicate;

  public static selectIsConfirmCustomerModalConfirm = (state: IState): boolean =>
    state.modules['customer-management'].modals.confirmModal.isConfirm;

  public static selectIsEditCustomerModalOpen = (state: IState): Optional<boolean> =>
    state.modules['customer-management'].modals.editModal.isOpen;

  public static selectIsEditCustomerModalLoading = (state: IState): Optional<boolean> =>
    state.modules['customer-management'].modals.editModal.isLoading;

  public static selectEditCustomerModalData = (state: IState): Optional<CustomerManagementEntity> =>
    state.modules['customer-management'].modals.editModal.data;

  public static selectIsDeleteCustomerModalOpen = (state: IState): Optional<boolean> =>
    state.modules['customer-management'].modals.deleteModal.isOpen;

  public static selectIsDeleteCustomerModalLoading = (state: IState): Optional<boolean> =>
    state.modules['customer-management'].modals.deleteModal.isLoading;

  public static selectIsDeleteCustomerModalData = (state: IState): Optional<boolean> =>
    state.modules['customer-management'].modals.deleteModal.data;

  public static selectIsInviteUserModalOpen = (state: IState): Optional<boolean> =>
    state.modules['customer-management'].modals.inviteUserModal.isOpen;

  public static selectIsInviteUserModalLoading = (state: IState): Optional<boolean> =>
    state.modules['customer-management'].modals.inviteUserModal.isLoading;

  public static selectInviteUserModalData = (state: IState): Optional<User> =>
    state.modules['customer-management'].modals.inviteUserModal.data;

  public static selectIsReclaimMachineModalOpen = (state: IState): Optional<boolean> =>
    state.modules['customer-management'].modals.reclaimMachineModal.isOpen;

  public static selectIsReclaimMachineModalLoading = (state: IState): Optional<boolean> =>
    state.modules['customer-management'].modals.reclaimMachineModal.isLoading;

  public static selectIsReclaimMachineModalData = (state: IState): Optional<MachineOwnership> =>
    state.modules['customer-management'].modals.reclaimMachineModal.data;

  public static selectIsUnclaimMachineModalOpen = (state: IState): Optional<boolean> =>
    state.modules['customer-management'].modals.unclaimMachineModal.isOpen;

  public static selectIsUnclaimMachineModalLoading = (state: IState): Optional<boolean> =>
    state.modules['customer-management'].modals.unclaimMachineModal.isLoading;

  public static selectIsClaimNewDeviceModalOpen = (state: IState): Optional<boolean> =>
    state.modules['customer-management'].modals.claimNewDeviceModal.isOpen;

  public static selectIsClaimNewDeviceModalLoading = (state: IState): Optional<boolean> =>
    state.modules['customer-management'].modals.claimNewDeviceModal.isLoading;
}
