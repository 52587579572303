import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { inject, injectable } from 'tsyringe';
import {
  UserCreateResponse,
  UserInviteResponse,
  UserDetailsResponse,
  UserListResponse,
  UserDeleteResponse,
  UserUpdateResponse,
  UserResendInvitationResponse,
  UserListAlgNumbersResponse,
  ReassignUserToCustomerResponse,
} from './interfaces/User.types';
import { CustomerSearchResponse } from './interfaces/Customer.types';
import { GraphQlClient } from 'app/cross-cutting-concerns/communication/clients/GraphQlClient';
import {
  GetCustomersInput,
  InviteUserInput,
  MutationDeleteUserArgs,
  MutationReassignUserToCustomerArgs,
  MutationResendInviteEmailArgs,
  MutationUpdateUserArgs,
  QueryListUsersArgs,
  RegisterUserInput,
} from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export type CustomerClientSearchOptions = GetCustomersInput;

export type UserClientCreateOptions = RegisterUserInput;

export type UserClientInviteOptions = InviteUserInput;

export type UserClientListOptions = QueryListUsersArgs;

export type UserClientDeleteOptions = MutationDeleteUserArgs;

export type UserClientResendInvitationOptions = MutationResendInviteEmailArgs;

export type UserClientUpdateOptions = MutationUpdateUserArgs;

export type UserClientReassignOptions = MutationReassignUserToCustomerArgs;

@injectable()
export class UserClient {
  constructor(@inject('GraphQlClient') private client: GraphQlClient) {}
  public list = async ({
    filter,
    sortOptions,
    paginationOptions,
    search,
  }: UserClientListOptions): Promise<ApolloQueryResult<UserListResponse>> =>
    this.client.query({
      query: gql`
        query listUsers(
          $filter: UserFilter
          $paginationOptions: InputPagingOptions
          $sortOptions: InputSortOptions
          $search: String
        ) {
          listUsers(
            filter: $filter
            paginationOptions: $paginationOptions
            sortOptions: $sortOptions
            search: $search
          ) {
            metadata {
              paginationToken
              totalCount
            }
            data {
              customerName
              email
              fullName
              internalUserId
              status
              createdAt
              language
              phoneNumber
            }
          }
        }
      `,
      variables: {
        filter,
        sortOptions,
        paginationOptions,
        search,
      },
    });

  public detail = async (id: string): Promise<ApolloQueryResult<UserDetailsResponse>> =>
    this.client.query({
      query: gql`
        query GetUser($id: String!) {
          getUser(input: { id: $id }) {
            data {
              title
              country
              email
              customerNumber
              fullName
              firstName
              lastName
              language
              phoneNumber
              status
              invitationRevokedOn
              customerName
            }
          }
        }
      `,
      variables: {
        id,
      },
    });

  public listAlgs = async (): Promise<ApolloQueryResult<UserListAlgNumbersResponse>> =>
    this.client.query({
      query: gql`
        query ListAlgNumber {
          listAlgNumber {
            data
          }
        }
      `,
    });

  public create = async ({
    title,
    firstName,
    lastName,
    phoneNumber,
    language,
    email,
    password,
  }: UserClientCreateOptions): Promise<FetchResult<UserCreateResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation registerUser($input: RegisterUserInput) {
          registerUser(input: $input) {
            userId
          }
        }
      `,
      variables: {
        input: {
          title,
          firstName,
          lastName,
          phoneNumber,
          language,
          email,
          password,
        },
      },
      context: {
        headers: {
          Authorization: '',
          'x-api-key': process.env.REACT_APP_AM_SP_API_GRAPHQL_REGISTER_USER_API_KEY as string,
        },
      },
    });

  public searchCustomer = async ({
    algNumber,
    customerName,
    customerNumber,
  }: CustomerClientSearchOptions): Promise<ApolloQueryResult<CustomerSearchResponse>> =>
    this.client.query({
      query: gql`
        query getCustomers($input: GetCustomersInput) {
          getCustomers(input: $input) {
            data {
              SAPCustomerNumber
              customerId
              customerName
            }
          }
        }
      `,
      variables: {
        input: {
          algNumber,
          customerName,
          customerNumber,
        },
      },
    });

  public invite = async ({
    title,
    firstName,
    lastName,
    email,
    phoneNumber,
    customerId,
    language,
  }: UserClientInviteOptions): Promise<FetchResult<UserInviteResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation InviteUser($input: InviteUserInput) {
          inviteUser(input: $input) {
            data {
              email
              title
              firstName
              lastName
              fullName
              phoneNumber
              country
              language
              customerId
              customerNumber
              createdAt
              status
              userId
              internalUserId
              invitedOn
            }
          }
        }
      `,
      variables: {
        input: {
          title,
          firstName,
          lastName,
          email,
          phoneNumber,
          customerId,
          language,
        },
      },
    });

  public resend = async ({
    input,
  }: UserClientResendInvitationOptions): Promise<FetchResult<UserResendInvitationResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation ResendInvitation($input: ResendInviteEmail) {
          resendInviteEmail(input: $input) {
            invitationRevokedOn
          }
        }
      `,
      variables: {
        input,
      },
    });

  public delete = async ({ input }: UserClientDeleteOptions): Promise<FetchResult<UserDeleteResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation DeleteUser($input: DeleteUserInput) {
          deleteUser(input: $input)
        }
      `,
      variables: {
        input,
      },
    });

  public update = async ({ input }: UserClientUpdateOptions): Promise<FetchResult<UserUpdateResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation Mutation($input: UpdateUserInput) {
          updateUser(input: $input) {
            data {
              email
              title
              firstName
              lastName
              phoneNumber
              country
              language
              customerNumber
              status
              internalUserId
            }
          }
        }
      `,
      variables: {
        input,
      },
    });

  public reassign = async ({
    input,
  }: UserClientReassignOptions): Promise<FetchResult<ReassignUserToCustomerResponse>> =>
    this.client.mutate({
      mutation: gql`
        mutation ReassignUserToCustomer($input: ReassignUserToCustomerInput) {
          reassignUserToCustomer(input: $input) {
            data {
              country
              customerId
              customerName
              customerNumber
              userId
            }
          }
        }
      `,
      variables: {
        input,
      },
    });
}
