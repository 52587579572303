import { Col, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce, noop } from 'lodash-es';
import { SelectValue } from 'antd/lib/select';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RangeValue } from 'rc-picker/lib/interface';
import moment, { Moment } from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { MachineListActions } from '../../state/machineListActions';
import * as machineListSelectors from '../../state/machineListSelectors';
import { StyledMachineList } from './MachineList.styles';
import mockAvailableFilters from './mockAvailableFilters.json';
import { getMachineListColumns } from './columns/MachineList.columns';
import { Select } from 'lib/components/Select/Select';
import { Table } from 'lib/components/Table/Table';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { RangePicker } from 'lib/components/RangePicker/RangePicker';
import { DateTime } from 'lib/utils/date-handling/DateTime';
import { AutoCompleteInput } from 'lib/components/AutoCompleteInput/AutoCompleteInput';
import { isFeatureEnabled } from 'lib/utils/feature-flags/isFeatureEnabled';
import { Permission } from 'config/permissions';
import { isUserPermitted } from 'app/cross-cutting-concerns/authentication/utils/isUserPermitted';
import * as authenticationSelectors from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { SortableColumnTitle } from 'lib/components/Table/SortableColumnTitle/SortableColumnTitle';
import { MachineReclaimingModal } from 'app/modules/machine-directory/modals/components/MachineReclaimingModal/MachineReclaimingModal';
import { MachineModalActions } from 'app/modules/machine-directory/modals/state/machineModalActions';
import {
  MachineOwnership,
  Maybe,
  SortOrders,
} from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { MachineDirectorySelectors } from 'app/modules/machine-directory/state/machineDirectorySelectors';
import { MachineUnclaimModal } from 'app/modules/machine-directory/modals/components/MachineUnclaimModal/MachineUnclaimModal';

const { Option } = Select;

export const MachineList = (): JSX.Element => {
  // TODO: remove mockMachines when BE return  data
  const availableFilters = mockAvailableFilters;
  const areFiltersLoading = false;

  const [today, setToday] = useState<Date>(DateTime.today());
  const [oneWeekAgo, setOneWeekAgo] = useState<Date>(DateTime.oneWeekAgo());
  const [selectedMachines, setSelectedMachines] = useState<MachineOwnership[]>([]);
  const [rowKeys, setRowKeys] = useState<React.Key[]>([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const machines = useSelector(machineListSelectors.selectData) || [];

  const totalCount = useSelector(machineListSelectors.selectTotalCount) || 0;
  const paginationTokens = useSelector(machineListSelectors.selectPaginationTokens);
  const isLoading = !!useSelector(machineListSelectors.selectIsLoading);
  const page = useSelector(machineListSelectors.selectPage);
  const pageSize = useSelector(machineListSelectors.selectPageSize);
  const startDate =
    useSelector(machineListSelectors.selectPeriodStartDate) ||
    DateTime.formatDateByLocale('en-CA', oneWeekAgo.toISOString(), 1);
  const endDate =
    useSelector(machineListSelectors.selectPeriodEndDate) ||
    DateTime.formatDateByLocale('en-CA', today.toISOString(), 1);
  const sortField = useSelector(machineListSelectors.selectSortField) || 'dateOfClaim';
  const sortOrder = useSelector(machineListSelectors.selectSortOrder) || SortOrders.Desc;
  const userPermissions = useSelector(authenticationSelectors.selectPermissions);
  noop(endDate, startDate, setToday, setOneWeekAgo);
  const searchText = useSelector(machineListSelectors.selectSearchText);
  const algFilter = useSelector(machineListSelectors.selectAlg);
  const algs = useSelector(MachineDirectorySelectors.selectAlgs);
  const isLoadingAlg = useSelector(MachineDirectorySelectors.selectAreAlgsLoading);
  const reClaimedMachine = useSelector(MachineDirectorySelectors.selectReclaimMachineData);
  const unClaimedMachine = useSelector(MachineDirectorySelectors.selectUnclaimMachineData);

  useEffect(() => {
    if (reClaimedMachine || unClaimedMachine) {
      setRowKeys([]);
      setSelectedMachines([]);
    }
  }, [dispatch, reClaimedMachine, unClaimedMachine]);

  const hasPermissionMachineEditClaim = useMemo(
    () => isUserPermitted(userPermissions, [Permission.Machine.CLAIM, Permission.Machine.UNCLAIM]),
    [userPermissions]
  );

  const isSortingAndFilteringEnabled = isFeatureEnabled(
    process.env.REACT_APP_FEATURE_ENABLE_MACHINE_LIST_FILTERS_AND_SORTING
  );

  const isFilterCustomerNoEnabled = isFeatureEnabled(
    process.env.REACT_APP_FEATURE_ENABLE_MACHINE_LIST_FILTERS_CUSTOMER_NO
  );

  const isFilterIOTDeviceEnable = isFeatureEnabled(
    process.env.REACT_APP_FEATURE_ENABLE_MACHINE_LIST_FILTERS_IOT_DEVICE
  );

  const isFilterPeriodEnable = isFeatureEnabled(process.env.REACT_APP_FEATURE_ENABLE_MACHINE_LIST_FILTERS_PERIOD);

  useEffect(
    () => (): void => {
      dispatch(MachineListActions.resetState());
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(
      MachineListActions.getMachineListRequest({
        search: searchText,
        filter: {
          algNumber: algFilter,
        },
        sortOptions: {
          field: sortField,
          order: sortOrder,
        },
        paginationOptions: {
          paginationToken: paginationTokens[page] || '',
          limit: pageSize,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, sortField, sortOrder, algFilter, searchText]);

  const handleALGFilterChange = (value: SelectValue): void => {
    dispatch(MachineListActions.setActiveAlgFilter(value?.toString() || ''));
  };
  const handleCustomerNoFilterChange = (value: SelectValue): void => noop(value);
  const handleIOTDeviceFilterChange = (value: SelectValue): void => noop(value);
  const handleClickTableRow = (machine: MachineOwnership): void => noop(machine);
  const onRangePickerChange = (dates: RangeValue<Moment>): void => {
    if (dates && dates[0] && dates[1]) {
      dispatch(
        MachineListActions.setActivePeriodFilter({
          startDate: dates[0].format('YYYY-MM-DD'),
          endDate: dates[1].format('YYYY-MM-DD'),
        })
      );
    }
  };

  const onPageChange = (value: number): void => {
    dispatch(MachineListActions.changeTablePage({ page: value }));
  };

  const handleReclaim = (): void => {
    dispatch(MachineModalActions.showMachineReclaimingModal());
  };

  const handleUnclaim = (): void => {
    dispatch(MachineModalActions.showMachineUnclaimModal());
  };

  const onPageLimitChange = (value: number): void => {
    dispatch(MachineListActions.changeTablePageSize({ pageSize: value }));
  };

  const onChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<MachineOwnership> | SorterResult<MachineOwnership>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    let sorterOrder = SortOrders.Asc;
    if (sorter.order === 'descend') sorterOrder = SortOrders.Desc;
    dispatch(MachineListActions.setActiveSortField(sorter.field as Maybe<string>));
    dispatch(MachineListActions.setActiveSortOrder(sorterOrder as Maybe<SortOrders>));
  };

  const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: MachineOwnership[]): void => {
    setRowKeys(selectedRowKeys);
    setSelectedMachines(selectedRows);
  };

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(MachineListActions.setActiveSearchText(e.target.value));
  }, 500);

  const columnTitle = SortableColumnTitle<MachineOwnership>(
    t('machineList.table.selected'),
    isSortingAndFilteringEnabled,
    'selected'
  );

  const tableColumns = getMachineListColumns({
    t,
    isSortingAndFilteringEnabled,
    hasPermissionMachineEditClaim,
  });

  return (
    <StyledMachineList className="machine-list">
      <div className="machine-list__header">
        <div className="machine-list__header-content">
          <div className="machine-list__page-info">
            <h1 className="machine-list__title">{t('machineList.title').toLocaleUpperCase()}</h1>
            <pre className="machine-list__description">{t('machineList.description')}</pre>
          </div>

          <div className="machine-list__filter-wrapper">
            {isSortingAndFilteringEnabled && (
              <div className="machine-list__filter">
                <div className="machine-list__select-wrapper machine-list__select-alg">
                  <span className="machine-list__filter-label">{t('machineList.filter.ALG')}</span>
                  <Select
                    defaultValue={t('common.all')}
                    value={algFilter || t('common.all')}
                    className="machine-list__select"
                    loading={isLoadingAlg}
                    onChange={handleALGFilterChange}
                    showSearch
                  >
                    <Option value="">{t('common.all')}</Option>
                    {algs?.map((alg, i) => (
                      <Option value={alg} key={`${alg} - ${i}`}>
                        {alg}
                      </Option>
                    ))}
                  </Select>
                </div>
                {isFilterCustomerNoEnabled && (
                  <div className="machine-list__select-wrapper">
                    <div className="machine-list__filter">
                      <div>
                        <span className="machine-list__filter-label">{t('machineList.filter.customerNo')}</span>
                        <Select
                          className="machine-list__select"
                          loading={areFiltersLoading}
                          onChange={handleCustomerNoFilterChange}
                        >
                          <Option value="">{t('common.all')}</Option>
                          {availableFilters?.customerId.map(mn => (
                            <Option value={mn} key={mn}>
                              {mn}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                )}
                {isFilterIOTDeviceEnable && (
                  <div className="machine-list__select-wrapper">
                    <div className="machine-list__filter">
                      <div>
                        <span className="machine-list__filter-label">{t('machineList.filter.IOTDevice')}</span>
                        <Select
                          defaultValue=""
                          className="machine-list__select"
                          loading={areFiltersLoading}
                          onChange={handleIOTDeviceFilterChange}
                        >
                          <Option value="">{t('common.all')}</Option>
                          {availableFilters?.IOTDevice.map(e => (
                            <Option value={e} key={e}>
                              {e}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                  </div>
                )}
                {isFilterPeriodEnable && (
                  <div className="machine-list__select-wrapper">
                    <div className="machine-list__filter">
                      <div>
                        <span className="machine-list__filter-label">{t('common.period')}</span>
                        <br />
                        <RangePicker
                          onChange={onRangePickerChange}
                          defaultValue={[moment(startDate), moment(endDate)]}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="machine-list__adjustment-wrapper">
              <div className="machine-list__reclaim-to-customer-wrapper">
                <PermissionGuard requiredPermissions={[Permission.Machine.CLAIM]}>
                  <PrimaryButton
                    size="m"
                    className="machine-list__reclaim-button"
                    onClick={handleReclaim}
                    disabled={!(selectedMachines.length > 0)}
                  >
                    {t('machineList.reclaimToCustomer')}
                  </PrimaryButton>
                </PermissionGuard>
              </div>
              <div className="machine-list__unclaim-wrapper">
                <PermissionGuard requiredPermissions={[Permission.Machine.UNCLAIM]}>
                  <PrimaryButton
                    size="m"
                    className="machine-list__unclaim-button"
                    onClick={handleUnclaim}
                    disabled={!(selectedMachines.length > 0)}
                  >
                    {t('machineList.unclaim')}
                  </PrimaryButton>
                </PermissionGuard>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="machine-list__body">
        <div className="machine-list__body-content">
          <Row gutter={[26, 26]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <div className="machine-list__search-input">
                <AutoCompleteInput
                  placeholder={t('machineList.searchInputHolder')}
                  className="machine-list__search-custom-input"
                  prefix={<SearchOutlined />}
                  onChange={handleSearch}
                />
              </div>
              <Table
                pagination={{
                  total: totalCount,
                  pageSize,
                  page,
                  onPageChange,
                  onPageLimitChange,
                  isLoading,
                }}
                dataSource={machines}
                loading={isLoading}
                className="machine-list__table"
                columns={tableColumns}
                rowKey={(record: MachineOwnership): React.Key =>
                  (record.customerId as string) + record.materialNumber + record.serialNumber
                }
                onRow={(machineRowData: MachineOwnership): { onClick(): void } => ({
                  onClick: (): void => {
                    handleClickTableRow(machineRowData);
                  },
                })}
                onChange={onChange}
                sortDirections={['ascend', 'descend', 'ascend']}
                rowSelection={{
                  columnTitle,
                  onChange: onSelectChange,
                  type: 'radio',
                  selectedRowKeys: rowKeys,
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <MachineReclaimingModal selectedMachines={selectedMachines} />
      <MachineUnclaimModal selectedMachines={selectedMachines} />
    </StyledMachineList>
  );
};
