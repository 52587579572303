import { Link } from 'react-router-dom';
import logo from '../../../assets/images/kaercher-logo.png';
import { StyledAppHeader } from './AppHeader.styles';
import profile from 'app/assets/images/profile.svg';
import { ProfilePopover } from 'lib/components/ProfilePopover/ProfilePopover';
import { Profile } from 'app/components/Profile/Profile';

export const AppHeader = (): JSX.Element => (
  <StyledAppHeader className="header">
    <div className="header__top">
      <Link to={'/'}>
        <img src={logo} alt="logo" />
      </Link>
      <ProfilePopover
        overlayClassName="profile-popover"
        content={<Profile />}
        arrowPointAtCenter={false}
        placement="bottom"
        trigger="click"
        getPopupContainer={(triggerNode): HTMLElement => triggerNode}
        profileUrl={profile}
      />
    </div>
  </StyledAppHeader>
);
