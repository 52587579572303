import { Action } from 'redux';
import { UserClientListOptions } from 'app/modules/user-management/UserClient';
import {
  PollUserListRequestOptions,
  UserListAlgNumbersResponse,
  UserListResponse,
} from 'app/modules/user-management/interfaces/User.types';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Optional } from 'lib/types/Optional';
import { SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export type IPollUserListRequestOption = PollUserListRequestOptions;
export type IPollUserListRequestAction = Action<typeof UserListActions.POLL_USER_LIST_REQUEST> & {
  payload: PollUserListRequestOptions;
};

export type IGetUserListRequestOptions = UserClientListOptions;
export type IGetUserListRequestAction = Action<typeof UserListActions.GET_USER_LIST_REQUEST> & {
  payload: UserClientListOptions;
};

export type IGetUserListSuccessOptions = Optional<UserListResponse>;
export type IGetUserListSuccessAction = Action<typeof UserListActions.GET_USER_LIST_SUCCESS> & {
  payload: Optional<UserListResponse>;
};

export type IGetUserListErrorAction = IErrorAction<typeof UserListActions.GET_USER_LIST_ERROR>;

export type IChangeUserListTablePage = Action<typeof UserListActions.CHANGE_TABLE_PAGE> & {
  payload: {
    page: number;
  };
};

export type IChangeUserListTablePageSize = Action<typeof UserListActions.CHANGE_TABLE_PAGE_SIZE> & {
  payload: {
    pageSize: number;
  };
};

export type IGetUserListFiltersRequestAction = Action<typeof UserListActions.GET_USER_LIST_FILTERS_REQUEST>;

export type IGetUserListFiltersSuccessOptions = Optional<UserListAlgNumbersResponse>;
export type IGetUserListFiltersSuccessAction = Action<typeof UserListActions.GET_USER_LIST_FILTERS_REQUEST> & {
  payload: Optional<UserListAlgNumbersResponse>;
};
export type IGetUserListFiltersErrorAction = IErrorAction<typeof UserListActions.GET_USER_LIST_FILTERS_ERROR>;

export type ISetActiveAlgFilter = Action<typeof UserListActions.SET_ACTIVE_ALG_FILTER> & {
  payload: {
    alg: Optional<string>;
  };
};

export type ISetActiveSortField = Action<typeof UserListActions.SET_ACTIVE_SORT_FIELD> & {
  payload: {
    field: Optional<string>;
  };
};

export type ISetActiveSortOrder = Action<typeof UserListActions.SET_ACTIVE_SORT_ORDER> & {
  payload: {
    order: Optional<SortOrders>;
  };
};

export type ISetActiveSearchText = Action<typeof UserListActions.SET_ACTIVE_SEARCH_TEXT> & {
  payload: {
    searchText: Optional<string>;
  };
};

export class UserListActions {
  public static GET_USER_LIST_REQUEST = 'USER_LIST::GET_USER_LIST_REQUEST';
  public static GET_USER_LIST_SUCCESS = 'USER_LIST::GET_USER_LIST_SUCCESS';
  public static GET_USER_LIST_ERROR = 'USER_LIST::GET_USER_LIST_ERROR';

  public static CHANGE_TABLE_PAGE = 'USER_LIST::CHANGE_TABLE_PAGE';
  public static CHANGE_TABLE_PAGE_SIZE = 'USER_LIST::CHANGE_TABLE_PAGE_SIZE';
  public static RESET_STATE = 'USER_LIST::RESET_STATE';

  public static GET_USER_LIST_FILTERS_REQUEST = 'USER_LIST::GET_USER_LIST_FILTERS_REQUEST';
  public static GET_USER_LIST_FILTERS_SUCCESS = 'USER_LIST::GET_USER_LIST_FILTERS_SUCCESS';
  public static GET_USER_LIST_FILTERS_ERROR = 'USER_LIST::GET_USER_LIST_FILTERS_ERROR';

  public static SET_ACTIVE_ALG_FILTER = 'USER_LIST::SET_ACTIVE_ALG_FILTER';

  public static SET_ACTIVE_SORT_FIELD = 'USER_LIST::SET_ACTIVE_SORT_FIELD';
  public static SET_ACTIVE_SORT_ORDER = 'USER_LIST::SET_ACTIVE_SORT_ORDER';
  public static SET_ACTIVE_SEARCH_TEXT = 'USER_LIST::SET_ACTIVE_SEARCH_TEXT';

  public static POLL_USER_LIST_REQUEST = 'USER_LIST::POLL_USER_LIST_REQUEST';

  public static USER_LIST_LOADING_REQUEST = 'USER_LIST::USER_LIST_LOADING_REQUEST';

  public static getUserListRequest({
    filter,
    sortOptions,
    paginationOptions,
    search,
  }: IGetUserListRequestOptions): IGetUserListRequestAction {
    return {
      type: UserListActions.GET_USER_LIST_REQUEST,
      payload: { filter, sortOptions, paginationOptions, search },
    };
  }

  public static getUserListSuccess(data: IGetUserListSuccessOptions): IGetUserListSuccessAction {
    return {
      type: UserListActions.GET_USER_LIST_SUCCESS,
      payload: data,
    };
  }

  public static getUserListError({ error }: IActionCreatorErrorOptions): IGetUserListErrorAction {
    return {
      type: UserListActions.GET_USER_LIST_ERROR,
      error,
    };
  }

  public static changeTablePage(data: { page: number }): IChangeUserListTablePage {
    return {
      type: UserListActions.CHANGE_TABLE_PAGE,
      payload: data,
    };
  }

  public static changeTablePageSize(data: { pageSize: number }): IChangeUserListTablePageSize {
    return {
      type: UserListActions.CHANGE_TABLE_PAGE_SIZE,
      payload: data,
    };
  }

  public static resetState(): Action<typeof UserListActions.RESET_STATE> {
    return {
      type: UserListActions.RESET_STATE,
    };
  }

  public static getUserListFiltersRequest(): IGetUserListFiltersRequestAction {
    return {
      type: UserListActions.GET_USER_LIST_FILTERS_REQUEST,
    };
  }

  public static getUserListFiltersSuccess(
    availableFilters: IGetUserListFiltersSuccessOptions
  ): IGetUserListFiltersSuccessAction {
    return {
      type: UserListActions.GET_USER_LIST_FILTERS_SUCCESS,
      payload: availableFilters,
    };
  }

  public static getUserListFiltersError({ error }: IActionCreatorErrorOptions): IGetUserListFiltersErrorAction {
    return {
      type: UserListActions.GET_USER_LIST_FILTERS_ERROR,
      error,
    };
  }

  public static setActiveAlgFilter(alg: Optional<string>): ISetActiveAlgFilter {
    return {
      type: UserListActions.SET_ACTIVE_ALG_FILTER,
      payload: {
        alg,
      },
    };
  }

  public static setActiveSortField(field: Optional<string>): ISetActiveSortField {
    return {
      type: UserListActions.SET_ACTIVE_SORT_FIELD,
      payload: {
        field,
      },
    };
  }

  public static setActiveSortOrder(order: Optional<SortOrders>): ISetActiveSortOrder {
    return {
      type: UserListActions.SET_ACTIVE_SORT_ORDER,
      payload: {
        order,
      },
    };
  }

  public static setActiveSearchText(searchText: Optional<string>): ISetActiveSearchText {
    return {
      type: UserListActions.SET_ACTIVE_SEARCH_TEXT,
      payload: {
        searchText,
      },
    };
  }

  public static pollUserListRequest(data: IPollUserListRequestOption): IPollUserListRequestAction {
    return {
      type: UserListActions.POLL_USER_LIST_REQUEST,
      payload: data,
    };
  }

  public static userListLoading(): Action<typeof UserListActions.USER_LIST_LOADING_REQUEST> {
    return {
      type: UserListActions.USER_LIST_LOADING_REQUEST,
    };
  }
}
