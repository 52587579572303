import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce, noop } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons/lib/icons';
import { customerModalsActions } from '../../../../modals/state/customerModalsSlice';
import { CustomerReclaimMachineModal } from '../../../../modals/components/CustomerReclaimMachineModal/CustomerReclaimMachineModal';
import { CustomerUnclaimMachineModal } from '../../../../modals/components/CustomerUnclaimMachineModal/CustomerUnclaimMachineModal';
import { CustomerDetailsSelectors } from '../../../state/customerDetailsSelectors';
import { getCustomerDetailsMachineColumns } from './column/CustomerDetailsMachines.column';
import { Table } from 'lib/components/Table/Table';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { isFeatureEnabled } from 'lib/utils/feature-flags/isFeatureEnabled';
import { AutoCompleteInput } from 'lib/components/AutoCompleteInput/AutoCompleteInput';
import { Permission } from 'config/permissions';
import { isUserPermitted } from 'app/cross-cutting-concerns/authentication/utils/isUserPermitted';
import * as authenticationSelectors from 'app/cross-cutting-concerns/authentication/state/authenticationSelectors';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { SortableColumnTitle } from 'lib/components/Table/SortableColumnTitle/SortableColumnTitle';
import { MachineListActions } from 'app/modules/machine-directory/machine-list/state/machineListActions';
import * as machineListSelectors from 'app/modules/machine-directory/machine-list/state/machineListSelectors';
import {
  MachineOwnership,
  Maybe,
  SortOrders,
} from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { MachineDirectorySelectors } from 'app/modules/machine-directory/state/machineDirectorySelectors';
import { CustomerClaimMachineModal } from 'app/modules/customer-management/modals/components/CustomerClaimMachineModal/CustomerClaimMachineModal';

export const CustomerDetailsMachines = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const customerDetailsData = useSelector(CustomerDetailsSelectors.selectData);

  const isSortingAndFilteringEnabled = isFeatureEnabled(
    process.env.REACT_APP_FEATURE_ENABLE_MACHINE_LIST_FILTERS_AND_SORTING
  );

  const [selectedMachines, setSelectedMachines] = useState<MachineOwnership[]>([]);
  const [rowKeys, setRowKeys] = useState<React.Key[]>([]);

  const machines = useSelector(machineListSelectors.selectData);

  const totalCount = useSelector(machineListSelectors.selectTotalCount) || 0;
  const paginationTokens = useSelector(machineListSelectors.selectPaginationTokens);
  const isLoading = !!useSelector(machineListSelectors.selectIsLoading);
  const page = useSelector(machineListSelectors.selectPage);
  const pageSize = useSelector(machineListSelectors.selectPageSize);
  const sortField = useSelector(machineListSelectors.selectSortField) || 'dateOfClaim';
  const sortOrder = useSelector(machineListSelectors.selectSortOrder) || SortOrders.Desc;
  const userPermissions = useSelector(authenticationSelectors.selectPermissions);
  const searchText = useSelector(machineListSelectors.selectSearchText);

  const reClaimedMachine = useSelector(MachineDirectorySelectors.selectReclaimMachineData);
  const unClaimedMachine = useSelector(MachineDirectorySelectors.selectUnclaimMachineData);

  useEffect(() => {
    if (reClaimedMachine || unClaimedMachine) {
      setRowKeys([]);
      setSelectedMachines([]);
    }
  }, [dispatch, reClaimedMachine, unClaimedMachine]);

  const hasPermissionMachineEditClaim = useMemo(
    () => isUserPermitted(userPermissions, [Permission.Machine.CLAIM, Permission.Machine.UNCLAIM]),
    [userPermissions]
  );

  useEffect(() => {
    if (customerDetailsData) {
      dispatch(
        MachineListActions.getMachineListRequest({
          search: searchText,
          filter: {
            algNumber: customerDetailsData.alg,
            customerId: customerDetailsData.customerId,
          },
          sortOptions: {
            field: sortField,
            order: sortOrder,
          },
          paginationOptions: {
            paginationToken: paginationTokens[page] || '',
            limit: pageSize,
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, sortField, sortOrder, searchText, customerDetailsData]);

  useEffect(() => {
    const selectedMachinesIds = selectedMachines.map(machine => machine.id);
    const selectedMachinesInList = machines?.filter(machine => selectedMachinesIds.includes(machine.id));
    if (selectedMachinesInList?.length !== selectedMachines.length) {
      setRowKeys([]);
      setSelectedMachines([]);
    }
  }, [machines, selectedMachines]);

  const handleClickTableRow = (machine: MachineOwnership): void => noop(machine);

  const onPageChange = (value: number): void => {
    dispatch(MachineListActions.changeTablePage({ page: value }));
  };

  const onPageLimitChange = (value: number): void => {
    dispatch(MachineListActions.changeTablePageSize({ pageSize: value }));
  };

  const onChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<MachineOwnership> | SorterResult<MachineOwnership>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    let sorterOrder = SortOrders.Asc;
    if (sorter.order === 'descend') sorterOrder = SortOrders.Desc;
    dispatch(MachineListActions.setActiveSortField(sorter.field as Maybe<string>));
    dispatch(MachineListActions.setActiveSortOrder(sorterOrder as Maybe<SortOrders>));
  };

  const onSelectChange = (selectedRowKeys: React.Key[], selectedRows: MachineOwnership[]): void => {
    setRowKeys(selectedRowKeys);
    setSelectedMachines(selectedRows);
  };

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(MachineListActions.setActiveSearchText(e.target.value));
  }, 500);

  const handleReclaim = (): void => {
    dispatch(customerModalsActions.openReclaimMachineModal());
  };

  const handleUnclaim = (): void => {
    dispatch(customerModalsActions.openUnclaimMachineModal());
  };

  const columnTitle = SortableColumnTitle<MachineOwnership>(
    t('machineList.table.selected'),
    isSortingAndFilteringEnabled,
    'selected'
  );

  const tableColumns = getCustomerDetailsMachineColumns({
    t,
    isSortingAndFilteringEnabled,
    hasPermissionMachineEditClaim,
  });

  return (
    <Col xs={24} sm={24} md={24} lg={24}>
      <div className="machine-list__filter-wrapper">
        {isSortingAndFilteringEnabled && (
          <div className="machine-list__filter">
            <div className="machine-list__search-input">
              <AutoCompleteInput
                placeholder={t('machineList.searchInputHolder')}
                className="machine-list__search-custom-input"
                prefix={<SearchOutlined />}
                onChange={handleSearch}
              />
            </div>
          </div>
        )}
        <div className="machine-list__adjustment-wrapper">
          <div className="machine-list__reclaim-to-customer-wrapper">
            <PermissionGuard requiredPermissions={[Permission.Machine.CLAIM]}>
              <PrimaryButton
                size="m"
                className="machine-list__reclaim-button"
                onClick={handleReclaim}
                disabled={!(selectedMachines.length > 0)}
              >
                {t('machineList.reclaimToCustomer')}
              </PrimaryButton>
            </PermissionGuard>
          </div>
          <div className="machine-list__unclaim-wrapper">
            <PermissionGuard requiredPermissions={[Permission.Machine.UNCLAIM]}>
              <PrimaryButton
                size="m"
                className="machine-list__unclaim-button"
                onClick={handleUnclaim}
                disabled={!(selectedMachines.length > 0)}
              >
                {t('machineList.unclaim')}
              </PrimaryButton>
            </PermissionGuard>
          </div>
        </div>
      </div>

      <Table
        pagination={{
          total: totalCount,
          pageSize,
          page,
          onPageChange,
          onPageLimitChange,
          isLoading,
        }}
        dataSource={machines || []}
        loading={isLoading}
        className="customer-list__table"
        columns={tableColumns}
        rowKey="id"
        onRow={(machineRowData: MachineOwnership): { onClick(): void } => ({
          onClick: (): void => {
            handleClickTableRow(machineRowData);
          },
        })}
        onChange={onChange}
        sortDirections={['ascend', 'descend', 'ascend']}
        rowSelection={{
          columnTitle,
          onChange: onSelectChange,
          type: 'radio',
          selectedRowKeys: rowKeys,
        }}
      />
      <CustomerReclaimMachineModal selectedMachines={selectedMachines} />
      <CustomerUnclaimMachineModal selectedMachines={selectedMachines} />
      <CustomerClaimMachineModal />
    </Col>
  );
};
