// Split to new file to avoid issue cycle dependency between file: routes and pages/components
export const RoutePaths = {
  LOGIN: '/login',
  ROOT: '/',
  CALLBACK: '/callback',
  OVERVIEW: '/overview',
  MACHINES: '/machines',
  CLAIM_MACHINE: '/machines/claim',
  MACHINE_CLAIM_PAIR: '/machines/claim-pair',
  USERS: '/users',
  USER_DETAILS: '/users/:id',
  INVITE_USER: '/users/invite',
  CREATE_USER: '/users/register',
  CREATE_USER_SUCCESS: '/user-register-success',
  INVITE_USER_SUCCESS: '/users/invite/success',
  ACCESS_DENIED: '/access-denied',
  CUSTOMERS: '/customers',
  CUSTOMERS_DETAILS: '/customers/:id',
};
