import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledCustomerHasUserOrDeviceDeleteModal = styled(Modal)`
  .ant-tabs-nav {
    &:before {
      border-bottom: none;
    }
  }
  .ant-tabs-tab {
    font-family: ${(props): string => props.theme.fonts.menu};
    &:hover {
      color: ${(props): string => props.theme.colors.black};
    }
  }

  .ant-tabs-ink-bar {
    background: ${(props): string => props.theme.colors.black};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(props): string => props.theme.colors.black};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
  }

  .customer-delete-modal__footer {
    display: flex;
    justify-content: space-between;
  }

  .ant-tabs-tab-disabled {
    &:hover {
      color: rgba(${(props): string => props.theme.colors.textRgb}, 0.3);
    }
  }
`;
