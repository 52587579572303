import { MachineConnectionColors } from '../enum/MachineConnectionColors';
import {
  ClaimedMachinesResults,
  QueryListClaimedMachinesArgs,
  ResponseMachineClaim,
  ResponseMachinePairing,
  ResponseMachinePairingSearch,
  ResponseUnclaimMachine,
  ResponseValidateMachine,
  SearchMaterialNumberResponse,
} from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export interface IConnectionStatus {
  color: MachineConnectionColors;
  description: string;
  text: string;
}

export const ConnectionStatuses: IConnectionStatus[] = [
  {
    color: MachineConnectionColors.GREEN,
    description: 'machineConnectionStatus.green.description',
    text: 'machineConnectionStatus.green.text',
  },
  {
    color: MachineConnectionColors.ORANGE,
    description: 'machineConnectionStatus.orange.description',
    text: 'machineConnectionStatus.orange.text',
  },
  {
    color: MachineConnectionColors.RED,
    description: 'machineConnectionStatus.red.description',
    text: 'machineConnectionStatus.red.text',
  },
];

export interface MachineListResponse {
  listClaimedMachines: ClaimedMachinesResults;
}

export interface MachineClaimResponse {
  adminMachineClaim: ResponseMachineClaim;
}

export interface MachineSearchMaterialNumberResponse {
  searchMaterialNumber: SearchMaterialNumberResponse;
}

export interface MachinePairingSearchResponse {
  machinePairingSearch: ResponseMachinePairingSearch;
}

export interface MachinePairingResponse {
  machinePairing: ResponseMachinePairing;
}
export interface MachineFindResponse {
  validateMachine: ResponseValidateMachine;
}

export interface MachineUnclaimResponse {
  unclaimMachine: ResponseUnclaimMachine;
}

export enum PollMachineListOperation {
  CLAIM_NEWDEVICE = 'claimNewDevice',
  RECLAIM = 'reclaim',
  UNCLAIM = 'unclaim',
}

export interface PollMachineListRequest {
  materialNumber: string;
  serialNumber: string;
  operation: PollMachineListOperation;
  filterOption: QueryListClaimedMachinesArgs;
}
