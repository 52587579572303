import { initReactI18next, getI18n } from 'react-i18next';
import i18next, { ResourceLanguage } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import cs from 'antd/lib/locale/cs_CZ';
import da from 'antd/lib/locale/da_DK';
import de from 'antd/lib/locale/de_DE';
import en from 'antd/lib/locale/en_US';
import fr from 'antd/lib/locale/fr_FR';
import it from 'antd/lib/locale/it_IT';
import nb from 'antd/lib/locale/nb_NO';
import nl from 'antd/lib/locale/nl_NL';
import pl from 'antd/lib/locale/pl_PL';
import sv from 'antd/lib/locale/sv_SE';
import { Locale } from 'antd/lib/locale-provider';
import translationsCs from '../../../config/translations/cs.json';
import translationsDa from '../../../config/translations/da.json';
import translationsDe from '../../../config/translations/de.json';
import translationsEn from '../../../config/translations/en-US.json';
import translationsFr from '../../../config/translations/fr.json';
import translationsIt from '../../../config/translations/it.json';
import translationsNl from '../../../config/translations/nl.json';
import translationsNb from '../../../config/translations/nb.json';
import translationsPl from '../../../config/translations/pl.json';
import translationsSv from '../../../config/translations/sv.json';
import { LANGUAGES_USER_SNAP_SUPPORTED } from './constants';
import { isFeatureEnabled } from 'lib/utils/feature-flags/isFeatureEnabled';

export const supportedLanguages: {
  [language: string]: ResourceLanguage & { name: string };
} = {
  cs: {
    translation: translationsCs,
    name: 'languages.cs',
  },
  da: {
    translation: translationsDa,
    name: 'languages.da',
  },
  de: {
    translation: translationsDe,
    name: 'languages.de',
  },
  en: {
    translation: translationsEn,
    name: 'languages.en',
  },
  fr: {
    translation: translationsFr,
    name: 'languages.fr',
  },
  it: {
    translation: translationsIt,
    name: 'languages.it',
  },
  nl: {
    translation: translationsNl,
    name: 'languages.nl',
  },
  nb: {
    translation: translationsNb,
    name: 'languages.nb',
  },
  pl: {
    translation: translationsPl,
    name: 'languages.pl',
  },
  sv: {
    translation: translationsSv,
    name: 'languages.sv',
  },
};

export class Translations {
  public static initialize = async (): Promise<void> => {
    await i18next
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: 'en',
        resources: supportedLanguages,
        detection: {
          order: ['navigator'],
        },
        debug: isFeatureEnabled(process.env.REACT_APP_ENABLE_I18NEXT_DEBUGGING),
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      });
  };

  public static initializeForTests = async (): Promise<void> => {
    await i18next
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        // This causes the translation keys to be rendered instead of the translated values.
        // Useful so the tests are independent of the actual translation
        lng: 'cimode',
        resources: {
          en: {
            translation: translationsEn,
          },
        },
        debug: isFeatureEnabled(process.env.REACT_APP_ENABLE_I18NEXT_DEBUGGING),
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
      });
  };

  public static getAntdLocale = (): Locale => {
    const { language } = getI18n();
    switch (language) {
      case 'cs':
        return cs;
      case 'da':
        return da;
      case 'de':
        return de;
      case 'en':
        return en;
      case 'fr':
        return fr;
      case 'it':
        return it;
      case 'nl':
        return nl;
      case 'nb':
        return nb;
      case 'pl':
        return pl;
      case 'sv':
        return sv;

      default:
        return en;
    }
  };

  public static getPrimaryLanguageSubtag(locale: string): string {
    return locale.split('-').shift() ?? 'en';
  }

  public static getUserSnapLocaleSupported = (): string => {
    const { language } = getI18n();
    const primaryLanguageSubTag = Translations.getPrimaryLanguageSubtag(language);

    const localeSupported = LANGUAGES_USER_SNAP_SUPPORTED[primaryLanguageSubTag];
    if (!localeSupported) {
      console.error(`Locale ${primaryLanguageSubTag} is not supported by UserSnap`);
      return LANGUAGES_USER_SNAP_SUPPORTED.en;
    }
    return localeSupported;
  };
}
