import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import { CustomerDetailsSelectors } from '../../state/customerDetailsSelectors';
import { customerDetailsActions } from '../../state/customerDetailsSlice';
import { CustomerManagementEntity } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { convertCodeToCountry } from 'app/modules/user-management/utils/convertCodeToCountry';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { DATE_FORMAT_PATTERN, DateTime } from 'lib/utils/date-handling/DateTime';
import { CustomerDetailsTab } from 'app/modules/customer-management/interfaces/Customer.types';
import { Tabs } from 'lib/components/Tabs/Tabs';
import { RoutePaths } from 'config/route-paths';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { customerModalsActions } from 'app/modules/customer-management/modals/state/customerModalsSlice';

const SubTitleInformation: Record<string, keyof CustomerManagementEntity> = {
  createdAt: 'createdAt',
  customerId: 'customerId',
  alg: 'alg',
  sapNumber: 'sapNumber',
};

export const CustomerDetailsHeader = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customerDetailsData = useSelector(CustomerDetailsSelectors.selectData);
  const customerDetailsActiveTab = useSelector(CustomerDetailsSelectors.selectActiveTab);

  const handleOpenInviteUserModal = useCallback(() => {
    dispatch(customerModalsActions.openInviteUserModal());
  }, [dispatch]);

  const handleOpenClaimNewDevice = useCallback(() => {
    dispatch(customerModalsActions.openClaimNewDeviceModal());
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(customerDetailsActions.getCustomerDetailsActiveTab({ activeTab: CustomerDetailsTab.DETAILS }));
    },
    [dispatch]
  );

  const handleTabChange = (activeTab: string): void => {
    dispatch(customerDetailsActions.getCustomerDetailsActiveTab({ activeTab: activeTab as CustomerDetailsTab }));
  };

  const handleBackButton = (): void => {
    navigate(RoutePaths.CUSTOMERS);
  };

  const tabs = [
    {
      label: t('customerDetails.tabs.details'),
      key: CustomerDetailsTab.DETAILS,
    },
    {
      label: t('customerDetails.tabs.users'),
      key: CustomerDetailsTab.USERS,
    },
    {
      label: t('customerDetails.tabs.machines'),
      key: CustomerDetailsTab.MACHINES,
    },
  ];

  useEffect(
    () => () => {
      dispatch(customerDetailsActions.getCustomerDetailsActiveTab({ activeTab: CustomerDetailsTab.DETAILS }));
    },
    [dispatch]
  );

  const getCustomerSubTitleInformation = (info: keyof CustomerManagementEntity): JSX.Element => {
    if (info === SubTitleInformation.country) {
      const getCountryName = convertCodeToCountry(customerDetailsData?.[info], t);

      return (
        <Col className="customer-details-header__page-sub-info">
          <p className="customer-details-header__sub-title--label">{t(`customerDetails.header.label.${info}`)}</p>
          <p className="customer-details-header__sub-title--data">{getCountryName}</p>
        </Col>
      );
    }

    if (info === SubTitleInformation.createdAt) {
      const customerCreatedAtFormat = DateTime.formatDateByLocale(
        i18n.language,
        customerDetailsData?.createdAt,
        DATE_FORMAT_PATTERN.DATE
      );

      return (
        <Col className="customer-details-header__page-sub-info">
          <p className="customer-details-header__sub-title--label">{t(`customerDetails.header.label.${info}`)}</p>
          <p className="customer-details-header__sub-title--data">{customerCreatedAtFormat}</p>
        </Col>
      );
    }

    return (
      <Col className="customer-details-header__page-sub-info">
        <p className="customer-details-header__sub-title--label">{t(`customerDetails.header.label.${info}`)}</p>
        <p className="customer-details-header__sub-title--data">{customerDetailsData?.[info] || '-'}</p>
      </Col>
    );
  };

  return (
    <div className="customer-details-header">
      <Row className="customer-details-header__main-content" justify="space-between" align="middle">
        <Col span={24} className="customer-details-header__page-info--customer-name">
          <Row gutter={[16, 16]} align="middle" justify="start" onClick={handleBackButton}>
            <Col>
              <Button type="text" className="customer-details-header__back-button" icon={<SvgIcon name="arrow" />} />
            </Col>
            <Col>
              <h1 className="customer-details-header__title">{customerDetailsData?.customerName || '-'}</h1>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="customer-details-header__sub-content" justify="space-between" align="top">
        {getCustomerSubTitleInformation(SubTitleInformation.createdAt)}
        {getCustomerSubTitleInformation(SubTitleInformation.alg)}
        {getCustomerSubTitleInformation(SubTitleInformation.customerId)}
        {getCustomerSubTitleInformation(SubTitleInformation.sapNumber)}
        {customerDetailsActiveTab === CustomerDetailsTab.USERS && (
          <PrimaryButton
            size="m"
            onClick={handleOpenInviteUserModal}
            icon={<SvgIcon className="customer-details-header__icon" name="plus" />}
            className="customer-details-header__invitation-btn"
          >
            {t('userInvite.title')}
          </PrimaryButton>
        )}
        {customerDetailsActiveTab === CustomerDetailsTab.MACHINES && (
          <PrimaryButton
            size="m"
            onClick={handleOpenClaimNewDevice}
            icon={<SvgIcon className="customer-details-header__icon" name="plus" />}
            className="customer-details-header__invitation-btn"
          >
            {t('machineList.claimNewDevice')}
          </PrimaryButton>
        )}
      </Row>
      <Row className="customer-details-header__sub-content" justify="space-between" align="middle">
        <Tabs
          destroyInactiveTabPane={true}
          items={tabs}
          onChange={handleTabChange}
          activeKey={customerDetailsActiveTab as string}
        />
      </Row>
    </div>
  );
};
