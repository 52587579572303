import styled from 'styled-components';
import { ButtonBase } from '../ButtonBase';

export const StyledTextButton = styled(ButtonBase)`
  font-family: ${(props): string => props.theme.fonts.pageTitle};
  border: none;
  background-color: transparent;
  color: ${(props): string => props.theme.colors.text};
  box-shadow: none;
  text-transform: uppercase;
  justify-content: center;

  :hover,
  :focus,
  :active {
    background-color: transparent;
    color: ${(props): string => props.theme.colors.text};
  }

  ::after {
    display: none;
  }

  :disabled,
  :disabled:hover {
    background-color: transparent;
  }

  &.button__size-s {
    height: auto;
    font-size: 13px;
    line-height: 17px;
    padding: 8px 14px 7px;
  }

  &.button__size-m {
    height: 44px;
    font-size: 15px;
    line-height: 19px;
    padding: 11px 11px 10px;
  }
`;
