/* eslint-disable max-len */
import styled from 'styled-components';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

export const StyledRangePicker = styled(RangePicker)`
  width: 250px;
  height: 44px;
  border: 2px solid ${(props): string => props.theme.colors.border};
  border-radius: 0;
  text-transform: uppercase;

  &.ant-picker {
    &:hover,
    &:focus,
    &:active,
    &.ant-picker-focused {
      border-right-width: 2px !important;
    }

    &:hover {
      border-color: ${(props): string => props.theme.colors.border};
      background-color: #f4f4f4; // TODO: propose an existing kind of grey color
    }

    &:focus,
    &.ant-picker-focused {
      background-color: ${(props): string => props.theme.colors.white};
      border-color: ${(props): string => props.theme.colors.focusBorder};
      box-shadow: unset;
    }
  }

  .ant-picker-active-bar {
    background-color: ${(props): string => props.theme.colors.focusBorder};
  }

  .ant-picker-input > input {
    width: 82px;
  }

  .ant-picker-suffix {
    color: ${(props): string => props.theme.colors.black};
    padding-left: 8px;
  }

  .ant-picker-range-separator {
    padding: 0 6px;
  }

  .ant-picker-panels {
    .ant-picker-header-super-next-btn,
    .ant-picker-header-super-prev-btn {
      color: ${(props): string => props.theme.colors.black};
    }

    .ant-picker-date-panel {
      .ant-picker-header-next-btn,
      .ant-picker-header-prev-btn {
        color: ${(props): string => props.theme.colors.black};
      }

      & *:first-child button.ant-picker-header-next-btn {
        visibility: visible !important; /* override inline style */
      }

      & *:first-child button.ant-picker-header-super-prev-btn {
        visibility: hidden !important; /* override inline style */
      }

      & .ant-picker-body {
        padding: 0 24px 24px 24px;
      }

      & table.ant-picker-content {
        width: 324px;

        & tr {
          height: 64px;
          padding: 10px 0;
        }

        // remove weekdays of upcoming month
        & tbody tr:last-child {
          display: none;
        }
      }

      .ant-picker-cell-in-view {
        &.ant-picker-cell-in-range {
          &.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
            left: 0;
          }

          &.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
            right: 0;
          }
        }

        &.ant-picker-cell-range-start .ant-picker-cell-inner,
        &.ant-picker-cell-range-end .ant-picker-cell-inner {
          border-radius: 50%;
        }

        &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end)
          .ant-picker-cell-inner,
        &.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start)
          .ant-picker-cell-inner {
          border-radius: 50%;
        }

        &.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
        &.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
          background: ${(props): string => props.theme.colors.primary};
        }
      }
    }

    // remove second picker
    & > .ant-picker-panel:nth-child(2) {
      display: none;
    }

    .ant-picker-header {
      border-bottom: none;
      height: 65px;
      display: flex;
      align-items: end;
      padding: 0 60px;
    }
  }

  .ant-picker-date-panel,
  .ant-picker-month-panel,
  .ant-picker-year-panel,
  .ant-picker-decade-panel {
    width: 376px;
    height: 463px;
    border: 2px solid ${(props): string => props.theme.colors.border};

    & .ant-picker-body {
      height: 100%;

      & .ant-picker-content {
        height: 100%;
        th {
          color: ${(props): string => props.theme.colors.gray};
        }
      }
    }
  }

  .ant-picker-cell {
    height: 44px;
    padding: 0;

    &::before {
      height: 44px;
    }

    & .ant-picker-cell-inner {
      border-radius: 50%;
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;

      &:hover,
      :active,
      :focus {
        border-radius: 50%;
      }
    }
  }

  .ant-picker-month-panel .ant-picker-cell .ant-picker-cell-inner {
    width: 115px;
    border-radius: 0;
  }

  .ant-picker-year-panel .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 0;
    padding: 25px;
  }

  .ant-picker-decade-panel .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 0;
    padding: 30px;
  }

  .ant-picker-cell-in-view {
    &.ant-picker-cell-range-start .ant-picker-cell-inner,
    &.ant-picker-cell-range-end .ant-picker-cell-inner {
      background-color: ${(props): string => props.theme.colors.black};
    }

    &.ant-picker-cell-in-range::before,
    &.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    &.ant-picker-cell-range-end:not(.ant-picker-cell-range-start-single)::before {
      background-color: ${(props): string => props.theme.colors.semiGray};
    }

    &.ant-picker-cell-selected .ant-picker-cell-inner {
      background-color: ${(props): string => props.theme.colors.black};
    }

    &.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
    &.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
    &.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after,
    &.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
    &.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
    &.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(
        .ant-picker-cell-range-end
      )::after,
    &.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(
        .ant-picker-cell-range-end
      )::after {
      height: 44px;
      border-color: ${(props): string => props.theme.colors.black};
    }
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: 2px solid ${(props): string => props.theme.colors.focusBorder};
  }

  .ant-picker-range-arrow {
    display: none;
    &::after {
      content: none;
    }
  }

  .ant-picker-dropdown {
    top: 33.3125px !important; /* override inline style */
  }

  .ant-picker-panel-container {
    border-radius: 0;

    .ant-picker-month-btn {
      text-transform: uppercase;
    }
  }
`;
