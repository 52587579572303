import { RefObject } from 'react';
import upArrow from '../../assets/images/upArrow.svg';
import { StyledScrollToTop } from './ScrollToTopButton.styles';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';

export interface ScrollToTopProps {
  containerRef: RefObject<HTMLDivElement>;
}

export const ScrollToTopButton = ({ containerRef }: ScrollToTopProps): JSX.Element => (
  <StyledScrollToTop className="scroll-to-top">
    <TextButton
      onClick={(): void => containerRef?.current?.scrollIntoView({ behavior: 'smooth' })}
      className="scroll-to-top__button"
    >
      <img src={upArrow} alt="up arrow" />
    </TextButton>
  </StyledScrollToTop>
);
