import styled from 'styled-components';

export const StyledMachineClaim = styled.div`
  .machine-claim__form-item {
    .ant-form-item-label {
      padding: 0;
      > label {
        font-family: ${(props): string => props.theme.fonts.formLabel};
        font-size: ${(props): string => props.theme.fontSizes.formLabel};
        color: ${(props): string => props.theme.colors.text};
        text-transform: uppercase;
      }
    }

    .machine-claim__alg-select,
    .machine-claim__input {
      width: 234px;
    }

    .ant-input-disabled {
      border-color: ${(props): string => props.theme.colors.neutralWhiteColor};
      background-color: ${(props): string => props.theme.colors.white};
      pointer-events: none;
    }
  }

  .machine-claim__customer-data {
    .ant-form {
      margin-bottom: 8px;
    }

    .machine-claim__message-missing {
      color: ${(props): string => props.theme.colors.red};
      margin-bottom: 32px;
      margin-top: -24px;
    }
  }

  .machine-claim__customer-data,
  .machine-claim__machine-data {
    p {
      margin-bottom: 5px;
      font-family: ${(props): string => props.theme.fonts.formBody};
      font-size: ${(props): string => props.theme.fontSizes.formBody};
      font-weight: 475;
      line-height: 20px;
    }
  }

  .machine-claim__customer-list-selected-column {
    display: flex;
    justify-content: center;

    .ant-radio-wrapper {
      margin: 0;
    }
  }

  .machine-claim__radio-error {
    .ant-radio-inner {
      border-color: red;
    }
  }

  .machine-claim__button--search {
    padding-top: 22px;
  }
`;
