import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { customerModalsActions } from '../../state/customerModalsSlice';
import { customerModalSelector } from '../../state/customerModalsSelectors';
import { StyledCustomerInviteUserModal } from './CustomerInviteUserModal.styles';
import { CustomerInviteUserForm } from './CustomerInviteUserForm/CustomerInviteUserForm';
import { InviteUserInput } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { CustomerSearchActions } from 'app/modules/user-management/customer-search/state/customerSearchAction';

export const CustomerInviteUserModal = ({ customerId }: { customerId: string }): JSX.Element => {
  const { t } = useTranslation();
  const [formInstance] = Form.useForm();
  const dispatch = useDispatch();

  const isOpen = !!useSelector(customerModalSelector.selectIsInviteUserModalOpen);

  const handleOk = useCallback(() => {
    formInstance.submit();
  }, [formInstance]);

  const handleCancel = useCallback(() => {
    dispatch(customerModalsActions.closeInviteUserModal());
    dispatch(CustomerSearchActions.customerSearchClearList());
    formInstance.resetFields();
  }, [dispatch, formInstance]);

  const onFinish = useCallback(
    (values: InviteUserInput) => {
      dispatch(customerModalsActions.inviteUserModalRequest({ ...values, customerId }));
    },
    [dispatch, customerId]
  );

  useEffect(() => {
    if (!isOpen) {
      formInstance.resetFields();
    }
  }, [isOpen, formInstance]);

  return (
    <StyledCustomerInviteUserModal
      className="customer-invite-user-modal"
      title={t('userInvite.title')}
      centered
      destroyOnClose
      open={isOpen}
      closable={false}
      width={800}
      footer={null}
    >
      <div className="customer-invite-user__body">
        <div className="customer-invite-user__body-content">
          <p>{t('userInvite.mandatoryField')}</p>
          <h3 className="customer-invite-user__sub-title">{t('userInvite.userInformation')}</h3>
          <CustomerInviteUserForm
            formInstance={formInstance}
            handleCancel={handleCancel}
            handleOk={handleOk}
            onFinish={onFinish}
          />
        </div>
      </div>
    </StyledCustomerInviteUserModal>
  );
};
