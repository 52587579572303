import styled from 'styled-components';
import { Row } from 'antd';

export const StyledAvatarInitials = styled(Row)`
  &.avatar-initials {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: ${(props): string => props.theme.colors.gray};
    color: ${(props): string => props.theme.colors.white};
    font-family: ${(props): string => props.theme.fonts.body};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    font-weight: 500;

    p {
      margin: 0;
      margin-top: 3px;
      height: fit-content;
    }
  }
`;
