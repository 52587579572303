import { useCallback, useEffect, useMemo, useState } from 'react';
import { Row, Col, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/lib/form/Form';
import { MachineClaimPairSelectors } from '../../state/machineClaimPairSelectors';
import { StyledMachineClaim } from './MachineClaim.styles';
import { CustomerSearchSelectors } from 'app/modules/user-management/customer-search/state/customerSearchSelectors';
import { CustomerSearchActions } from 'app/modules/user-management/customer-search/state/customerSearchAction';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { MachineDirectoryActions } from 'app/modules/machine-directory/state/machineDirectoryActions';
import { Input } from 'lib/components/Input/Input';
import { Select } from 'lib/components/Select/Select';
import { MachineClaimPairActions } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairActions';
import { MachineDirectorySelectors } from 'app/modules/machine-directory/state/machineDirectorySelectors';
import { CustomerSearchFormValues } from 'app/modules/user-management/interfaces/Customer.types';
import { CustomerSelectedTable } from 'app/components/CustomerSelectedTable/CustomerSelectedTable';

const { Option } = Select;

export const MachineClaim = (): JSX.Element => {
  const { t } = useTranslation();
  const [formSearchCustomer] = useForm();
  const [formMachineData] = useForm();
  const dispatch = useDispatch();

  const [customerSelectedId, setCustomerSelectedId] = useState<string>('');
  const [selectedAlg, setSelectedAlg] = useState<string>('');
  const [isMissingCustomerId, setIsMissingCustomerId] = useState<boolean>(false);

  const algs = useSelector(MachineDirectorySelectors.selectAlgs);
  const areAlgsLoading = useSelector(MachineDirectorySelectors.selectAreAlgsLoading);

  const customerList = useSelector(CustomerSearchSelectors.selectCustomerSearchList);
  const isCustomerSearchLoading = useSelector(CustomerSearchSelectors.selectIsCustomerSearchLoading);

  const isMachineClaimLoading = useSelector(MachineClaimPairSelectors.selectMachineClaimLoading);
  const selectMachineFindData = useSelector(MachineClaimPairSelectors.selectMachineFindData);

  const selectMachineClaimedData = useSelector(MachineClaimPairSelectors.selectMachineClaimData);

  const isListCustomer = useMemo(() => customerList.length > 0, [customerList]);

  const handleSearchCustomerButtonClick = useCallback(() => {
    formSearchCustomer.submit();
  }, [formSearchCustomer]);

  const handleSearchCustomer = useCallback(
    ({ algNumber, customerNameOrNumber }: CustomerSearchFormValues) => {
      dispatch(
        CustomerSearchActions.customerSearchRequest({
          algNumber,
          customerName: customerNameOrNumber,
          customerNumber: customerNameOrNumber,
        })
      );
      setSelectedAlg(algNumber);
    },
    [dispatch]
  );

  const handleClaimMachine = useCallback(() => {
    if (customerSelectedId) {
      dispatch(
        MachineClaimPairActions.claimMachineRequest({
          materialNumber: selectMachineFindData?.materialNumber || '',
          serialNumber: selectMachineFindData?.serialNumber || '',
          customerId: customerSelectedId,
          algNumber: selectedAlg,
        })
      );
    } else {
      setIsMissingCustomerId(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedAlg, customerSelectedId]);

  const handleCustomerNameOrNumberBlur = (_e: React.ChangeEvent<HTMLInputElement>): void => {
    formSearchCustomer.validateFields(['customerNameOrNumber']);
  };

  const handleBackButton = useCallback(() => {
    formSearchCustomer.resetFields();
    dispatch(MachineClaimPairActions.setStepFindMachineActive());
    dispatch(MachineClaimPairActions.findMachineResetState());
  }, [dispatch, formSearchCustomer]);

  useEffect(() => {
    formSearchCustomer.resetFields();
    dispatch(MachineDirectoryActions.getAlgsRequest());
    dispatch(CustomerSearchActions.customerSearchClearList());
  }, [dispatch, formSearchCustomer]);

  useEffect(() => {
    if (customerSelectedId) {
      setIsMissingCustomerId(false);
    }
  }, [customerSelectedId]);

  useEffect(() => {
    formMachineData.setFieldsValue({
      materialNumber: selectMachineFindData?.materialNumber,
      serialNumber: selectMachineFindData?.serialNumber,
    });
  }, [formMachineData, selectMachineFindData?.materialNumber, selectMachineFindData?.serialNumber]);

  useEffect(() => {
    if (selectMachineClaimedData) {
      dispatch(MachineClaimPairActions.setStepSummaryMachineActive());
    }
  }, [dispatch, selectMachineClaimedData]);

  useEffect(() => {
    if (customerList.length > 0 && customerSelectedId !== '') {
      const isCustomerInList = customerList.some(customer => customer.customerId === customerSelectedId);
      if (isCustomerInList) {
        return;
      }
    }
    setCustomerSelectedId('');
  }, [customerList, customerSelectedId]);

  const onSelectCustomer = (selectedRowKeys: React.Key[]): void => {
    setCustomerSelectedId(selectedRowKeys[0] as string);
  };

  return (
    <StyledMachineClaim className="machine-claim">
      <div className="machine-claim__machine-data">
        <p>{t('machineClaiming.machineDataSubTitle')}</p>
        <Form form={formMachineData} layout="vertical" name="machine-claiming">
          <Row gutter={24}>
            <Col>
              <Form.Item
                name="materialNumber"
                label={t('machineClaiming.forms.materialNumber')}
                className="machine-claim__form-item"
              >
                <Input
                  disabled
                  className="machine-claim__input"
                  placeholder={t('machineClaiming.forms.placeholderMaterialNumber')}
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="serialNumber"
                label={t('machineClaiming.forms.serialNumber')}
                className="machine-claim__form-item"
              >
                <Input
                  disabled
                  className="machine-claim__input"
                  placeholder={t('machineClaiming.forms.placeholderSerialNumber')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="machine-claim__customer-data">
        <p>{t('machineClaiming.customerDataSubTitle')}</p>
        <Form
          form={formSearchCustomer}
          layout="vertical"
          name="machine-claiming"
          onFinish={handleSearchCustomer}
          validateTrigger="onChange"
        >
          <Row gutter={24}>
            <Col>
              <Form.Item
                name="algNumber"
                label={t('common.alg')}
                required
                className="machine-claim__form-item"
                rules={[
                  {
                    required: true,
                    message: t('forms.errors.required'),
                  },
                ]}
              >
                <Select
                  className="machine-claim__alg-select"
                  loading={areAlgsLoading}
                  placeholder={t('machineClaiming.forms.placeholderAlg')}
                  showSearch
                >
                  {algs.map(alg => (
                    <Option value={alg} key={alg}>
                      {alg}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                name="customerNameOrNumber"
                label={t('machineClaiming.forms.customerNameOrNumber')}
                className="machine-claim__form-item"
                requiredMark={true}
                rules={[
                  {
                    required: true,
                    message: t('machineClaiming.forms.errors.requiredCustomer'),
                  },
                ]}
                validateTrigger="onChange"
              >
                <Input
                  placeholder={t('machineClaiming.forms.placeholderCustomerNameOrNumber')}
                  className="machine-claim__input"
                  onBlur={handleCustomerNameOrNumberBlur}
                />
              </Form.Item>
            </Col>
            {isListCustomer && (
              <Col className="machine-claim__button--search">
                <PrimaryButton
                  size="m"
                  className="machine-claim__button"
                  onClick={handleSearchCustomerButtonClick}
                  loading={isCustomerSearchLoading}
                >
                  {t('machineClaiming.searchButton')}
                </PrimaryButton>
              </Col>
            )}
          </Row>
        </Form>
        {!isListCustomer && (
          <Row gutter={24}>
            <Col>
              <PrimaryButton
                size="m"
                className="machine-claim__button"
                onClick={handleSearchCustomerButtonClick}
                loading={isCustomerSearchLoading}
              >
                {t('machineClaiming.searchButton')}
              </PrimaryButton>
            </Col>
            <Col>
              <TextButton size="m" onClick={handleBackButton}>
                {t('machineClaiming.backButton')}
              </TextButton>
            </Col>
          </Row>
        )}

        {isListCustomer && <CustomerSelectedTable customerList={customerList} onSelectCustomer={onSelectCustomer} />}
        {isMissingCustomerId && (
          <p className="machine-claim__message-missing">{t('machineClaiming.forms.errors.missingCustomerId')}</p>
        )}
        {isListCustomer && (
          <Row gutter={24} style={{ marginTop: '32px' }}>
            <Col>
              <PrimaryButton
                size="m"
                className="machine-claim__button"
                onClick={handleClaimMachine}
                loading={isMachineClaimLoading}
                disabled={!(customerSelectedId !== '')}
              >
                {t('machineClaiming.claimButton')}
              </PrimaryButton>
            </Col>
            <Col>
              <TextButton size="m" className="machine-claim__button" onClick={handleBackButton}>
                {t('machineClaiming.backButton')}
              </TextButton>
            </Col>
          </Row>
        )}
      </div>
    </StyledMachineClaim>
  );
};
