import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'react-i18next';
import { Skeleton, Table } from 'antd';
import { MachineOwnership } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export const getMachineSelectedColumns = ({ t }: { t: TFunction }): ColumnsType<MachineOwnership> => [
  {
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    width: '9%',
    render: (): JSX.Element => (
      <div className="machine-list__avatar-column">
        <Skeleton.Image className="machine-list__avatar-image" />
      </div>
    ),
  },
  {
    title: t('machineList.table.machineType').toLocaleUpperCase(),
    dataIndex: 'type',
    width: '25%',
    key: 'type',
    render: (value, machine): JSX.Element => (
      <>
        <p>{value}</p>
        <span>{machine.materialNumber}</span>
      </>
    ),
  },
  {
    title: t('machineList.table.serialNo'),
    dataIndex: 'serialNumber',
    width: '20%',
    key: 'serialNumber',
  },
  {
    title: t('machineList.table.customer'),
    dataIndex: 'customerName',
    width: '25%',
    key: 'customerName',
    render: (value, machine): JSX.Element => (
      <>
        <p>{value}</p>
        <span>{machine.customerNumber}</span>
      </>
    ),
  },
  {
    title: t('common.alg'),
    dataIndex: 'algNumber',
    width: '21%',
    key: 'algNumber',
  },
  // TODO: Switch to antd table selection functionality and make <Table> a controlled component in regards
  //  to selection state
  // See https://ant.design/components/table/#components-table-demo-row-selection-and-operation
  Table.SELECTION_COLUMN,
];
