import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledMachinePairingModal = styled(Modal)`
  .ant-modal-header {
    padding: 16px 32px;
  }

  .ant-modal-body {
    padding: 32px;
  }
  .ant-modal-footer {
    justify-content: space-between;
    padding: 8px 32px 32px 32px;
  }

  .machine-pairing__modal-title {
    font-family: ${(props): string => props.theme.fonts.formBody};
    font-size: ${(props): string => props.theme.fontSizes.p0};
    line-height: 20px;
    color: ${(props): string => props.theme.colors.black};
    margin-bottom: 8px;
  }

  .machine-pairing__modal__form-item {
    .ant-form-item-label {
      padding: 0;
      > label {
        font-family: ${(props): string => props.theme.fonts.captionBold};
        font-size: ${(props): string => props.theme.fontSizes.caption};
        color: ${(props): string => props.theme.colors.black};
        text-transform: uppercase;
      }
    }
    pointer-events: none;
  }

  .machine-pairing__modal__input {
    border: 2px solid;
    border-color: ${(props): string => props.theme.colors.neutralWhiteColor};
    width: 256px;
  }

  .machine-pairing__modal__already-paired {
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.p0};
    line-height: 20px;
    color: ${(props): string => props.theme.colors.red};
    margin-bottom: 2px;
  }

  .machine-pairing__modal__question {
    font-family: ${(props): string => props.theme.fonts.formBody};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    font-size: ${(props): string => props.theme.fontSizes.p0};
    line-height: 20px;
    color: ${(props): string => props.theme.colors.black};
    margin-bottom: 16px;
  }
`;
