import styled from 'styled-components';
import { Layout } from 'antd';

const { Sider } = Layout;

export const StyledAppSider = styled(Sider)`
  position: absolute;
  height: calc(100% - 94px);
  background-color: ${(props): string => props.theme.colors.gray};
  font-family: ${(props): string => props.theme.fonts.menu};
  text-transform: uppercase;
  z-index: 99;

  .ant-menu {
    background-color: ${(props): string => props.theme.colors.gray};

    &.ant-menu-vertical {
      border-right: none;
    }

    & .ant-menu-item {
      display: flex;
      align-items: center;
      margin: 0;
      background-color: ${(props): string => props.theme.colors.gray};
      height: 44px;
      white-space: break-spaces;
      line-height: 14px;

      :focus {
        border: 2px solid ${(props): string => props.theme.colors.focusBorder};
      }

      &.ant-menu-item-active {
        background-color: #686868;
      }

      & a {
        color: ${(props): string => props.theme.colors.white};
        font-size: 11px;
      }

      & .ant-menu-item-icon {
        fill: ${(props): string => props.theme.colors.white};
        width: 22px;
        height: 20px;
        flex-shrink: 0;

        & + span {
          margin-left: 8px;
        }
      }

      &.ant-menu-item-selected {
        background-color: ${(props): string => props.theme.colors.text};
        font-weight: bold;

        & a {
          color: ${(props): string => props.theme.colors.primary};
        }

        & .ant-menu-item-icon path,
        & .ant-menu-item-icon rect {
          fill: ${(props): string => props.theme.colors.primary} !important;
        }
      }
    }
  }

  .header__product-name {
    width: min-content;
    line-height: 22px;
    margin: 0;
    padding: 9px 8px;
    font-family: ${(props): string => props.theme.fonts.pageTitle};
    font-size: 20px;
    color: ${(props): string => props.theme.colors.primary};
  }
`;
