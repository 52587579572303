/* eslint-disable max-len */
import {
  addDays,
  compareAsc,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  parseISO,
  sub,
} from 'date-fns/esm';
import { Optional } from 'lib/types/Optional';

export enum DATE_FORMAT_PATTERN {
  DATE_TIME,
  DATE,
  DATE_DAY_AND_MONTH,
  DATE_WITH_WEEKDAY_NAME,
  TIME,
  TIME_12_HOUR,
}

const MAPPING_INTL_DATE_TIME_FORMAT_OPTIONS: Record<DATE_FORMAT_PATTERN, Intl.DateTimeFormatOptions> = {
  [DATE_FORMAT_PATTERN.DATE_TIME]: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  [DATE_FORMAT_PATTERN.DATE]: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  [DATE_FORMAT_PATTERN.DATE_WITH_WEEKDAY_NAME]: {
    weekday: 'long',
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  },
  [DATE_FORMAT_PATTERN.DATE_DAY_AND_MONTH]: {
    month: 'numeric',
    day: 'numeric',
  },
  [DATE_FORMAT_PATTERN.TIME]: {
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  [DATE_FORMAT_PATTERN.TIME_12_HOUR]: {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  },
};

export interface RoundDurationByToMinutesOptions {
  ms: number;
}

export interface FormatDurationByMillisecondsOptions {
  ms: number;
  showSeconds?: boolean;
}

export interface FormatDurationByStartAndEndDateOptions {
  startDate: string;
  endDate: string;
  showSeconds?: boolean;
}

export class DateTime {
  public static today = (): Date => new Date();
  public static oneWeekAgo = (): Date => addDays(DateTime.today(), -7);
  public static oneDayAgo = (): Date => addDays(DateTime.today(), -1);
  public static threeDaysAgo = (): Date => addDays(DateTime.today(), -3);
  public static beginningOfEpochTime = (): Date => new Date(0);

  /**
   * @param locale - It must be passed as value of `i18n.language`
   * @param timestamp - It should be defined as ISO 8601 timestamp string
   * E.g. `2021-08-24T14:00:00.000Z` or `2021-08-24T16:00:00.000+0200`
   * Otherwise the parsing with `new Date()` is unreliable.
   * @param dateFormatPattern - Use enum DATE_FORMAT_PATTERN
   * @returns {string} - String of date formatted according to the i18n's language as locale
   *
   * References:
   * - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/Date#timestamp_string
   * - https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
   */
  public static formatDateByLocale = (
    locale: string,
    timestamp: Optional<string>,
    dateFormatPattern: DATE_FORMAT_PATTERN = DATE_FORMAT_PATTERN.DATE_TIME
  ): string => {
    if (!timestamp) return '';

    return new Intl.DateTimeFormat(locale, MAPPING_INTL_DATE_TIME_FORMAT_OPTIONS[dateFormatPattern]).format(
      new Date(timestamp)
    );
  };

  public static roundMsDurationToMinutes = ({ ms }: RoundDurationByToMinutesOptions): number => {
    if (!Number.isInteger(ms)) {
      throw Error('Argument "ms" needs to be a integer.');
    }

    const durationInMsRoundedDownToMinutes = Math.floor(ms / 60000) * 60000;

    return durationInMsRoundedDownToMinutes;
  };

  public static formatDurationByMilliseconds = ({
    ms,
    showSeconds = true,
  }: FormatDurationByMillisecondsOptions): string => {
    if (!Number.isInteger(ms)) {
      throw Error('Argument "ms" needs to be a integer.');
    }

    const startDate = new Date(0).toISOString();
    const endDate = new Date(ms).toISOString();

    return DateTime.formatDurationByStartAndEndDate({
      startDate,
      endDate,
      showSeconds,
    });
  };

  public static formatDurationByStartAndEndDate = ({
    startDate,
    endDate,
    showSeconds = true,
  }: FormatDurationByStartAndEndDateOptions): string => {
    let remainingMinutes: Date;
    let minutes: number;

    const pad = (value: number): string => String(value).padStart(2, '0');
    const interval = {
      start: parseISO(startDate),
      end: parseISO(endDate),
    };

    const sign = compareAsc(interval.start, interval.end);

    const hours = Math.abs(differenceInHours(interval.start, interval.end));

    if (!showSeconds) {
      // If seconds are hidden, round down minutes
      remainingMinutes = sub(interval.start, { hours: sign * hours });
      minutes = Math.abs(Math.floor(differenceInMinutes(remainingMinutes, interval.end)));
    } else {
      remainingMinutes = sub(interval.start, { hours: sign * hours });
      minutes = Math.abs(differenceInMinutes(remainingMinutes, interval.end));
    }

    const remainingSeconds = sub(remainingMinutes, { minutes: sign * minutes });
    const seconds = Math.abs(differenceInSeconds(remainingSeconds, interval.end));

    if (Number.isNaN(hours) || Number.isNaN(minutes) || Number.isNaN(seconds)) {
      throw Error('Unable to calculate duration. Are startDate and endDate parsable ISO 8601 dates?');
    }

    let formattedDuration = `${pad(hours)}:${pad(minutes)}`;

    if (showSeconds) {
      formattedDuration = `${formattedDuration}:${pad(seconds)}`;
    }

    if (sign > 0) {
      formattedDuration = `-${formattedDuration}`;
    }

    return formattedDuration;
  };
}
