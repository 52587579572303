import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CustomerDetailsSelectors } from '../../../state/customerDetailsSelectors';
import { customerDetailsActions } from '../../../state/customerDetailsSlice';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { CustomerManagementEntity } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { customerModalsActions } from 'app/modules/customer-management/modals/state/customerModalsSlice';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { CustomerHasUserOrDeviceDeleteModal } from 'app/modules/customer-management/modals/components/CustomerDeleteModal/CustomerHasUserOrDeviceDeleteModal/CustomerHasUserOrDeviceDeleteModal';
import { CustomerDeleteModal } from 'app/modules/customer-management/modals/components/CustomerDeleteModal/CustomerNoUserNoDeviceModal/CustomerDeleteModal';
import { userListSelectors } from 'app/modules/user-management/user-list/state/userListSelectors';
import * as machineListSelectors from 'app/modules/machine-directory/machine-list/state/machineListSelectors';
import { CustomerEditModal } from 'app/modules/customer-management/modals/components/CustomerEditModal/CustomerEditModal';
import { deleteCustomerActions } from 'app/modules/customer-management/modals/components/CustomerDeleteModal/state/deleteCustomerSlice';
import { MachineListActions } from 'app/modules/machine-directory/machine-list/state/machineListActions';
import { UserListActions } from 'app/modules/user-management/user-list/state/userListActions';

const CustomerInformation: Record<string, keyof CustomerManagementEntity> = {
  customerName: 'customerName',
  alg: 'alg',
  street: 'street',
  houseNumber: 'houseNumber',
  zipCode: 'zipCode',
  city: 'city',
  region: 'region',
  district: 'district',
};

export const CustomerDetailsInfo = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: customerId } = useParams();

  const customerDetailsData = useSelector(CustomerDetailsSelectors.selectData);
  const userList = useSelector(userListSelectors.selectData);
  const deviceList = useSelector(machineListSelectors.selectData);

  const users = useMemo(
    () =>
      userList.map(user => ({
        ...user,
        key: user.internalUserId,
      })),
    [userList]
  );
  const devices = useMemo(
    () =>
      deviceList
        ? deviceList.map(device => ({
            ...device,
            key: device.id,
          }))
        : [],
    [deviceList]
  );

  const handleOpenCustomerDeleteModal = useCallback(() => {
    dispatch(customerModalsActions.openDeleteCustomerModal());
  }, [dispatch]);

  const handleOpenCustomerEditModal = useCallback(() => {
    dispatch(customerModalsActions.openEditCustomerModal());
  }, [dispatch]);

  const getCustomerDetailsInformation = (info: keyof CustomerManagementEntity): JSX.Element => (
    <Row className="customer-details-body__left-content--info-content">
      <Col className="customer-details-body__left-content--info">
        <p className={`customer-details-body__left-content--info-label label-${info}`}>
          {t(`customerDetails.body.label.${info}`)}
        </p>
        <p className="customer-details-body__left-content--info-data">{customerDetailsData?.[info] || '-'}</p>
      </Col>
    </Row>
  );

  useEffect(() => {
    dispatch(
      UserListActions.getUserListRequest({
        filter: {
          customerId,
        },
        paginationOptions: {
          limit: 1000,
          paginationToken: '',
        },
      })
    );
  }, [dispatch, customerId]);

  useEffect(() => {
    if (customerId) {
      dispatch(MachineListActions.setActiveCustomerFilter(customerId));
      dispatch(
        MachineListActions.getMachineListRequest({
          search: '',
          filter: {
            algNumber: customerDetailsData?.alg || '',
            customerId,
          },
          paginationOptions: {
            paginationToken: '',
            limit: 1000,
          },
        })
      );
    }
  }, [customerDetailsData?.alg, customerId, dispatch]);

  useEffect(() => {
    if (!customerId) return;
    dispatch(customerDetailsActions.getCustomerDetailsRequest({ id: customerId }));
  }, [dispatch, customerId]);

  useEffect(
    () => () => {
      dispatch(deleteCustomerActions.resetState());
    },
    [dispatch, customerId]
  );

  if (!customerId) return <></>;

  const customerNoUsersNoDevices = users.length === 0 && devices.length === 0;

  return (
    <>
      <Col span={12} className="customer-details-body__left">
        <p className="customer-details-body__left-heading">{t('customerDetails.body.label.customerInformation')}</p>
        <div className="customer-details-body__left-content">
          {getCustomerDetailsInformation(CustomerInformation.customerName)}
          {getCustomerDetailsInformation(CustomerInformation.street)}
          {getCustomerDetailsInformation(CustomerInformation.houseNumber)}
          {getCustomerDetailsInformation(CustomerInformation.zipCode)}
          {getCustomerDetailsInformation(CustomerInformation.city)}
          {getCustomerDetailsInformation(CustomerInformation.region)}
          {getCustomerDetailsInformation(CustomerInformation.district)}
        </div>
        <PrimaryButton
          size="m"
          className="customer-details-body__left-content--edit-btn"
          onClick={handleOpenCustomerEditModal}
        >
          {t('customerDetails.body.button.editCustomerInformation')}
        </PrimaryButton>
      </Col>

      <Col span={12} className="customer-details-body__right">
        <div className="customer-details-body__right-content">
          <p className="customer-details-body__right-heading">{t('customerDetails.body.label.customerDelete')}</p>
          <Row className="customer-details-body__right-content--info-content">
            <Col className="customer-details-body__right-content--info-data">
              <p className="customer-details-body__right-content--description">
                {t('customerDetails.body.userDeleteDescription')}
              </p>
              <SecondaryButton
                size="m"
                className="customer-details-body__right-content--delete-btn"
                onClick={handleOpenCustomerDeleteModal}
              >
                {t('customerDetails.body.button.deleteCustomer')}
              </SecondaryButton>
            </Col>
          </Row>
        </div>
      </Col>

      <CustomerEditModal />
      {customerNoUsersNoDevices && <CustomerDeleteModal customerName={customerDetailsData?.customerName || ''} />}
      {!customerNoUsersNoDevices && <CustomerHasUserOrDeviceDeleteModal />}
    </>
  );
};
