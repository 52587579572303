import { Action } from 'redux';
import {
  MachineClaimResponse,
  MachineFindResponse,
  MachinePairingResponse,
  MachinePairingSearchResponse,
  MachineSearchMaterialNumberResponse,
} from '../../interfaces/Machine.types';
import {
  MachineClientClaimOptions,
  MachineClientFindOptions,
  MachineClientPairOptions,
  MachineClientPairSearchOptions,
  MachineClientSearchMaterialOptions,
} from '../../MachineClient';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Optional } from 'lib/types/Optional';

export type ISetStepFindMachineActiveAction = Action<typeof MachineClaimPairActions.SET_STEP_FIND_MACHINE_ACTIVE>;

export type ISetStepClaimMachineActiveAction = Action<typeof MachineClaimPairActions.SET_STEP_CLAIM_MACHINE_ACTIVE>;

export type ISetStepPairMachineActiveAction = Action<typeof MachineClaimPairActions.SET_STEP_PAIR_MACHINE_ACTIVE>;

export type ISetStepSummaryMachineActiveAction = Action<typeof MachineClaimPairActions.SET_STEP_SUMMARY_MACHINE_ACTIVE>;

export type ISearchMaterialNumberRequestOptions = MachineClientSearchMaterialOptions;
export type ISearchMaterialNumberRequestAction = Action<
  typeof MachineClaimPairActions.SEARCH_MATERIAL_NUMBER_REQUEST
> & {
  payload: MachineClientSearchMaterialOptions;
};

export type ISearchMaterialNumberSuccessOptions = Optional<MachineSearchMaterialNumberResponse>;
export type ISearchMaterialNumberSuccessAction = Action<
  typeof MachineClaimPairActions.SEARCH_MATERIAL_NUMBER_SUCCESS
> & {
  payload: Optional<MachineSearchMaterialNumberResponse>;
};

export type ISearchMaterialNumberErrorAction = IErrorAction<
  typeof MachineClaimPairActions.SEARCH_MATERIAL_NUMBER_ERROR
>;

export type ISearchMaterialNumberResetStateAction = Action<
  typeof MachineClaimPairActions.SEARCH_MATERIAL_NUMBER_RESET_STATE
>;

export type IMachineFindRequestOptions = MachineClientFindOptions;
export type IMachineFindRequestAction = Action<typeof MachineClaimPairActions.FIND_MACHINE_REQUEST> & {
  payload: MachineClientFindOptions;
};

export type IMachineFindSuccessOptions = Optional<MachineFindResponse>;
export type IMachineFindSuccessAction = Action<typeof MachineClaimPairActions.FIND_MACHINE_SUCCESS> & {
  payload: Optional<MachineFindResponse>;
};

export type IMachineFindErrorAction = IErrorAction<typeof MachineClaimPairActions.FIND_MACHINE_ERROR>;

export type IMachineFindResetStateAction = Action<typeof MachineClaimPairActions.FIND_MACHINE_RESET_STATE>;

export type IClaimMachineRequestOptions = MachineClientClaimOptions;

export type IClaimMachineRequestAction = Action<typeof MachineClaimPairActions.CLAIM_MACHINE_REQUEST> & {
  payload: MachineClientClaimOptions;
};

export type IClaimMachineSuccessOptions = Optional<MachineClaimResponse>;
export type IClaimMachineSuccessAction = Action<typeof MachineClaimPairActions.CLAIM_MACHINE_SUCCESS> & {
  payload: IClaimMachineSuccessOptions;
};
export type IClaimMachineErrorAction = IErrorAction<typeof MachineClaimPairActions.CLAIM_MACHINE_ERROR>;

export type IClaimMachineResetStateAction = Action<typeof MachineClaimPairActions.CLAIM_MACHINE_RESET_STATE>;

export type IPairMachineSearchRequestOptions = MachineClientPairSearchOptions;

export type IPairMachineSearchRequestAction = Action<typeof MachineClaimPairActions.PAIR_MACHINE_SEARCH_REQUEST> & {
  payload: IPairMachineSearchRequestOptions;
};

export type IPairMachineSearchClearDataAction = Action<typeof MachineClaimPairActions.PAIR_MACHINE_SEARCH_CLEAR_DATA>;

export type IPairMachineSearchSuccessOptions = Optional<MachinePairingSearchResponse>;
export type IPairMachineSearchSuccessAction = Action<typeof MachineClaimPairActions.PAIR_MACHINE_SEARCH_SUCCESS> & {
  payload: IPairMachineSearchSuccessOptions;
};
export type IPairMachineSearchErrorAction = IErrorAction<typeof MachineClaimPairActions.PAIR_MACHINE_SEARCH_ERROR>;

export type IPairMachineRequestOptions = MachineClientPairOptions;
export type IPairMachineRequestAction = Action<typeof MachineClaimPairActions.PAIR_MACHINE_REQUEST> & {
  payload: IPairMachineRequestOptions;
};

export type IPairMachineSuccessOptions = Optional<MachinePairingResponse>;
export type IPairMachineSuccessAction = Action<typeof MachineClaimPairActions.PAIR_MACHINE_SUCCESS> & {
  payload: IPairMachineSuccessOptions;
};
export type IPairMachineErrorAction = IErrorAction<typeof MachineClaimPairActions.PAIR_MACHINE_ERROR>;

export type IPairMachineResetStateAction = Action<typeof MachineClaimPairActions.PAIR_MACHINE_RESET_STATE>;

export class MachineClaimPairActions {
  public static SET_STEP_FIND_MACHINE_ACTIVE = 'MACHINE_CLAIMING_PAIRING::SET_STEP_FIND_MACHINE_ACTIVE';
  public static SET_STEP_CLAIM_MACHINE_ACTIVE = 'MACHINE_CLAIMING_PAIRING::SET_STEP_CLAIM_MACHINE_ACTIVE';
  public static SET_STEP_PAIR_MACHINE_ACTIVE = 'MACHINE_CLAIMING_PAIRING::SET_STEP_PAIR_MACHINE_ACTIVE';
  public static SET_STEP_SUMMARY_MACHINE_ACTIVE = 'MACHINE_CLAIMING_PAIRING::SET_STEP_SUMMARY_MACHINE_ACTIVE';

  public static SEARCH_MATERIAL_NUMBER_REQUEST = 'MACHINE_CLAIMING_PAIRING::SEARCH_MATERIAL_NUMBER_REQUEST';
  public static SEARCH_MATERIAL_NUMBER_SUCCESS = 'MACHINE_CLAIMING_PAIRING::SEARCH_MATERIAL_NUMBER_SUCCESS';
  public static SEARCH_MATERIAL_NUMBER_ERROR = 'MACHINE_CLAIMING_PAIRING::SEARCH_MATERIAL_NUMBER_ERROR';
  public static SEARCH_MATERIAL_NUMBER_RESET_STATE = 'MACHINE_CLAIMING_PAIRING::SEARCH_MATERIAL_NUMBER_RESET_STATE';

  public static FIND_MACHINE_REQUEST = 'MACHINE_CLAIMING_PAIRING::FIND_MACHINE_REQUEST';
  public static FIND_MACHINE_SUCCESS = 'MACHINE_CLAIMING_PAIRING::FIND_MACHINE_SUCCESS';
  public static FIND_MACHINE_ERROR = 'MACHINE_CLAIMING_PAIRING::FIND_MACHINE_ERROR';
  public static FIND_MACHINE_RESET_STATE = 'MACHINE_CLAIMING_PAIRING::FIND_MACHINE_RESET_STATE';

  public static CLAIM_MACHINE_REQUEST = 'MACHINE_CLAIMING_PAIRING::CLAIM_MACHINE_REQUEST';
  public static CLAIM_MACHINE_SUCCESS = 'MACHINE_CLAIMING_PAIRING::CLAIM_MACHINE_SUCCESS';
  public static CLAIM_MACHINE_ERROR = 'MACHINE_CLAIMING_PAIRING::CLAIM_MACHINE_ERROR';
  public static CLAIM_MACHINE_RESET_STATE = 'MACHINE_CLAIMING_PAIRING::CLAIM_MACHINE_RESET_STATE';

  public static PAIR_MACHINE_SEARCH_REQUEST = 'MACHINE_CLAIMING_PAIRING::PAIR_MACHINE_SEARCH_REQUEST';
  public static PAIR_MACHINE_SEARCH_SUCCESS = 'MACHINE_CLAIMING_PAIRING::PAIR_MACHINE_SEARCH_SUCCESS';
  public static PAIR_MACHINE_SEARCH_ERROR = 'MACHINE_CLAIMING_PAIRING::PAIR_MACHINE_SEARCH_ERROR';

  public static PAIR_MACHINE_SEARCH_CLEAR_DATA = 'MACHINE_CLAIMING_PAIRING::PAIR_MACHINE_SEARCH_CLEAR_DATA';

  public static PAIR_MACHINE_REQUEST = 'MACHINE_CLAIMING_PAIRING::PAIR_MACHINE_REQUEST';
  public static PAIR_MACHINE_SUCCESS = 'MACHINE_CLAIMING_PAIRING::PAIR_MACHINE_SUCCESS';
  public static PAIR_MACHINE_ERROR = 'MACHINE_CLAIMING_PAIRING::PAIR_MACHINE_ERROR';
  public static PAIR_MACHINE_RESET_STATE = 'MACHINE_CLAIMING_PAIRING::PAIR_MACHINE_RESET_STATE';

  public static setStepFindMachineActive(): ISetStepFindMachineActiveAction {
    return {
      type: MachineClaimPairActions.SET_STEP_FIND_MACHINE_ACTIVE,
    };
  }

  public static setStepClaimMachineActive(): ISetStepClaimMachineActiveAction {
    return {
      type: MachineClaimPairActions.SET_STEP_CLAIM_MACHINE_ACTIVE,
    };
  }

  public static setStepPairMachineActive(): ISetStepPairMachineActiveAction {
    return {
      type: MachineClaimPairActions.SET_STEP_PAIR_MACHINE_ACTIVE,
    };
  }

  public static setStepSummaryMachineActive(): ISetStepSummaryMachineActiveAction {
    return {
      type: MachineClaimPairActions.SET_STEP_SUMMARY_MACHINE_ACTIVE,
    };
  }

  public static searchMaterialNumberRequest({
    search,
    filter,
    paginationOptions,
  }: ISearchMaterialNumberRequestOptions): ISearchMaterialNumberRequestAction {
    return {
      type: MachineClaimPairActions.SEARCH_MATERIAL_NUMBER_REQUEST,
      payload: {
        search,
        filter,
        paginationOptions,
      },
    };
  }

  public static searchMaterialNumberSuccess(
    data: ISearchMaterialNumberSuccessOptions
  ): ISearchMaterialNumberSuccessAction {
    return {
      type: MachineClaimPairActions.SEARCH_MATERIAL_NUMBER_SUCCESS,
      payload: data,
    };
  }

  public static searchMaterialNumberError({ error }: IActionCreatorErrorOptions): ISearchMaterialNumberErrorAction {
    return {
      type: MachineClaimPairActions.SEARCH_MATERIAL_NUMBER_ERROR,
      error,
    };
  }

  public static searchMaterialNumberResetState(): ISearchMaterialNumberResetStateAction {
    return {
      type: MachineClaimPairActions.SEARCH_MATERIAL_NUMBER_RESET_STATE,
    };
  }

  public static findMachineRequest({
    materialNumber,
    serialNumber,
  }: IMachineFindRequestOptions): IMachineFindRequestAction {
    return {
      type: MachineClaimPairActions.FIND_MACHINE_REQUEST,
      payload: {
        materialNumber,
        serialNumber,
      },
    };
  }

  public static findMachineSuccess(data: IMachineFindSuccessOptions): IMachineFindSuccessAction {
    return {
      type: MachineClaimPairActions.FIND_MACHINE_SUCCESS,
      payload: data,
    };
  }

  public static findMachineError({ error }: IActionCreatorErrorOptions): IMachineFindErrorAction {
    return {
      type: MachineClaimPairActions.FIND_MACHINE_ERROR,
      error,
    };
  }

  public static findMachineResetState(): IMachineFindResetStateAction {
    return {
      type: MachineClaimPairActions.FIND_MACHINE_RESET_STATE,
    };
  }

  public static claimMachineRequest({
    algNumber,
    customerId,
    materialNumber,
    serialNumber,
  }: IClaimMachineRequestOptions): IClaimMachineRequestAction {
    return {
      type: MachineClaimPairActions.CLAIM_MACHINE_REQUEST,
      payload: {
        algNumber,
        customerId,
        materialNumber,
        serialNumber,
      },
    };
  }

  public static claimMachineSuccess(response: IClaimMachineSuccessOptions): IClaimMachineSuccessAction {
    return {
      type: MachineClaimPairActions.CLAIM_MACHINE_SUCCESS,
      payload: response,
    };
  }

  public static claimMachineError({ error }: IActionCreatorErrorOptions): IClaimMachineErrorAction {
    return {
      type: MachineClaimPairActions.CLAIM_MACHINE_ERROR,
      error,
    };
  }

  public static claimMachineResetState(): IClaimMachineResetStateAction {
    return {
      type: MachineClaimPairActions.CLAIM_MACHINE_RESET_STATE,
    };
  }

  public static pairMachineSearchRequest({
    gatewayMaterialNumber,
    gatewaySerialNumber,
    machineMaterialNumber,
    machineSerialNumber,
  }: IPairMachineSearchRequestOptions): IPairMachineSearchRequestAction {
    return {
      type: MachineClaimPairActions.PAIR_MACHINE_SEARCH_REQUEST,
      payload: {
        gatewayMaterialNumber,
        gatewaySerialNumber,
        machineMaterialNumber,
        machineSerialNumber,
      },
    };
  }

  public static pairMachineSearchSuccess(response: IPairMachineSearchSuccessOptions): IPairMachineSearchSuccessAction {
    return {
      type: MachineClaimPairActions.PAIR_MACHINE_SEARCH_SUCCESS,
      payload: response,
    };
  }

  public static pairMachineSearchError({ error }: IActionCreatorErrorOptions): IPairMachineSearchErrorAction {
    return {
      type: MachineClaimPairActions.PAIR_MACHINE_SEARCH_ERROR,
      error,
    };
  }

  public static pairMachineSearchClearData(): IPairMachineSearchClearDataAction {
    return {
      type: MachineClaimPairActions.PAIR_MACHINE_SEARCH_CLEAR_DATA,
    };
  }

  public static pairMachineRequest({
    gatewayMaterialNumber,
    gatewaySerialNumber,
    machineMaterialNumber,
    machineSerialNumber,
    operatingHours,
  }: IPairMachineRequestOptions): IPairMachineRequestAction {
    return {
      type: MachineClaimPairActions.PAIR_MACHINE_REQUEST,
      payload: {
        gatewayMaterialNumber,
        gatewaySerialNumber,
        machineMaterialNumber,
        machineSerialNumber,
        operatingHours,
      },
    };
  }

  public static pairMachineSuccess(response: IPairMachineSuccessOptions): IPairMachineSuccessAction {
    return {
      type: MachineClaimPairActions.PAIR_MACHINE_SUCCESS,
      payload: response,
    };
  }

  public static pairMachineError({ error }: IActionCreatorErrorOptions): IPairMachineErrorAction {
    return {
      type: MachineClaimPairActions.PAIR_MACHINE_ERROR,
      error,
    };
  }

  public static pairMachineResetState(): IPairMachineResetStateAction {
    return {
      type: MachineClaimPairActions.PAIR_MACHINE_RESET_STATE,
    };
  }
}
