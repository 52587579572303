import React, { useEffect, useCallback } from 'react';
import { Form, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { InviteUserInput } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { supportedLanguages } from 'app/cross-cutting-concerns/translations/Translations';
import { CustomerSearchActions } from 'app/modules/user-management/customer-search/state/customerSearchAction';
import { MachineDirectoryActions } from 'app/modules/machine-directory/state/machineDirectoryActions';
import { customerModalSelector } from 'app/modules/customer-management/modals/state/customerModalsSelectors';
import { userInviteSelectors } from 'app/modules/user-management/user-invite/state/userInviteSelectors';
import { StyledUserInviteForm } from 'app/modules/user-management/user-invite/components/InviteUser/UserInviteForm.styles';
import { StyledUserInviteGlobalStyles } from 'app/modules/user-management/user-invite/components/InviteUser/UserInviteTooltip/UserInviteTooltip.global.styles';
import { UserInformationForm } from 'app/components/UserInformationForm/UserInformationForm';

const languageOptions = Object.entries(supportedLanguages).map(([key, values]) => ({
  value: key,
  content: values.name,
}));

interface UserInfoFormProps {
  formInstance: FormInstance<any>;
  handleCancel: () => void;
  handleOk: () => void;
  onFinish: (values: InviteUserInput) => void;
}

export const CustomerInviteUserForm = ({
  formInstance,
  handleCancel,
  handleOk,
  onFinish,
}: UserInfoFormProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isUserInviteLoading = useSelector(userInviteSelectors.selectIsUserInviteLoading);
  const isCustomerUserInviteLoading = useSelector(customerModalSelector.selectIsInviteUserModalLoading);

  useEffect(
    () => () => {
      dispatch(CustomerSearchActions.customerSearchClearList());
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(MachineDirectoryActions.getAlgsRequest());
  }, [dispatch]);

  const handleSubmitFormInstance = useCallback(() => {
    handleOk();
  }, [handleOk]);

  return (
    <StyledUserInviteForm>
      <Form
        form={formInstance}
        name="invite-user"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{ language: languageOptions[3].value }}
      >
        <UserInformationForm />
      </Form>

      <StyledUserInviteGlobalStyles />
      <div className="invite-user__buttons">
        <SecondaryButton size="m" key="back-button" className="invite-user__back-button" onClick={handleCancel}>
          {t('common.back')}
        </SecondaryButton>
        <div style={{ display: 'flex' }}>
          <PrimaryButton
            size="m"
            key="submit"
            className="invite-user__submit-button"
            type="primary"
            onClick={handleSubmitFormInstance}
            loading={!!isUserInviteLoading || !!isCustomerUserInviteLoading}
          >
            {t('userInvite.title')}
          </PrimaryButton>
        </div>
      </div>
    </StyledUserInviteForm>
  );
};
