import { produce } from 'immer';
import { AnyAction } from 'redux';
import { UserModalsActions } from './userModalsActions';
import { Optional } from 'lib/types/Optional';

export interface UserModalsState {
  deleteUser: {
    visible: boolean;
    isLoading: boolean;
  };
  editUser: {
    visible: boolean;
    isLoading: boolean;
  };
  resendInvitation: {
    visible: boolean;
    isLoading: boolean;
  };
  changeCustomer: {
    visible: boolean;
    isLoading: boolean;
    userId?: Optional<string>;
  };
}

export const initialState: UserModalsState = {
  deleteUser: {
    visible: false,
    isLoading: false,
  },
  editUser: {
    visible: false,
    isLoading: false,
  },
  resendInvitation: {
    visible: false,
    isLoading: false,
  },
  changeCustomer: {
    visible: false,
    isLoading: false,
    userId: '',
  },
};

export const userModalsReducer = (state = initialState, action: AnyAction): UserModalsState =>
  produce(state, draft => {
    switch (action.type) {
      case UserModalsActions.SHOW_DELETE_USER_MODAL: {
        draft.deleteUser.visible = true;
        return draft;
      }

      case UserModalsActions.HIDE_DELETE_USER_MODAL: {
        draft.deleteUser.visible = false;
        return draft;
      }

      case UserModalsActions.SHOW_RESEND_INVITATION_MODAL: {
        draft.resendInvitation.visible = true;
        return draft;
      }

      case UserModalsActions.HIDE_RESEND_INVITATION_MODAL: {
        draft.resendInvitation.visible = false;
        return draft;
      }

      case UserModalsActions.SHOW_EDIT_USER_MODAL: {
        draft.editUser.visible = true;
        return draft;
      }

      case UserModalsActions.HIDE_EDIT_USER_MODAL: {
        draft.editUser.visible = false;
        return draft;
      }

      case UserModalsActions.IS_DELETE_USER_MODAL_LOADING: {
        draft.deleteUser.isLoading = true;
        return draft;
      }

      case UserModalsActions.IS_DELETE_USER_MODAL_NOT_LOADING: {
        draft.deleteUser.isLoading = false;
        return draft;
      }

      case UserModalsActions.IS_EDIT_USER_MODAL_LOADING: {
        draft.editUser.isLoading = true;
        return draft;
      }

      case UserModalsActions.IS_EDIT_USER_MODAL_NOT_LOADING: {
        draft.editUser.isLoading = false;
        return draft;
      }

      case UserModalsActions.IS_RESEND_INVITATION_USER_MODAL_LOADING: {
        draft.resendInvitation.isLoading = true;
        return draft;
      }

      case UserModalsActions.IS_RESEND_INVITATION_USER_MODAL_NOT_LOADING: {
        draft.resendInvitation.isLoading = false;
        return draft;
      }

      case UserModalsActions.SHOW_CHANGE_CUSTOMER_MODAL: {
        draft.changeCustomer.visible = true;
        draft.changeCustomer.userId = action.payload.userId;
        return draft;
      }

      case UserModalsActions.HIDE_CHANGE_CUSTOMER_MODAL: {
        draft.changeCustomer.visible = false;
        draft.changeCustomer.userId = '';
        return draft;
      }

      case UserModalsActions.IS_CHANGE_CUSTOMER_MODAL_LOADING: {
        draft.changeCustomer.isLoading = true;
        return draft;
      }

      case UserModalsActions.IS_CHANGE_CUSTOMER_MODAL_NOT_LOADING: {
        draft.changeCustomer.isLoading = false;
        return draft;
      }

      default:
        return draft;
    }
  });
