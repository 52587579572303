import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spin } from 'antd';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { deleteCustomerSelector } from '../state/deleteCustomerSelector';
import { deleteCustomerActions } from '../state/deleteCustomerSlice';
import { CustomerDeleteDescription } from '../CustomerDeleteDescription/CustomerDeleteDescription';
import { getUserListColumns } from '../CustomerHasUserOrDeviceDeleteModal/columns/CusTomerDeleteHasUser.columns';
import { CustomerReassignUserModal } from '../../CustomerReassignUserModal/CustomerReassignUserModal';
import { customerModalSelector } from '../../../state/customerModalsSelectors';
import { StyledCustomerDeleteHasUserTab } from './CustomerDeleteHasUserTab.styles';
import { userListSelectors } from 'app/modules/user-management/user-list/state/userListSelectors';
import * as machineListSelectors from 'app/modules/machine-directory/machine-list/state/machineListSelectors';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Checkbox } from 'lib/components/Checkbox/Checkbox';
import { User } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { UserListActions } from 'app/modules/user-management/user-list/state/userListActions';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { PopConfirm } from 'lib/components/PopConfirm/PopConfirm';
import { Table } from 'lib/components/Table/Table';
import { ChangeCustomerModal } from 'app/modules/user-management/modals/components/ChangeCustomerModal/ChangeCustomerModal';

interface ICustomerDeleteHasUserTabProps {
  isOpen: boolean;
}

export const CustomerDeleteHasUserTab = ({ isOpen }: ICustomerDeleteHasUserTabProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: customerId } = useParams();
  const [isReassignModalOpen, setIsReassignModalOpen] = useState<boolean>(false);
  const [selectedReassignUsers, setSelectedReassignUsers] = useState<string[] | null>(null);

  const userListLoading = useSelector(userListSelectors.selectIsLoading);
  const deviceListLoading = useSelector(machineListSelectors.selectIsLoading);

  const isLoading = !!useSelector(customerModalSelector.selectIsDeleteCustomerModalLoading);
  const modalLoading = useMemo(() => !!(userListLoading || deviceListLoading), [userListLoading, deviceListLoading]);

  const userList = useSelector(userListSelectors.selectData);

  const users = useMemo(() => {
    if (userListLoading) return [];
    return userList.map(user => ({
      ...user,
      key: user.internalUserId,
    }));
  }, [userList, userListLoading]);

  const deleteUsers = useSelector(deleteCustomerSelector.selectDeleteUser);
  const reassignUsers = useSelector(deleteCustomerSelector.selectReassignUser);

  const userDeleted = useMemo(
    () => Object.keys(deleteUsers).filter(key => deleteUsers[key].completed).length,
    [deleteUsers]
  );

  const userReassigned = useMemo(
    () => Object.keys(reassignUsers).filter(key => reassignUsers[key].completed).length,
    [reassignUsers]
  );

  const userDeleteError = useMemo(
    () => Object.keys(deleteUsers).filter(key => deleteUsers[key].error).length,
    [deleteUsers]
  );

  const userReassignError = useMemo(
    () => Object.keys(reassignUsers).filter(key => reassignUsers[key].error).length,
    [reassignUsers]
  );

  const userDeleteLoading = useMemo(
    () => Object.keys(deleteUsers).filter(key => deleteUsers[key].loading).length,
    [deleteUsers]
  );

  const userReassignLoading = useMemo(
    () => Object.keys(reassignUsers).filter(key => reassignUsers[key].loading).length,
    [reassignUsers]
  );

  const isHandleAllUsers = useMemo(
    () =>
      users.length ===
      userDeleted + userReassigned + userDeleteError + userReassignError + userDeleteLoading + userReassignLoading,
    [
      userDeleteError,
      userDeleteLoading,
      userDeleted,
      userReassignError,
      userReassignLoading,
      userReassigned,
      users.length,
    ]
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]): void => {
    setSelectedRowKeys(newSelectedRowKeys.filter(key => !deleteUsers[key as string] && !reassignUsers[key as string]));
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedRowKeys([]);
      dispatch(deleteCustomerActions.resetState());
    }
  }, [dispatch, isOpen]);

  const onToggleSingleRow = useCallback(
    (internalUserId: string): void => {
      if (selectedRowKeys.includes(internalUserId)) {
        setSelectedRowKeys(selectedRowKeys.filter(key => key !== internalUserId));
      } else {
        if (deleteUsers[internalUserId] || reassignUsers[internalUserId]) return;
        setSelectedRowKeys([...selectedRowKeys, internalUserId]);
      }
    },
    [deleteUsers, reassignUsers, selectedRowKeys]
  );

  const isCheckedAll = useMemo(() => {
    if (users.length === 0 || selectedRowKeys.length === 0) {
      return false;
    }
    return users
      .filter(({ internalUserId }) => !deleteUsers[internalUserId] && !reassignUsers[internalUserId])
      .every(user => selectedRowKeys.includes(user?.internalUserId));
  }, [deleteUsers, reassignUsers, selectedRowKeys, users]);

  const toggleAllRows = (): void => {
    if (isCheckedAll) {
      setSelectedRowKeys([]);
    } else {
      const internalUserIds = users
        .map(user => user?.internalUserId)
        .filter(id => !deleteUsers[id] && !reassignUsers[id]);
      setSelectedRowKeys([...selectedRowKeys, ...internalUserIds]);
    }
  };

  const rowSelection: TableRowSelection<User> = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: 'checkbox',
    columnWidth: 80,
    columnTitle: <Checkbox checked={isCheckedAll} onChange={toggleAllRows} disabled={isHandleAllUsers} />,
    renderCell: (checked, record) => (
      <>
        {!deleteUsers[record.internalUserId]?.loading &&
          !deleteUsers[record.internalUserId]?.completed &&
          !deleteUsers[record.internalUserId]?.error &&
          !reassignUsers[record.internalUserId]?.loading &&
          !reassignUsers[record.internalUserId]?.completed &&
          !reassignUsers[record.internalUserId]?.error && (
            <Checkbox checked={checked} onChange={(): void => onToggleSingleRow(record.internalUserId)} />
          )}
        {deleteUsers[record.internalUserId] && (
          <div className="customer-delete-has-user__check">
            {deleteUsers[record.internalUserId]?.loading && (
              <Spin size="small" className="customer-delete-has-user__check-spin" />
            )}
            {deleteUsers[record.internalUserId]?.completed && (
              <SvgIcon name="success" className="customer-delete-has-user__success-icon" />
            )}
            {deleteUsers[record.internalUserId]?.error && (
              <SvgIcon name="error" className="customer-delete-has-user__success-icon" />
            )}
          </div>
        )}
        {reassignUsers[record.internalUserId] && (
          <div className="customer-delete-has-user__check">
            {reassignUsers[record.internalUserId]?.loading && (
              <Spin size="small" className="customer-delete-has-user__check-spin" />
            )}
            {reassignUsers[record.internalUserId]?.completed && (
              <SvgIcon name="success" className="customer-delete-has-user__success-icon" />
            )}
            {reassignUsers[record.internalUserId]?.error && (
              <SvgIcon name="error" className="customer-delete-has-user__success-icon" />
            )}
          </div>
        )}
      </>
    ),
  };

  const sharedOnCell = (record: User): object => {
    if (deleteUsers[record.internalUserId] || reassignUsers[record.internalUserId]) {
      return {
        colSpan: 4,
      };
    }

    return {};
  };

  const hideOnCell = (record: User): object => {
    const deleteUsersLoading = deleteUsers[record.internalUserId]?.loading;
    const deleteUsersCompleted = deleteUsers[record.internalUserId]?.completed;
    const deleteUsersError = deleteUsers[record.internalUserId]?.error;
    const reassignUsersLoading = reassignUsers[record.internalUserId]?.loading;
    const reassignUsersCompleted = reassignUsers[record.internalUserId]?.completed;
    const reassignUsersError = reassignUsers[record.internalUserId]?.error;
    if (
      deleteUsersLoading ||
      deleteUsersCompleted ||
      deleteUsersError ||
      reassignUsersLoading ||
      reassignUsersCompleted ||
      reassignUsersError
    ) {
      return {
        colSpan: 0,
      };
    }

    return {};
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(
        UserListActions.getUserListRequest({
          filter: {
            customerId,
          },
          paginationOptions: {
            limit: 1000,
            paginationToken: '',
          },
        })
      );
    }
  }, [dispatch, customerId, isOpen]);

  const handleClickReassign = (userId: string): void => {
    setSelectedReassignUsers([userId]);
    setIsReassignModalOpen(true);
  };

  const handleClickDelete = (userId: string): void => {
    dispatch(deleteCustomerActions.deleteUserRequest({ input: { id: userId } }));
    setSelectedRowKeys(selectedRowKeys.filter(key => key !== userId));
  };

  const tableColumns = getUserListColumns({
    t,
    handleClickDelete,
    handleClickReassign,
    sharedOnCell,
    hideOnCell,
    deleteUsers,
    reassignUsers,
  });

  const handleClickDeleteUserBtn = (): void => {
    const deleteIds: { input: { id: string } }[] = [];
    selectedRowKeys.forEach(userId => {
      deleteIds.push({ input: { id: userId as string } });
    });
    setSelectedRowKeys([]);
    dispatch(deleteCustomerActions.deleteMultiUsersRequest(deleteIds));
  };

  const handleClickReassignBtn = (): void => {
    setIsReassignModalOpen(true);
    const reassignIds: string[] = [];
    selectedRowKeys.forEach(userId => {
      reassignIds.push(userId as string);
    });
    setSelectedReassignUsers(reassignIds);
  };

  const closeReassignModal = (): void => {
    setIsReassignModalOpen(false);
  };

  const onReassignUser = (selectedCustomerId: string): void => {
    const payload =
      selectedReassignUsers?.map(userId => ({
        input: {
          userId,
          customerId: selectedCustomerId,
        },
      })) || [];

    if (payload.length === selectedRowKeys.length) {
      setSelectedRowKeys([]);
    } else {
      setSelectedRowKeys(selectedRowKeys.filter(key => !selectedReassignUsers?.includes(key as string)));
    }
    dispatch(deleteCustomerActions.reassignMultiUsersRequest(payload));
    closeReassignModal();
  };

  return (
    <StyledCustomerDeleteHasUserTab>
      <Row justify="center" align="middle">
        <Col span={24}>
          <CustomerDeleteDescription
            message1={t('customerDetails.modals.delete.descriptionHasUser.message1')}
            note={t('customerDetails.modals.delete.descriptionHasUser.note')}
            message2={t('customerDetails.modals.delete.descriptionHasUser.message2')}
          />
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col span={24} className="customer-delete-modal__user-options">
          <SecondaryButton
            size="m"
            key="reassign-button"
            className="secondary-button"
            onClick={handleClickReassignBtn}
            disabled={selectedRowKeys.length === 0}
          >
            {t('customerDetails.modals.delete.button.reassignBtn')}
          </SecondaryButton>
          <PopConfirm
            key="save-button"
            title={t('common.popConfirm.titleConfirm')}
            cancelText={t('common.popConfirm.cancelText')}
            okText={t('common.popConfirm.okTextConfirm')}
            onConfirm={(): void => {
              handleClickDeleteUserBtn();
            }}
          >
            <SecondaryButton
              size="m"
              key="delete-user-button"
              className="secondary-button"
              disabled={selectedRowKeys.length === 0}
            >
              {t('customerDetails.modals.delete.button.deleteUserBtn')}
            </SecondaryButton>
          </PopConfirm>
        </Col>
      </Row>
      <Row justify="center" align="middle">
        <Col span={24}>
          <Table
            scroll={{ x: 200, y: 250 }}
            dataSource={users}
            loading={isLoading || modalLoading}
            className="user-list__table"
            columns={tableColumns}
            rowKey={(record: User): React.Key => record.internalUserId}
            rowSelection={rowSelection}
          />
        </Col>
      </Row>

      <CustomerReassignUserModal
        isVisible={isReassignModalOpen}
        closeModal={closeReassignModal}
        onConfirm={onReassignUser}
      />
      <ChangeCustomerModal />
    </StyledCustomerDeleteHasUserTab>
  );
};
