import { all, AllEffect } from 'redux-saga/effects';
import { machineDirectorySaga } from '../../modules/machine-directory/state/machineDirectorySaga';
import { authenticationSaga } from '../authentication/state/authenticationSaga';
import { machineListSaga } from '../../modules/machine-directory/machine-list/state/machineListSaga';
import { customerListSaga } from '../../modules/customer-management/customer-list/state/customerListSaga';
import { customerModalsSaga } from '../../modules/customer-management/modals/state/customerModalsSaga';
import { userListSaga } from 'app/modules/user-management/user-list/state/userListSaga';
import { userCreateSaga } from 'app/modules/user-management/user-create/state/userCreateSaga';
import { customerSearchSaga } from 'app/modules/user-management/customer-search/state/customerSearchSaga';
import { userInviteSaga } from 'app/modules/user-management/user-invite/state/userInviteSaga';
import { userDetailsSaga } from 'app/modules/user-management/user-details/state/userDetailsSaga';
import { machineClaimPairSaga } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairSaga';
import { customerDetailsSaga } from 'app/modules/customer-management/customer-details/state/customerDetailsSaga';
import { deleteCustomerSaga } from 'app/modules/customer-management/modals/components/CustomerDeleteModal/state/deleteCustomerSaga';

export function* sagas(): Generator<AllEffect<any>, void> {
  yield all([
    // Cross-cutting concerns
    authenticationSaga(),
    machineListSaga(),
    // Modules
    machineDirectorySaga(),
    userListSaga(),
    userCreateSaga(),
    userInviteSaga(),
    customerSearchSaga(),
    userDetailsSaga(),
    machineClaimPairSaga(),
    customerListSaga(),
    customerModalsSaga(),
    customerDetailsSaga(),
    deleteCustomerSaga(),
  ]);
}
