import { produce } from 'immer';
import { AnyAction } from 'redux';
import { Optional } from '../../../../../lib/types/Optional';
import { MachineListActions } from './machineListActions';
import { MachineOwnership, SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { DEFAULT_PAGE_SIZE_VALUE, DEFAULT_PAGE_VALUE } from 'config/constants';
import { PaginationTokens } from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';

export interface MachineListState {
  data: Optional<MachineOwnership[]>;
  totalCount: Optional<number>;
  isLoading: Optional<boolean>;
  paginationTokens: PaginationTokens;
  page: number;
  pageSize: number;
  search: Optional<string>;
  filters: {
    active: {
      period: {
        startDate: Optional<string>;
        endDate: Optional<string>;
      };
      alg: Optional<string>;
      customerId: Optional<string>;
    };
  };
  sortOptions: {
    field: Optional<string>;
    order: Optional<SortOrders>;
  };
}

export const initialState: MachineListState = {
  data: [],
  totalCount: null,
  isLoading: null,
  paginationTokens: {},
  page: DEFAULT_PAGE_VALUE,
  pageSize: DEFAULT_PAGE_SIZE_VALUE,
  search: undefined,
  filters: {
    active: {
      period: {
        startDate: undefined,
        endDate: undefined,
      },
      alg: null,
      customerId: null,
    },
  },
  sortOptions: {
    field: null,
    order: null,
  },
};

export const machineListReducer = (state = initialState, action: AnyAction): MachineListState =>
  produce(state, draft => {
    switch (action.type) {
      case MachineListActions.GET_MACHINE_LIST_REQUEST: {
        draft.isLoading = true;
        break;
      }

      case MachineListActions.POLL_MACHINE_LIST_REQUEST: {
        draft.isLoading = true;
        break;
      }

      case MachineListActions.GET_MACHINE_LIST_SUCCESS: {
        const {
          listClaimedMachines: {
            data,
            metadata: { totalCount, paginationToken },
          },
        } = action.payload;
        draft.isLoading = false;
        draft.data = data;
        draft.totalCount = totalCount;

        if (paginationToken) {
          draft.paginationTokens[draft.page + 1] = paginationToken;
        }

        break;
      }

      case MachineListActions.GET_MACHINE_LIST_ERROR: {
        draft.isLoading = false;
        break;
      }

      case MachineListActions.CHANGE_TABLE_PAGE: {
        const { page } = action.payload;

        draft.page = page;
        break;
      }

      case MachineListActions.CHANGE_TABLE_PAGE_SIZE: {
        const { pageSize } = action.payload;
        draft.paginationTokens = {};
        draft.page = DEFAULT_PAGE_VALUE;
        draft.pageSize = pageSize;
        break;
      }

      case MachineListActions.RESET_STATE: {
        draft.page = DEFAULT_PAGE_VALUE;
        draft.pageSize = DEFAULT_PAGE_SIZE_VALUE;
        draft.paginationTokens = {};
        draft.data = null;
        draft.totalCount = null;
        draft.filters.active.alg = null;
        draft.search = undefined;
        break;
      }

      case MachineListActions.SET_ACTIVE_PERIOD_FILTER: {
        const { period } = action.payload;

        draft.filters.active.period = period;
        break;
      }

      case MachineListActions.SET_ACTIVE_SORT_FIELD: {
        const { field } = action.payload;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        draft.sortOptions.field = field;
        break;
      }

      case MachineListActions.SET_ACTIVE_SORT_ORDER: {
        const { order } = action.payload;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        draft.sortOptions.order = order;
        break;
      }

      case MachineListActions.SET_ACTIVE_SEARCH_TEXT: {
        const { searchText } = action.payload;
        draft.search = searchText;
        break;
      }

      case MachineListActions.SET_ACTIVE_ALG_FILTER: {
        const { alg } = action.payload;
        draft.filters.active.alg = alg;
        break;
      }

      case MachineListActions.SET_ACTIVE_CUSTOMER_FILTER: {
        const { customerId } = action.payload;

        draft.filters.active.customerId = customerId;
        break;
      }

      default:
        break;
    }
  });
