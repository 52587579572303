import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledCustomerClaimMachineModal = styled(Modal)`
  min-height: inherit;
  .machine-reclaiming__description {
    font-size: ${(props): string => props.theme.fontSizes.pageDescription};
    font-family: ${(props): string => props.theme.fonts.body};
    color: ${(props): string => props.theme.colors.text};
    overflow: unset;
    text-transform: none;
    margin: 18px auto 0;
  }

  .machine-find__radio-group {
    margin-bottom: 28px;
    margin-left: 12px;
  }

  .machine-find__input {
    width: 234px;
  }

  .ant-select-disabled {
    color: ${(props): string => props.theme.colors.white};
    pointer-events: none;
  }

  .ant-input-affix-wrapper-disabled {
    border: 2px solid ${(props): string => props.theme.colors.neutralWhiteColor};
  }

  .machine-find__form-item {
    .ant-form-item {
      margin: 0;
      position: absolute;
    }

    .ant-form-item-label {
      padding: 0;
      > label {
        font-family: ${(props): string => props.theme.fonts.captionBold};
        font-size: ${(props): string => props.theme.fontSizes.caption};
        line-height: ${(props): string => props.theme.lineHeights.caption};
        font-weight: ${(props): number => props.theme.fontWeights.captionBold};
      }
    }

    .ant-form-item-control {
      .ant-input-disabled {
        color: ${(props): string => props.theme.colors.neutralWhiteColor};
        background-color: ${(props): string => props.theme.colors.white};
        pointer-events: none;
        border: transparent;
      }
    }

    .ant-form-item-explain-error {
      position: absolute;
    }
  }

  .ant-form-item-required {
    text-transform: uppercase;
  }
  .machine-find__button-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
  }

  .machine-find__delete-selection-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .machine-find__info-icon {
    display: flex;
    align-items: center;
    margin: 20px 0 0 7px;
    svg {
      width: 18px;
      height: 18px;
    }

    i {
      height: 18px;
      width: 18px;
    }
  }

  .machine-find__table-result {
    background-color: ${(props): string => props.theme.colors.brightGrey};
    padding: 16px;
    margin-bottom: 32px;
    width: inherit;

    .ant-row-space-between {
      margin-bottom: 18px;
    }
  }

  .machine-find__title {
    text-transform: uppercase;
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
    line-height: ${(props): string => props.theme.lineHeights.caption};
  }

  .machine-find__info {
    text-transform: uppercase;
    font-family: ${(props): string => props.theme.fonts.captionMedium};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    margin: 0;
  }

  .auto-complete__invalid-message {
    position: absolute;
    color: ${(props): string => props.theme.colors.errorExplainColor};
  }

  .machine-find__col {
    display: flex;
  }
`;
