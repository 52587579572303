import styled from 'styled-components';

export const StyledMachineSummary = styled.div`
  .summary__sub-title {
    font-family: ${(props): string => props.theme.fonts.formBody};
    font-size: 14px;
    font-weight: 475;
    line-height: 20px;
    margin-top: 6px;
    margin-bottom: 16px;
  }

  .summary__info {
    background-color: ${(props): string => props.theme.colors.brightGrey};
    width: fit-content;
    height: fit-content;
    padding: 16px;
    border: 1px solid ${(props): string => props.theme.colors.gray};

    .summary__title {
      font-family: ${(props): string => props.theme.fonts.heading};
      font-size: ${(props): string => props.theme.fontSizes.formTitle};
      font-weight: 700;
      line-height: 14px;
      text-transform: uppercase;
      margin-bottom: 4px;
    }

    .summary__content {
      font-family: ${(props): string => props.theme.fonts.captionMedium};
      font-size: ${(props): string => props.theme.fontSizes.caption};
      font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
      line-height: ${(props): string => props.theme.lineHeights.p1};
      text-transform: uppercase;
    }

    .ant-divider {
      margin: 0 0 16px 0;
    }
  }

  .summary__continue-section {
    margin-top: 32px;
  }

  .machine-summary__info-icon {
    display: flex;
    align-items: center;
    i {
      height: 18px;
      width: 18px;
    }
  }
`;
