import { Input } from 'antd';
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  width: 243px;
  height: 44px;
  font-size: 13px;
  line-height: 13px;
  background-color: ${(props): string => props.theme.colors.white};
  border-radius: 0;
  border: 2px solid ${(props): string => props.theme.colors.darkGrey};
  padding: 14px 16px 13px;

  :hover {
    background-color: ${(props): string => props.theme.colors.inputBg};
    border: 2px solid ${(props): string => props.theme.colors.border};
    border-right-width: 2px !important;

    input {
      background-color: ${(props): string => props.theme.colors.inputBg};
    }
  }

  :focus {
    border-color: ${(props): string => props.theme.colors.focusBorder};
    box-shadow: none;
    border-right-width: 2px !important;
  }

  :disabled,
  :disabled:hover {
    border-color: ${(props): string => props.theme.colors.white};
    background-color: transparent;
    color: rgba(${(props): string => props.theme.colors.textRgb}, 0.3);
  }
`;

export const StyledInputPassword = styled(Input.Password)`
  width: 243px;
  height: 44px;
  font-size: 13px;
  line-height: 13px;
  background-color: ${(props): string => props.theme.colors.white};
  border-radius: 0;
  border: 2px solid ${(props): string => props.theme.colors.darkGrey};
  padding: 14px 16px 13px;

  :hover {
    background-color: ${(props): string => props.theme.colors.inputBg};
    border: 2px solid ${(props): string => props.theme.colors.border};
    border-right-width: 2px !important;

    input {
      background-color: ${(props): string => props.theme.colors.inputBg};
    }
  }

  :focus {
    border-color: ${(props): string => props.theme.colors.focusBorder};
    box-shadow: none;
    border-right-width: 2px !important;
  }

  :disabled,
  :disabled:hover {
    border-color: ${(props): string => props.theme.colors.white};
    background-color: transparent;
    color: rgba(${(props): string => props.theme.colors.textRgb}, 0.3);
  }
`;
