import { Action } from 'redux';
import { UserCreateResponse } from '../../interfaces/User.types';
import { UserServiceCreateOptions } from '../../UserService';
import { Optional } from 'lib/types/Optional';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';

export type IUserCreateRequestOptions = UserServiceCreateOptions;
export type IUserCreateRequestAction = Action<typeof UserCreateActions.CREATE_USER_REQUEST> & {
  payload: IUserCreateRequestOptions;
};

export type IUserCreateSuccessOptions = Optional<UserCreateResponse>;
export type IUserCreateSuccessAction = Action<typeof UserCreateActions.CREATE_USER_SUCCESS> & {
  payload: Optional<UserCreateResponse>;
};

export type IUserCreateErrorAction = IErrorAction<typeof UserCreateActions.CREATE_USER_ERROR>;

export class UserCreateActions {
  public static CREATE_USER_REQUEST = 'CREATE_USER::CREATE_USER_REQUEST';
  public static CREATE_USER_SUCCESS = 'CREATE_USER::CREATE_USER_SUCCESS';
  public static CREATE_USER_ERROR = 'CREATE_USER::CREATE_USER_ERROR';

  public static userCreateRequest = ({
    title,
    firstName,
    lastName,
    phoneNumber,
    language,
    email,
    password,
  }: IUserCreateRequestOptions): IUserCreateRequestAction => ({
    type: UserCreateActions.CREATE_USER_REQUEST,
    payload: {
      title,
      firstName,
      lastName,
      phoneNumber,
      language,
      email,
      password,
    },
  });

  public static userCreateSuccess = (data: IUserCreateSuccessOptions): IUserCreateSuccessAction => ({
    type: UserCreateActions.CREATE_USER_SUCCESS,
    payload: data,
  });

  public static userCreateError = ({ error }: IActionCreatorErrorOptions): IUserCreateErrorAction => ({
    type: UserCreateActions.CREATE_USER_ERROR,
    error,
  });
}
