import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { call, getContext, put, takeLatest } from 'typed-redux-saga';
import { UserInviteResponse } from '../../interfaces/User.types';
import { splitLangPrefix } from '../../utils/splitLangPrefix';
import {
  IUserInviteErrorAction,
  IUserInviteRequestAction,
  IUserInviteSuccessAction,
  UserInviteActions,
} from './userInviteActions';
import { Optional } from 'lib/types/Optional';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { RegisterUserTitleEnum } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { RoutePaths } from 'config/route-paths';

export function* userInvite(
  action: IUserInviteRequestAction
): Generator<
  | IDependencies
  | GetContextEffect
  | CallEffect<Optional<UserInviteResponse>>
  | CallEffect<void>
  | PutEffect<IUserInviteSuccessAction>
  | PutEffect<IUserInviteErrorAction>
  | void
> {
  const { userService, toastService, t, history } = yield* getContext<IDependencies>('dependencies');

  try {
    const inviteInput = {
      ...action.payload,
      title: action.payload.title || RegisterUserTitleEnum.None,
      firstName: action.payload.firstName.trim(),
      lastName: action.payload.lastName.trim(),
      phoneNumber: action.payload.phoneNumber?.trim() || undefined,
      customerId: action.payload.customerId.trim(),
      language: splitLangPrefix(action.payload.language as string),
    };

    const response = yield* call(userService.invite, inviteInput);

    yield* put(UserInviteActions.userInviteSuccess(response));

    if (response?.inviteUser?.data) {
      history.push(RoutePaths.INVITE_USER_SUCCESS);
    }
  } catch (error) {
    yield* put(UserInviteActions.userInviteError({ error }));

    if ((error as Error).message === '0199: User exists with input email') {
      yield* call(toastService.error, {
        message: t('userInvite.toasts.error.emailAlreadyExistsMessage'),
        description: t('userInvite.toasts.error.emailALreadyExistsDescription'),
      });
    } else {
      yield* call(toastService.error, {
        message: t('userInvite.toasts.error.message'),
        description: (error as Error).message,
      });
    }
  }
}

export function* userInviteSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(UserInviteActions.INVITE_USER_REQUEST, userInvite);
}
