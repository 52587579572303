import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { useParams } from 'react-router-dom';
import { customerModalsActions } from '../../state/customerModalsSlice';
import { customerModalSelector } from '../../state/customerModalsSelectors';
import { StyledCustomerEditModal } from './CustomerEditModal.styles';
import { CustomerEditForm } from './CustomerEditForm/CustomerEditForm';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { UpdateCustomerInput } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export const CustomerEditModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formEditInstance] = Form.useForm();
  const { id } = useParams();

  const isOpen = !!useSelector(customerModalSelector.selectIsEditCustomerModalOpen);
  const isLoading = !!useSelector(customerModalSelector.selectIsEditCustomerModalLoading);

  const handleCancel = useCallback(() => {
    dispatch(customerModalsActions.closeEditCustomerModal());
    formEditInstance.resetFields();
  }, [dispatch, formEditInstance]);

  const handleEdit = useCallback(() => {
    formEditInstance.submit();
  }, [formEditInstance]);

  const handleFinish = useCallback(
    (values: UpdateCustomerInput) => {
      if (!id) return;
      dispatch(
        customerModalsActions.editCustomerModalRequest({
          ...values,
          customerId: id,
        })
      );
    },
    [dispatch, id]
  );

  return (
    <StyledCustomerEditModal
      className="customer-edit-modal"
      title={t('customerDetails.modals.edit.title')}
      width={600}
      centered
      closable={false}
      open={isOpen}
      footer={[
        <SecondaryButton size="m" key="cancel-button" className="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </SecondaryButton>,

        <PrimaryButton
          size="m"
          key="submit"
          type="primary"
          className="submit-button"
          loading={isLoading}
          onClick={handleEdit}
        >
          {t('customerDetails.body.button.editCustomer')}
        </PrimaryButton>,
      ]}
    >
      <CustomerEditForm formInstance={formEditInstance} onFinish={handleFinish} />
    </StyledCustomerEditModal>
  );
};
