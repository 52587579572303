import { createBrowserHistory } from 'history';
import { container, instanceCachingFactory } from 'tsyringe';
import { DefaultTheme } from 'styled-components';
import { Authentication } from '../app/cross-cutting-concerns/authentication/Authentication';
import { AuthenticationService } from '../app/cross-cutting-concerns/authentication/AuthenticationService';
import { InvalidUserPoolProviderName } from '../app/cross-cutting-concerns/authentication/errors/InvalidUserPoolProviderName';
import { GraphQlClient } from '../app/cross-cutting-concerns/communication/clients/GraphQlClient';
import { InvalidApiUrlError } from '../app/cross-cutting-concerns/communication/errors/InvalidApiUrlError';
import { Styling } from '../app/cross-cutting-concerns/styling/Styling';
import { InvalidAwsRegionError } from '../lib/errors/InvalidAwsRegionError';
import { isValidEnvVarValue } from '../lib/utils/isValidEnvVarValue';
import { userPoolConfig } from './user-pool-config';

export const registerEnvVariables = (): void => {
  if (!isValidEnvVarValue(process.env.REACT_APP_AM_API_GRAPHQL_HTTP_ENDPOINT)) {
    throw new InvalidApiUrlError('HTTP API url not specified or not a string.');
  }

  if (!isValidEnvVarValue(process.env.REACT_APP_AWS_REGION)) {
    throw new InvalidAwsRegionError();
  }

  if (!isValidEnvVarValue(process.env.REACT_APP_AWS_USER_POOL_PROVIDER_NAME)) {
    throw new InvalidUserPoolProviderName();
  }

  container.register('AmApiGraphqlHttpEndpoint', {
    useValue: process.env.REACT_APP_AM_API_GRAPHQL_HTTP_ENDPOINT,
  });

  container.register('AwsRegion', {
    useValue: process.env.REACT_APP_AWS_REGION,
  });

  container.register('UserPoolProviderName', {
    useValue: process.env.REACT_APP_AWS_USER_POOL_PROVIDER_NAME,
  });
};

export const registerDependencies = (): void => {
  registerEnvVariables();

  container.register('GraphQlClient', {
    useClass: GraphQlClient,
  });

  container.register('Theme', {
    useFactory: instanceCachingFactory<DefaultTheme>(() => Styling.getTheme()),
  });

  container.register('AuthenticationService', {
    useClass: AuthenticationService,
  });

  container.register('History', {
    useValue: createBrowserHistory(),
  });

  container.register('Authentication', {
    useClass: Authentication,
  });

  container.register('UserPoolConfig', {
    useValue: userPoolConfig,
  });
};
