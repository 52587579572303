import { produce } from 'immer';
import { AnyAction } from 'redux';
import { MachineClaimPairActions } from './machineClaimPairActions';
import {
  MachineOwnership,
  MachinePairingInfo,
  MachinePairingSearchInfo,
  ResponseValidateMachineData,
  SearchMaterialNumberResponseData,
} from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { Optional } from 'lib/types/Optional';

export interface IMachineClaimPairState {
  stepData: {
    step: number;
    isPairingStep: boolean;
  };
  materialNumber: {
    isLoading: boolean;
    data: Optional<SearchMaterialNumberResponseData[]>;
  };
  machineFind: {
    isLoading: boolean;
    data: Optional<ResponseValidateMachineData>;
  };
  machineClaim: {
    isLoading: boolean;
    data: Optional<MachineOwnership>;
  };
  machinePairingSearch: {
    isLoading: boolean;
    data: Optional<MachinePairingSearchInfo>;
  };
  machinePairing: {
    isLoading: boolean;
    isLoadingWithSearch: boolean;
    data: Optional<MachinePairingInfo>;
  };
}

export const initialState: IMachineClaimPairState = {
  stepData: {
    step: 0,
    isPairingStep: false,
  },
  materialNumber: {
    data: null,
    isLoading: false,
  },
  machineFind: {
    isLoading: false,
    data: null,
  },
  machineClaim: {
    data: null,
    isLoading: false,
  },
  machinePairingSearch: {
    data: null,
    isLoading: false,
  },
  machinePairing: {
    data: null,
    isLoading: false,
    isLoadingWithSearch: false,
  },
};

export const machineClaimPairReducer = (state = initialState, action: AnyAction): IMachineClaimPairState =>
  produce(state, draft => {
    switch (action.type) {
      case MachineClaimPairActions.SET_STEP_FIND_MACHINE_ACTIVE: {
        draft.stepData.step = 0;
        break;
      }

      case MachineClaimPairActions.SET_STEP_CLAIM_MACHINE_ACTIVE: {
        draft.stepData.step = 1;
        draft.stepData.isPairingStep = false;
        break;
      }

      case MachineClaimPairActions.SET_STEP_PAIR_MACHINE_ACTIVE: {
        draft.stepData.step = 1;
        draft.stepData.isPairingStep = true;
        break;
      }

      case MachineClaimPairActions.SET_STEP_SUMMARY_MACHINE_ACTIVE: {
        draft.stepData.step = 2;
        break;
      }

      case MachineClaimPairActions.SEARCH_MATERIAL_NUMBER_REQUEST: {
        draft.materialNumber.isLoading = true;
        break;
      }

      case MachineClaimPairActions.SEARCH_MATERIAL_NUMBER_SUCCESS: {
        const { searchMaterialNumber } = action.payload;

        draft.materialNumber.isLoading = false;
        draft.materialNumber.data = searchMaterialNumber?.data || [];
        break;
      }

      case MachineClaimPairActions.SEARCH_MATERIAL_NUMBER_ERROR: {
        draft.materialNumber.isLoading = false;
        break;
      }

      case MachineClaimPairActions.SEARCH_MATERIAL_NUMBER_RESET_STATE: {
        draft.materialNumber.data = initialState.materialNumber.data;
        break;
      }

      case MachineClaimPairActions.FIND_MACHINE_REQUEST: {
        draft.machineFind.isLoading = true;
        break;
      }

      case MachineClaimPairActions.FIND_MACHINE_SUCCESS: {
        const { validateMachine } = action.payload;

        draft.machineFind.isLoading = false;
        draft.machineFind.data = validateMachine?.data;
        break;
      }

      case MachineClaimPairActions.FIND_MACHINE_ERROR: {
        draft.machineFind.isLoading = false;
        break;
      }

      case MachineClaimPairActions.FIND_MACHINE_RESET_STATE: {
        draft.machineFind.isLoading = false;
        draft.machineFind.data = null;
        break;
      }

      case MachineClaimPairActions.CLAIM_MACHINE_REQUEST: {
        draft.machineClaim.isLoading = true;
        break;
      }

      case MachineClaimPairActions.CLAIM_MACHINE_SUCCESS: {
        const { adminMachineClaim } = action.payload;

        draft.machineClaim.data = adminMachineClaim?.data;
        draft.machineClaim.isLoading = false;
        break;
      }

      case MachineClaimPairActions.CLAIM_MACHINE_ERROR: {
        draft.machineClaim.isLoading = false;
        break;
      }

      case MachineClaimPairActions.CLAIM_MACHINE_RESET_STATE: {
        draft.machineClaim.data = initialState.machineClaim.data;
        break;
      }

      case MachineClaimPairActions.PAIR_MACHINE_SEARCH_REQUEST: {
        draft.machinePairingSearch.isLoading = true;
        draft.machinePairing.isLoadingWithSearch = true;
        break;
      }

      case MachineClaimPairActions.PAIR_MACHINE_SEARCH_SUCCESS: {
        const { machinePairingSearch } = action.payload;
        draft.machinePairingSearch.data = machinePairingSearch?.data;
        draft.machinePairingSearch.isLoading = false;
        break;
      }

      case MachineClaimPairActions.PAIR_MACHINE_SEARCH_ERROR: {
        draft.machinePairingSearch.isLoading = false;
        break;
      }

      case MachineClaimPairActions.PAIR_MACHINE_SEARCH_CLEAR_DATA: {
        draft.machinePairingSearch.data = initialState.machinePairingSearch.data;
        break;
      }

      case MachineClaimPairActions.PAIR_MACHINE_REQUEST: {
        draft.machinePairing.isLoading = true;
        break;
      }

      case MachineClaimPairActions.PAIR_MACHINE_SUCCESS: {
        const { machinePairing } = action.payload;
        draft.machinePairing.data = machinePairing?.data;
        draft.machinePairing.isLoading = false;
        draft.machinePairing.isLoadingWithSearch = false;
        break;
      }

      case MachineClaimPairActions.PAIR_MACHINE_ERROR: {
        draft.machinePairing.isLoading = false;
        break;
      }

      case MachineClaimPairActions.PAIR_MACHINE_RESET_STATE: {
        draft.machinePairing.data = initialState.machinePairing.data;
        break;
      }

      default:
        break;
    }
  });
