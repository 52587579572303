import styled from 'styled-components';
import { Select } from 'antd';

export const StyledSelect = styled(Select)`
  &.ant-select.ant-select-single,
  &.ant-select.ant-select-multiple {
    .ant-select-selector {
      height: 44px;
      padding: 0 16px;
      border-radius: 0;
      border: 2px solid ${(props): string => props.theme.colors.border};

      :hover {
        background-color: #f2f2f2; // TODO: propose an existing kind of grey color
      }

      :focus {
        border-color: ${(props): string => props.theme.colors.focusBorder};
      }

      :active {
        color: rgba(${(props): string => props.theme.colors.textRgb}, 0.3);
      }

      .ant-select-selection-placeholder {
        line-height: 40px;
      }
    }

    &.ant-select.ant-select-single {
      .ant-select-selection-item {
        line-height: 44px;
      }

      .ant-select-selection-search {
        color: ${(props): string => props.theme.colors.text};
        margin-left: 4px;
        margin-top: 6px;
      }
    }

    &.ant-select-open {
      .ant-select-selector {
        color: rgba(${(props): string => props.theme.colors.textRgb}, 0.3);
        background-color: ${(props): string => props.theme.colors.white};
      }
    }

    &.ant-select-disabled,
    &.ant-select-disabled:hover {
      .ant-select-selector {
        background-color: ${(props): string => props.theme.colors.white};
        color: rgba(${(props): string => props.theme.colors.textRgb}, 0.3);
        border-color: ${(props): string => props.theme.colors.semiGray};
      }

      .ant-select-arrow {
        color: rgba(${(props): string => props.theme.colors.textRgb}, 0.3);
      }
    }

    &.ant-select-focused {
      .ant-select-selector {
        border-color: ${(props): string => props.theme.colors.focusBorder};
      }
    }

    .ant-select-arrow {
      color: ${(props): string => props.theme.colors.text};
      font-size: 1rem;
      width: fit-content;
      margin-top: -7px;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
  &.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-right-width: 2px !important;
    box-shadow: none;
  }

  .ant-select-dropdown {
    top: 39.4375px !important;
    border-radius: 0;
    padding: 0;

    .ant-select-item-option {
      height: 44px;
      display: flex;
      align-items: center;
      border-left: 2px solid;
      border-right: 2px solid;
      border-color: ${(props): string => props.theme.colors.border};
      padding: 14px 16px 13px;

      &:last-child {
        border-bottom: 2px solid ${(props): string => props.theme.colors.border};
      }
    }

    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: ${(props): string => props.theme.colors.white};
      background-color: ${(props): string => props.theme.colors.text};
      border-color: ${(props): string => props.theme.colors.text};

      .ant-select-item-option-state {
        color: ${(props): string => props.theme.colors.white};
      }
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: ${(props): string => props.theme.colors.border};
  }
`;

export const StyledOption = styled(Select.Option)`
  // TBD
`;

export const StyledOptGroup = styled(Select.OptGroup)`
  // TBD
`;
