import { User, SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';
import { PaginationTokens } from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Optional } from 'lib/types/Optional';

export class userListSelectors {
  public static selectData = (state: IState): User[] => state.modules['user-management']['user-list'].data;

  public static selectTotalCount = (state: IState): Optional<number> =>
    state.modules['user-management']['user-list'].totalCount;

  public static selectIsLoading = (state: IState): Optional<boolean> =>
    state.modules['user-management']['user-list'].isLoading;

  public static selectPaginationTokens = (state: IState): PaginationTokens =>
    state.modules['user-management']['user-list'].paginationTokens;

  public static selectPage = (state: IState): number => state.modules['user-management']['user-list'].page;

  public static selectPageSize = (state: IState): number => state.modules['user-management']['user-list'].pageSize;

  public static selectFilters = (state: IState): string[] =>
    state.modules['user-management']['user-list'].filters.available.algs;

  public static selectAreFiltersLoading = (state: IState): boolean =>
    state.modules['user-management']['user-list'].filters.isLoading;

  public static selectActiveAlgFilter = (state: IState): Optional<string> =>
    state.modules['user-management']['user-list'].filters.active.alg;

  public static selectSortField = (state: IState): Optional<string> =>
    state.modules['user-management']['user-list'].sortOptions.field;

  public static selectSortOrder = (state: IState): Optional<SortOrders> =>
    state.modules['user-management']['user-list'].sortOptions.order;

  public static selectSearchText = (state: IState): Optional<string> =>
    state.modules['user-management']['user-list'].searchText;
}
