import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledLoginHeader } from './LoginHeader.styles';

export const LoginHeader = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledLoginHeader className="login-header">
      <h1 className="login-header__title">{t('login.header.title')}</h1>
      <p className="login-header__description">{t('login.header.description')}</p>
    </StyledLoginHeader>
  );
};
