import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { CustomerEntity } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { SortableColumnTitle } from 'lib/components/Table/SortableColumnTitle/SortableColumnTitle';

interface GetCustomerListColumnsOptions {
  t: TFunction;
}

export const getCustomerListColumns = ({ t }: GetCustomerListColumnsOptions): ColumnsType<CustomerEntity> => [
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<CustomerEntity>(t('customerList.table.customerName'), true, 'customerName', sortColumns),
    dataIndex: 'customerName',
    key: 'customerName',
    width: '20%',
    sorter: true,
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<CustomerEntity>(t('customerList.table.customerAlg'), true, 'alg', sortColumns),
    dataIndex: 'alg',
    key: 'alg',
    width: '20%',
    sorter: true,
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<CustomerEntity>(t('customerList.table.customerNumber'), true, 'sapNumber', sortColumns),
    dataIndex: 'sapNumber',
    key: 'sapNumber',
    width: '25%',
    sorter: true,
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<CustomerEntity>(t('customerList.table.pudsId'), true, 'pudsId', sortColumns),
    dataIndex: 'customerId',
    key: 'pudsId',
    width: '20%',
    sorter: true,
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<CustomerEntity>(t('customerList.table.createdAt'), true, 'createdAt', sortColumns),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '20%',
    sorter: true,
    defaultSortOrder: 'descend',
    render: (createdAt: string): JSX.Element => <p>{moment(new Date(createdAt)).format('DD.MM.YYYY')}</p>,
  },
];
