import styled from 'styled-components';
import { Radio } from 'antd';

export const StyledRadio = styled(Radio)`
  // Chang radio color
  .ant-radio-checked {
    .ant-radio-inner {
      background-color: ${(props): string => props.theme.colors.black};
      border-color: ${(props): string => props.theme.colors.text};
    }

    .ant-radio-inner:after {
      background-color: ${(props): string => props.theme.colors.primary};
    }

    &::after {
      border-color: ${(props): string => props.theme.colors.text};
    }
  }

  .ant-radio:hover {
    .ant-radio-inner {
      border-color: ${(props): string => props.theme.colors.text};
    }
  }
`;

export const StyledGroup = styled(Radio.Group)`
  // TBD
`;

export const StyledButton = styled(Radio.Button)`
  // TBD
`;
