import styled from 'styled-components';

export const StyledCustomerDeleteHasUserTab = styled.div`
  .ant-table-container table .ant-table-tbody tr > td {
    padding: 24px 4px;
  }

  .customer-delete-has-user__reassign {
    text-decoration-line: underline;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    display: flex;
    span {
      font-family: ${(props): string => props.theme.fonts.pinPopupBody};
      text-transform: capitalize;
    }
  }

  .customer-delete-has-user__delete {
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
    }
  }

  .customer-delete-modal__user-options {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    padding-bottom: 24px;
  }

  .customer-delete-has-user__success-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .user-list__table {
    margin-bottom: 24px;
    .ant-table-footer {
      display: none;
    }
  }
`;
