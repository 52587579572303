import styled from 'styled-components';

export const StyledUserInviteForm = styled.div`
  .invite-user__info-icon {
    display: flex;
    align-items: center;
    margin: 7px 0 0 7px;
    svg {
      width: 18px;
      height: 18px;
    }
  }

  .invite-user__customer-selection-table {
  }
`;
