import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Divider, Row, Tooltip } from 'antd';
import { StyledMachineSummary } from './MachineSummary.styles';
import { MachineIsPairableTooltip } from './MachineIsPairableTooltip/MachineIsPairableTooltip';
import { StyledMachineIsPairableGlobalStyles } from './MachineIsPairableTooltip/MachineIsPairable.global.styles';
import { MachineClaimPairActions } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairActions';
import { MachineClaimPairSelectors } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairSelectors';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { getMachineClassification } from 'app/modules/machine-directory/utils/getMachineClassification';
import { MachineClassification } from 'app/modules/machine-directory/enum/MachineClassification';
import { getMachineIsPairable } from 'app/modules/machine-directory/utils/getMachineIsPairable';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

export const MachineSummary = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectMachineClaimedData = useSelector(MachineClaimPairSelectors.selectMachineClaimData);
  const selectMachinePairedData = useSelector(MachineClaimPairSelectors.selectMachinePairingData);
  const selectIsPairingStep = useSelector(MachineClaimPairSelectors.selectIsPairingStep);
  const selectMaterialNumberData = useSelector(MachineClaimPairSelectors.selectMaterialNumberData);

  const machineClassification = getMachineClassification(
    selectMachineClaimedData?.materialNumber,
    selectMaterialNumberData
  );

  const isMachinePairable =
    getMachineIsPairable(selectMachineClaimedData?.materialNumber, selectMaterialNumberData) || false;

  const handleContinuePair = useCallback(() => {
    dispatch(MachineClaimPairActions.setStepPairMachineActive());
    dispatch(MachineClaimPairActions.searchMaterialNumberResetState());
  }, [dispatch]);

  const handleBackToStart = useCallback(() => {
    dispatch(MachineClaimPairActions.setStepFindMachineActive());
    dispatch(MachineClaimPairActions.searchMaterialNumberResetState());
    dispatch(MachineClaimPairActions.findMachineResetState());
    dispatch(MachineClaimPairActions.claimMachineResetState());
    dispatch(MachineClaimPairActions.pairMachineResetState());
    dispatch(MachineClaimPairActions.pairMachineSearchClearData());
  }, [dispatch]);

  return (
    <StyledMachineSummary className="summary">
      <p className="summary__sub-title">
        {selectIsPairingStep ? t('machineClaiming.subTitlePairSummary') : t('machineClaiming.subTitleClaimSummary')}
      </p>
      <div className="summary__info">
        <Row gutter={32} justify="start" wrap={false}>
          <Col>
            <h4 className="summary__title">{t('machineClaiming.summary.machineType')}</h4>
            <p className="summary__content">{selectMachineClaimedData?.type || selectMachinePairedData?.machineType}</p>
          </Col>
          <Col>
            <h4 className="summary__title">{t('machineClaiming.summary.materialNumber')}</h4>
            <p className="summary__content">
              {selectMachineClaimedData?.materialNumber || selectMachinePairedData?.machineMaterialNumber}
            </p>
          </Col>
          <Col>
            <h4 className="summary__title">{t('machineClaiming.summary.serialNumber')}</h4>
            <p className="summary__content">
              {selectMachineClaimedData?.serialNumber || selectMachinePairedData?.machineSerialNumber}
            </p>
          </Col>
          <Col>
            <h4 className="summary__title">{t('machineClaiming.summary.alg')}</h4>
            <p className="summary__content">
              {selectMachineClaimedData?.algNumber || selectMachinePairedData?.algNumber}
            </p>
          </Col>
          <Col>
            <h4 className="summary__title">{t('machineClaiming.summary.customer')}</h4>
            <p className="summary__content">
              {selectMachineClaimedData?.customerName || selectMachinePairedData?.customerName}
              <br />
              {selectMachineClaimedData?.customerNumber || selectMachinePairedData?.customerNumber}
            </p>
          </Col>
        </Row>

        {selectIsPairingStep && (
          <>
            <Divider />
            <Row gutter={32} justify="start" wrap={false}>
              <Col>
                <h4 className="summary__title">{t('machineClaiming.summary.machineType')}</h4>
                <p className="summary__content">{selectMachinePairedData?.gatewayType}</p>
              </Col>
              <Col>
                <h4 className="summary__title">{t('machineClaiming.summary.materialNumber')}</h4>
                <p className="summary__content">{selectMachinePairedData?.gatewayMaterialNumber}</p>
              </Col>
              <Col>
                <h4 className="summary__title">{t('machineClaiming.summary.serialNumber')}</h4>
                <p className="summary__content">{selectMachinePairedData?.gatewaySerialNumber}</p>
              </Col>
            </Row>
          </>
        )}
      </div>
      <div className="summary__continue-section">
        {selectIsPairingStep ? (
          <Row gutter={24}>
            <Col>
              <PrimaryButton size="m" className="summary__button" onClick={handleBackToStart}>
                {t('machineClaiming.backToStartButton')}
              </PrimaryButton>
            </Col>
          </Row>
        ) : (
          <Row gutter={24} align="middle">
            <StyledMachineIsPairableGlobalStyles />
            {machineClassification !== MachineClassification.STAND_ALONE && (
              <>
                <Col>
                  <PrimaryButton
                    disabled={!isMachinePairable}
                    size="m"
                    className="summary__button"
                    onClick={handleContinuePair}
                  >
                    {t('machineClaiming.continueWithPairingButton')}
                  </PrimaryButton>
                </Col>
                <Col>
                  {!isMachinePairable && (
                    <Tooltip
                      title={MachineIsPairableTooltip}
                      overlayClassName="machine-pairable-tooltip-overlay"
                      placement="bottomLeft"
                    >
                      <div className="machine-summary__info-icon">
                        <SvgIcon name="info"></SvgIcon>
                      </div>
                    </Tooltip>
                  )}
                </Col>
              </>
            )}
            <Col>
              <TextButton size="m" className="summary__button" onClick={handleBackToStart}>
                {t('machineClaiming.backToStartButton')}
              </TextButton>
            </Col>
          </Row>
        )}
      </div>
    </StyledMachineSummary>
  );
};
