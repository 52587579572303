import moment from 'moment';
import { Optional } from 'lib/types/Optional';

export const convertedOperatingHours = (operatingHours: Optional<string>): number | undefined => {
  if (!operatingHours) {
    return undefined;
  }

  const [hours, minutes] = operatingHours.trim().split(':');

  if (Number.isNaN(Number(hours)) || Number.isNaN(Number(minutes))) {
    throw Error('Invalid operating hours format');
  }

  return moment.duration(hours, 'hours').asSeconds() + moment.duration(minutes, 'minutes').asSeconds();
};
