import styled from 'styled-components';

export const StyledCustomerDetails = styled.div`
  font-family: ${(props): string => props.theme.fonts.body};
  font-size: ${(props): string => props.theme.fontSizes.pageDescription};
  min-height: inherit;
  word-break: break-word;

  .customer-details-header {
    background-color: ${(props): string => props.theme.colors.brightGrey};
    position: sticky;
    top: -100px;
    z-index: 97;
  }

  .customer-details-header__page-info--customer-name {
    .ant-row {
      width: fit-content;
      cursor: pointer;
    }
  }

  .customer-details-header__back-button {
    :hover,
    :active,
    :visited,
    :focus,
    :target {
      background-color: transparent;
    }

    height: 100%;

    svg {
      rotate: 180deg;
      width: 24px;
      height: 24px;

      path {
        stroke-width: 1px;
      }
    }
  }

  .customer-details-header__main-content {
    cursor: pointer;
  }

  .customer-details-header__main-content,
  .customer-details-header__sub-content {
    max-width: 1056px;
    margin: auto;
    padding: 38px 24px 15px;
  }

  .customer-details-header__main-content {
    padding-bottom: 0;
  }

  .customer-details-header__sub-content {
    padding-top: 0;
  }

  .customer-details-header__title {
    color: ${(props): string => props.theme.colors.text};
    font-family: ${(props): string => props.theme.fonts.pageTitle};
    font-size: ${(props): string => props.theme.fontSizes.pageTitle};
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .customer-details-header__sub-title--label {
    color: ${(props): string => props.theme.colors.border};
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    text-transform: capitalize;
    margin-bottom: 10px;
  }

  .customer-details-header__sub-title--data {
    color: ${(props): string => props.theme.colors.text};
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    margin-bottom: 10px;
  }

  .customer-details-body {
    max-width: 1056px;
    margin: 26px auto 0px;
    padding: 0 24px;
  }

  .customer-details-body__left-heading,
  .customer-details-body__right-heading {
    color: ${(props): string => props.theme.colors.text};
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.formSubheading};
    text-transform: uppercase;
    margin-bottom: 20px;
  }
  .customer-details-body__left-content--info-content {
    margin-bottom: 24px;
  }

  .customer-details-body__left-content--info-label,
  .customer-details-body__left-content--info-data {
    margin-bottom: 4px;
  }

  .customer-details-body__left-content--info-label {
    color: ${(props): string => props.theme.colors.text};
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    line-height: 18px;
    text-transform: capitalize;
  }

  .customer-details-body__left-content--info-data,
  .customer-details-body__right-content--description {
    font-family: ${(props): string => props.theme.fonts.formBody};
    font-size: ${(props): string => props.theme.fontSizes.p1};
    font-weight: 475;
    line-height: 18px;
  }

  .customer-details-body__left-content--edit-btn,
  .customer-details-body__right-content--delete-btn {
    text-transform: uppercase;
  }

  .customer-list__search-input {
    margin: 0 0 24px 0;
    display: flex;
    width: 100%;

    .customer-list__search-custom-input {
      height: 44px;
      width: 320px;

      .ant-input {
        height: 17px;
        width: 249px;
        font-size: 13px;
      }

      .ant-input-prefix svg {
        width: 18px;
        height: 18px;
        stroke-width: 30px;
        stroke: ${(props): string => props.theme.colors.white};
      }

      .ant-input-suffix {
        margin-right: 15px;
      }
    }
  }

  .customer-details-header__invitation-btn {
    &.button__size-m {
      padding: 9px 11px 7px;
    }
  }

  .customer-details-header__icon {
    svg {
      width: 24px;
      height: 24px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .customer-list__table {
    margin-bottom: 40px;
    width: 100%;

    .customer-list__user-status-tag {
      width: fit-content;
      min-width: 100px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      border: none;
      color: ${(props): string => props.theme.colors.black};
      font-family: ${(props): string => props.theme.fonts.formBody};
      font-size: ${(props): string => props.theme.fontSizes.caption};
      line-height: ${(props): string => props.theme.lineHeights.formSubheading};
      font-weight: 475;
    }

    .ant-table-thead {
      .ant-table-cell {
        padding: 17px 5px;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        cursor: pointer;

        .ant-table-cell {
          padding: 24px 5px 23px !important;
          vertical-align: top;
          background-color: inherit !important;
        }
      }
    }

    .machine-list__avatar-column {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .machine-list__avatar-image {
      width: 48px;
      height: 48px;
    }
  }

  .machine-list__filter-wrapper {
    height: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    .machine-list__filter {
      display: flex;
      justify-content: space-between;
      align-items: end;

      .machine-list__select-wrapper {
        display: flex;
        flex-direction: column;
        min-width: 148px;
        padding-right: 32px;
      }
      .ant-select {
        width: 326px;
        .ant-select-selector {
          background-color: ${(props): string => props.theme.colors.white};
        }
      }
    }
  }

  .machine-list__search-input {
    .machine-list__search-custom-input {
      height: 44px;
      width: 320px;

      .ant-input {
        height: 17px;
        width: 249px;
        font-size: 13px;
      }

      .ant-input-prefix svg {
        width: 18px;
        height: 18px;
        stroke-width: 30px;
        stroke: ${(props): string => props.theme.colors.white};
      }

      .ant-input-suffix {
        margin-right: 15px;
      }
    }
  }

  .machine-list__adjustment-wrapper {
    display: flex;
    align-self: end;

    .machine-list__unclaim-wrapper {
      align-self: end;
      padding-left: 24px;
    }
  }

  @media screen and (max-width: 1406px) {
    margin-left: 175px;
  }
`;
