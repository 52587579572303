import produce from 'immer';
import { AnyAction } from 'redux';
import { UserCreateActions } from './userCreateActions';
import { Optional } from 'lib/types/Optional';

export interface UserCreateState {
  userId: Optional<string>;
  isLoading: boolean;
}

export const initialState: UserCreateState = {
  userId: null,
  isLoading: false,
};

export const userCreateReducer = (state = initialState, action: AnyAction): UserCreateState =>
  produce(state, draft => {
    switch (action.type) {
      case UserCreateActions.CREATE_USER_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case UserCreateActions.CREATE_USER_SUCCESS: {
        const { registerUser } = action.payload;

        draft.isLoading = false;
        draft.userId = registerUser?.userId || '';
        return draft;
      }

      case UserCreateActions.CREATE_USER_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      default:
        return draft;
    }
  });
