import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { call, getContext, put, takeLatest } from 'typed-redux-saga';
import { IDependencies } from '../../../../cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { CustomerSearchResponse } from '../../interfaces/Customer.types';
import {
  CustomerSearchActions,
  ICustomerSearchErrorAction,
  ICustomerSearchRequestAction,
  ICustomerSearchSuccessAction,
} from './customerSearchAction';

export function* getCustomerSearchSaga(
  action: ICustomerSearchRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<CustomerSearchResponse | unknown>
  | PutEffect<ICustomerSearchSuccessAction>
  | PutEffect<ICustomerSearchErrorAction>,
  void,
  IDependencies
> {
  const { userService, toastService, t } = yield* getContext<IDependencies>('dependencies');
  try {
    const response = yield* call(userService.customerSearch, action.payload);
    // TODO: condition will be rewritten when API is available
    if (response?.getCustomers?.data?.length === 0) {
      yield* call(toastService.error, {
        message: t('machineClaiming.toasts.customerSearch.error.message'),
      });
    }
    yield* put(CustomerSearchActions.customerSearchSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      CustomerSearchActions.customerSearchError({
        error,
      })
    );
  }
}

export function* customerSearchSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(CustomerSearchActions.CUSTOMER_SEARCH_REQUEST, getCustomerSearchSaga);
}
