import { TFunction } from 'react-i18next';
import { ReactNode } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { DeleteCustomerState } from '../../state/deleteCustomerSlice';
import { User } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { PopConfirm } from 'lib/components/PopConfirm/PopConfirm';

interface IGetUserListColumnsProps {
  t: TFunction;
  handleClickDelete: (internalUserId: string) => void;
  handleClickReassign: (internalUserId: string) => void;
  sharedOnCell: (record: User) => object;
  hideOnCell: (record: User) => object;
  deleteUsers: DeleteCustomerState['deleteUsers'];
  reassignUsers: DeleteCustomerState['reassignUsers'];
}

export const getUserListColumns = ({
  t,
  handleClickDelete,
  handleClickReassign,
  sharedOnCell,
  hideOnCell,
  deleteUsers,
  reassignUsers,
}: IGetUserListColumnsProps): ColumnsType<User> => [
  {
    title: () => (
      <div className="customer-delete-has-user__table__title">{t('customerDetails.modals.delete.table.name')}</div>
    ),
    dataIndex: 'fullName',
    key: 'fullName',
    render: (fullName, user): JSX.Element => {
      if (deleteUsers[user.internalUserId]?.loading) {
        return (
          <span>
            {t('customerDetails.modals.delete.status.deleting')}&nbsp;{user.title}&nbsp;{fullName}
          </span>
        );
      }

      if (deleteUsers[user.internalUserId]?.completed) {
        return (
          <span>
            {user.title}&nbsp;{fullName}&nbsp;{t('customerDetails.modals.delete.status.deleted')}
          </span>
        );
      }

      if (deleteUsers[user.internalUserId]?.error) {
        const str = deleteUsers[user.internalUserId]?.error as string;
        const substrings = str.split(': {', 1);
        return (
          <>
            <span>
              {user.title}&nbsp;{fullName}&nbsp;{t('customerDetails.modals.delete.status.deleteError')}
            </span>
            <div>{substrings}</div>
          </>
        );
      }

      if (reassignUsers[user.internalUserId]?.loading) {
        return (
          <span>
            {t('customerDetails.modals.delete.status.reassigning')}&nbsp;{user.title}&nbsp;{fullName}
          </span>
        );
      }

      if (reassignUsers[user.internalUserId]?.completed) {
        return (
          <span>
            {user.title}&nbsp;{fullName}&nbsp;{t('customerDetails.modals.delete.status.reassigned')}
          </span>
        );
      }

      if (reassignUsers[user.internalUserId]?.error) {
        const str = reassignUsers[user.internalUserId]?.error as string;
        const substrings = str.split(': {', 1);
        return (
          <>
            <span>
              {user.title}&nbsp;{fullName}&nbsp;{t('customerDetails.modals.delete.status.reassignError')}
            </span>
            <div>{substrings}</div>
          </>
        );
      }
      return (
        <span>
          {user.title}&nbsp;{fullName}
        </span>
      );
    },
    onCell: sharedOnCell,
  },
  {
    title: () => (
      <div className="customer-delete-has-user__table__title">{t('customerDetails.modals.delete.table.email')}</div>
    ),
    dataIndex: 'email',
    width: 193,
    key: 'email',
    ellipsis: {
      showTitle: true,
    },
    onCell: hideOnCell,
  },
  {
    title: '',
    width: 84,
    key: 'reassign',
    dataIndex: 'internalUserId',
    render: (internalUserId: string): ReactNode => (
      <TextButton
        type="text"
        className="customer-delete-has-user__reassign"
        onClick={(): void => handleClickReassign(internalUserId)}
      >
        {t('customerDetails.modals.delete.table.reassign')}
      </TextButton>
    ),
    onCell: hideOnCell,
  },
  {
    title: '',
    width: 56,
    key: 'delete',
    dataIndex: 'internalUserId',
    render: (internalUserId: string): ReactNode => (
      <PopConfirm
        key="save-button"
        title={t('customerDetails.modals.delete.descriptionHasUser.confirmDeleteUser')}
        cancelText={t('common.popConfirm.cancelText')}
        okText={t('common.popConfirm.okTextConfirm')}
        onConfirm={(): void => {
          handleClickDelete(internalUserId);
        }}
      >
        <TextButton className="customer-delete-has-user__delete" icon={<SvgIcon name="delete" />}></TextButton>
      </PopConfirm>
    ),
    onCell: hideOnCell,
  },
];
