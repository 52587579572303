import styled from 'styled-components';
import { Modal } from '../../../../../../lib/components/Modal/Modal';

export const StyledCustomerCreateModal = styled(Modal)`
  .customer-create__input {
    width: 100%;

    &::placeholder {
      font-size: ${(props): string => props.theme.fontSizes.formBody};
      font-family: ${(props): string => props.theme.fonts.modalSubtitle};
      color: ${(props): string => props.theme.colors.border};
    }
  }

  .customer-create__select {
    width: 100%;

    .ant-select-selection-placeholder {
      font-size: ${(props): string => props.theme.fontSizes.formBody};
      font-family: ${(props): string => props.theme.fonts.modalSubtitle};
      color: ${(props): string => props.theme.colors.border};
    }
  }

  .ant-form-item-label {
    padding-bottom: 0;

    > label {
      text-transform: uppercase;
      font-size: ${(props): string => props.theme.fontSizes.formTitle};
      font-family: ${(props): string => props.theme.fonts.formTitle};
      color: ${(props): string => props.theme.colors.text};
    }
  }

  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${(props): string => props.theme.colors.red};
    box-shadow: none;
    border-right-width: 2px !important;
  }

  .ant-form-item-explain-error {
    color: ${(props): string => props.theme.colors.red};
  }
`;
