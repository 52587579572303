import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { getContext, call, put, takeLatest, retry } from 'typed-redux-saga';
import { MachineListResponse, PollMachineListOperation } from '../../interfaces/Machine.types';
import {
  IGetMachineListErrorAction,
  IGetMachineListRequestAction,
  IGetMachineListSuccessAction,
  IPollMachineListRequestAction,
  MachineListActions,
} from './machineListActions';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { MachineOwnership } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { POLL_INTERVAL, POLL_MAX_RETRIES } from 'config/constants';

export function* getMachineListSaga(
  action: IGetMachineListRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<MachineListResponse>
  | PutEffect<IGetMachineListSuccessAction>
  | PutEffect<IGetMachineListErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.list, action.payload);
    yield* put(MachineListActions.getMachineListSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      MachineListActions.getMachineListError({
        error,
      })
    );
  }
}

export function* pollMachineListSaga(
  action: IPollMachineListRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<MachineListResponse>
  | PutEffect<IGetMachineListSuccessAction>
  | PutEffect<IGetMachineListErrorAction>,
  void,
  IDependencies
> {
  const { machineService } = yield* getContext<IDependencies>('dependencies');
  const { materialNumber, serialNumber, operation, filterOption } = action.payload;

  const getMachineListAfterMutation = async (): Promise<MachineListResponse> => {
    let predicate: () => boolean;

    const checkMachine: MachineListResponse = await machineService.list({
      filter: {
        customerId: filterOption.filter?.customerId,
        serialNumber,
      },
      search: materialNumber,
    });

    const newMachineList = (checkMachine.listClaimedMachines.data as MachineOwnership[]) || [];

    switch (operation) {
      case PollMachineListOperation.UNCLAIM:
        predicate = (): boolean =>
          newMachineList.length === 0 || newMachineList.every(machine => machine.serialNumber !== serialNumber);
        break;

      case PollMachineListOperation.CLAIM_NEWDEVICE:
        predicate = (): boolean =>
          newMachineList.some(
            machine => machine.materialNumber === materialNumber && machine.serialNumber === serialNumber
          );
        break;

      case PollMachineListOperation.RECLAIM:
        predicate = (): boolean =>
          newMachineList.length === 0 || newMachineList.every(machine => machine.serialNumber !== serialNumber);
        break;

      default: {
        throw new Error(`Invalid operation: ${operation}`);
      }
    }

    if (!predicate()) {
      throw new Error('List Machine not updated');
    }

    const response: MachineListResponse = await machineService.list(filterOption);

    return response;
  };

  try {
    const response: MachineListResponse = yield* retry(POLL_MAX_RETRIES, POLL_INTERVAL, getMachineListAfterMutation);
    yield* put(MachineListActions.getMachineListSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      MachineListActions.getMachineListError({
        error,
      })
    );
  }
}

export function* machineListSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(MachineListActions.GET_MACHINE_LIST_REQUEST, getMachineListSaga);
  yield* takeLatest(MachineListActions.POLL_MACHINE_LIST_REQUEST, pollMachineListSaga);
}
