import React, { useEffect } from 'react';
import { Col, Form, Row, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsEditUserModalVisible } from '../../state/userModalsSelectors';
import { Input } from 'lib/components/Input/Input';
import { Select } from 'lib/components/Select/Select';
import { Radio } from 'lib/components/Radio/Radio';
import { userDetailsSelectors } from 'app/modules/user-management/user-details/state/userDetailsSelector';
import {
  RegisterUserTitleEnum,
  UpdateUserInput,
} from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { supportedLanguages } from 'app/cross-cutting-concerns/translations/Translations';
import { UserStatusValue } from 'app/modules/user-management/interfaces/User.types';
import { splitLangPrefix } from 'app/modules/user-management/utils/splitLangPrefix';

const { Option } = Select;

const CONSTANTS = {
  salutations: [
    { value: RegisterUserTitleEnum.Mr, content: 'userCreate.form.salutations.mr' },
    { value: RegisterUserTitleEnum.Mrs, content: 'userCreate.form.salutations.mrs' },
  ],
  status: [
    { value: UserStatusValue.UNAUTHORIZED, content: 'userDetails.editUserModal.form.unauthorized' },
    { value: UserStatusValue.IN_PROGRESS, content: 'userDetails.editUserModal.form.inProgress' },
    { value: UserStatusValue.AUTHORIZED, content: 'userDetails.editUserModal.form.authorized' },
  ],
};

const languageOptions = Object.entries(supportedLanguages).map(([key, values]) => ({
  value: key,
  content: values.name,
}));

interface EditUserFormProps {
  formInstance: FormInstance<any>;
  onFinish: (values: UpdateUserInput) => void;
}

export const EditUserForm = ({ formInstance, onFinish }: EditUserFormProps): JSX.Element => {
  const { t } = useTranslation();

  const userDetail = useSelector(userDetailsSelectors.selectUserDetails);
  const isVisible = useSelector(selectIsEditUserModalVisible);

  useEffect(() => {
    if (userDetail && isVisible) {
      const getPrefixLanguage = splitLangPrefix(userDetail.language);

      formInstance.setFieldsValue({
        ...userDetail,
        language: getPrefixLanguage,
        title: userDetail.title === RegisterUserTitleEnum.None ? null : userDetail.title,
      });
    }
  }, [formInstance, userDetail, isVisible]);

  return (
    <Form form={formInstance} name="edit-user" layout="vertical" onFinish={onFinish} autoComplete="off">
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="title"
            label={t('userDetails.editUserModal.form.salutation')}
            className="edit-user__input-group"
          >
            <Select
              className="edit-user__select"
              placeholder={t('userDetails.editUserModal.form.placeholderNull')}
              showArrow
            >
              {CONSTANTS.salutations.map(salutation => (
                <Option value={salutation.value} key={salutation.value}>
                  {t(salutation.content)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="firstName"
            label={t('userDetails.editUserModal.form.firstName')}
            className="edit-user__input-group"
          >
            <Input
              className="edit-user__input"
              placeholder={t('userDetails.editUserModal.form.placeholderFirstName')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="lastName"
            label={t('userDetails.editUserModal.form.lastName')}
            className="edit-user__input-group"
          >
            <Input className="edit-user__input" placeholder={t('userDetails.editUserModal.form.placeholderLastName')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} sm={12}>
          <Form.Item
            name="phoneNumber"
            label={t('userDetails.editUserModal.form.phoneNumber')}
            className="edit-user__input-group"
          >
            <Input
              className="edit-user__input"
              placeholder={t('userDetails.editUserModal.form.placeholderPhoneNumber')}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item
            name="language"
            label={t('userDetails.editUserModal.form.language')}
            className="edit-user__input-group"
          >
            <Select
              className="edit-user__select"
              placeholder={t('userDetails.editUserModal.form.placeholderNull')}
              showArrow
            >
              {languageOptions.map(({ value, content }) => (
                <Option key={value} value={value}>
                  {t(content)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24}>
          <Form.Item
            name="email"
            label={t('userDetails.editUserModal.form.email')}
            className="edit-user__input-group"
            required
            rules={[
              { type: 'email', message: t('userDetails.editUserModal.form.errors.emailInvalid') },
              { required: true, message: t('userDetails.editUserModal.form.errors.emailRequired') },
            ]}
          >
            <Input
              className="edit-user__input edit-user__input-email"
              placeholder={t('userDetails.editUserModal.form.placeholderEmail')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="status"
        label={t('userDetails.editUserModal.form.userStatus')}
        required
        rules={[{ required: true, message: t('userDetails.editUserModal.form.errors.userStatusRequired') }]}
      >
        <Radio.Group>
          <Row gutter={24}>
            {CONSTANTS.status.map(({ value, content }) => (
              <Col xs={24} sm={8} key={`user-status-${value}`}>
                <Radio value={value}>{t(content)}</Radio>
              </Col>
            ))}
          </Row>
        </Radio.Group>
      </Form.Item>
    </Form>
  );
};
