import { useCallback, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { FormInstance, useForm } from 'antd/lib/form/Form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidatorRule } from 'rc-field-form/lib/interface';
import { MachineClaimPairSelectors } from '../../state/machineClaimPairSelectors';
import { MachineClaimPairActions } from '../../state/machineClaimPairActions';
import { PairConditionEnum, getPairingMessage, pairMachineGatewayCondition } from '../../../utils/getPairingMessage';
import { MaterialNumberAutoComplete } from '../MaterialNumberAutoComplete/MaterialNumberAutoComplete';
import { StyledMachineIsPairableGlobalStyles } from '../MachineSummary/MachineIsPairableTooltip/MachineIsPairable.global.styles';
import { StyledPairMachine } from './MachinePair.styles';
import { MachinePairingModal } from 'app/modules/machine-directory/modals/components/MachinePairingModal/MachinePairingModal';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { Input } from 'lib/components/Input/Input';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { InputMachinePairingSearch } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { MachineModalActions } from 'app/modules/machine-directory/modals/state/machineModalActions';
import { MachineClassification } from 'app/modules/machine-directory/enum/MachineClassification';
import { MN_VALIDATION } from 'app/modules/machine-directory/utils/constant';

export const MachinePair = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formPair] = useForm();
  const [gatewayMaterialNumberInput, setGatewayMaterialNumberInput] = useState('');
  const [gatewaySerialNumberInput, setGatewaySerialNumberInput] = useState('');

  const handleDisableButton = (): void => {
    setGatewayMaterialNumberInput(formPair.getFieldValue('gatewayMaterialNumber'));
    setGatewaySerialNumberInput(formPair.getFieldValue('gatewaySerialNumber'));
  };

  const selectPairingSearchData = useSelector(MachineClaimPairSelectors.selectMachinePairingSearchData);
  const selectMachinePairData = useSelector(MachineClaimPairSelectors.selectMachinePairingData);
  const selectMachineClaimData = useSelector(MachineClaimPairSelectors.selectMachineClaimData);
  const isLoadingPairSearch = useSelector(MachineClaimPairSelectors.selectMachinePairSearchLoading);
  const isLoadingPairWithSearch = useSelector(MachineClaimPairSelectors.selectMachinePairLoadingWithSearch);
  const selectFindMachineData = useSelector(MachineClaimPairSelectors.selectMachineFindData);
  const selectMaterialNumberData = useSelector(MachineClaimPairSelectors.selectMaterialNumberData);

  const isMaterialNumberFound = selectMaterialNumberData?.some(
    materialNumberInfo => materialNumberInfo?.materialNumber === gatewayMaterialNumberInput
  );

  const inputValidation = useMemo(
    () => gatewayMaterialNumberInput && gatewaySerialNumberInput && isMaterialNumberFound,
    [gatewayMaterialNumberInput, gatewaySerialNumberInput, isMaterialNumberFound]
  );

  const isPairAndSearchLoading = (): boolean => {
    const pairingCondition = pairMachineGatewayCondition(selectPairingSearchData || {});

    if (pairingCondition === PairConditionEnum.BOTH_NOT_PAIRED) {
      return isLoadingPairWithSearch;
    }

    return isLoadingPairSearch;
  };

  const handleFinishPairSearch = useCallback(
    ({
      machineMaterialNumber,
      machineSerialNumber,
      gatewayMaterialNumber,
      gatewaySerialNumber,
    }: InputMachinePairingSearch) => {
      dispatch(
        MachineClaimPairActions.pairMachineSearchRequest({
          machineMaterialNumber,
          machineSerialNumber,
          gatewayMaterialNumber,
          gatewaySerialNumber,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (selectPairingSearchData) {
      const pairingMessage = getPairingMessage(selectPairingSearchData || {});

      if (pairingMessage) {
        dispatch(MachineModalActions.showMachinePairingModal(pairingMessage));
      } else {
        dispatch(MachineClaimPairActions.pairMachineRequest(formPair.getFieldsValue()));
      }
    }
  }, [dispatch, formPair, selectPairingSearchData]);

  useEffect(() => {
    if (selectMachinePairData) {
      dispatch(MachineClaimPairActions.setStepSummaryMachineActive());
      dispatch(MachineModalActions.hideMachinePairingModal());
    }
  }, [dispatch, selectMachinePairData]);

  useEffect(() => {
    formPair.resetFields();
  }, [dispatch, formPair]);

  const handlePairButtonClick = useCallback(() => {
    formPair.submit();
  }, [formPair]);

  const [showOperatingHours, setShowOperatingHours] = useState(false);

  const showMachineOperatingHours = useCallback(() => {
    setShowOperatingHours(true);
  }, []);

  const deleteMachineOperatingHoursClick = useCallback(() => {
    formPair.setFieldsValue({ operatingHours: '' });
  }, [formPair]);

  const handleBack = useCallback(() => {
    dispatch(MachineClaimPairActions.setStepFindMachineActive());
    dispatch(MachineClaimPairActions.pairMachineSearchClearData());
    dispatch(MachineClaimPairActions.findMachineResetState());
  }, [dispatch]);

  const handleValidationSN = ({ getFieldValue }: FormInstance): ValidatorRule => ({
    validator(_, value): Promise<void> {
      if (getFieldValue('gatewayMaterialNumber') === MN_VALIDATION && value.length !== 11) {
        return Promise.reject(new Error(t('machineClaiming.forms.errors.SNValidate')));
      }
      return Promise.resolve();
    },
  });

  return (
    <StyledPairMachine>
      <Form
        form={formPair}
        layout="vertical"
        name="machine-pairing"
        onFinish={handleFinishPairSearch}
        className="machine-pairing"
        onFieldsChange={handleDisableButton}
        initialValues={{
          machineMaterialNumber: selectFindMachineData?.materialNumber,
          machineSerialNumber: selectFindMachineData?.serialNumber,
        }}
      >
        <p className="machine-pairing__sub-title">{t('pairMachine.machineDataTitle')}</p>
        <Row gutter={24}>
          <Col>
            <Form.Item
              name="machineMaterialNumber"
              label={t('machineClaiming.forms.materialNumber')}
              className="machine-pairing__form-item"
            >
              <Input className="machine-pairing__machine-data-input machine-pairing__input" disabled />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="machineSerialNumber"
              label={t('machineClaiming.forms.serialNumber')}
              className="machine-pairing__form-item"
            >
              <Input className="machine-pairing__machine-data-input machine-pairing__input" disabled />
            </Form.Item>
          </Col>
        </Row>
        <p className="machine-pairing__sub-title">{t('pairMachine.moduleDataTitle')}</p>
        <Row gutter={24}>
          <Col>
            <Form.Item
              name="gatewayMaterialNumber"
              label={t('machineClaiming.forms.materialNumber')}
              className="machine-pairing__form-item"
              rules={[
                {
                  required: true,
                  message: t('forms.errors.required'),
                },
              ]}
            >
              <MaterialNumberAutoComplete
                className="machine-pairing__material-number-input machine-pairing__input"
                placeholder={t('machineClaiming.forms.placeholderMaterialNumber')}
                classifications={[MachineClassification.GATEWAY]}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="gatewaySerialNumber"
              label={t('machineClaiming.forms.serialNumber')}
              className="machine-pairing__form-item"
              rules={[
                {
                  required: true,
                  message: t('forms.errors.required'),
                },
                handleValidationSN(formPair),
              ]}
            >
              <Input
                className="machine-pairing__serial-number-input machine-pairing__input"
                placeholder={t('machineClaiming.forms.placeholderSerialNumber')}
              />
            </Form.Item>
          </Col>
        </Row>
        <StyledMachineIsPairableGlobalStyles />
        {!showOperatingHours ? (
          <Row className="machine-pairing__machine-operating-hours__info">
            <SecondaryButton
              size="m"
              className="machine-pairing__show-machine-operating-hours-button"
              key="show-machine-operating-hours-info"
              onClick={showMachineOperatingHours}
              type="link"
            >
              {t('pairMachine.addMachineOperatingHoursButton')}
            </SecondaryButton>
          </Row>
        ) : (
          <Col>
            <Form.Item
              name="operatingHours"
              label={t('pairMachine.machineOperatingHoursToDateTitle')}
              className="machine-pairing__form-item"
              rules={[
                {
                  pattern: /\d+:\d+/,
                  message: t('pairMachine.forms.errors.machineOperatingHoursToDateValidation'),
                },
              ]}
            >
              <Input
                className="machine-pairing__machine-operating-hours-input"
                placeholder={t('pairMachine.machineOperatingHoursToDatePlaceholder')}
                suffix={
                  <SecondaryButton
                    className="machine-pairing__machine-operating-hours-input__delete-button"
                    type="text"
                    onClick={deleteMachineOperatingHoursClick}
                  >
                    <SvgIcon name="delete" className="machine-pairing__delete-icon"></SvgIcon>
                  </SecondaryButton>
                }
              />
            </Form.Item>
            <Col span={12} className="machine-pairing__machine-operating-hours-description">
              {t('pairMachine.machineOperatingHoursToDateDescription')}
            </Col>
          </Col>
        )}
        <Row className="machine-pairing__confirm">
          <Col className="machine-pairing__button-info">
            <PrimaryButton
              size="m"
              className="machine-pairing__button"
              loading={isPairAndSearchLoading()}
              onClick={handlePairButtonClick}
              disabled={!inputValidation}
            >
              {t('pairMachine.pairButton')}
            </PrimaryButton>
          </Col>
          {selectMachineClaimData ? (
            <TextButton
              size="m"
              className="machine-pairing__button machine-pairing__back-button"
              key="back-button"
              onClick={handleBack}
            >
              {t('machineClaiming.backToStartButton')}
            </TextButton>
          ) : (
            <TextButton
              size="m"
              className="machine-pairing__button machine-pairing__back-button"
              key="back-button"
              onClick={handleBack}
            >
              {t('pairMachine.backButton')}
            </TextButton>
          )}
        </Row>
      </Form>
      <MachinePairingModal formValue={formPair.getFieldsValue()} />
    </StyledPairMachine>
  );
};
