import styled from 'styled-components';

export const StyledMachineFind = styled.div`
  min-height: inherit;
  float: left;

  .machine-find__radio-group {
    margin-bottom: 28px;
    margin-left: 12px;
  }

  .machine-find__input {
    width: 234px;
  }

  .ant-select-disabled {
    color: ${(props): string => props.theme.colors.white};
    pointer-events: none;
  }

  .ant-input-affix-wrapper-disabled {
    border: 2px solid ${(props): string => props.theme.colors.neutralWhiteColor};
  }

  .machine-find__form-item {
    .ant-form-item-label {
      padding: 0;
      > label {
        font-family: ${(props): string => props.theme.fonts.captionBold};
        font-size: ${(props): string => props.theme.fontSizes.caption};
        line-height: ${(props): string => props.theme.lineHeights.caption};
        font-weight: ${(props): number => props.theme.fontWeights.captionBold};
      }
    }

    .ant-form-item-control {
      .ant-input-disabled {
        color: ${(props): string => props.theme.colors.neutralWhiteColor};
        background-color: ${(props): string => props.theme.colors.white};
        pointer-events: none;
      }
    }
  }

  .ant-form-item-required {
    text-transform: uppercase;
  }

  .machine-find__button-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .machine-find__button {
    text-transform: uppercase;
    margin-top: 20px;
  }

  .machine-find__info-icon {
    display: flex;
    align-items: center;
    margin: 20px 0 0 7px;
    svg {
      width: 18px;
      height: 18px;
    }

    i {
      height: 18px;
      width: 18px;
    }
  }

  .machine-find__table-result {
    background-color: ${(props): string => props.theme.colors.brightGrey};
    border: 1px solid ${(props): string => props.theme.colors.gray};
    padding: 16px;
    margin-bottom: 32px;
    width: inherit;

    .ant-row-space-between {
      margin-bottom: 18px;
    }
  }

  .machine-find__title {
    text-transform: uppercase;
    font-family: ${(props): string => props.theme.fonts.captionBold};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
    line-height: ${(props): string => props.theme.lineHeights.caption};
  }

  .machine-find__info {
    text-transform: uppercase;
    font-family: ${(props): string => props.theme.fonts.captionMedium};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.p1};
    margin: 0;
  }

  .machine-find__col {
    display: flex;
  }
`;
