import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsMachineUnclaimModalVisible } from '../../state/machineModalSelectors';
import { MachineModalActions } from '../../state/machineModalActions';
import { StyledMachineUnclaimModal } from './MachineUnclaimModal.styles';
import { MachineSelectedTable } from 'app/components/MachineSelectedTable/MachineSelectedTable';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { MachineOwnership } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { MachineDirectorySelectors } from 'app/modules/machine-directory/state/machineDirectorySelectors';
import {
  IUnclaimMachineRequestOptions,
  MachineDirectoryActions,
} from 'app/modules/machine-directory/state/machineDirectoryActions';

export interface MachineUnclaimingModalProps {
  selectedMachines: MachineOwnership[];
}

export const MachineUnclaimModal = ({ selectedMachines }: MachineUnclaimingModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedMachinesModal, setSelectedMachinesModal] = useState<MachineOwnership[]>([]);
  const [selectedMachinesId, setSelectedMachinesId] = useState<React.Key[]>([]);

  const isVisible = useSelector(selectIsMachineUnclaimModalVisible);
  const isUnclaimMachineLoading = useSelector(MachineDirectorySelectors.isUnclaimMachineLoading);

  useEffect(() => {
    if (isVisible) {
      setSelectedMachinesModal(selectedMachines);
      setSelectedMachinesId(selectedMachines.map(machine => machine.id) as React.Key[]);
    }
  }, [selectedMachines, isVisible]);

  const handleCancel = useCallback(() => dispatch(MachineModalActions.hideMachineUnclaimModal()), [dispatch]);
  const handleUnclaim = useCallback(() => {
    const requestOptions: IUnclaimMachineRequestOptions = {
      input: {
        materialNumber: selectedMachines[0].materialNumber as string,
        serialNumber: selectedMachines[0].serialNumber as string,
      },
    };

    dispatch(MachineDirectoryActions.unclaimMachineRequest(requestOptions));
  }, [dispatch, selectedMachines]);

  const onSelectChangeModal = (selectedRowKeys: React.Key[]): void => {
    if (selectedRowKeys.length > 0) {
      setSelectedMachinesId(selectedRowKeys);
    }
  };

  return (
    <StyledMachineUnclaimModal
      className="machine-unclaim__modal"
      title={t('machineUnclaim.title')}
      centered
      open={isVisible}
      closable={false}
      footer={[
        <SecondaryButton size="m" className="cancel-button" key="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton
          size="m"
          type="primary"
          key="submit"
          className="unclaim-button"
          onClick={handleUnclaim}
          loading={isUnclaimMachineLoading}
        >
          {t('machineUnclaim.button')}
        </PrimaryButton>,
      ]}
    >
      <MachineSelectedTable
        selectedMachinesModal={selectedMachinesModal}
        selectedMachinesId={selectedMachinesId}
        onSelectChangeModal={onSelectChangeModal}
      />
    </StyledMachineUnclaimModal>
  );
};
