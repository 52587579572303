import { produce } from 'immer';
import { AnyAction } from 'redux';
import { Customer } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { CustomerSearchActions } from 'app/modules/user-management/customer-search/state/customerSearchAction';

export interface CustomerSearchState {
  customer: {
    isLoading: boolean;
    list: Customer[];
  };
}

export const initialState: CustomerSearchState = {
  customer: {
    list: [],
    isLoading: false,
  },
};

export const customerSearchReducer = (state = initialState, action: AnyAction): CustomerSearchState =>
  produce(state, draft => {
    switch (action.type) {
      case CustomerSearchActions.CUSTOMER_SEARCH_REQUEST: {
        draft.customer.isLoading = true;
        return draft;
      }

      case CustomerSearchActions.CUSTOMER_SEARCH_SUCCESS: {
        const { getCustomers } = action.payload;
        draft.customer.list = getCustomers.data;
        draft.customer.isLoading = false;

        return draft;
      }

      case CustomerSearchActions.CUSTOMER_SEARCH_ERROR: {
        draft.customer.isLoading = false;
        return draft;
      }

      case CustomerSearchActions.CUSTOMER_SEARCH_CLEAR_LIST: {
        return initialState;
      }

      default:
        return draft;
    }
  });
