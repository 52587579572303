import styled from 'styled-components';

const SIDE_BAR_WIDTH = '175px';

export const StyledLoadingPage = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: calc(100vw - ${SIDE_BAR_WIDTH});
  margin: 0 0 0 ${SIDE_BAR_WIDTH};
  height: calc(100vh - 294px);
`;
