import styled from 'styled-components';

export const StyledUserInformationForm = styled.div`
  .invite-user__input {
    width: 100%;
    &::placeholder {
      font-size: ${(props): string => props.theme.fontSizes.formBody};
      font-family: ${(props): string => props.theme.fonts.modalSubtitle};
      color: ${(props): string => props.theme.colors.border};
    }
  }

  .invite-user-modal__select {
    width: 100%;

    .ant-select-selection-placeholder {
      font-size: ${(props): string => props.theme.fontSizes.formBody};
      font-family: ${(props): string => props.theme.fonts.modalSubtitle};
      color: ${(props): string => props.theme.colors.border};

      // Set placeholder center
      position: absolute;
      top: 50%;
      right: 11px;
      left: 11px;
      transform: translateY(-50%);
      transition: all 0.3s;
    }
  }

  .customer-invite-user__sub-title {
    font-size: ${(props): string => props.theme.fontSizes.formSubheading};
    color: ${(props): string => props.theme.colors.text};
    font-family: ${(props): string => props.theme.fonts.pageTitle};
    text-transform: uppercase;
    margin: 20px 0;
  }

  .customer-invite-user__body-content {
    font-family: ${(props): string => props.theme.fonts.formBody};
    color: ${(props): string => props.theme.colors.text};
  }

  .ant-form-item-label {
    padding-bottom: 0;

    > label {
      text-transform: uppercase;
      font-size: ${(props): string => props.theme.fontSizes.formTitle};
      font-family: ${(props): string => props.theme.fonts.formTitle};
      color: ${(props): string => props.theme.colors.text};
    }
  }

  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${(props): string => props.theme.colors.red};
    box-shadow: none;
    border-right-width: 2px !important;
  }

  .ant-form-item-explain-error {
    color: ${(props): string => props.theme.colors.red};
  }

  .invite-user__customer-selection-table {
    .ant-table-tbody {
      > tr.ant-table-row-selected:hover > td {
        background-color: inherit !important;
      }

      .ant-table-cell {
        background-color: inherit !important;
      }
    }
  }
`;
