import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'react-i18next';
import { Table } from 'antd';
import { Customer } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export const getCustomerListSelectedColumns = (t: TFunction): ColumnsType<Customer> => [
  {
    title: t('customerList.table.customerName').toLocaleUpperCase(),
    dataIndex: 'customerName',
    width: '30%',
    key: 'customerName',
    render: (value): JSX.Element => <>{value !== '' ? <span>{value}</span> : <span>{t('common.noneData')}</span>}</>,
  },
  {
    title: t('customerList.table.customerNumber').toLocaleUpperCase(),
    dataIndex: 'SAPCustomerNumber',
    width: '30%',
    key: 'SAPCustomerNumber',
    render: (value): JSX.Element => <>{value !== '' ? <span>{value}</span> : <span>{t('common.noneData')}</span>}</>,
  },
  {
    title: t('customerList.table.pudsId').toLocaleUpperCase(),
    dataIndex: 'customerId',
    width: '30%',
    key: 'customerId',
    render: (value): JSX.Element => <>{value !== '' ? <span>{value}</span> : <span>{t('common.noneData')}</span>}</>,
  },
  Table.SELECTION_COLUMN,
];
