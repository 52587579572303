import { TabsProps as AntdTabsProps } from 'antd';
import classNames from 'classnames';
import { StyledTabs } from './Tabs.styles';

export type TabsProps = AntdTabsProps;

export const Tabs = ({ className, ...props }: TabsProps): JSX.Element => {
  const tabsClassName = classNames(className, 'tabs');

  return <StyledTabs {...props} className={tabsClassName} />;
};
