import styled from 'styled-components';

export const StyledAccessDenied = styled.div`
  font-family: ${(props): string => props.theme.fonts.body};
  font-size: ${(props): string => props.theme.fontSizes.pageDescription};
  color: ${(props): string => props.theme.colors.text};
  min-height: inherit;

  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  align-items: center;

  .access-denied__title,
  .access-denied__btn {
    text-transform: uppercase;
    font-weight: bold;
  }

  .access-denied__title {
    font-family: ${(props): string => props.theme.fonts.pageTitle};
    font-weight: ${(props): number => props.theme.fontWeights.pageTitle};
    line-height: ${(props): string => props.theme.lineHeights.pageTitle};
    font-size: ${(props): string => props.theme.fontSizes.pageTitle};
    text-align: left;
    padding: 16px 0;
  }

  .access-denied__description {
    font-family: ${(props): string => props.theme.fonts.formBody};
    font-size: ${(props): string => props.theme.fontSizes.formSubheading};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.formSubheading};
    text-align: left;
    padding: 16px 0;
  }

  .access-denied__btn {
    font-family: ${(props): string => props.theme.fonts.buttonM};
    font-size: ${(props): string => props.theme.fontSizes.buttonM};
    cursor: pointer;
    font-weight: ${(props): number => props.theme.fontWeights.buttonM};
    line-height: ${(props): string => props.theme.lineHeights.buttonM};
    text-align: left;
  }
`;
