import produce from 'immer';
import { AnyAction } from 'redux';
import { MachineDirectoryActions } from './machineDirectoryActions';
import { MachineOwnership } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

// TODO: will add data property when response api is defined
export interface IMachineState {
  reClaimedMachine: MachineOwnership;
  isLoading: boolean;
  algs: {
    data: string[];
    isLoading: boolean;
  };
  unClaimedMachine: MachineOwnership;
}

export const initialState: IMachineState = {
  reClaimedMachine: {},
  isLoading: false,
  algs: {
    data: [],
    isLoading: false,
  },
  unClaimedMachine: {},
};

export const machineReducer = (state = initialState, action: AnyAction): IMachineState =>
  produce(state, draft => {
    switch (action.type) {
      case MachineDirectoryActions.RECLAIM_MACHINE_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case MachineDirectoryActions.RECLAIM_MACHINE_SUCCESS: {
        const { adminMachineClaim } = action.payload;

        draft.reClaimedMachine = adminMachineClaim?.data || {};
        draft.isLoading = false;
        return draft;
      }

      case MachineDirectoryActions.RECLAIM_MACHINE_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case MachineDirectoryActions.GET_ALGS_REQUEST: {
        draft.algs.isLoading = true;
        return draft;
      }

      case MachineDirectoryActions.GET_ALGS_SUCCESS: {
        const data = action.payload;
        draft.algs.data = [...new Set(data?.listAlgNumber?.data || [])] as string[];
        draft.algs.isLoading = false;

        return draft;
      }

      case MachineDirectoryActions.GET_ALGS_ERROR: {
        draft.algs.isLoading = false;
        return draft;
      }

      case MachineDirectoryActions.UNCLAIM_MACHINE_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case MachineDirectoryActions.UNCLAIM_MACHINE_SUCCESS: {
        const { unclaimMachine } = action.payload;

        draft.unClaimedMachine = unclaimMachine?.data || {};
        draft.isLoading = false;
        return draft;
      }

      case MachineDirectoryActions.UNCLAIM_MACHINE_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      default:
        return draft;
    }
  });
