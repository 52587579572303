import styled from 'styled-components';

export const StyledMachineList = styled.div`
  font-family: ${(props): string => props.theme.fonts.body};
  font-size: ${(props): string => props.theme.fontSizes.pageDescription};
  min-height: inherit;

  .machine-list__header {
    background-color: ${(props): string => props.theme.colors.lightGray};
    position: sticky;
    top: -82px;
    z-index: 97;
  }

  .machine-list__header-content {
    max-width: 1056px;
    margin: auto;
    padding: 38px 24px 15px;

    .machine-list__page-info {
      .machine-list__description {
        font-family: ${(props): string => props.theme.fonts.body};
        overflow: unset;
        margin-bottom: 0;
      }
    }

    .machine-list__filter-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 24px;

      .machine-list__reclaim-to-customer-wrapper,
      .machine-list__unclaim-wrapper {
        align-self: end;
        padding-left: 8px;
      }
    }
  }

  .machine-list__title {
    font-size: ${(props): string => props.theme.fontSizes.pageTitle};
    color: ${(props): string => props.theme.colors.text};
    font-family: ${(props): string => props.theme.fonts.pageTitle};
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .machine-list__filter {
    display: flex;
    justify-content: space-between;

    .machine-list__select-wrapper {
      display: flex;
      flex-direction: column;
      min-width: 148px;
      margin: 0 20px 0px 0;
    }

    .machine-list__select-alg {
      margin-top: 3px;
    }
    .ant-select {
      width: 100%;

      .ant-select-selector {
        background-color: ${(props): string => props.theme.colors.white};
      }
    }
  }

  .machine-list__filter-label {
    font-family: ${(props): string => props.theme.fonts.filterLabel};
    font-size: ${(props): string => props.theme.fontSizes.filterLabel};
    color: ${(props): string => props.theme.colors.text};
    text-transform: uppercase;
  }

  .machine-list__select {
    width: 100%;
  }

  .machine-list__filter-label {
    font-family: ${(props): string => props.theme.fonts.filterLabel};
    font-size: ${(props): string => props.theme.fontSizes.filterLabel};
    color: ${(props): string => props.theme.colors.text};
    text-transform: uppercase;
  }

  .machine-list__adjustment-wrapper {
    display: flex;
    align-self: end;
  }

  .machine-list__body-content {
    max-width: 1056px;
    margin: 26px auto 0;
    padding: 0 24px;

    .machine-list__search-input {
      margin: 0 0 24px 0;
      display: flex;
      width: 100%;
      flex-direction: row-reverse;

      .machine-list__search-custom-input {
        width: 320px;

        .ant-input {
          height: 17px;
          width: 249px;
          font-size: 13px;
        }

        .ant-input-prefix svg {
          width: 18px;
          height: 18px;
          stroke-width: 30px;
          stroke: ${(props): string => props.theme.colors.white};
        }

        .ant-input-suffix {
          margin-right: 15px;
        }
      }
    }
  }

  .machine-list__table {
    margin-bottom: 40px;

    .ant-table-thead {
      .ant-table-cell {
        padding: 17px 5px;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        cursor: pointer;

        .ant-table-cell {
          padding: 24px 5px 23px !important;
          vertical-align: top;
          background-color: inherit !important;
        }
      }
    }
  }

  .machine-list__avatar-column {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .machine-list__avatar-image {
    width: 48px;
    height: 48px;
  }

  //TODO: uncomment whenever the reclaim api changed to choose multiple machines
  /* .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: ${(props): string => props.theme.colors.text};
      background-color: ${(props): string => props.theme.colors.text};
    }

    &::after {
      border-color: ${(props): string => props.theme.colors.text};
    }
  }

  .ant-checkbox {
    .ant-checkbox-inner {
      border: 2px solid ${(props): string => props.theme.colors.text};
      border-radius: 0;
      width: 20px;
      height: 20px;
    }
  } */

  @media screen and (max-width: 1406px) {
    .machine-list__header-content,
    .machine-list__body-content {
      margin-left: 175px;
    }
  }
`;
