import { useTranslation } from 'react-i18next';
import { StyledCustomerDeleteTitle } from './CustomerDeleteTitle.styles';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';

export const CustomerDeleteTitle = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledCustomerDeleteTitle>
      <div className="delete-customer-title">
        <SvgIcon name="warningInformation" className="delete-customer-title__icon" />
        <span className="delete-customer-title__title">{t('customerDetails.modals.delete.title2')}</span>
      </div>
    </StyledCustomerDeleteTitle>
  );
};
