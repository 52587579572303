import classnames from 'classnames';
import { ButtonBaseProps } from '../ButtonBase';
import { StyledPrimaryButton } from './PrimaryButton.styles';

type ButtonProps = ButtonBaseProps;

export const PrimaryButton = ({ className, ...props }: ButtonProps): JSX.Element => {
  const buttonClassName = classnames(className, 'primary-button');

  return <StyledPrimaryButton className={buttonClassName} size="m" {...props} />;
};
