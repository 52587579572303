import { notification } from 'antd';
import { ArgsProps as AntdNotificationConfig, NotificationPlacement } from 'antd/es/notification';
import { singleton } from 'tsyringe';

export type ToastConfig = AntdNotificationConfig;

@singleton()
export class ToastService {
  private static defaultConfig = {
    className: 'custom-antd-notification-error-message',
    top: 100,
    placement: 'topRight' as NotificationPlacement | undefined,
  };

  public success = (config: ToastConfig): void => {
    notification.success({
      ...ToastService.defaultConfig,
      ...config,
    });
  };

  public info = (config: ToastConfig): void => {
    notification.info({
      ...ToastService.defaultConfig,
      ...config,
    });
  };

  public warning = (config: ToastConfig): void => {
    notification.warning({
      ...ToastService.defaultConfig,
      ...config,
    });
  };

  public error = (config: ToastConfig): void => {
    notification.error({
      ...ToastService.defaultConfig,
      ...config,
    });
  };

  public close = (toastKey: string): void => {
    notification.close(toastKey);
  };
}
