import { TFunction } from 'react-i18next';
import { ReactNode } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { DeleteCustomerState } from '../../state/deleteCustomerSlice';
import { MachineOwnership } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { PopConfirm } from 'lib/components/PopConfirm/PopConfirm';

interface IGetDevicesListColumnsProps {
  t: TFunction;
  handleClickReclaim: (device: MachineOwnership) => void;
  handleClickUnclaim: (device: MachineOwnership) => void;
  sharedOnCell: (record: MachineOwnership) => object;
  hideOnCell: (record: MachineOwnership) => object;
  reclaimDevices: DeleteCustomerState['reclaimDevices'];
  unclaimDevices: DeleteCustomerState['unclaimDevices'];
}

export const getDeviceListColumns = ({
  t,
  handleClickReclaim,
  handleClickUnclaim,
  sharedOnCell,
  hideOnCell,
  reclaimDevices,
  unclaimDevices,
}: IGetDevicesListColumnsProps): ColumnsType<MachineOwnership> => [
  {
    title: () => (
      <div className="customer-delete-has-devices__table__title">
        {t('customerDetails.modals.delete.table.machine')}
      </div>
    ),
    width: 200,
    dataIndex: 'machineName',
    key: 'machineName',
    render: (_, device): JSX.Element => {
      const machine = `${device.type} ${device.materialNumber}` || '';
      const id = `${device.materialNumber}-${device.serialNumber}`;
      if (reclaimDevices[id]?.loading) {
        return (
          <span>
            {t('customerDetails.modals.delete.status.reclaiming')}&nbsp;{machine}
          </span>
        );
      }

      if (reclaimDevices[id]?.completed) {
        return (
          <span>
            {machine}&nbsp;{t('customerDetails.modals.delete.status.reclaimed')}
          </span>
        );
      }

      if (reclaimDevices[id]?.error) {
        const str = reclaimDevices[id]?.error as string;
        const substrings = str.split(': {', 1);
        return (
          <>
            <span>
              {machine}&nbsp;{t('customerDetails.modals.delete.status.reclaimError')}
            </span>
            <div>{substrings}</div>
          </>
        );
      }

      if (unclaimDevices[id]?.loading) {
        return (
          <span>
            {t('customerDetails.modals.delete.status.unclaiming')}&nbsp;{machine}
          </span>
        );
      }

      if (unclaimDevices[id]?.completed) {
        return (
          <span>
            {machine}&nbsp;{t('customerDetails.modals.delete.status.unclaimed')}
          </span>
        );
      }

      if (unclaimDevices[id]?.error) {
        const str = unclaimDevices[id]?.error as string;
        const substrings = str.split(': {', 1);
        return (
          <>
            <span>
              {machine}&nbsp;{t('customerDetails.modals.delete.status.unclaimError')}
            </span>
            <div>{substrings}</div>
          </>
        );
      }
      return <span>{machine}</span>;
    },
    onCell: sharedOnCell,
  },
  {
    title: () => (
      <div className="customer-delete-has-devices__table__title">
        {t('customerDetails.modals.delete.table.serialNumber')}
      </div>
    ),
    dataIndex: 'serialNumber',
    width: 193,
    key: 'serialNumber',
    ellipsis: {
      showTitle: true,
    },
    onCell: hideOnCell,
  },
  {
    title: '',
    width: 120,
    key: 'reclaim',
    dataIndex: 'id',
    render: (_, device): ReactNode => (
      <TextButton onClick={(): void => handleClickReclaim(device)} className="customer-delete-has-devices__reclaim">
        {t('customerDetails.modals.delete.table.reclaim')}
      </TextButton>
    ),
    onCell: hideOnCell,
  },
  {
    title: '',
    width: 120,
    key: 'unclaim',
    dataIndex: 'id',
    render: (_, device): ReactNode => (
      <PopConfirm
        key="save-button"
        title={t('customerDetails.modals.delete.descriptionHasDevice.confirmUnclaimMachine')}
        cancelText={t('common.popConfirm.cancelText')}
        okText={t('common.popConfirm.okTextConfirm')}
        onConfirm={(): void => {
          handleClickUnclaim(device);
        }}
      >
        <TextButton type="text" className="customer-delete-has-devices__unclaim">
          {t('customerDetails.modals.delete.table.unclaim')}
        </TextButton>
      </PopConfirm>
    ),
    onCell: hideOnCell,
  },
];
