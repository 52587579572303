import styled from 'styled-components';

export const StyledMachineIsPairableTooltip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: ${(props): string => props.theme.fontSizes.caption};
  position: relative;

  .machine-pairable-tooltip__description {
    font-family: ${(props): string => props.theme.fonts.formBody};
    margin-bottom: 0;
  }
`;
