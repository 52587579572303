import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import { StyledAccessDenied } from './AccessDenied.styles';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { AccessRequestSPLink } from 'config/constants';

export const AccessDenied = (): JSX.Element => {
  const { t } = useTranslation();

  const handleRequestAccess = (): void => {
    window.location.assign(AccessRequestSPLink);
  };
  return (
    <StyledAccessDenied>
      <Col span={12}>
        <div className="access-denied__title">{t('pages.accessDenied')}</div>
        <div className="access-denied__description">{t('accessDenied.description')}</div>
        <PrimaryButton size="m" onClick={handleRequestAccess} className="access-denied__btn">
          {t('accessDenied.button')}
        </PrimaryButton>
      </Col>
      <Col span={12}>
        <div className="access-denied__icon">
          <SvgIcon name="forbidden" />
        </div>
      </Col>
    </StyledAccessDenied>
  );
};
