import { RadioProps as AntdRadioProps } from 'antd';
import classNames from 'classnames';
import { StyledButton, StyledGroup, StyledRadio } from './Radio.styles';

type RadioProps = AntdRadioProps;

export const Radio = ({ className, ...props }: RadioProps): JSX.Element => {
  const radioClassName = classNames(className, 'radio');
  return <StyledRadio className={radioClassName} {...props} />;
};

Radio.Group = StyledGroup;
Radio.Button = StyledButton;
