import { TFunction } from 'react-i18next';
import { Optional } from 'lib/types/Optional';

export const convertCodeToCountry = (code: Optional<string>, t: TFunction<'translation', undefined>): string => {
  const codeRegex = /[A-Z]{2}/;

  if (code && codeRegex.test(code)) {
    const countryNameInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });

    return countryNameInEnglish.of(code) as string;
  }

  return code || t('userDetails.noneData');
};
