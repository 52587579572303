import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MachineClaimPairSelectors } from '../state/machineClaimPairSelectors';
import { MachineReclaimModal } from '../../modals/components/MachinePairReclaimModal/MachineReclaimModal';
import { MachineClaimPairActions } from '../state/machineClaimPairActions';
import { MachineFind } from './MachineFind/MachineFind';
import { MachineClaim } from './MachineClaim/MachineClaim';
import { MachineSummary } from './MachineSummary/MachineSummary';
import { MachinePair } from './MachinePair/MachinePair';
import { StyledMachineClaimingPairing } from './MachineClaimingPairing.styles';
import { Steps } from 'lib/components/Steps/Steps';

export const MachineClaimingPairing = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectStepData = useSelector(MachineClaimPairSelectors.selectStepData);
  const selectIsPairingStep = useSelector(MachineClaimPairSelectors.selectIsPairingStep);

  useEffect(() => {
    dispatch(MachineClaimPairActions.setStepFindMachineActive());
  }, [dispatch]);

  const StepItems = useMemo(
    () => [
      {
        title: t('machineClaimingPairing.steps.titleFindMachine'),
        description: selectStepData === 0 && <MachineFind />,
        icon: 1,
      },
      !selectIsPairingStep
        ? {
            title: t('machineClaimingPairing.steps.titleClaimMachine'),
            description: selectStepData === 1 && <MachineClaim />,
            icon: 2,
          }
        : {
            title: t('machineClaimingPairing.steps.titlePairMachine'),
            description: selectStepData === 1 && <MachinePair />,
            icon: 2,
          },
      {
        title: t('machineClaimingPairing.steps.titleSummary'),
        description: selectStepData === 2 && <MachineSummary />,
        icon: 3,
      },
    ],
    [selectIsPairingStep, selectStepData, t]
  );

  return (
    <StyledMachineClaimingPairing>
      <div className="machine-claiming-pairing__header">
        <div className="machine-claiming-pairing__header-content">
          <div className="machine-claiming-pairing__page-info">
            <h1 className="machine-claiming-pairing__title">{t('machineClaimingPairing.title')}</h1>
          </div>
        </div>
      </div>

      <div className=" machine-claiming-pairing__body">
        <div className="machine-claiming-pairing__body-content">
          <Steps current={selectStepData} direction="vertical" items={StepItems} />
        </div>
      </div>
      <MachineReclaimModal />
    </StyledMachineClaimingPairing>
  );
};
