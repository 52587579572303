import styled from 'styled-components';
import { AutoComplete } from 'lib/components/AutoComplete/AutoComplete';

export const StyledMaterialNumberAutoComplete = styled(AutoComplete)`
  .auto-complete__custom-input {
    .ant-input-prefix svg {
      width: 20px;
      height: 20px;
      stroke-width: 30px;
      stroke: ${(props): string => props.theme.colors.white};
    }

    .ant-input-suffix {
      margin-right: 15px;
    }

    input {
      font-size: ${(props): string => props.theme.fontSizes.formBody};
      line-height: 15px;
      &::placeholder {
        color: ${(props): string => props.theme.colors.textGray};
      }
    }
  }

  .auto-complete__custom-input-error {
    border-color: ${(props): string => props.theme.colors.errorExplainColor};

    .ant-input-prefix svg {
      color: ${(props): string => props.theme.colors.errorExplainColor};
    }

    :hover {
      border-color: ${(props): string => props.theme.colors.errorExplainColor};
    }
  }

  .ant-input-affix-wrapper-status-error {
    :hover {
      input {
        background-color: white;
      }
    }
  }

  .ant-select-clear {
    background: none;
  }
`;
