import React, { useEffect, useCallback, useState } from 'react';
import { Form, FormInstance, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userInviteSelectors } from '../../state/userInviteSelectors';
import { StyledUserInviteForm } from './UserInviteForm.styles';
import { UserInviteTooltip } from './UserInviteTooltip/UserInviteTooltip';
import { StyledUserInviteGlobalStyles } from './UserInviteTooltip/UserInviteTooltip.global.styles';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { InviteUserInput } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { supportedLanguages } from 'app/cross-cutting-concerns/translations/Translations';
import { CustomerSelectionForm } from 'app/components/CustomerSelectionForm/CustomerSelectionForm';
import { CustomerSearchFormValues } from 'app/modules/user-management/interfaces/Customer.types';
import { CustomerSearchActions } from 'app/modules/user-management/customer-search/state/customerSearchAction';
import { MachineDirectoryActions } from 'app/modules/machine-directory/state/machineDirectoryActions';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { customerModalSelector } from 'app/modules/customer-management/modals/state/customerModalsSelectors';
import { UserInformationForm } from 'app/components/UserInformationForm/UserInformationForm';
import { CustomerSelectedTable } from 'app/components/CustomerSelectedTable/CustomerSelectedTable';

const languageOptions = Object.entries(supportedLanguages).map(([key, values]) => ({
  value: key,
  content: values.name,
}));

interface UserInfoFormProps {
  formInstance: FormInstance<any>;
  handleCancel: () => void;
  handleOk: () => void;
  onFinish: (values: InviteUserInput) => void;
}

export const UserInfoForm = ({ formInstance, handleCancel, handleOk, onFinish }: UserInfoFormProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formCustomerSelection] = Form.useForm();
  const [customerSelectedId, setCustomerSelectedId] = useState<string>('');

  const isUserInviteLoading = useSelector(userInviteSelectors.selectIsUserInviteLoading);
  const isCustomerUserInviteLoading = useSelector(customerModalSelector.selectIsInviteUserModalLoading);

  const customerList = useSelector(userInviteSelectors.selectCustomerSearchList);

  useEffect(
    () => () => {
      dispatch(CustomerSearchActions.customerSearchClearList());
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(MachineDirectoryActions.getAlgsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (customerList.length > 0 && customerSelectedId !== '') {
      const isCustomerInList = customerList.some(customer => customer.customerId === customerSelectedId);
      if (isCustomerInList) {
        return;
      }
    }
    setCustomerSelectedId('');
  }, [customerList, customerSelectedId]);

  const handleSearch = (): void => {
    formCustomerSelection.submit();
    formInstance.setFieldsValue({ customerId: undefined });
  };

  const handleSubmitFormInstance = useCallback(() => {
    handleOk();
  }, [handleOk]);

  const onSearchFinish = useCallback(
    (values: CustomerSearchFormValues) => {
      dispatch(
        CustomerSearchActions.customerSearchRequest({
          algNumber: values.algNumber,
          customerName: values.customerNameOrNumber.trim(),
          customerNumber: values.customerNameOrNumber.trim(),
        })
      );
    },
    [dispatch]
  );

  const onSelectCustomer = (selectedRowKeys: React.Key[]): void => {
    formInstance.setFieldsValue({ customerId: selectedRowKeys[0] as string });
    setCustomerSelectedId(selectedRowKeys[0] as string);
  };

  return (
    <StyledUserInviteForm>
      <Form
        form={formInstance}
        name="invite-user"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        initialValues={{ language: languageOptions[3].value }}
      >
        <UserInformationForm />

        <CustomerSelectionForm
          formCustomerSelection={formCustomerSelection}
          handleSearch={handleSearch}
          onSearchFinish={onSearchFinish}
        />
        <Form.Item name="customerId" required rules={[{ required: true, message: t('customerList.rules.message') }]}>
          {customerList.length > 0 && (
            <CustomerSelectedTable customerList={customerList} onSelectCustomer={onSelectCustomer} />
          )}
        </Form.Item>
      </Form>

      <StyledUserInviteGlobalStyles />
      <div className="invite-user__buttons">
        <SecondaryButton size="m" key="back-button" className="invite-user__back-button" onClick={handleCancel}>
          {t('common.back')}
        </SecondaryButton>
        <div style={{ display: 'flex' }}>
          <PrimaryButton
            size="m"
            key="submit"
            className="invite-user__submit-button"
            type="primary"
            onClick={handleSubmitFormInstance}
            loading={!!isUserInviteLoading || !!isCustomerUserInviteLoading}
            disabled={!(customerSelectedId !== '')}
          >
            {t('userInvite.title')}
          </PrimaryButton>
          {!customerSelectedId && (
            <Tooltip title={UserInviteTooltip} overlayClassName="invite-user-tooltip-overlay" placement="topLeft">
              <div className="invite-user__info-icon">
                <SvgIcon name="info" />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
    </StyledUserInviteForm>
  );
};
