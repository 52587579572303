import styled from 'styled-components';

export const StyledMachineClaiming = styled.div`
  font-family: ${(props): string => props.theme.fonts.body};
  font-size: ${(props): string => props.theme.fontSizes.pageDescription};
  min-height: inherit;

  .machine-claiming__header {
    background-color: ${(props): string => props.theme.colors.lightGray};
    position: sticky;
    top: -94px; // height: 108px
    z-index: 97;
  }

  .machine-claiming__header-content {
    max-width: 1056px;
    margin: auto;
    padding: 38px 24px 15px;
  }

  .machine-claiming__body-content {
    max-width: 1056px;
    margin: 26px auto 0;
    padding: 0 24px;

    .ant-radio-group {
      width: 100%;
    }
  }

  .machine-claiming__title {
    font-size: ${(props): string => props.theme.fontSizes.pageTitle};
    color: ${(props): string => props.theme.colors.text};
    font-family: ${(props): string => props.theme.fonts.pageTitle};
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .machine-claiming__description {
    font-family: ${(props): string => props.theme.fonts.body};
    overflow: unset;
    margin-bottom: 0;
  }

  .machine-claiming__subheading {
    font-family: ${(props): string => props.theme.fonts.formSubheading};
    font-size: ${(props): string => props.theme.fontSizes.formSubheading};
    color: ${(props): string => props.theme.colors.text};
  }

  .ant-form-vertical .ant-form-item-label {
    padding: 0;
  }

  .machine-claiming__form-item {
    .ant-form-item-label > label {
      font-family: ${(props): string => props.theme.fonts.formLabel};
      font-size: ${(props): string => props.theme.fontSizes.formLabel};
      color: ${(props): string => props.theme.colors.text};
      text-transform: uppercase;
    }
  }

  .machine-claiming__select {
    width: 320px;
  }

  .machine-claiming__input {
    width: 234px;
  }

  .machine-claiming__button {
    text-transform: uppercase;
  }

  .machine-claiming__customer-list-selected-column {
    display: flex;
    justify-content: center;

    .ant-radio-wrapper {
      margin: 0;
    }
  }

  .machine-claiming__customer-list-table {
    width: 100%;
    margin-bottom: 40px;

    .ant-table-thead {
      > tr > th:last-child {
        text-align: center;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        cursor: pointer;
      }
    }

    .ant-table-footer {
      display: none;
    }
  }

  @media screen and (max-width: 1406px) {
    .machine-claiming__header-content,
    .machine-claiming__body-content {
      margin-left: 175px;
    }
  }
`;
