import styled from 'styled-components';
import { Checkbox } from 'antd';

export const StyledCheckbox = styled(Checkbox)`
  &.ant-checkbox-wrapper {
    align-items: center;
  }

  // Checkbox normal - unchecked
  .ant-checkbox {
    top: 0;

    .ant-checkbox-inner {
      border-color: unset;
      width: 20px;
      height: 20px;

      &:after {
        border: 1px solid ${(props): string => props.theme.colors.primary};
        border-top: 0;
        border-inline-start: 0;
        border-radius: 0;
        width: 6px;
        height: 11px;
        transform: rotate(45deg) translate(-56%, -56%);
      }
    }

    // Checkbox checked
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${(props): string => props.theme.colors.black};

        &:after {
          border-color: ${(props): string => props.theme.colors.primary};
        }
      }
    }

    // Indeterminate
    &.ant-checkbox-indeterminate {
      .ant-checkbox-inner {
        &:after {
          width: 10px;
          height: 10px;
          background-color: ${(props): string => props.theme.colors.black};
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale(1);
        }
      }
    }

    // Indeterminate
    &.ant-checkbox-indeterminate.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: ${(props): string => props.theme.colors.white};

        &:after {
          background-color: ${(props): string => props.theme.colors.white};
        }
      }
    }
  }
`;
