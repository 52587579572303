import React from 'react';
import { ModalProps as AntdModalProps } from 'antd';
import { StyledModal } from './Modal.styles';

interface ModalProps extends AntdModalProps {
  children?: React.ReactNode;
}

export const Modal: React.FC<ModalProps> = (props): JSX.Element => {
  const { children, className, ...rest } = props;
  const modalClassName = [className, 'modal'].join(' ');

  return (
    <StyledModal className={modalClassName} {...rest}>
      {children}
    </StyledModal>
  );
};
