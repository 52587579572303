import { singleton } from 'tsyringe';
import {
  ReassignUserToCustomerResponse,
  UserCreateResponse,
  UserDeleteResponse,
  UserDetailsResponse,
  UserInviteResponse,
  UserListAlgNumbersResponse,
  UserListResponse,
  UserResendInvitationResponse,
  UserUpdateResponse,
} from './interfaces/User.types';
import {
  UserClient,
  UserClientCreateOptions,
  UserClientListOptions,
  CustomerClientSearchOptions,
  UserClientInviteOptions,
  UserClientDeleteOptions,
  UserClientUpdateOptions,
  UserClientResendInvitationOptions,
  UserClientReassignOptions,
} from './UserClient';
import { CustomerSearchResponse } from './interfaces/Customer.types';
import { Optional } from 'lib/types/Optional';

export type UserServiceCreateOptions = UserClientCreateOptions;

export type CustomerServiceSearchOptions = CustomerClientSearchOptions;

export type UserListServiceOptions = UserClientListOptions;

export type UserServiceInviteOptions = UserClientInviteOptions;

export type UserServiceListOptions = UserClientListOptions;

export type UserServiceDeleteOptions = UserClientDeleteOptions;

export type UserServiceResendInvitationOptions = UserClientResendInvitationOptions;

export type UserServiceUpdateOptions = UserClientUpdateOptions;

export type UserServiceReassignOptions = UserClientReassignOptions;

@singleton()
export class UserService {
  constructor(private userClient: UserClient) {}

  public list = async ({
    filter,
    sortOptions,
    paginationOptions,
    search,
  }: UserServiceListOptions): Promise<UserListResponse> => {
    const { data } = await this.userClient.list({ filter, sortOptions, paginationOptions, search });
    return data;
  };

  public details = async (userId: string): Promise<Optional<UserDetailsResponse>> => {
    const { data } = await this.userClient.detail(userId);
    return data;
  };

  public listAlgs = async (): Promise<Optional<UserListAlgNumbersResponse>> => {
    const { data } = await this.userClient.listAlgs();

    return data;
  };

  public create = async ({
    title,
    firstName,
    lastName,
    phoneNumber,
    language,
    email,
    password,
  }: UserServiceCreateOptions): Promise<Optional<UserCreateResponse>> => {
    const { data } = await this.userClient.create({
      title,
      firstName,
      lastName,
      phoneNumber,
      language,
      email,
      password,
    });

    return data;
  };

  public customerSearch = async ({
    algNumber,
    customerName,
    customerNumber,
  }: CustomerServiceSearchOptions): Promise<CustomerSearchResponse> => {
    const { data } = await this.userClient.searchCustomer({ algNumber, customerName, customerNumber });
    return data;
  };

  public invite = async ({
    title,
    firstName,
    lastName,
    email,
    phoneNumber,
    customerId,
    language,
  }: UserServiceInviteOptions): Promise<Optional<UserInviteResponse>> => {
    const { data, errors } = await this.userClient.invite({
      title,
      firstName,
      lastName,
      email,
      phoneNumber,
      customerId,
      language,
    });
    if (errors && errors.length > 0) {
      throw new Error(errors?.[0]?.message);
    }
    return data;
  };

  public delete = async ({ input }: UserServiceDeleteOptions): Promise<Optional<UserDeleteResponse>> => {
    const { data, errors } = await this.userClient.delete({ input });
    if (errors && errors.length > 0) {
      throw new Error(errors?.[0]?.message);
    }
    return data;
  };

  public resend = async ({
    input,
  }: UserServiceResendInvitationOptions): Promise<Optional<UserResendInvitationResponse>> => {
    const { data } = await this.userClient.resend({ input });
    return data;
  };

  public update = async ({ input }: UserServiceUpdateOptions): Promise<Optional<UserUpdateResponse>> => {
    const { data } = await this.userClient.update({ input });
    return data;
  };

  public reassign = async ({
    input,
  }: UserServiceReassignOptions): Promise<Optional<ReassignUserToCustomerResponse>> => {
    const { data, errors } = await this.userClient.reassign({ input });
    if (errors && errors.length > 0) {
      throw new Error(errors?.[0]?.message);
    }
    return data;
  };
}
