import { Menu } from 'antd';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { useEffect, useState } from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { first } from 'lodash-es';
import { useDependencies } from '../../../cross-cutting-concerns/dependency-injection/useDependencies';
import { StyledAppSider } from './AppSider.styles';

export const AppSider = (): JSX.Element => {
  const { t } = useTranslation();
  const routeMatch = useMatch('/:path/*');
  const { menuItemManager } = useDependencies();
  const [menuItems, setMenuItems] = useState<ItemType[]>([]);

  useEffect(() => {
    const getMenuItemsAsync = async (): Promise<void> => {
      const items = await menuItemManager.getMenuItems();
      const preparedItems = items.map(item => ({
        label: <NavLink to={item.path}>{t(item.translationKey)}</NavLink>,
        key: item.path,
        icon: item.icon,
      }));

      setMenuItems(preparedItems);
    };
    getMenuItemsAsync();
  }, [menuItemManager, t]);

  const firstMenuItem = first(menuItems);

  const getPathName = (): string => {
    if (routeMatch?.pathname.includes('machines/claim-pair')) {
      return routeMatch?.pathname as string;
    }
    return routeMatch?.pathnameBase as string;
  };

  return (
    <StyledAppSider width={175}>
      <h1 className="header__product-name">{t('sider.productName')}</h1>
      <Menu selectedKeys={[getPathName() || (String(firstMenuItem?.key) ?? '')]} items={menuItems} />
    </StyledAppSider>
  );
};
