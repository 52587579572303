import styled from 'styled-components';
import { Layout } from 'antd';

export const StyledLayout = styled(Layout)`
  height: 100vh;

  .ant-layout-content {
    background-color: ${(props): string => props.theme.colors.white};
    overflow: auto;
  }

  .outlet-wrapper {
    min-height: calc(100vh - 304px);
    margin: auto;
  }
`;
