import { singleton } from 'tsyringe';
import { Optional } from '../../../lib/types/Optional';
import { QueryValidateCreateArgs } from '../../cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { UserClientReassignOptions } from '../user-management/UserClient';
import { UserServiceDeleteOptions, UserServiceReassignOptions } from '../user-management/UserService';
import { ReassignUserToCustomerResponse, UserDeleteResponse } from '../user-management/interfaces/User.types';
import { MachineServiceReclaimOptions, MachineServiceUnclaimOptions } from '../machine-directory/MachineService';
import { MachineClaimResponse, MachineUnclaimResponse } from '../machine-directory/interfaces/Machine.types';
import {
  CustomerListAlgNumbersResponse,
  CustomerListResponse,
  CustomerCreateResponse,
  CustomerValidateCreateResponse,
  CustomerGetResponse,
  CustomerUpdateResponse,
  CustomerDeleteResponse,
} from './interfaces/Customer.types';
import {
  CustomerClient,
  CustomerClientCreateOptions,
  CustomerClientDeleteOptions,
  CustomerClientGetOptions,
  CustomerClientListOptions,
  CustomerClientUpdateOptions,
} from './CustomerClient';

export type CustomerServiceListOptions = CustomerClientListOptions;
export type CustomerServiceCreateOptions = CustomerClientCreateOptions;
export type CustomerServiceGetOptions = CustomerClientGetOptions;
export type CustomerServiceUpdateOptions = CustomerClientUpdateOptions;
export type CustomerServiceDeleteOptions = CustomerClientDeleteOptions;
export type CustomerServiceValidateOptions = QueryValidateCreateArgs;
export type UserServiceReassignUsersOptions = UserClientReassignOptions;

@singleton()
export class CustomerService {
  constructor(private customerClient: CustomerClient) {}

  public list = async ({
    filter,
    sortOptions,
    paginationOptions,
    search,
  }: CustomerServiceListOptions): Promise<CustomerListResponse> => {
    const { data } = await this.customerClient.list({ filter, sortOptions, paginationOptions, search });

    return data;
  };

  public listAlgs = async (): Promise<Optional<CustomerListAlgNumbersResponse>> => {
    const { data } = await this.customerClient.listAlgs();

    return data;
  };

  public create = async (input: CustomerServiceCreateOptions): Promise<Optional<CustomerCreateResponse>> => {
    const { data } = await this.customerClient.create(input);

    return data;
  };

  public validateCreate = async (
    input: CustomerServiceValidateOptions
  ): Promise<Optional<CustomerValidateCreateResponse>> => {
    const { data } = await this.customerClient.validateCreate(input);

    return data;
  };

  public get = async ({ id }: CustomerServiceGetOptions): Promise<Optional<CustomerGetResponse>> => {
    const { data } = await this.customerClient.get({ id });

    return data;
  };

  public update = async ({
    alg,
    city,
    customerId,
    customerName,
    district,
    houseNumber,
    region,
    sapNumber,
    street,
    zipCode,
  }: CustomerServiceUpdateOptions): Promise<Optional<CustomerUpdateResponse>> => {
    const { data } = await this.customerClient.update({
      alg,
      city,
      customerId,
      customerName,
      district,
      houseNumber,
      region,
      sapNumber,
      street,
      zipCode,
    });

    return data;
  };

  public delete = async ({ customerId }: CustomerServiceDeleteOptions): Promise<Optional<CustomerDeleteResponse>> => {
    const { data } = await this.customerClient.delete({ customerId });

    return data;
  };

  public reassignUser = async ({
    input,
  }: UserServiceReassignOptions): Promise<Optional<ReassignUserToCustomerResponse>> => {
    const { data, errors } = await this.customerClient.reassignUser({
      input,
    });
    if (errors && errors.length > 0) {
      throw new Error(errors?.[0]?.message);
    }
    return data;
  };

  public deleteUsers = async ({ input }: UserServiceDeleteOptions): Promise<Optional<UserDeleteResponse>> => {
    const { data, errors } = await this.customerClient.deleteUser({ input });
    if (errors && errors.length > 0) {
      throw new Error(errors?.[0]?.message);
    }
    return data;
  };

  public reClaim = async ({ input }: MachineServiceReclaimOptions): Promise<Optional<MachineClaimResponse>> => {
    const { data, errors } = await this.customerClient.reClaim({
      input,
    });

    if (errors && errors.length > 0) {
      throw new Error(errors?.[0]?.message);
    }
    return data;
  };

  public unClaim = async ({ input }: MachineServiceUnclaimOptions): Promise<Optional<MachineUnclaimResponse>> => {
    const { data, errors } = await this.customerClient.unClaim({
      input,
    });

    if (errors && errors.length > 0) {
      throw new Error(errors?.[0]?.message);
    }

    return data;
  };
}
