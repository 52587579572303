import { Action } from 'redux';

export interface IShowDeleteUserModalAction extends Action<typeof UserModalsActions.SHOW_DELETE_USER_MODAL> {
  payload: {
    userId: string;
  };
}
export type IHideDeleteUserModalAction = Action<typeof UserModalsActions.HIDE_DELETE_USER_MODAL>;

export type IShowEditUserModalAction = Action<typeof UserModalsActions.SHOW_EDIT_USER_MODAL>;
export type IHideEditUserModalAction = Action<typeof UserModalsActions.HIDE_EDIT_USER_MODAL>;

export type IShowResendInvitationModalAction = Action<typeof UserModalsActions.SHOW_RESEND_INVITATION_MODAL>;
export type IHideResendInvitationModalAction = Action<typeof UserModalsActions.HIDE_RESEND_INVITATION_MODAL>;

export type IIsDeleteUserModalLoadingAction = Action<typeof UserModalsActions.IS_DELETE_USER_MODAL_LOADING>;
export type IIsDeleteUserModalNotLoadingAction = Action<typeof UserModalsActions.IS_DELETE_USER_MODAL_NOT_LOADING>;

export type IIsEditUserModalLoadingAction = Action<typeof UserModalsActions.IS_EDIT_USER_MODAL_LOADING>;
export type IIsEditUserModalNotLoadingAction = Action<typeof UserModalsActions.IS_EDIT_USER_MODAL_NOT_LOADING>;

export type IIsResendInvitationModalLoadingAction = Action<
  typeof UserModalsActions.IS_RESEND_INVITATION_USER_MODAL_LOADING
>;
export type IIsResendInvitationModalNotLoadingAction = Action<
  typeof UserModalsActions.IS_RESEND_INVITATION_USER_MODAL_NOT_LOADING
>;

export interface IShowChangeCustomerModalAction extends Action<typeof UserModalsActions.SHOW_CHANGE_CUSTOMER_MODAL> {
  payload: {
    userId: string;
  };
}
export type IHideChangeCustomerModalAction = Action<typeof UserModalsActions.HIDE_CHANGE_CUSTOMER_MODAL>;
export type IIsChangeCustomerModalLoadingAction = Action<typeof UserModalsActions.IS_CHANGE_CUSTOMER_MODAL_LOADING>;
export type IIsChangeCustomerModalNotLoadingAction = Action<
  typeof UserModalsActions.IS_CHANGE_CUSTOMER_MODAL_NOT_LOADING
>;

export class UserModalsActions {
  public static SHOW_DELETE_USER_MODAL = 'USER_MODALS::SHOW_DELETE_USER_MODAL';
  public static HIDE_DELETE_USER_MODAL = 'USER_MODALS::HIDE_DELETE_USER_MODAL';

  public static SHOW_EDIT_USER_MODAL = 'USER_MODALS::SHOW_EDIT_USER_MODAL';
  public static HIDE_EDIT_USER_MODAL = 'USER_MODALS::HIDE_EDIT_USER_MODAL';

  public static SHOW_RESEND_INVITATION_MODAL = 'USER_MODALS::SHOW_RESEND_INVITATION_MODAL';
  public static HIDE_RESEND_INVITATION_MODAL = 'USER_MODALS::HIDE_RESEND_INVITATION_MODAL';

  public static IS_DELETE_USER_MODAL_LOADING = 'USER_MODALS::IS_DELETE_USER_MODAL_LOADING';
  public static IS_DELETE_USER_MODAL_NOT_LOADING = 'USER_MODALS::IS_DELETE_USER_MODAL_NOT_LOADING';

  public static IS_EDIT_USER_MODAL_LOADING = 'USER_MODALS::IS_EDIT_USER_MODAL_LOADING';
  public static IS_EDIT_USER_MODAL_NOT_LOADING = 'USER_MODALS::IS_EDIT_USER_MODAL_NOT_LOADING';

  public static IS_RESEND_INVITATION_USER_MODAL_LOADING = 'USER_MODALS::IS_RESEND_INVITATION_USER_MODAL_LOADING';
  public static IS_RESEND_INVITATION_USER_MODAL_NOT_LOADING =
    'USER_MODALS::IS_RESEND_INVITATION_USER_MODAL_NOT_LOADING';

  public static SHOW_CHANGE_CUSTOMER_MODAL = 'USER_MODALS::SHOW_CHANGE_CUSTOMER_MODAL';
  public static HIDE_CHANGE_CUSTOMER_MODAL = 'USER_MODALS::HIDE_CHANGE_CUSTOMER_MODAL';
  public static IS_CHANGE_CUSTOMER_MODAL_LOADING = 'USER_MODALS::IS_CHANGE_CUSTOMER_MODAL_LOADING';
  public static IS_CHANGE_CUSTOMER_MODAL_NOT_LOADING = 'USER_MODALS::IS_CHANGE_CUSTOMER_MODAL_NOT_LOADING';

  public static showDeleteUserModal({ userId }: { userId: string }): IShowDeleteUserModalAction {
    return {
      type: UserModalsActions.SHOW_DELETE_USER_MODAL,
      payload: {
        userId,
      },
    };
  }

  public static hideDeleteUserModal(): IHideDeleteUserModalAction {
    return {
      type: UserModalsActions.HIDE_DELETE_USER_MODAL,
    };
  }

  public static showEditUserModal(): IShowEditUserModalAction {
    return {
      type: UserModalsActions.SHOW_EDIT_USER_MODAL,
    };
  }

  public static hideEditUserModal(): IHideEditUserModalAction {
    return {
      type: UserModalsActions.HIDE_EDIT_USER_MODAL,
    };
  }

  public static showResendInvitationModal(): IShowResendInvitationModalAction {
    return {
      type: UserModalsActions.SHOW_RESEND_INVITATION_MODAL,
    };
  }

  public static hideResendInvitationModal(): IHideResendInvitationModalAction {
    return {
      type: UserModalsActions.HIDE_RESEND_INVITATION_MODAL,
    };
  }

  public static isDeleteUserModalLoading(): IIsDeleteUserModalLoadingAction {
    return {
      type: UserModalsActions.IS_DELETE_USER_MODAL_LOADING,
    };
  }

  public static isDeleteUserModalNotLoading(): IIsDeleteUserModalNotLoadingAction {
    return {
      type: UserModalsActions.IS_DELETE_USER_MODAL_NOT_LOADING,
    };
  }

  public static isEditUserModalLoading(): IIsEditUserModalLoadingAction {
    return {
      type: UserModalsActions.IS_EDIT_USER_MODAL_LOADING,
    };
  }

  public static isEditUserModalNotLoading(): IIsEditUserModalNotLoadingAction {
    return {
      type: UserModalsActions.IS_EDIT_USER_MODAL_NOT_LOADING,
    };
  }

  public static setResendInvitationModalLoading(): IIsResendInvitationModalLoadingAction {
    return {
      type: UserModalsActions.IS_RESEND_INVITATION_USER_MODAL_LOADING,
    };
  }

  public static setResendInvitationModalNotLoading(): IIsResendInvitationModalNotLoadingAction {
    return {
      type: UserModalsActions.IS_RESEND_INVITATION_USER_MODAL_NOT_LOADING,
    };
  }

  public static showChangeCustomerModal({ userId }: { userId: string }): IShowChangeCustomerModalAction {
    return {
      type: UserModalsActions.SHOW_CHANGE_CUSTOMER_MODAL,
      payload: {
        userId,
      },
    };
  }

  public static hideChangeCustomerModal(): IHideChangeCustomerModalAction {
    return {
      type: UserModalsActions.HIDE_CHANGE_CUSTOMER_MODAL,
    };
  }

  public static isChangeCustomerModalLoading(): IIsChangeCustomerModalLoadingAction {
    return {
      type: UserModalsActions.IS_CHANGE_CUSTOMER_MODAL_LOADING,
    };
  }

  public static isChangeCustomerModalNotLoading(): IIsChangeCustomerModalNotLoadingAction {
    return {
      type: UserModalsActions.IS_CHANGE_CUSTOMER_MODAL_NOT_LOADING,
    };
  }
}
