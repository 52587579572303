import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { debounce } from 'lodash-es';
import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { MachineClaimPairSelectors } from '../../state/machineClaimPairSelectors';
import { MachineClaimPairActions } from '../../state/machineClaimPairActions';
import { MaterialNumberAutoCompleteGlobalStyles } from './MaterialNumberAutoComplete.global.styles';
import { StyledMaterialNumberAutoComplete } from './MaterialNumberAutoComplete.styles';
import { AutoCompleteInput } from 'lib/components/AutoCompleteInput/AutoCompleteInput';
import { AutoCompleteProps } from 'lib/components/AutoComplete/AutoComplete';
import { PAGE_SIZE_OPTIONS } from 'config/constants';

export type MaterialNumberAutoCompleteProps = AutoCompleteProps & {
  placeholder?: string;
  classifications: string[];
  isNonKaercherDevice?: boolean;
};

export const MaterialNumberAutoComplete = (props: MaterialNumberAutoCompleteProps): JSX.Element => {
  const { className = '', placeholder, classifications, isNonKaercherDevice, onChange, value, ...rest } = props;
  const materialNumberAutocompleteClassName = classnames('material-number-auto-complete', className);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isMaterialNumberValid, setIsMaterialNumberValid] = useState('');

  const selectIsSearchMaterialNumberLoading = useSelector(MachineClaimPairSelectors.selectSearchMaterialNumberLoading);
  const selectSearchMaterialNumberData = useSelector(MachineClaimPairSelectors.selectMaterialNumberData);

  const calculatedAutoCompleteOptions = useMemo(
    () =>
      selectSearchMaterialNumberData?.map(({ materialNumber }, i) => ({
        key: `${i}-${materialNumber}`,
        value: materialNumber,
        label: <span>{materialNumber}</span>,
      })),
    [selectSearchMaterialNumberData]
  );

  const handleSelectChange = (searchValue: string): void => {
    if (onChange) {
      onChange(searchValue, { value: searchValue, label: searchValue });
      setIsMaterialNumberValid(searchValue);
    }
  };

  const handleSearchInput = debounce((e: React.ChangeEvent<HTMLInputElement>): void => {
    const searchText = e.target.value.trim();

    // Fetch new one
    dispatch(
      MachineClaimPairActions.searchMaterialNumberRequest({
        search: searchText,
        filter: {
          classifications,
          isNonKaercherDevice,
        },
        paginationOptions: {
          limit: PAGE_SIZE_OPTIONS[0].value,
        },
      })
    );
  }, 1000);

  const handleValidateMaterialNumber = useMemo((): boolean => {
    if (!isMaterialNumberValid) {
      return false;
    }

    const isMaterialNumberFound = selectSearchMaterialNumberData?.some(
      materialNumberInfo => materialNumberInfo?.materialNumber === isMaterialNumberValid
    );

    if (!isMaterialNumberFound && selectSearchMaterialNumberData) {
      return true;
    }

    return false;
  }, [selectSearchMaterialNumberData, isMaterialNumberValid]);

  return (
    <>
      <MaterialNumberAutoCompleteGlobalStyles />
      <StyledMaterialNumberAutoComplete
        {...rest}
        allowClear
        defaultValue={null}
        options={calculatedAutoCompleteOptions}
        notFoundContent={
          selectSearchMaterialNumberData?.length === 0 && t('machineClaiming.materialNumberSearch.notFound')
        }
        className={materialNumberAutocompleteClassName}
        onSelect={handleSelectChange}
        onChange={handleSelectChange}
      >
        <AutoCompleteInput
          placeholder={placeholder}
          className={`auto-complete__custom-input ${
            handleValidateMaterialNumber && 'auto-complete__custom-input-error'
          }`}
          prefix={<SearchOutlined className="auto-complete__search-icon" />}
          suffix={selectIsSearchMaterialNumberLoading && <LoadingOutlined />}
          disabled={true}
          onChange={handleSearchInput}
        />
      </StyledMaterialNumberAutoComplete>
      {handleValidateMaterialNumber && (
        <p className="auto-complete__invalid-message">
          {t('machineClaiming.materialNumberSearch.invalidMaterialNumber')}
        </p>
      )}
    </>
  );
};
