import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { StyledUserInformationForm } from './UserInformationForm.styles';
import { Select } from 'lib/components/Select/Select';
import { Input } from 'lib/components/Input/Input';
import { RegisterUserTitleEnum } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { supportedLanguages } from 'app/cross-cutting-concerns/translations/Translations';

const { Option } = Select;

export const CONSTANTS = {
  salutations: [
    { value: RegisterUserTitleEnum.Mr, content: 'userInvite.form.salutations.mr' },
    { value: RegisterUserTitleEnum.Mrs, content: 'userInvite.form.salutations.mrs' },
  ],
};

const languageOptions = Object.entries(supportedLanguages).map(([key, values]) => ({
  value: key,
  content: values.name,
}));

export const UserInformationForm = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledUserInformationForm>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name="title"
            label={t('userInvite.form.salutation')}
            className="invite-user__input-group"
            rules={[{ required: true, message: t('userInvite.form.errors.salutationRequired') }]}
          >
            <Select className="invite-user-modal__select" placeholder={t('userInvite.form.placeholderNull')} showArrow>
              {CONSTANTS.salutations.map(({ value, content }) => (
                <Option key={value} value={value}>
                  {t(content)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="phoneNumber"
            label={t('userInvite.form.phoneNumber')}
            className="invite-user__input-group"
            rules={[
              {
                pattern: /([+]([0-9]{2})|[0-9])([0-9]{9,})/g,
                message: t('userInvite.form.errors.phoneNumberInvalid'),
              },
              {
                max: 25,
                message: t('userInvite.form.errors.phoneNumberInvalid'),
              },
            ]}
          >
            <Input className="invite-user__input" placeholder={t('userInvite.form.placeholderPhoneNumber')} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name="firstName"
            label={t('userInvite.form.firstName')}
            className="invite-user__input-group"
            required
            rules={[
              {
                required: true,
                message: t('userInvite.form.errors.userFirstNameRequired'),
              },
              {
                min: 2,
                message: t('userInvite.form.errors.userFirstNameMinLength'),
              },
              {
                max: 35,
                message: t('userInvite.form.errors.userFirstNameMaxLength'),
              },
            ]}
          >
            <Input className="invite-user__input" placeholder={t('userInvite.form.placeholderFirstName')} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lastName"
            label={t('userInvite.form.lastName')}
            className="invite-user__input-group"
            required
            rules={[
              { required: true, message: t('userInvite.form.errors.userLastNameRequired') },
              {
                min: 2,
                message: t('userInvite.form.errors.userLastNameMinLength'),
              },
              {
                max: 35,
                message: t('userInvite.form.errors.userLastNameMaxLength'),
              },
            ]}
          >
            <Input className="invite-user__input" placeholder={t('userInvite.form.placeholderLastName')} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="email"
        label={t('userInvite.form.email')}
        className="invite-user__input-group"
        required
        rules={[
          { type: 'email', message: t('userInvite.form.errors.emailInvalid') },
          { required: true, message: t('userInvite.form.errors.emailRequired') },
        ]}
      >
        <Input className="invite-user__input" placeholder={t('userInvite.form.placeholderEmail')} />
      </Form.Item>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name="language" label={t('userInvite.form.language')} className="invite-user__input-group">
            <Select className="invite-user-modal__select" showArrow>
              {languageOptions.map(({ value, content }) => (
                <Option value={value} key={value}>
                  {t(content)}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </StyledUserInformationForm>
  );
};
