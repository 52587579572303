import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TableRowSelection } from 'antd/lib/table/interface';
import { getMachineSelectedColumns } from './columns/MachineSelected.columns';
import { StyledMachineUnclaimModal } from './MachineSelectedTable.styles';
import { Table } from 'lib/components/Table/Table';
import { MachineOwnership } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export const MachineSelectedTable = ({
  selectedMachinesModal,
  selectedMachinesId,
  onSelectChangeModal,
  noSelectedColumn,
}: {
  selectedMachinesModal: MachineOwnership[];
  selectedMachinesId: React.Key[];
  onSelectChangeModal?: (selectedRowKeys: React.Key[]) => void;
  noSelectedColumn?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();

  const tableColumns = getMachineSelectedColumns({
    t,
  });

  const rowSelection = useMemo((): TableRowSelection<MachineOwnership> | undefined => {
    if (noSelectedColumn) {
      return undefined;
    }
    return {
      columnTitle: t('machineList.table.selected'),
      onChange: onSelectChangeModal,
      selectedRowKeys: selectedMachinesId,
      type: 'radio',
    };
  }, [noSelectedColumn, onSelectChangeModal, selectedMachinesId, t]);

  return (
    <StyledMachineUnclaimModal>
      <div className="machine-selection__header">
        <div className="machine-selection__header-content">
          <h3 className="machine-selection__subTitle">{t('machineReclaiming.table.subTitle')}</h3>
        </div>
      </div>
      <div className="machine-selection__body">
        <div className="machine-selection__body-content">
          <Table
            className="machine-selection__table"
            loading={false}
            dataSource={selectedMachinesModal}
            rowKey="id"
            columns={tableColumns}
            rowSelection={rowSelection}
          />
        </div>
      </div>
    </StyledMachineUnclaimModal>
  );
};
