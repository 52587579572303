import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { UsersStatusTag } from '../UsersStatusTag/UsersStatusTag';
import { User } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { SortableColumnTitle } from 'lib/components/Table/SortableColumnTitle/SortableColumnTitle';
import { convertCodeToLanguage } from 'app/modules/user-management/utils/convertCodeToLanguage';
import { AvatarInitials } from 'lib/components/AvatarInitials/AvatarInitials';
import { getInitialNames } from 'app/utils/getInitialNames';

interface GetCustomerDetailsUserColumnsOptions {
  t: TFunction;
  isSortingAndFilteringEnabled: boolean;
}

export const getCustomerDetailsUsersColumns = ({
  t,
  isSortingAndFilteringEnabled,
}: GetCustomerDetailsUserColumnsOptions): ColumnsType<User> => [
  {
    dataIndex: 'fullName',
    key: 'fullName',
    width: '8%',
    render: (fullName: string): JSX.Element => (
      <div className="machine-list__avatar-column">
        <AvatarInitials initialNames={getInitialNames(fullName)} />,
      </div>
    ),
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<User>(t('userList.table.name'), isSortingAndFilteringEnabled, 'fullName', sortColumns),
    dataIndex: 'fullName',
    key: 'fullName',
    width: '20%',
    sorter: isSortingAndFilteringEnabled,
    ellipsis: true,
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<User>(t('userList.table.email'), isSortingAndFilteringEnabled, 'email', sortColumns),
    dataIndex: 'email',
    key: 'email',
    width: '25%',
    sorter: isSortingAndFilteringEnabled,
    ellipsis: true,
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<User>(
        t('userList.table.phoneNumber'),
        isSortingAndFilteringEnabled,
        'phoneNumber',
        sortColumns
      ),
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    width: '17%',
    sorter: isSortingAndFilteringEnabled,
    ellipsis: true,
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<User>(t('userList.table.language'), isSortingAndFilteringEnabled, 'language', sortColumns),
    dataIndex: 'language',
    key: 'language',
    width: '15%',
    sorter: isSortingAndFilteringEnabled,
    ellipsis: true,
    render: (language: string): JSX.Element => <>{convertCodeToLanguage(language, t)}</>,
  },
  {
    title: ({ sortColumns }): ReactNode =>
      SortableColumnTitle<User>(t('userList.table.status'), isSortingAndFilteringEnabled, 'status', sortColumns),
    dataIndex: 'status',
    key: 'status',
    width: '15%',
    sorter: isSortingAndFilteringEnabled,
    ellipsis: true,
    render: (status: string): JSX.Element => <UsersStatusTag status={status} />,
  },
];
