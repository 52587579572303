import React from 'react';
import { singleton } from 'tsyringe';
import { RoutePaths } from '../../../config/route-paths';
import { AppLayout } from '../../components/app-layout/AppLayout/AppLayout';
import { AuthorizationGuard } from '../authentication/components/AuthorizationGuard/AuthorizationGuard';
import { AuthenticationService } from '../authentication/AuthenticationService';
import { IRoute } from './interfaces/Routing.types';
import { privateRoutes as unprotectedPrivateRoutes, publicRoutes } from 'config/routes';

@singleton()
export class Routing {
  constructor(private authenticationService: AuthenticationService) {}

  public getAllRoutesWithAppLayout(): IRoute[] {
    return [
      {
        path: RoutePaths.ROOT,
        element: <AppLayout />,
        children: this.getProtectedPrivateRoutes(),
        permissions: [],
      },
      ...this.getPublicRoutes(),
    ];
  }

  public getAllRoutes(): IRoute[] {
    const privateRoutes = this.getPrivateRoutes();

    return [...privateRoutes, ...this.getPublicRoutes()];
  }

  public async getAllRoutesFilteredByPermission(): Promise<IRoute[]> {
    const privateRoutes = this.getPrivateRoutes();

    const userPermissions = await this.authenticationService.getPermissions();

    const filteredPrivateRoutes = privateRoutes.filter(route => {
      if (route.permissions.length <= 0) {
        return true;
      }

      return route.permissions.some(routePermission => userPermissions.includes(routePermission));
    });

    return [...filteredPrivateRoutes, ...this.getPublicRoutes()];
  }

  public getPublicRoutes(): IRoute[] {
    return publicRoutes;
  }

  public getPrivateRoutes(): IRoute[] {
    const privateRoutes: IRoute[] = unprotectedPrivateRoutes;

    return privateRoutes;
  }

  public getProtectedPrivateRoutes(): IRoute[] {
    const privateRoutes: IRoute[] = this.getPrivateRoutes();

    return privateRoutes.map(route => ({
      ...route,
      element: <AuthorizationGuard route={route}>{route.element}</AuthorizationGuard>,
    }));
  }
}
