import { createSlice } from '@reduxjs/toolkit';
import {
  CustomerManagementEntity,
  SortOrders,
} from '../../../../cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import * as customerListActionTypes from './customerListActions.types';
import { Optional } from 'lib/types/Optional';
import { PaginationTokens } from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { DEFAULT_PAGE_SIZE_VALUE, DEFAULT_PAGE_VALUE } from 'config/constants';

export interface CustomerListState {
  data: CustomerManagementEntity[];
  totalCount: Optional<number>;
  isLoading: Optional<boolean>;
  paginationTokens: PaginationTokens;
  page: number;
  pageSize: number;
  searchText: Optional<string>;
  filters: {
    available: {
      algs: string[];
    };
    active: {
      alg: Optional<string>;
    };
    isLoading: boolean;
  };
  sortOptions: {
    field: Optional<string>;
    order: Optional<SortOrders>;
  };
}

export const initialState: CustomerListState = {
  data: [],
  totalCount: null,
  isLoading: null,
  paginationTokens: {},
  page: DEFAULT_PAGE_VALUE,
  pageSize: DEFAULT_PAGE_SIZE_VALUE,
  searchText: null,
  filters: {
    available: {
      algs: [],
    },
    active: {
      alg: null,
    },
    isLoading: false,
  },
  sortOptions: {
    field: null,
    order: null,
  },
};

const customerListSlice = createSlice({
  name: 'customer-list',
  initialState,
  reducers: {
    // customer list
    customerListRequest: (state, _action: customerListActionTypes.CustomerListRequestAction) => {
      state.isLoading = true;
      return state;
    },
    customerListSuccess: (state, action: customerListActionTypes.CustomerListSuccessAction) => {
      state.data = (action.payload?.searchCustomers.data as CustomerManagementEntity[]) || [];
      state.totalCount = action.payload?.searchCustomers.metadata?.totalCount || null;

      if (action.payload?.searchCustomers.metadata?.paginationToken) {
        state.paginationTokens[state.page + 1] = action.payload?.searchCustomers.metadata?.paginationToken;
      }

      state.isLoading = false;
      return state;
    },
    customerListError: (state, _action: customerListActionTypes.CustomerListErrorAction) => {
      state.isLoading = false;
      return state;
    },

    // list algs
    customerListAlgsRequest: (state, _action: customerListActionTypes.CustomerListAlgsRequestAction) => {
      state.filters.isLoading = true;
      return state;
    },
    customerListAlgsSuccess: (state, action: customerListActionTypes.CustomerListAlgsSuccessAction) => {
      state.filters.available.algs = (action.payload?.listAlgNumber.data as string[]) || [];
      state.filters.isLoading = false;
      return state;
    },
    customerListAlgsError: (state, _action: customerListActionTypes.CustomerListAlgsErrorAction) => {
      state.filters.isLoading = false;
      return state;
    },

    setAlgFilter: (state, action: customerListActionTypes.CustomerListSetFiltersAction) => {
      state.filters.active.alg = action.payload;
      state.page = DEFAULT_PAGE_VALUE;
      state.paginationTokens = {};
      return state;
    },

    //
    setActiveSearchText: (state, action: customerListActionTypes.CustomerListSetActiveSearchTextAction) => {
      state.searchText = action.payload;
      state.page = DEFAULT_PAGE_VALUE;
      state.paginationTokens = {};
      return state;
    },

    resetState: (_state, _action: customerListActionTypes.CustomerListResetStateAction) => initialState,

    setChangeTablePage: (state, action: customerListActionTypes.CustomerListChangeTablePageAction) => {
      state.page = action.payload;
      return state;
    },

    setChangeTablePageSize: (state, action: customerListActionTypes.CustomerListChangeTablePageSizeAction) => {
      state.pageSize = action.payload;
      state.page = DEFAULT_PAGE_VALUE;
      state.paginationTokens = {};

      return state;
    },

    setChangeSortOrder: (state, action: customerListActionTypes.CustomerListChangeSortOrderAction) => {
      state.page = DEFAULT_PAGE_VALUE;
      state.sortOptions.order = action.payload as SortOrders;
      state.paginationTokens = {};
      return state;
    },

    setChangeSortField: (state, action: customerListActionTypes.CustomerListChangeSortFieldAction) => {
      state.page = DEFAULT_PAGE_VALUE;
      state.sortOptions.field = action.payload;
      state.paginationTokens = {};
      return state;
    },

    customerListPollRequest: (state, _action: customerListActionTypes.CustomerListPollRequestAction) => {
      state.isLoading = true;
      return state;
    },
  },
});

export const CustomerListActions = {
  ...customerListSlice.actions,
};

export const customerListReducer = customerListSlice.reducer;
