import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { customerModalSelector } from '../../state/customerModalsSelectors';
import { customerModalsActions } from '../../state/customerModalsSlice';
import { StyledCustomerUnclaimMachineModal } from './CustomerUnclaimMachineModal.styles';
import { MachineSelectedTable } from 'app/components/MachineSelectedTable/MachineSelectedTable';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { MachineOwnership } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { IUnclaimMachineRequestOptions } from 'app/modules/machine-directory/state/machineDirectoryActions';

export interface MachineUnclaimingModalProps {
  selectedMachines: MachineOwnership[];
}

export const CustomerUnclaimMachineModal = ({ selectedMachines }: MachineUnclaimingModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedMachinesModal, setSelectedMachinesModal] = useState<MachineOwnership[]>([]);
  const [selectedMachinesId, setSelectedMachinesId] = useState<React.Key[]>([]);

  const isOpen = useSelector(customerModalSelector.selectIsUnclaimMachineModalOpen) || false;
  const isUnclaimMachineLoading = useSelector(customerModalSelector.selectIsUnclaimMachineModalLoading) || false;

  useEffect(() => {
    if (isOpen) {
      setSelectedMachinesModal(selectedMachines);
      setSelectedMachinesId(selectedMachines.map(machine => machine.id) as React.Key[]);
    }
  }, [selectedMachines, isOpen]);

  const handleCancel = useCallback(() => dispatch(customerModalsActions.closeUnclaimMachineModal()), [dispatch]);
  const handleUnclaim = useCallback(() => {
    const requestOptions: IUnclaimMachineRequestOptions = {
      input: {
        materialNumber: selectedMachines[0].materialNumber as string,
        serialNumber: selectedMachines[0].serialNumber as string,
      },
    };

    dispatch(customerModalsActions.unclaimMachineModalRequest(requestOptions));
  }, [dispatch, selectedMachines]);

  const onSelectChangeModal = (selectedRowKeys: React.Key[]): void => {
    if (selectedRowKeys.length > 0) {
      setSelectedMachinesId(selectedRowKeys);
    }
  };

  return (
    <StyledCustomerUnclaimMachineModal
      className="machine-unclaim__modal"
      width={1024}
      title={t('machineUnclaim.title')}
      centered
      open={isOpen}
      closable={false}
      destroyOnClose
      footer={[
        <SecondaryButton size="m" className="cancel-button" key="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton
          size="m"
          type="primary"
          key="submit"
          className="unclaim-button"
          onClick={handleUnclaim}
          loading={isUnclaimMachineLoading}
        >
          {t('machineUnclaim.button')}
        </PrimaryButton>,
      ]}
    >
      <MachineSelectedTable
        selectedMachinesModal={selectedMachinesModal}
        selectedMachinesId={selectedMachinesId}
        onSelectChangeModal={onSelectChangeModal}
      />
    </StyledCustomerUnclaimMachineModal>
  );
};
