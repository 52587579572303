import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Tabs } from 'antd';
import { isNil } from 'lodash-es';
import { customerModalsActions } from '../../../state/customerModalsSlice';
import { customerModalSelector } from '../../../state/customerModalsSelectors';
import { CustomerDeleteTitle } from '../CustomerDeleteTitle/CustomerDeleteTitle';
import { deleteCustomerSelector } from '../state/deleteCustomerSelector';
import { CustomerDeleteHasUserTab } from '../CustomerDeleteHasUserTab/CustomerDeleteHasUserTab';
import { CustomerDeleteHasDevicesTab } from '../CustomerDeleteHasDevicesTab/CustomerDeleteHasDevicesTab';
import { StyledCustomerHasUserOrDeviceDeleteModal } from './CustomerHasUserOrDeviceDeleteModal.styles';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { UserListActions } from 'app/modules/user-management/user-list/state/userListActions';
import { userListSelectors } from 'app/modules/user-management/user-list/state/userListSelectors';
import { CustomerDeleteTab } from 'app/modules/customer-management/interfaces/Customer.types';
import * as machineListSelectors from 'app/modules/machine-directory/machine-list/state/machineListSelectors';

export const CustomerHasUserOrDeviceDeleteModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: customerId } = useParams();

  const userList = useSelector(userListSelectors.selectData);
  const deviceList = useSelector(machineListSelectors.selectData);
  const isOpen = !!useSelector(customerModalSelector.selectIsDeleteCustomerModalOpen);
  const isLoading = !!useSelector(customerModalSelector.selectIsDeleteCustomerModalLoading);

  const userListLoading = useSelector(userListSelectors.selectIsLoading);
  const deviceListLoading = useSelector(machineListSelectors.selectIsLoading);

  const deleteUsers = useSelector(deleteCustomerSelector.selectDeleteUser);
  const reassignUsers = useSelector(deleteCustomerSelector.selectReassignUser);
  const reclaimDevices = useSelector(deleteCustomerSelector.selectReclaimDevices);
  const unclaimDevices = useSelector(deleteCustomerSelector.selectUnclaimDevices);

  const users = useMemo(() => {
    if (userListLoading) return [];
    return userList.map(user => ({
      ...user,
      key: user.internalUserId,
    }));
  }, [userList, userListLoading]);

  const devices = useMemo(
    () =>
      deviceList
        ? deviceList.map(device => ({
            ...device,
            key: `${device.materialNumber}-${device.serialNumber}`,
          }))
        : [],
    [deviceList]
  );

  const [activeTab, selectActiveTab] = useState(CustomerDeleteTab.USERS);

  useEffect(() => {
    if (!deviceListLoading && !userListLoading) {
      if (users.length > 0 && devices.length === 0) selectActiveTab(CustomerDeleteTab.USERS);
      else if (devices.length > 0 && users.length === 0) selectActiveTab(CustomerDeleteTab.MACHINES);
    }
  }, [deviceListLoading, userListLoading, users, devices]);

  const userDeleted = useMemo(
    () =>
      Object.keys(deleteUsers).filter(key => {
        const isInUserList = users.some(user => user.internalUserId === key);
        return deleteUsers[key].completed && !!isInUserList;
      }).length,
    [deleteUsers, users]
  );

  const userReassigned = useMemo(
    () =>
      Object.keys(reassignUsers).filter(key => {
        const isInUserList = users.some(user => user.internalUserId === key);
        return reassignUsers[key].completed && !!isInUserList;
      }).length,
    [reassignUsers, users]
  );

  const devicesReclaimed = useMemo(
    () =>
      Object.keys(reclaimDevices).filter(key => {
        const isInDeviceList = devices.some(device => `${device.materialNumber}-${device.serialNumber}` === key);
        return reclaimDevices[key].completed && !!isInDeviceList;
      }).length,
    [devices, reclaimDevices]
  );

  const devicesUnclaimed = useMemo(
    () =>
      Object.keys(unclaimDevices).filter(key => {
        const isInDeviceList = devices.some(device => `${device.materialNumber}-${device.serialNumber}` === key);
        return unclaimDevices[key].completed && !!isInDeviceList;
      }).length,
    [devices, unclaimDevices]
  );

  const isDeleteButtonDisabled = useMemo(
    () =>
      !!(
        users.length > userDeleted + userReassigned ||
        devices.length > devicesReclaimed + devicesUnclaimed ||
        isLoading ||
        deviceListLoading ||
        userListLoading
      ),
    [
      deviceListLoading,
      devices.length,
      devicesReclaimed,
      devicesUnclaimed,
      isLoading,
      userDeleted,
      userListLoading,
      userReassigned,
      users.length,
    ]
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(
        UserListActions.getUserListRequest({
          filter: {
            customerId,
          },
          paginationOptions: {
            limit: 1000,
            paginationToken: '',
          },
        })
      );
    }
  }, [dispatch, customerId, isOpen]);

  const handleCancel = useCallback(() => {
    dispatch(customerModalsActions.closeDeleteCustomerModal());
  }, [dispatch]);

  const handleDelete = useCallback(() => {
    if (isNil(customerId)) return;

    dispatch(customerModalsActions.deleteCustomerModalRequest({ customerId }));
  }, [customerId, dispatch]);

  const tabs = useMemo(
    () => [
      {
        label: t('customerDetails.modals.delete.tabs.users'),
        key: CustomerDeleteTab.USERS,
        disabled: users.length === 0,
      },
      {
        label: t('customerDetails.modals.delete.tabs.machines'),
        key: CustomerDeleteTab.MACHINES,
        disabled: devices.length === 0,
      },
    ],
    [devices.length, t, users.length]
  );

  const handleTabChange = (tabKey: string): void => {
    selectActiveTab(tabKey as CustomerDeleteTab);
  };

  return (
    <StyledCustomerHasUserOrDeviceDeleteModal
      className="customer-delete-modal"
      title={<CustomerDeleteTitle />}
      width={600}
      centered
      closable={false}
      open={isOpen}
      destroyOnClose
      footer={
        <Col span={24} className="customer-delete-modal__footer">
          <SecondaryButton size="m" key="cancel-button" className="secondary-button" onClick={handleCancel}>
            {t('customerDetails.modals.delete.button.close')}
          </SecondaryButton>

          <PrimaryButton
            size="m"
            key="submit"
            className="submit-button"
            type="primary"
            loading={isLoading}
            onClick={handleDelete}
            disabled={isDeleteButtonDisabled}
          >
            {t('customerDetails.modals.delete.button.deleteCustomer')}
          </PrimaryButton>
        </Col>
      }
    >
      <Tabs destroyInactiveTabPane={true} items={tabs} onChange={handleTabChange} activeKey={activeTab} />
      {activeTab === CustomerDeleteTab.USERS && <CustomerDeleteHasUserTab isOpen={isOpen} />}
      {activeTab === CustomerDeleteTab.MACHINES && <CustomerDeleteHasDevicesTab isOpen={isOpen} />}
    </StyledCustomerHasUserOrDeviceDeleteModal>
  );
};
