import React, { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInstance, RuleObject } from 'antd/lib/form';
import { Col, Form, Row } from 'antd';
import { useSelector } from 'react-redux';
import { IUserCreateRequestOptions } from '../../../state/userCreateActions';
import { userCreateSelectors } from '../../../state/userCreateSelectors';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { Select } from 'lib/components/Select/Select';
import { Input, InputPassword } from 'lib/components/Input/Input';
import { RegisterUserTitleEnum } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { supportedLanguages } from 'app/cross-cutting-concerns/translations/Translations';
import { splitLangPrefix } from 'app/modules/user-management/utils/splitLangPrefix';
import { getDecodedFromURL } from 'app/modules/user-management/utils/getDecodedFromURL';

const { Item } = Form;
const { Option } = Select;

const CONSTANTS = {
  salutations: [
    { value: RegisterUserTitleEnum.Mr, content: 'userCreate.form.salutations.mr' },
    { value: RegisterUserTitleEnum.Mrs, content: 'userCreate.form.salutations.mrs' },
  ],
};

const languageOptions = Object.entries(supportedLanguages).map(([key, values]) => ({
  value: key,
  content: values.name,
}));

interface UserCreateFormProps {
  formInstance: FormInstance<any>;
  handleOk: () => void;
  onFinish: (values: IUserCreateRequestOptions) => void;
}

export const UserCreateForm = ({ formInstance, handleOk, onFinish }: UserCreateFormProps): JSX.Element => {
  const { t } = useTranslation();
  const isUserCreateLoading = useSelector(userCreateSelectors.selectIsUserCreateLoading);

  const checkConfirmPassword = (): RuleObject => ({
    validator(_, value: RuleObject): Promise<any> {
      if (!value || formInstance.getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(t('userCreate.form.errors.confirmPasswordNotMatched')));
    },
  });

  useEffect(() => {
    const encodedData = getDecodedFromURL('user', '|');
    if (encodedData.length === 0) return;
    const [salutation, email, firstName, lastName, language] = encodedData;
    const getPrefixLanguage = splitLangPrefix(language);
    const getTitle = salutation === RegisterUserTitleEnum.None ? null : salutation;
    formInstance.setFieldsValue({ title: getTitle, email, firstName, lastName, language: getPrefixLanguage });
  }, [formInstance]);

  return (
    <Fragment>
      <Form form={formInstance} name="user-create" onFinish={onFinish} layout="vertical" autoComplete="off">
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Item
              name="title"
              label={t('userCreate.form.salutation')}
              className="user-create__input-group"
              required
              rules={[{ required: true, message: t('userCreate.form.errors.salutationRequired') }]}
            >
              <Select className="user-create__select" placeholder={t('userCreate.form.placeholderNull')} showArrow>
                {CONSTANTS.salutations.map(({ value, content }) => (
                  <Option key={value} value={value}>
                    {t(content)}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
          <Col xs={24} md={12}>
            <Item name="language" label={t('userCreate.form.language')} className="user-create__input-group">
              <Select className="user-create__select" showArrow>
                {languageOptions.map(({ value, content }) => (
                  <Option key={value} value={value}>
                    {t(content)}
                  </Option>
                ))}
              </Select>
            </Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Item
              name="firstName"
              label={t('userCreate.form.firstName')}
              className="user-create__input-group"
              required
              rules={[
                {
                  required: true,
                  message: t('userCreate.form.errors.userFirstNameRequired'),
                },
                {
                  min: 2,
                  message: t('userCreate.form.errors.userFirstNameMinLength'),
                },
                {
                  max: 35,
                  message: t('userCreate.form.errors.userFirstNameMaxLength'),
                },
              ]}
            >
              <Input className="user-create__input" placeholder={t('userCreate.form.placeholderFirstName')} disabled />
            </Item>
          </Col>
          <Col xs={24} md={12}>
            <Item
              name="lastName"
              label={t('userCreate.form.lastName')}
              className="user-create__input-group"
              required
              rules={[
                {
                  required: true,
                  message: t('userCreate.form.errors.userLastNameRequired'),
                },
                {
                  min: 2,
                  message: t('userCreate.form.errors.userLastNameMinLength'),
                },
                {
                  max: 35,
                  message: t('userCreate.form.errors.userLastNameMaxLength'),
                },
              ]}
            >
              <Input className="user-create__input" placeholder={t('userCreate.form.placeholderLastName')} disabled />
            </Item>
          </Col>
        </Row>
        {/* NOTE: Temp disabled due to the strict phone number validation in PUDS */}
        {/* TODO: will uncomment later and get back to original design */}
        {/* <Row gutter={24}>
          <Col xs={24} md={12}>
            <Item
              name="phoneNumber"
              label={t('userCreate.form.phoneNumber')}
              className="user-create__input-group"
              rules={[
                {
                  pattern: /([+]([0-9]{2})|[0-9])([0-9]{9,})/g,
                  message: t('userCreate.form.errors.phoneNumberInvalid'),
                },
              ]}
            >
              <Input className="user-create__input" placeholder={t('userCreate.form.placeholderPhoneNumber')} />
            </Item>
          </Col>
        </Row> */}

        <Row gutter={24}>
          <Col span={24}>
            <Item
              name="email"
              label={t('userCreate.form.email')}
              className="user-create__input-group"
              required
              rules={[
                {
                  required: true,
                  message: t('userCreate.form.errors.userEmailRequired'),
                },
              ]}
            >
              <Input className="user-create__input" placeholder={t('userCreate.form.placeholderEmail')} disabled />
            </Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Item
              name="password"
              label={t('userCreate.form.password')}
              className="user-create__input-group"
              required
              rules={[
                {
                  required: true,
                  message: t('userCreate.form.errors.passwordRequired'),
                },
                {
                  min: 8,
                  message: t('userCreate.form.errors.passwordLengthRequired'),
                },
                {
                  pattern: /[a-z]/,
                  message: t('userCreate.form.errors.passwordOneLowerCaseLetterRequired'),
                },
                {
                  pattern: /[A-Z]/,
                  message: t('userCreate.form.errors.passwordOneUpperCaseLetterRequired'),
                },
                {
                  pattern: /\d/,
                  message: t('userCreate.form.errors.passwordOneDigitRequired'),
                },
                {
                  pattern: /[~[\]`!@#$%^&*()_+<=>?,./:;"'}{|\\-]/,
                  message: t('userCreate.form.errors.passwordOneSpecialCharRequired'),
                },
              ]}
            >
              <InputPassword
                type="password"
                className="user-create__input"
                placeholder={t('userCreate.form.placeholderPassword')}
              />
            </Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={24}>
            <Item
              name="confirmPassword"
              label={t('userCreate.form.confirmPassword')}
              className="user-create__input-group"
              dependencies={['password']}
              required
              rules={[
                {
                  required: true,
                  message: t('userCreate.form.errors.confirmPasswordRequired'),
                },
                checkConfirmPassword(),
              ]}
            >
              <InputPassword
                type="password"
                className="user-create__input"
                placeholder={t('userCreate.form.placeholderConfirmPassword')}
              />
            </Item>
          </Col>
        </Row>
      </Form>
      <div className="user-create__buttons">
        <PrimaryButton
          size="m"
          key="submit"
          className="user-create__submit-button"
          loading={!!isUserCreateLoading}
          type="primary"
          onClick={handleOk}
        >
          {t('userCreate.title')}
        </PrimaryButton>
      </div>
    </Fragment>
  );
};
