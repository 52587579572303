import { MachineOwnership } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { IState } from 'app/cross-cutting-concerns/state-management/interfaces/IState';

export class MachineDirectorySelectors {
  public static isReclaimMachineLoading = (state: IState): boolean =>
    state.modules['machine-directory'].machine.isLoading;

  public static selectReclaimMachineData = (state: IState): MachineOwnership =>
    state.modules['machine-directory'].machine.reClaimedMachine;

  public static selectAlgs = (state: IState): string[] => state.modules['machine-directory'].machine.algs.data;

  public static selectAreAlgsLoading = (state: IState): boolean =>
    state.modules['machine-directory'].machine.algs.isLoading;

  public static isUnclaimMachineLoading = (state: IState): boolean =>
    state.modules['machine-directory'].machine.isLoading;

  public static selectUnclaimMachineData = (state: IState): MachineOwnership =>
    state.modules['machine-directory'].machine.unClaimedMachine;
}
