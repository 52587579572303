import { Action } from 'redux';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from '../../../cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { MachineServiceReclaimOptions, MachineServiceUnclaimOptions } from '../MachineService';
import { MachineUnclaimResponse } from '../interfaces/Machine.types';
import { Optional } from 'lib/types/Optional';
import { ListAlgNumberResponse } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export type IGetAlgsRequestAction = Action<typeof MachineDirectoryActions.GET_ALGS_REQUEST>;

export type IGetAlgsSuccessOptions = ListAlgNumberResponse;
export type IGetAlgsSuccessAction = Action<typeof MachineDirectoryActions.GET_ALGS_SUCCESS> & {
  payload: ListAlgNumberResponse;
};
export type IGetAlgsErrorAction = IErrorAction<typeof MachineDirectoryActions.GET_ALGS_ERROR>;

export type IReclaimMachineRequestOptions = MachineServiceReclaimOptions;
export type IReclaimMachineRequestAction = Action<typeof MachineDirectoryActions.RECLAIM_MACHINE_REQUEST> & {
  payload: IReclaimMachineRequestOptions;
};

// TODO: Specify precise payload type once known
export type IReclaimMachineSuccessOptions = Optional<Record<string, any>>;
export type IReclaimMachineSuccessAction = Action<typeof MachineDirectoryActions.RECLAIM_MACHINE_SUCCESS> & {
  payload: IReclaimMachineSuccessOptions;
};

export type IReclaimMachineErrorAction = IErrorAction<typeof MachineDirectoryActions.RECLAIM_MACHINE_ERROR>;

// UNCLAIM
export type IUnclaimMachineRequestOptions = MachineServiceUnclaimOptions;
export type IUnclaimMachineRequestAction = Action<typeof MachineDirectoryActions.UNCLAIM_MACHINE_REQUEST> & {
  payload: IUnclaimMachineRequestOptions;
};
export type IUnclaimMachineSuccessOptions = Optional<MachineUnclaimResponse>;
export type IUnclaimMachineSuccessAction = Action<typeof MachineDirectoryActions.UNCLAIM_MACHINE_SUCCESS> & {
  payload: IUnclaimMachineSuccessOptions;
};
export type IUnclaimMachineErrorAction = IErrorAction<typeof MachineDirectoryActions.UNCLAIM_MACHINE_ERROR>;

export class MachineDirectoryActions {
  public static GET_ALGS_REQUEST = 'MACHINE_DIRECTORY::GET_ALGS_REQUEST';
  public static GET_ALGS_SUCCESS = 'MACHINE_DIRECTORY::GET_ALGS_SUCCESS';
  public static GET_ALGS_ERROR = 'MACHINE_DIRECTORY::GET_ALGS_ERROR';

  public static RECLAIM_MACHINE_REQUEST = 'MACHINE_DIRECTORY::RECLAIM_MACHINE_REQUEST';
  public static RECLAIM_MACHINE_SUCCESS = 'MACHINE_DIRECTORY::RECLAIM_MACHINE_SUCCESS';
  public static RECLAIM_MACHINE_ERROR = 'MACHINE_DIRECTORY::RECLAIM_MACHINE_ERROR';

  public static UNCLAIM_MACHINE_REQUEST = 'MACHINE_DIRECTORY::UNCLAIM_MACHINE_REQUEST';
  public static UNCLAIM_MACHINE_SUCCESS = 'MACHINE_DIRECTORY::UNCLAIM_MACHINE_SUCCESS';
  public static UNCLAIM_MACHINE_ERROR = 'MACHINE_DIRECTORY::UNCLAIM_MACHINE_ERROR';

  public static getAlgsRequest(): IGetAlgsRequestAction {
    return {
      type: MachineDirectoryActions.GET_ALGS_REQUEST,
    };
  }

  public static getAlgsSuccess(data: IGetAlgsSuccessOptions): IGetAlgsSuccessAction {
    return {
      type: MachineDirectoryActions.GET_ALGS_SUCCESS,
      payload: data,
    };
  }

  public static getAlgsError({ error }: IActionCreatorErrorOptions): IGetAlgsErrorAction {
    return {
      type: MachineDirectoryActions.GET_ALGS_ERROR,
      error,
    };
  }

  public static reClaimMachineRequest({ input }: IReclaimMachineRequestOptions): IReclaimMachineRequestAction {
    return {
      type: MachineDirectoryActions.RECLAIM_MACHINE_REQUEST,
      payload: { input },
    };
  }

  public static reClaimMachineSuccess(response: IReclaimMachineSuccessOptions): IReclaimMachineSuccessAction {
    return {
      type: MachineDirectoryActions.RECLAIM_MACHINE_SUCCESS,
      payload: response,
    };
  }

  public static reClaimMachineError({ error }: IActionCreatorErrorOptions): IReclaimMachineErrorAction {
    return {
      type: MachineDirectoryActions.RECLAIM_MACHINE_ERROR,
      error,
    };
  }

  public static unclaimMachineRequest({ input }: IUnclaimMachineRequestOptions): IUnclaimMachineRequestAction {
    return {
      type: MachineDirectoryActions.UNCLAIM_MACHINE_REQUEST,
      payload: { input },
    };
  }

  public static unclaimMachineSuccess(response: IUnclaimMachineSuccessOptions): IUnclaimMachineSuccessAction {
    return {
      type: MachineDirectoryActions.UNCLAIM_MACHINE_SUCCESS,
      payload: response,
    };
  }

  public static unclaimMachineError({ error }: IActionCreatorErrorOptions): IUnclaimMachineErrorAction {
    return {
      type: MachineDirectoryActions.UNCLAIM_MACHINE_ERROR,
      error,
    };
  }
}
