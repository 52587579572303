import React, { useCallback } from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserInviteActions } from '../../state/userInviteActions';
import { StyledUserInvite } from './UserInvite.styles';
import { UserInfoForm } from './UserInviteForm';
import { InviteUserInput } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { RoutePaths } from 'config/route-paths';

export const UserInvite = (): JSX.Element => {
  const { t } = useTranslation();
  const [formInstance] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOk = useCallback(() => {
    formInstance.submit();
  }, [formInstance]);

  const handleCancel = (): void => {
    navigate(RoutePaths.USERS);
  };

  const onFinish = useCallback(
    (values: InviteUserInput) => {
      dispatch(UserInviteActions.userInviteRequest(values));
    },
    [dispatch]
  );

  return (
    <StyledUserInvite className="invite-user">
      <div className="invite-user__header">
        <div className="invite-user__header-content">
          <div className="invite-user__page-info">
            <h1 className="invite-user__title">{t('userInvite.title')}</h1>
          </div>
        </div>
      </div>
      <div className="invite-user__body">
        <div className="invite-user__body-content">
          <p>{t('userInvite.mandatoryField')}</p>
          <h3 className="invite-user__sub-title">{t('userInvite.userInformation')}</h3>
          <UserInfoForm
            formInstance={formInstance}
            handleCancel={handleCancel}
            handleOk={handleOk}
            onFinish={onFinish}
          />
        </div>
      </div>
    </StyledUserInvite>
  );
};
