import styled from 'styled-components';

export const StyledCustomerDeleteTitle = styled.div`
  .ant-modal-title {
    display: flex;
    align-items: center;
  }

  .delete-customer-title__icon {
    svg,
    path,
    i {
      width: 36px;
      height: 36px;
    }
    padding-right: 16px;
  }

  .delete-customer-title__title {
    font-family: ${(props): string => props.theme.fonts.heading};
    font-size: ${(props): string => props.theme.fontSizes.pageTitle};
    font-weight: ${(props): number => props.theme.fontWeights.captionBold};
    line-height: ${(props): string => props.theme.lineHeights.heading};
    text-transform: none;
  }
`;
