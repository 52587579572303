import styled from 'styled-components';
import { ButtonBase } from '../ButtonBase';

export const StyledPrimaryButton = styled(ButtonBase)`
  font-family: ${(props): string => props.theme.fonts.button};
  background-color: ${(props): string => props.theme.colors.primary};
  color: ${(props): string => props.theme.colors.text};
  border-radius: 0;
  border: 2px solid ${(props): string => props.theme.colors.primary};
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;

  :hover,
  :focus,
  :active {
    background-color: ${(props): string => props.theme.colors.primary};
    color: ${(props): string => props.theme.colors.text};
    border-color: transparent;
  }

  :hover {
    background-color: ${(props): string => props.theme.colors.primaryHover};
  }

  :focus {
    border-color: ${(props): string => props.theme.colors.focusBorder};
  }

  :active {
    background-color: ${(props): string => props.theme.colors.text};
    color: ${(props): string => props.theme.colors.primary};
  }

  :disabled,
  :disabled:hover {
    border-color: ${(props): string => props.theme.colors.semiGray};
    background-color: ${(props): string => props.theme.colors.semiGray};
    color: rgba(${(props): string => props.theme.colors.textRgb}, 0.3);
  }

  &.button__size-s {
    height: auto;
    font-size: 13px;
    line-height: 17px;
    padding: 6px 12px 5px;
  }

  &.button__size-m {
    height: 44px;
    font-size: 15px;
    line-height: 19px;
    padding: 11px 11px 10px;
  }
`;
