import styled from 'styled-components';

export const StyledUserCreate = styled.div`
  font-family: ${(props): string => props.theme.fonts.body};
  font-size: ${(props): string => props.theme.fontSizes.pageDescription};
  color: ${(props): string => props.theme.colors.text};
  min-height: inherit;

  .user-create__header {
    background-color: ${(props): string => props.theme.colors.lightGray};
    position: sticky;
    top: -128px; // header - breadcrumb = 176 - 48
    z-index: 97;

    .user-create__title {
      font-family: ${(props): string => props.theme.fonts.pageTitle};
      font-size: ${(props): string => props.theme.fontSizes.pageTitle};
      color: ${(props): string => props.theme.colors.text};
      text-transform: uppercase;
      margin-bottom: 7px;
    }
  }

  .user-create__header-content {
    max-width: 1056px;
    margin: auto;
    padding: 38px 24px 15px;
  }

  .user-create__body-content {
    max-width: 1056px;
    margin: 26px auto 0;
    padding: 0 24px;
  }

  .user-create__body {
    .user-create__mandatory-field {
      font-family: ${(props): string => props.theme.fonts.formBody};
      font-size: ${(props): string => props.theme.fontSizes.pageDescription};
      color: ${(props): string => props.theme.colors.text};
    }

    .user-create__sub-title {
      font-family: ${(props): string => props.theme.fonts.formSubheading};
      font-size: ${(props): string => props.theme.fontSizes.formSubheading};
      color: ${(props): string => props.theme.colors.text};
      text-transform: uppercase;
      margin: 20px 0;
    }

    .user-create__input-group {
      .user-create__select {
        width: 100%;

        .ant-select-selection-placeholder {
          font-size: ${(props): string => props.theme.fontSizes.formBody};
          font-family: ${(props): string => props.theme.fonts.modalSubtitle};
          color: ${(props): string => props.theme.colors.border};

          // Set placeholder center
          position: absolute;
          top: 50%;
          right: 11px;
          left: 11px;
          transform: translateY(-50%);
          transition: all 0.3s;
        }
      }

      .ant-form-item-label {
        padding-bottom: 0;

        > label {
          font-family: ${(props): string => props.theme.fonts.formLabel};
          font-size: ${(props): string => props.theme.fontSizes.formLabel};
          color: ${(props): string => props.theme.colors.text};
          text-transform: uppercase;
        }
      }

      .user-create__input {
        width: 100%;
        &::placeholder {
          font-size: ${(props): string => props.theme.fontSizes.formBody};
          font-family: ${(props): string => props.theme.fonts.modalSubtitle};
          color: ${(props): string => props.theme.colors.border};
        }
        &:disabled {
          font-family: ${(props): string => props.theme.fonts.heading};
          border-color: ${(props): string => props.theme.colors.neutralWhiteColor};
        }

        &.ant-input-password,
        input {
          background-color: ${(props): string => props.theme.colors.white};
          border-color: ${(props): string => props.theme.colors.border};
          :hover {
            background-color: ${(props): string => props.theme.colors.white};
          }
        }

        input {
          height: 16px;
        }
      }
    }

    .user-create__buttons {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .user-create__submit-button {
        text-transform: uppercase;
        font-size: ${(props): string => props.theme.fontSizes.buttonTitle};
        font-family: ${(props): string => props.theme.fonts.button};
        color: ${(props): string => props.theme.colors.text};
      }
    }
  }

  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${(props): string => props.theme.colors.red};
    box-shadow: none;
    border-right-width: 2px !important;
  }

  .ant-form-item-explain-error {
    color: ${(props): string => props.theme.colors.red};
  }

  @media screen and (max-width: 1406px) {
    .user-create__header-content,
    .user-create__body-content {
      margin-left: 175px;
    }
  }
`;
