import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectIsMachinePairReclaimModalVisible } from '../../state/machineModalSelectors';
import { MachineModalActions } from '../../state/machineModalActions';
import { StyledMachineReclaimModal } from './MachineReclaimModal.styles';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { MachineClaimPairActions } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairActions';

export const MachineReclaimModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isVisible = useSelector(selectIsMachinePairReclaimModalVisible);

  const handleCancel = useCallback(() => dispatch(MachineModalActions.hideMachinePairReclaimModal()), [dispatch]);

  const handleReclaim = useCallback(() => {
    dispatch(MachineModalActions.hideMachinePairReclaimModal());
    dispatch(MachineClaimPairActions.setStepClaimMachineActive());
    dispatch(MachineClaimPairActions.claimMachineResetState());
  }, [dispatch]);

  return (
    <StyledMachineReclaimModal
      className="machine-reclaim__modal"
      title={t('machineClaiming.modals.reclaim.title')}
      centered
      open={isVisible}
      closable={false}
      footer={[
        <SecondaryButton size="m" className="reclaim-button" key="reclaim-button" onClick={handleReclaim}>
          {t('machineClaiming.modals.reclaim.reClaimButton')}
        </SecondaryButton>,
        <PrimaryButton size="m" className="cancel-button" type="primary" key="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </PrimaryButton>,
      ]}
    >
      <p>{t('machineClaiming.modals.reclaim.content')}</p>
    </StyledMachineReclaimModal>
  );
};
