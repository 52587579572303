import styled from 'styled-components';

export const StyledPairMachine = styled.div`
  min-height: inherit;

  .machine-pairing__sub-title {
    font-family: ${(props): string => props.theme.fonts.formBody};
    font-size: ${(props): string => props.theme.fontSizes.p0};
    line-height: 20px;
    color: ${(props): string => props.theme.colors.black};
    margin-bottom: 5px;
  }

  .machine-pairing__form-item {
    .ant-form-item-label {
      padding: 0;
      > label {
        font-family: ${(props): string => props.theme.fonts.captionBold};
        font-size: ${(props): string => props.theme.fontSizes.caption};
        color: ${(props): string => props.theme.colors.black};
        text-transform: uppercase;
      }
    }

    .ant-form-item-control {
      .ant-input-disabled {
        border: 2px solid ${(props): string => props.theme.colors.neutralWhiteColor};
        color: ${(props): string => props.theme.colors.neutralWhiteColor};
        background-color: ${(props): string => props.theme.colors.white};
        pointer-events: none;
      }
    }
  }

  .ant-row > .machine-pairing__form-item {
    margin-bottom: 8px;
  }

  .machine-pairing__input {
    width: 234px;
  }

  .machine-pairing__machine-data-input {
    border: 2px solid;
    border-color: ${(props): string => props.theme.colors.neutralWhiteColor};
    pointer-events: none;
  }

  .machine-pairing__button-info {
    display: flex;
    justify-content: space-between;
  }

  .machine-pairing__button {
    text-transform: uppercase;
  }

  .machine-pairing__info-icon {
    margin-top: 16px;
    margin-left: 10.5px;
  }

  .machine-pairing__machine-operating-hours__info {
    width: 175px;
    height: 14px;
    padding-bottom: 35px;
  }

  .machine-pairing__show-machine-operating-hours-button {
    border: none;
    box-shadow: none;
    font-family: ${(props): string => props.theme.fonts.formBody};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    color: ${(props): string => props.theme.colors.black};
    line-height: ${(props): string => props.theme.fontSizes.caption};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    padding: 0px;
    margin-top: 19px;
    background-color: ${(props): string => props.theme.colors.stepsBackgroundColor};

    :hover,
    :active {
      background-color: ${(props): string => props.theme.colors.stepsBackgroundColor};
    }

    span {
      text-decoration: underline;
    }
  }

  .machine-pairing__machine-operating-hours-input {
    width: 493px;
    svg {
      width: 13px;
      height: 15px;
    }
  }

  input#machine-pairing_operatingHours {
    height: 13px;
  }

  .machine-pairing__machine-operating-hours-description {
    font-family: ${(props): string => props.theme.fonts.formBody};
    font-size: ${(props): string => props.theme.fontSizes.caption};
    line-height: 14px;
  }

  .ant-input-affix-wrapper .machine-pairing__machine-operating-hours-description:hover {
    border-color: ${(props): string => props.theme.colors.brightGrey};
  }

  .machine-pairing__confirm {
    margin-top: 35px;
    align-items: flex-start;
  }

  .machine-pairing__back-button {
    font-family: ${(props): string => props.theme.fonts.captionBold};
    border: none;
    box-shadow: none;
    margin-left: 40px;
  }

  .machine-pairing__machine-operating-hours-input__delete-button {
    border: none;
    background-color: transparent;
  }

  .machine-pairing__machine-operating-hours-input:hover {
    border-color: ${(props): string => props.theme.colors.darkGrey};
  }

  .ant-input-affix-wrapper > .ant-input:not(textarea) {
    padding-top: 2px;
  }
`;
