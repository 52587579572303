import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'antd';
import { useEffect } from 'react';
import { CustomerDetailsSelectors } from '../../state/customerDetailsSelectors';
import { CustomerDetailsInfo } from './CustomerDetailsInfo/CustomerDetailsInfo';
import { CustomerDetailsUsers } from './CustomerDetailsUsers/CustomerDetailsUsers';
import { CustomerDetailsMachines } from './CustomerDetailsMachines/CustomerDetailsMachines';
import { CustomerDetailsTab } from 'app/modules/customer-management/interfaces/Customer.types';
import { MachineListActions } from 'app/modules/machine-directory/machine-list/state/machineListActions';
import { UserListActions } from 'app/modules/user-management/user-list/state/userListActions';

export const CustomerDetailsBody = (): JSX.Element => {
  const dispatch = useDispatch();

  const customerActiveTab = useSelector(CustomerDetailsSelectors.selectActiveTab);

  const getCustomersActiveTabRender = (): JSX.Element => {
    switch (customerActiveTab) {
      case CustomerDetailsTab.DETAILS:
        return <CustomerDetailsInfo />;
      case CustomerDetailsTab.USERS:
        return <CustomerDetailsUsers />;
      case CustomerDetailsTab.MACHINES:
        return <CustomerDetailsMachines />;
      default:
        return <CustomerDetailsInfo />;
    }
  };

  useEffect(
    () => (): void => {
      dispatch(MachineListActions.resetState());
      dispatch(UserListActions.resetState());
    },
    [dispatch]
  );

  return (
    <div className="customer-details-body">
      <Row className="customer-details-body__content" align="top">
        {getCustomersActiveTabRender()}
      </Row>
    </div>
  );
};
