import { useTranslation } from 'react-i18next';
import { StyledUserInviteTooltip } from './UserInviteTooltip.styles';

export const UserInviteTooltip = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledUserInviteTooltip className="invite-user-tooltip">
      <div className="invite-user-tooltip__description">{t('customerList.rules.message')}</div>
    </StyledUserInviteTooltip>
  );
};
