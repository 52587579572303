import { ColumnsType } from 'antd/lib/table';
import { TFunction } from 'react-i18next';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Customer } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { Radio } from 'lib/components/Radio/Radio';

interface CustomerColumnsProps {
  t: TFunction;
  selectedCustomer: string;
  handleSelectCustomer: (e: RadioChangeEvent) => void;
  isMissingCustomerId?: boolean;
}

export const getCustomerListColumns = ({
  t,
  selectedCustomer,
  handleSelectCustomer,
  isMissingCustomerId,
}: CustomerColumnsProps): ColumnsType<Customer> => [
  {
    title: t('customerList.table.customerName'),
    dataIndex: 'customerName',
    key: 'customerName',
    width: '40%',
  },
  {
    title: t('customerList.table.customerNumber'),
    dataIndex: 'SAPCustomerNumber',
    key: 'SAPCustomerNumber',
    width: '40%',
  },
  {
    title: t('customerList.table.selected'),
    dataIndex: 'selected',
    key: 'selected',
    width: '20%',
    render: (_: boolean, customer: Customer) => (
      <Radio.Group
        onChange={handleSelectCustomer}
        value={selectedCustomer}
        className="machine-claim__customer-list-selected-column"
      >
        <Radio className={isMissingCustomerId ? 'machine-claim__radio-error' : ''} value={customer.customerId} />
      </Radio.Group>
    ),
  },
];
