import styled from 'styled-components';
import { UserStatusColors } from 'app/modules/user-management/interfaces/User.types';

export const StyledUserStatus = styled.div<{ $statusColor: UserStatusColors }>`
  .user-status__content {
    display: block;
  }

  .user-status__icon {
    font-size: 16px;
    color: ${(props): string => props.theme.colors[props.$statusColor]};

    &::before {
      margin-left: 0;
    }
  }
`;
