import styled from 'styled-components';

export const StyledLogin = styled.div`
  flex: auto;
  padding-top: 185px;
  text-align: center;

  .login__login-button {
    width: 146px;
    text-transform: uppercase;
  }
`;
