// eslint-disable-next-line import/no-extraneous-dependencies
import { HubCapsule } from '@aws-amplify/core';
import { Action } from 'redux';
import { Optional } from '../../../../lib/types/Optional';
import { IActionCreatorErrorOptions, IErrorAction } from '../../state-management/interfaces/StateManagement.types';
import { IPermission, IUserInfo } from '../interfaces/Authentication.types';
import { IAuthenticationTokens, IIdTokenPayload } from '../interfaces/IAuthenticationTokens';

export type ICheckAuthenticationAction = Action<typeof AuthenticationActions.CHECK_AUTHENTICATION>;

export interface ICheckAuthenticationAuthenticatedOptions {
  tokens: Optional<IAuthenticationTokens>;
  permissions: IPermission[];
}

export interface ICheckAuthenticationAuthenticatedAction
  extends Action<typeof AuthenticationActions.CHECK_AUTHENTICATION_AUTHENTICATED> {
  payload: {
    tokens: Optional<IAuthenticationTokens>;
    permissions: IPermission[];
  };
}

export type ICheckAuthenticationNotAuthenticatedAction = Action<
  typeof AuthenticationActions.CHECK_AUTHENTICATION_NOT_AUTHENTICATED
>;

export type ICheckAuthenticationErrorAction = IErrorAction<typeof AuthenticationActions.CHECK_AUTHENTICATION_ERROR>;

export type ISignInAction = Action<typeof AuthenticationActions.SIGN_IN>;

export type ISignInErrorAction = IErrorAction<typeof AuthenticationActions.SIGN_IN_ERROR>;

export type ISignOutAction = Action<typeof AuthenticationActions.SIGN_OUT>;

export interface ISignOutSuccessOptions {
  tokens: Optional<IAuthenticationTokens>;
}

export interface ISignOutSuccessAction extends Action<typeof AuthenticationActions.SIGN_OUT_SUCCESS> {
  payload: {
    tokens: Optional<IAuthenticationTokens>;
  };
}

export type ISignOutErrorAction = IErrorAction<typeof AuthenticationActions.SIGN_OUT_ERROR>;

export interface ITokenRefreshedOptions {
  tokens: Optional<IAuthenticationTokens>;
  permissions: IPermission[];
}

export interface ITokenRefreshedAction extends Action<typeof AuthenticationActions.TOKEN_REFRESHED> {
  payload: {
    tokens: Optional<IAuthenticationTokens>;
    permissions: IPermission[];
  };
}

export interface ITokenRefreshFailedOptions {
  data: HubCapsule;
}

export interface ITokenRefreshFailedAction extends Action<typeof AuthenticationActions.TOKEN_REFRESHED> {
  payload: {
    data: HubCapsule;
  };
}

export interface ISetUserInfoAction extends Action<typeof AuthenticationActions.SET_USER_INFO> {
  payload: {
    userInfo: IUserInfo;
  };
}

export class AuthenticationActions {
  public static CHECK_AUTHENTICATION = 'AUTHENTICATION::CHECK_AUTHENTICATION';
  public static CHECK_AUTHENTICATION_AUTHENTICATED = 'AUTHENTICATION::CHECK_AUTHENTICATION_AUTHENTICATED';
  public static CHECK_AUTHENTICATION_NOT_AUTHENTICATED = 'AUTHENTICATION::CHECK_AUTHENTICATION_NOT_AUTHENTICATED';
  public static CHECK_AUTHENTICATION_ERROR = 'AUTHENTICATION::CHECK_AUTHENTICATION_ERROR';
  public static SIGN_IN = 'AUTHENTICATION::SIGN_IN';
  public static SIGN_IN_ERROR = 'AUTHENTICATION::SIGN_IN_ERROR';
  public static SIGN_OUT = 'AUTHENTICATION::SIGN_OUT';
  public static SIGN_OUT_SUCCESS = 'AUTHENTICATION::SIGN_OUT_SUCCESS';
  public static SIGN_OUT_ERROR = 'AUTHENTICATION::SIGN_OUT_ERROR';
  public static TOKEN_REFRESHED = 'AUTHENTICATION::TOKEN_REFRESHED';
  public static TOKEN_REFRESH_FAILED = 'AUTHENTICATION::TOKEN_REFRESH_FAILED';
  public static SET_USER_INFO = 'AUTHENTICATION::SET_USER_INFO';

  public static checkAuthentication(): ICheckAuthenticationAction {
    return {
      type: AuthenticationActions.CHECK_AUTHENTICATION,
    };
  }

  public static checkAuthenticationAuthenticated({
    tokens,
    permissions,
  }: ICheckAuthenticationAuthenticatedOptions): ICheckAuthenticationAuthenticatedAction {
    return {
      type: AuthenticationActions.CHECK_AUTHENTICATION_AUTHENTICATED,
      payload: {
        tokens,
        permissions,
      },
    };
  }

  public static checkAuthenticationNotAuthenticated(): ICheckAuthenticationNotAuthenticatedAction {
    return {
      type: AuthenticationActions.CHECK_AUTHENTICATION_NOT_AUTHENTICATED,
    };
  }

  public static checkAuthenticationError({ error }: IActionCreatorErrorOptions): ICheckAuthenticationErrorAction {
    return {
      type: AuthenticationActions.CHECK_AUTHENTICATION_ERROR,
      error,
    };
  }

  public static signIn(): ISignInAction {
    return {
      type: AuthenticationActions.SIGN_IN,
    };
  }

  public static signInError({ error }: IActionCreatorErrorOptions): ISignInErrorAction {
    return {
      type: AuthenticationActions.SIGN_IN_ERROR,
      error,
    };
  }

  public static signOut(): ISignOutAction {
    return {
      type: AuthenticationActions.SIGN_OUT,
    };
  }

  public static signOutSuccess({ tokens }: ISignOutSuccessOptions): ISignOutSuccessAction {
    return {
      type: AuthenticationActions.SIGN_OUT_SUCCESS,
      payload: {
        tokens,
      },
    };
  }

  public static signOutError({ error }: IActionCreatorErrorOptions): ISignOutErrorAction {
    return {
      type: AuthenticationActions.SIGN_OUT_ERROR,
      error,
    };
  }

  public static tokenRefreshed({ tokens, permissions }: ITokenRefreshedOptions): ITokenRefreshedAction {
    return {
      type: AuthenticationActions.TOKEN_REFRESHED,
      payload: {
        tokens,
        permissions,
      },
    };
  }

  public static tokenRefreshFailed({ data }: ITokenRefreshFailedOptions): ITokenRefreshFailedAction {
    return {
      type: AuthenticationActions.TOKEN_REFRESH_FAILED,
      payload: {
        data,
      },
    };
  }

  public static setUserInfo({ decodedIdToken }: { decodedIdToken: IIdTokenPayload }): ISetUserInfoAction {
    return {
      type: AuthenticationActions.SET_USER_INFO,
      payload: {
        userInfo: decodedIdToken,
      },
    };
  }
}
