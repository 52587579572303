import { IUserStatus, UserStatusColors } from '../interfaces/User.types';
import { Optional } from 'lib/types/Optional';

export const getUserStatus = (userStatus: Optional<string>): IUserStatus => {
  switch (userStatus) {
    case 'In Progress':
      return {
        color: UserStatusColors.ORANGE,
        description: 'userList.userStatusIcon.inProgress',
      };

    case 'Authorized':
      return {
        color: UserStatusColors.GREEN,
        description: 'userList.userStatusIcon.authorized',
      };

    default:
      return {
        color: UserStatusColors.RED,
        description: 'userList.userStatusIcon.unauthorized',
      };
  }
};
