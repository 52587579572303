import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { TableRowSelection } from 'antd/lib/table/interface';
import { useParams } from 'react-router-dom';
import { customerModalSelector } from '../../../state/customerModalsSelectors';
import { CustomerDeleteDescription } from '../CustomerDeleteDescription/CustomerDeleteDescription';
import { deleteCustomerSelector } from '../state/deleteCustomerSelector';
import { deleteCustomerActions } from '../state/deleteCustomerSlice';
import { getDeviceListColumns } from '../CustomerHasUserOrDeviceDeleteModal/columns/CusTomerDeleteHasDevice.columns';
import { customerModalsActions } from '../../../state/customerModalsSlice';
import { CustomerDeleteDevicesReclaimingModal } from '../../CustomerDeleteDevicesReclaimingModal/CustomerDeleteDevicesReclaimingModal';
import { StyledCustomerDeleteHasDevicesTab } from './CustomerDeleteHasDevicesTab.styles';
import * as machineListSelectors from 'app/modules/machine-directory/machine-list/state/machineListSelectors';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { PopConfirm } from 'lib/components/PopConfirm/PopConfirm';
import { MachineOwnership } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { Checkbox } from 'lib/components/Checkbox/Checkbox';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { MachineListActions } from 'app/modules/machine-directory/machine-list/state/machineListActions';
import { Table } from 'lib/components/Table/Table';

interface CustomerDeleteHasDevicesTabProps {
  isOpen: boolean;
}

export const CustomerDeleteHasDevicesTab = ({ isOpen }: CustomerDeleteHasDevicesTabProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id: customerId } = useParams();
  const deviceListLoading = useSelector(machineListSelectors.selectIsLoading);
  const isLoading = !!useSelector(customerModalSelector.selectIsDeleteCustomerModalLoading);
  const deviceList = useSelector(machineListSelectors.selectData);
  const devices = useMemo(
    () =>
      deviceList
        ? deviceList.map(device => ({
            ...device,
            key: `${device.materialNumber}-${device.serialNumber}`,
          }))
        : [],
    [deviceList]
  );
  const reclaimDevices = useSelector(deleteCustomerSelector.selectReclaimDevices);
  const unclaimDevices = useSelector(deleteCustomerSelector.selectUnclaimDevices);

  const [selectedDevices, setSelectedDevices] = useState<MachineOwnership[]>([]);

  const devicesReclaimed = useMemo(
    () => Object.keys(reclaimDevices).filter(key => reclaimDevices[key].completed).length,
    [reclaimDevices]
  );

  const devicesUnclaimed = useMemo(
    () => Object.keys(unclaimDevices).filter(key => unclaimDevices[key].completed).length,
    [unclaimDevices]
  );

  const devicesReclaimedError = useMemo(
    () => Object.keys(reclaimDevices).filter(key => reclaimDevices[key].error).length,
    [reclaimDevices]
  );

  const devicesUnclaimedError = useMemo(
    () => Object.keys(unclaimDevices).filter(key => unclaimDevices[key].error).length,
    [unclaimDevices]
  );

  const devicesReclaimLoading = useMemo(
    () => Object.keys(reclaimDevices).filter(key => reclaimDevices[key].loading).length,
    [reclaimDevices]
  );

  const devicesUnclaimLoading = useMemo(
    () => Object.keys(unclaimDevices).filter(key => unclaimDevices[key].loading).length,
    [unclaimDevices]
  );

  const isHandleAllDevices = useMemo(
    () =>
      devices.length ===
      devicesReclaimed +
        devicesUnclaimed +
        devicesReclaimedError +
        devicesUnclaimedError +
        devicesReclaimLoading +
        devicesUnclaimLoading,
    [
      devices.length,
      devicesReclaimed,
      devicesUnclaimed,
      devicesReclaimedError,
      devicesUnclaimedError,
      devicesReclaimLoading,
      devicesUnclaimLoading,
    ]
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]): void => {
    setSelectedRowKeys(
      newSelectedRowKeys.filter(key => !unclaimDevices[key as string] && !reclaimDevices[key as string])
    );
  };

  const onToggleSingleRow = useCallback(
    (id: string): void => {
      if (selectedRowKeys.includes(id)) {
        setSelectedRowKeys(selectedRowKeys.filter(key => key !== id));
      } else {
        if (reclaimDevices[id] || unclaimDevices[id]) {
          return;
        }
        setSelectedRowKeys([...selectedRowKeys, id]);
      }
    },
    [reclaimDevices, selectedRowKeys, unclaimDevices]
  );

  useEffect(() => {
    if (!isOpen) {
      setSelectedRowKeys([]);
      dispatch(deleteCustomerActions.resetState());
    }
  }, [dispatch, isOpen]);

  const isCheckedAll = useMemo(() => {
    if (devices.length === 0 || selectedRowKeys.length === 0) {
      return false;
    }
    return devices
      .filter(({ materialNumber, serialNumber }) => {
        const id = `${materialNumber}-${serialNumber}`;
        return !unclaimDevices[id] && !reclaimDevices[id];
      })
      .every(device => selectedRowKeys.includes(`${device.materialNumber}-${device.serialNumber}` as string));
  }, [devices, selectedRowKeys, unclaimDevices, reclaimDevices]);

  const toggleAllRows = (): void => {
    if (isCheckedAll) {
      setSelectedRowKeys([]);
    } else {
      const devicesIds = devices
        .map(device => `${device.materialNumber}-${device.serialNumber}` as string)
        .filter(id => !unclaimDevices[id] && !reclaimDevices[id]);
      setSelectedRowKeys([...selectedRowKeys, ...devicesIds]);
    }
  };

  const rowSelection: TableRowSelection<MachineOwnership> = {
    selectedRowKeys,
    onChange: onSelectChange,
    type: 'checkbox',
    columnWidth: 80,
    columnTitle: <Checkbox checked={isCheckedAll} onChange={toggleAllRows} disabled={isHandleAllDevices} />,
    renderCell: (checked, record) => {
      const id = `${record.materialNumber}-${record.serialNumber}`;
      return (
        <>
          {!reclaimDevices[id]?.loading &&
            !reclaimDevices[id]?.completed &&
            !reclaimDevices[id]?.error &&
            !unclaimDevices[id]?.loading &&
            !unclaimDevices[id]?.completed &&
            !unclaimDevices[id]?.error && <Checkbox checked={checked} onChange={(): void => onToggleSingleRow(id)} />}
          {reclaimDevices[id] && (
            <div className="customer-delete-has-device__check">
              {reclaimDevices[id]?.loading && <Spin size="small" className="customer-delete-has-device__check-spin" />}
              {reclaimDevices[id]?.completed && (
                <SvgIcon name="success" className="customer-delete-has-device__success-icon" />
              )}
              {reclaimDevices[id]?.error && (
                <SvgIcon name="error" className="customer-delete-has-device__success-icon" />
              )}
            </div>
          )}
          {unclaimDevices[id] && (
            <div className="customer-delete-has-device__check">
              {unclaimDevices[id]?.loading && <Spin size="small" className="customer-delete-has-device__check-spin" />}
              {unclaimDevices[id]?.completed && (
                <SvgIcon name="success" className="customer-delete-has-device__success-icon" />
              )}
              {unclaimDevices[id]?.error && (
                <SvgIcon name="error" className="customer-delete-has-device__success-icon" />
              )}
            </div>
          )}
        </>
      );
    },
  };

  const sharedOnCell = (record: MachineOwnership): object => {
    const id = `${record.materialNumber}-${record.serialNumber}`;
    if (reclaimDevices[id] || unclaimDevices[id]) {
      return {
        colSpan: 4,
      };
    }

    return {};
  };

  const hideOnCell = (record: MachineOwnership): object => {
    const id = `${record.materialNumber}-${record.serialNumber}`;
    const reclaimDevicesLoading = reclaimDevices[id]?.loading;
    const reclaimDevicesCompleted = reclaimDevices[id]?.completed;
    const reclaimDevicesError = reclaimDevices[id]?.error;
    const unclaimDevicesLoading = unclaimDevices[id]?.loading;
    const unclaimDevicesCompleted = unclaimDevices[id]?.completed;
    const unclaimDevicesError = unclaimDevices[id]?.error;
    if (
      reclaimDevicesLoading ||
      reclaimDevicesCompleted ||
      reclaimDevicesError ||
      unclaimDevicesLoading ||
      unclaimDevicesCompleted ||
      unclaimDevicesError
    ) {
      return {
        colSpan: 0,
      };
    }

    return {};
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(
        MachineListActions.getMachineListRequest({
          filter: {
            customerId,
          },
          paginationOptions: {
            limit: 1000,
            paginationToken: '',
          },
        })
      );
    }
  }, [dispatch, customerId, isOpen]);

  const handleClickReclaim = (device: MachineOwnership): void => {
    setSelectedDevices(
      devices.filter(
        deviceReclaim =>
          device.materialNumber === deviceReclaim.materialNumber && device.serialNumber === deviceReclaim.serialNumber
      )
    );
    dispatch(customerModalsActions.openReclaimMachineModal());
  };

  const handleClickUnclaim = (device: MachineOwnership): void => {
    const id = `${device.materialNumber}-${device.serialNumber}`;
    const input = {
      materialNumber: device.materialNumber,
      serialNumber: device.serialNumber,
    };
    setSelectedRowKeys(selectedRowKeys.filter(key => key !== id));
    dispatch(deleteCustomerActions.unclaimDevicesRequest({ input }));
  };

  const tableColumns = getDeviceListColumns({
    t,
    handleClickReclaim,
    handleClickUnclaim,
    sharedOnCell,
    hideOnCell,
    reclaimDevices,
    unclaimDevices,
  });

  const handleClickReclaimBtn = (): void => {
    setSelectedDevices(
      devices.filter(device => selectedRowKeys.includes(`${device.materialNumber}-${device.serialNumber}`))
    );
    dispatch(customerModalsActions.openReclaimMachineModal());
  };

  const handleClickUnclaimBtn = (): void => {
    const devicesInput = devices
      .filter(device => selectedRowKeys.includes(`${device.materialNumber}-${device.serialNumber}`))
      .map(device => ({
        input: {
          materialNumber: device.materialNumber,
          serialNumber: device.serialNumber,
        },
      }));

    dispatch(deleteCustomerActions.unclaimMultiDevicesRequest(devicesInput));
    setSelectedDevices([]);
    setSelectedRowKeys([]);
  };

  const onRemoveDeviceFromSelected = (): void => {
    setSelectedRowKeys(
      selectedRowKeys.filter(
        key => !selectedDevices.some(device => key === `${device.materialNumber}-${device.serialNumber}`)
      )
    );
    setSelectedDevices([]);
  };

  return (
    <StyledCustomerDeleteHasDevicesTab>
      <Row justify="center" align="middle">
        <Col span={24}>
          <CustomerDeleteDescription
            message1={t('customerDetails.modals.delete.descriptionHasDevice.message1')}
            note={t('customerDetails.modals.delete.descriptionHasDevice.note')}
            message2={t('customerDetails.modals.delete.descriptionHasDevice.message2')}
          />
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col span={24} className="customer-delete-modal__device-options">
          <SecondaryButton
            size="m"
            key="unclaim-button"
            className="secondary-button"
            onClick={handleClickReclaimBtn}
            disabled={selectedRowKeys.length === 0}
          >
            {t('customerDetails.modals.delete.button.reclaimBtn')}
          </SecondaryButton>
          <PopConfirm
            key="save-button"
            title={t('common.popConfirm.titleConfirm')}
            cancelText={t('common.popConfirm.cancelText')}
            okText={t('common.popConfirm.okTextConfirm')}
            onConfirm={handleClickUnclaimBtn}
          >
            <SecondaryButton
              size="m"
              key="reclaim-button"
              className="secondary-button"
              disabled={selectedRowKeys.length === 0}
            >
              {t('customerDetails.modals.delete.button.unclaimBtn')}
            </SecondaryButton>
          </PopConfirm>
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col span={24}>
          <Table
            scroll={{ x: 200, y: 250 }}
            dataSource={devices}
            loading={isLoading || !!deviceListLoading}
            className="device-list__table"
            columns={tableColumns}
            rowKey={(record: MachineOwnership): React.Key => `${record.materialNumber}-${record.serialNumber}`}
            rowSelection={rowSelection}
          />
        </Col>
      </Row>
      <CustomerDeleteDevicesReclaimingModal
        selectedMachines={selectedDevices}
        onRemoveDeviceFromSelected={onRemoveDeviceFromSelected}
      />
    </StyledCustomerDeleteHasDevicesTab>
  );
};
