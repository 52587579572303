import { GraphQLErrors } from '@apollo/client/errors';
import { ErrorResponse } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import { inject, injectable } from 'tsyringe';
import i18next, { TFunction } from 'i18next';
import { RoutePaths } from '../../../../config/route-paths';
import { ToastService } from '../../toasts/ToastService';
import { AppSyncError } from 'app/cross-cutting-concerns/authentication/errors/AppSyncError';
import { AuthenticationService } from 'app/cross-cutting-concerns/authentication/AuthenticationService';

export const isErrorTokenHasExpired = (error: GraphQLError): boolean =>
  error.message === AppSyncError.TOKEN_HAS_EXPIRED;

export const isErrorValidAuthorizationHeaderNotProvided = (error: GraphQLError): boolean =>
  error.message === AppSyncError.VALID_AUTHORIZATION_HEADER_NOT_PROVIDED;

@injectable()
export class GraphQlErrorHandler {
  private readonly t: TFunction;
  private readonly operationsToIgnoreErrorsFrom: string[] = [
    'CustomerDeleteUser',
    'CustomerReassignUser',
    'CustomerReClaimMachines',
    'CustomerUnclaimMachines',
    'InviteUser',
  ];

  constructor(
    private authenticationService: AuthenticationService,
    // Typescript is reporting the error "Attempted import error: 'BrowserHistory' is not exported from 'history'"
    // Set to any type as a workaround
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @inject('History') private history: any,
    private toastService: ToastService
  ) {
    this.t = i18next.t;
  }

  public handle = (errorResponse: ErrorResponse): void => {
    const graphQlErrors: GraphQLErrors = errorResponse.graphQLErrors ?? [];

    if (graphQlErrors.some(isErrorTokenHasExpired)) {
      this.handleInvalidAccessToken();
      return;
    }

    if (graphQlErrors.some(isErrorValidAuthorizationHeaderNotProvided)) {
      this.handleInvalidAccessToken();
      return;
    }

    if (this.operationsToIgnoreErrorsFrom.includes(errorResponse.operation.operationName)) {
      return;
    }

    if (graphQlErrors.length > 0) {
      this.toastService.error({
        message: this.t('toasts.networkError.title') as string,
        description: graphQlErrors?.[0]?.message as string,
      });
    }
  };

  private handleInvalidAccessToken = async (): Promise<void> => {
    await this.authenticationService.signOut();
    this.history.push(RoutePaths.LOGIN);
  };
}
