import 'reflect-metadata';
import { Amplify } from 'aws-amplify';
import { BrowserHistory } from 'history';
import i18next from 'i18next';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Store } from 'redux';
import { DefaultTheme } from 'styled-components';
import { App } from './App';
import { Authentication } from './app/cross-cutting-concerns/authentication/Authentication';
import { AuthenticationService } from './app/cross-cutting-concerns/authentication/AuthenticationService';
import { DependencyInjection } from './app/cross-cutting-concerns/dependency-injection/DependencyInjection';
import { IDependencies } from './app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { MenuItemManager } from './app/cross-cutting-concerns/routing/MenuItemManager';
import { ToastService } from './app/cross-cutting-concerns/toasts/ToastService';
import { Translations } from './app/cross-cutting-concerns/translations/Translations';
import reportWebVitals from './reportWebVitals';
import { Routing } from './app/cross-cutting-concerns/routing/Routing';
import { CustomerService } from 'app/modules/customer-management/CustomerService';
import { MachineService } from 'app/modules/machine-directory/MachineService';
import { UserService } from 'app/modules/user-management/UserService';
import { Scripts } from 'app/utils/scripts/Scripts';

/**
 *  Initializes the application
 */
export function init(): void {
  Amplify.configure({
    Auth: {
      region: process.env.REACT_APP_AWS_REGION,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
      userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
      mandatorySignIn: true,
    },
    oauth: {
      domain: process.env.REACT_APP_AWS_USER_POOL_DOMAIN,
      scope: ['openid', 'profile', 'email'],
      redirectSignIn: `${window.location.origin}/`,
      redirectSignOut: `${window.location.origin}/logout`,
      responseType: 'code',
    },
  });

  Scripts.addScriptTags();

  Translations.initialize();

  DependencyInjection.registerDependencies();

  const theme = DependencyInjection.resolve<DefaultTheme>('Theme');
  const history = DependencyInjection.resolve<BrowserHistory>('History');
  const routing = DependencyInjection.resolve<Routing>(Routing);

  const dependencies: IDependencies = {
    authenticationService: DependencyInjection.resolve<AuthenticationService>(AuthenticationService),
    machineService: DependencyInjection.resolve<MachineService>(MachineService),
    userService: DependencyInjection.resolve<UserService>(UserService),
    customerService: DependencyInjection.resolve(CustomerService),
    toastService: DependencyInjection.resolve(ToastService),
    menuItemManager: DependencyInjection.resolve<MenuItemManager>(MenuItemManager),
    t: i18next.t,
    history,
  };

  DependencyInjection.registerStore({
    dependencies,
  });

  const store = DependencyInjection.resolve<Store>('Store');

  // Creates a AuthenticationEventListener as a singleton to listen for authentication events from Amplify Hub
  // - See https://docs.amplify.aws/lib/utilities/hub/q/platform/js/
  DependencyInjection.resolve<Authentication>(Authentication);

  const container = document.getElementById('root');

  // Non-null assertion as per React documentation
  // * See https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <App store={store} theme={theme} history={history} routing={routing} dependencies={dependencies} />
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}

init();
