import styled from 'styled-components';

export const StyledCustomerList = styled.div`
  font-family: ${(props): string => props.theme.fonts.body};
  font-size: ${(props): string => props.theme.fontSizes.pageDescription};
  min-height: inherit;

  .customer-list__header {
    background-color: ${(props): string => props.theme.colors.lightGray};
    position: sticky;
    top: -60px;
    z-index: 97;
  }

  .customer-list__header-content {
    max-width: 1056px;
    margin: auto;
    padding: 38px 24px 15px;

    .customer-list__filter-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 24px;

      .customer-list__action-buttons-wrapper {
        align-self: end;

        .customer-list__invitation-btn {
          text-transform: uppercase;

          svg {
            width: 24px;
            height: 24px;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .customer-list__title {
    font-size: ${(props): string => props.theme.fontSizes.pageTitle};
    color: ${(props): string => props.theme.colors.text};
    font-family: ${(props): string => props.theme.fonts.pageTitle};
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .customer-list__filter {
    display: flex;
    justify-content: space-between;

    .customer-list__select-wrapper {
      width: 230px;
    }

    .ant-select {
      width: 100%;

      .ant-select-selector {
        background-color: ${(props): string => props.theme.colors.white};
      }
    }
  }

  .customer-list__filter-label {
    font-family: ${(props): string => props.theme.fonts.filterLabel};
    font-size: ${(props): string => props.theme.fontSizes.filterLabel};
    color: ${(props): string => props.theme.colors.text};
    text-transform: uppercase;
  }

  .customer-list__select {
    width: 100%;
  }

  .customer-list__filter-label {
    font-family: ${(props): string => props.theme.fonts.filterLabel};
    font-size: ${(props): string => props.theme.fontSizes.filterLabel};
    color: ${(props): string => props.theme.colors.text};
    text-transform: uppercase;
  }

  .customer-list__body-content {
    max-width: 1056px;
    margin: 26px auto 0;
    padding: 0 24px;

    .customer-list__search-input {
      margin: 0 0 24px 0;
      width: 100%;
      flex-direction: row-reverse;

      .customer-list__search-customer-input {
        width: 320px;

        .ant-input-prefix svg {
          width: 18px;
          height: 18px;
          stroke-width: 30px;
          stroke: ${(props): string => props.theme.colors.white};
        }

        .ant-input-suffix {
          margin-right: 15px;
        }
      }
    }
  }

  .customer-list__table {
    margin-bottom: 40px;

    .ant-table-tbody {
      .ant-table-row {
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1406px) {
    .customer-list__header-content,
    .customer-list__body-content {
      margin-left: 175px;
    }
  }
`;
