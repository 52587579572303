import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { customerModalSelector } from '../../state/customerModalsSelectors';
import { customerModalsActions } from '../../state/customerModalsSlice';
import { StyledCustomerClaimMachineModal } from './CustomerClaimMachineModal.styles';
import { CustomerClaimMachineForm } from './CustomerClaimMachineForm/CustomerClaimMachineForm';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { CustomerDetailsSelectors } from 'app/modules/customer-management/customer-details/state/customerDetailsSelectors';
import { MachineClaimPairSelectors } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairSelectors';
import { MachineClaimPairActions } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairActions';
import { IReclaimMachineRequestOptions } from 'app/modules/machine-directory/state/machineDirectoryActions';

export const CustomerClaimMachineModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isOpen = useSelector(customerModalSelector.selectIsClaimNewDeviceModalOpen) ?? false;
  const customer = useSelector(CustomerDetailsSelectors.selectData);
  const machineFind = useSelector(MachineClaimPairSelectors.selectMachineFindData);
  const isMachineClaimed = !!machineFind?.customerName;

  const isClaimMachineLoading = useSelector(customerModalSelector.selectIsClaimNewDeviceModalLoading) ?? false;

  const handleClaimMachine = useCallback(() => {
    if (customer && machineFind) {
      const { alg, customerId } = customer;
      const { materialNumber, serialNumber } = machineFind;

      if (alg && customerId && materialNumber && serialNumber) {
        const reclaimMachineRequestOptions: IReclaimMachineRequestOptions = {
          input: {
            algNumber: alg,
            customerId,
            materialNumber,
            serialNumber,
          },
        };

        dispatch(customerModalsActions.claimNewDeviceModalRequest(reclaimMachineRequestOptions));
      }
    }

    dispatch(MachineClaimPairActions.findMachineResetState());
  }, [dispatch, customer, machineFind]);

  const handleCancel = useCallback(() => {
    dispatch(customerModalsActions.closeClaimNewDeviceModal());
    dispatch(MachineClaimPairActions.findMachineResetState());
  }, [dispatch]);

  return (
    <StyledCustomerClaimMachineModal
      width={1024}
      centered
      open={isOpen}
      title={
        <>
          {t('customerDetails.modals.claimNewDevice.title')}
          <p className="machine-reclaiming__description">{t('customerDetails.modals.claimNewDevice.description')}</p>
        </>
      }
      closable={false}
      destroyOnClose
      footer={[
        <SecondaryButton size="m" className="cancel-button" key="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          disabled={!machineFind}
          onClick={handleClaimMachine}
          loading={isClaimMachineLoading}
        >
          {isMachineClaimed
            ? t('machineReclaiming.reClaimButton')
            : t('customerDetails.modals.claimNewDevice.claimButton')}
        </PrimaryButton>,
      ]}
    >
      <CustomerClaimMachineForm />
    </StyledCustomerClaimMachineModal>
  );
};
