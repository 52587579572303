import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledAppSider } from 'app/components/app-layout/AppSider/AppSider.styles';

export const UserCreateSider = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledAppSider width={175}>
      <h1 className="header__product-name">{t('userCreate.siderTitle')}</h1>
    </StyledAppSider>
  );
};
