import { Popover } from 'antd';
import styled from 'styled-components';

export const StyledProfilePopover = styled(Popover)`
  height: 100%;
  display: flex;

  .header__profile-image {
    width: 64px;
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.header__profile-image--clicked {
      z-index: 1031;
      background-color: ${(props): string => props.theme.colors.semiGray};
    }
  }

  .profile-popover {
    &.ant-popover {
      padding-top: unset;
      top: 82px !important;

      .ant-popover-arrow {
        display: none;
      }

      .ant-popover-inner {
        min-width: 170px;
        background-color: ${(props): string => props.theme.colors.semiGray};
      }
    }
  }
`;
