import styled from 'styled-components';

export const StyledUserInviteSuccess = styled.div`
  font-family: ${(props): string => props.theme.fonts.body};
  font-size: ${(props): string => props.theme.fontSizes.pageDescription};
  color: ${(props): string => props.theme.colors.text};
  min-height: inherit;

  margin-left: 175px;

  .ant-result-success {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .user-invite-success__btn {
    padding: 11px !important;
  }
`;
