import { Action } from 'redux';
import { UserClientInviteOptions } from '../../UserClient';
import { UserInviteResponse } from '../../interfaces/User.types';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { Optional } from 'lib/types/Optional';

export type IUserInviteRequestOptions = UserClientInviteOptions;
export type IUserInviteRequestAction = Action<typeof UserInviteActions.INVITE_USER_REQUEST> & {
  payload: IUserInviteRequestOptions;
};

export type IUserInviteSuccessOptions = Optional<UserInviteResponse>;
export type IUserInviteSuccessAction = Action<typeof UserInviteActions.INVITE_USER_SUCCESS> & {
  payload: Optional<UserInviteResponse>;
};

export type IUserInviteErrorAction = IErrorAction<typeof UserInviteActions.INVITE_USER_ERROR>;

export class UserInviteActions {
  public static INVITE_USER_REQUEST = 'INVITE_USER::INVITE_USER_REQUEST';
  public static INVITE_USER_SUCCESS = 'INVITE_USER::INVITE_USER_SUCCESS';
  public static INVITE_USER_ERROR = 'INVITE_USER::INVITE_USER_ERROR';

  public static userInviteRequest = ({
    title,
    firstName,
    lastName,
    email,
    phoneNumber,
    customerId,
    language,
  }: IUserInviteRequestOptions): IUserInviteRequestAction => ({
    type: UserInviteActions.INVITE_USER_REQUEST,
    payload: { title, firstName, lastName, email, phoneNumber, customerId, language },
  });

  public static userInviteSuccess = (data: IUserInviteSuccessOptions): IUserInviteSuccessAction => ({
    type: UserInviteActions.INVITE_USER_SUCCESS,
    payload: data,
  });

  public static userInviteError = ({ error }: IActionCreatorErrorOptions): IUserInviteErrorAction => ({
    type: UserInviteActions.INVITE_USER_ERROR,
    error,
  });
}
