import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { call, getContext, put, takeLatest } from 'typed-redux-saga';
import { MachineClaimResponse, MachineUnclaimResponse } from '../interfaces/Machine.types';
import { IHideMachineReclaimingModalAction, MachineModalActions } from '../modals/state/machineModalActions';
import { IGetMachineListRequestAction, MachineListActions } from '../machine-list/state/machineListActions';
import {
  IGetAlgsErrorAction,
  IGetAlgsSuccessAction,
  IReclaimMachineErrorAction,
  IReclaimMachineRequestAction,
  IReclaimMachineSuccessAction,
  IUnclaimMachineErrorAction,
  IUnclaimMachineRequestAction,
  IUnclaimMachineSuccessAction,
  MachineDirectoryActions,
} from './machineDirectoryActions';
import { customerModalsActions } from 'app/modules/customer-management/modals/state/customerModalsSlice';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { Optional } from 'lib/types/Optional';
import {
  ListAlgNumberResponse,
  SortOrders,
} from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { DEFAULT_PAGE_SIZE_VALUE } from 'config/constants';

export function* getAlgsSaga(): Generator<
  GetContextEffect | CallEffect | PutEffect<IGetAlgsSuccessAction> | PutEffect<IGetAlgsErrorAction>,
  void,
  IDependencies
> {
  const { machineService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response: ListAlgNumberResponse = yield* call(machineService.listAlgs);
    yield* put(MachineDirectoryActions.getAlgsSuccess(response));
  } catch (error) {
    yield* put(
      MachineDirectoryActions.getAlgsError({
        error: (error as Error).message,
      })
    );

    yield* call(toastService.error, {
      message: t('machineClaiming.toasts.listAlgs.error.message'),
      description: (error as Error).message,
    });
  }
}

export function* reClaimMachineSaga(
  action: IReclaimMachineRequestAction
): Generator<
  | GetContextEffect
  | IDependencies
  | CallEffect<Optional<MachineClaimResponse>>
  | CallEffect<void>
  | PutEffect<IReclaimMachineSuccessAction>
  | PutEffect<IGetMachineListRequestAction>
  | PutEffect<IReclaimMachineErrorAction>
  | PutEffect<IHideMachineReclaimingModalAction>
  | void
> {
  const { machineService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.reClaim, action.payload);

    yield* put(MachineDirectoryActions.reClaimMachineSuccess(response));

    yield* put(
      MachineListActions.getMachineListRequest({
        sortOptions: {
          field: 'dateOfClaim',
          order: SortOrders.Desc,
        },
        paginationOptions: {
          paginationToken: '',
          limit: DEFAULT_PAGE_SIZE_VALUE,
        },
      })
    );

    yield* put(MachineModalActions.hideMachineReclaimingModal());

    if (response?.adminMachineClaim) {
      yield* call(toastService.success, {
        message: t('machineReclaiming.toasts.machineReclaiming.success.message'),
        description: t('machineReclaiming.toasts.machineReclaiming.success.description'),
      });
    }
  } catch (error) {
    yield* put(MachineDirectoryActions.reClaimMachineError({ error }));

    yield* call(toastService.error, {
      message: t('machineReclaiming.toasts.machineReclaiming.error.message'),
      description: (error as Error).message,
    });
  }
}

export function* unClaimMachineSaga(
  action: IUnclaimMachineRequestAction
): Generator<
  | GetContextEffect
  | IDependencies
  | CallEffect<Optional<MachineUnclaimResponse>>
  | CallEffect<void>
  | PutEffect<IUnclaimMachineSuccessAction>
  | PutEffect<IGetMachineListRequestAction>
  | PutEffect<IUnclaimMachineErrorAction>
  | PutEffect<IHideMachineReclaimingModalAction>
  | void
> {
  const { machineService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(machineService.unClaim, action.payload);

    yield* put(MachineDirectoryActions.unclaimMachineSuccess(response));

    yield* put(
      MachineListActions.getMachineListRequest({
        sortOptions: {
          field: 'dateOfClaim',
          order: SortOrders.Desc,
        },
        paginationOptions: {
          paginationToken: '',
          limit: DEFAULT_PAGE_SIZE_VALUE,
        },
      })
    );

    yield* put(MachineModalActions.hideMachineUnclaimModal());
    yield* put(customerModalsActions.closeUnclaimMachineModal());

    if (response?.unclaimMachine) {
      yield* call(toastService.success, {
        message: t('machineUnclaim.toasts.success.message'),
        description: t('machineUnclaim.toasts.success.description'),
      });
    }
  } catch (error) {
    yield* put(MachineDirectoryActions.unclaimMachineError({ error }));

    yield* call(toastService.error, {
      message: t('machineUnclaim.toasts.error.message'),
      description: (error as Error).message,
    });
  }
}

export function* machineDirectorySaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(MachineDirectoryActions.GET_ALGS_REQUEST, getAlgsSaga);
  yield* takeLatest(MachineDirectoryActions.RECLAIM_MACHINE_REQUEST, reClaimMachineSaga);
  yield* takeLatest(MachineDirectoryActions.UNCLAIM_MACHINE_REQUEST, unClaimMachineSaga);
}
