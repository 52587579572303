import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { FormInstance, useForm } from 'antd/lib/form/Form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ValidatorRule } from 'rc-field-form/lib/interface';
import { selectIsMachinePairingModalVisible, selectMachinePairModalMessage } from '../../state/machineModalSelectors';
import { MachineModalActions } from '../../state/machineModalActions';
import { StyledMachinePairingModal } from './MachinePairingModal.styles';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { Input } from 'lib/components/Input/Input';
import { MachineClientPairOptions } from 'app/modules/machine-directory/MachineClient';
import { MachineClaimPairActions } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairActions';
import { MachineClaimPairSelectors } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairSelectors';
import { MN_VALIDATION } from 'app/modules/machine-directory/utils/constant';

export interface IMachinePairingModalProps {
  formValue: MachineClientPairOptions;
}

export const MachinePairingModal = ({ formValue }: IMachinePairingModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formContinuerPair] = useForm();

  const isLoadingPair = useSelector(MachineClaimPairSelectors.selectMachinePairLoading);
  const isVisible = useSelector(selectIsMachinePairingModalVisible);
  const pairingMessage = useSelector(selectMachinePairModalMessage);

  const handlePair = useCallback(() => {
    formContinuerPair.submit();
  }, [formContinuerPair]);

  const handleFinishPair = useCallback(() => {
    dispatch(MachineClaimPairActions.pairMachineRequest({ ...formValue }));
  }, [dispatch, formValue]);

  const handleCancel = useCallback(() => {
    dispatch(MachineModalActions.hideMachinePairingModal());
  }, [dispatch]);

  useEffect(() => {
    formContinuerPair.setFieldsValue(formValue);
  }, [formContinuerPair, formValue]);

  const handleValidationSN = ({ getFieldValue }: FormInstance): ValidatorRule => ({
    validator(_, value): Promise<void> {
      if (getFieldValue('machineMaterialNumber') === MN_VALIDATION && value.length !== 11) {
        return Promise.reject(new Error(t('machineClaiming.forms.errors.SNValidate')));
      }
      return Promise.resolve();
    },
  });

  return (
    <StyledMachinePairingModal
      className="machine-pairing__modal"
      title={t('pairMachine.modal.title')}
      width={600}
      centered
      open={isVisible}
      closable={false}
      footer={[
        <SecondaryButton size="m" key="submit-button" type="primary" loading={isLoadingPair} onClick={handlePair}>
          {t('pairMachine.modal.title')}
        </SecondaryButton>,
        <PrimaryButton size="m" key="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </PrimaryButton>,
      ]}
    >
      <Form
        form={formContinuerPair}
        layout="vertical"
        name="machine-pairing"
        onFinish={handleFinishPair}
        className="machine-pairing__modal"
        validateMessages={{ required: t('forms.errors.required') }}
        initialValues={formValue}
      >
        <Row gutter={24} className="machine-pairing__modal__info">
          <Col span={24} className="machine-pairing__modal__already-paired">
            {t(pairingMessage)}
          </Col>
          <Col span={24} className="machine-pairing__modal__question">
            {t('pairMachine.modal.question')}
          </Col>
        </Row>
        <Row gutter={24} className="machine-pairing__modal machine-pairing__modal__machine-data">
          <Col span={24} className="machine-pairing__modal-title">
            {t('pairMachine.machineDataTitle')}
          </Col>
          <Col span={12}>
            <Form.Item
              name="machineMaterialNumber"
              label={t('machineClaiming.forms.materialNumber')}
              className="machine-pairing__modal__form-item"
            >
              <Input className="machine-pairing__modal__input" disabled />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="machineSerialNumber"
              label={t('machineClaiming.forms.serialNumber')}
              className="machine-pairing__modal__form-item"
              rules={[handleValidationSN(formContinuerPair)]}
            >
              <Input className="machine-pairing__modal__input" disabled />
            </Form.Item>
          </Col>
          <Col span={24} className="machine-pairing__modal-title">
            {t('pairMachine.moduleDataTitle')}
          </Col>
          <Col span={12}>
            <Form.Item
              name="gatewayMaterialNumber"
              label={t('machineClaiming.forms.materialNumber')}
              className="machine-pairing__modal__form-item"
            >
              <Input className="machine-pairing__modal__input" disabled />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="gatewaySerialNumber"
              label={t('machineClaiming.forms.serialNumber')}
              className="machine-pairing__modal__form-item"
            >
              <Input className="machine-pairing__modal__input" disabled />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </StyledMachinePairingModal>
  );
};
