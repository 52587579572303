import styled from 'styled-components';

export const StyledUserDetails = styled.div`
  font-family: ${(props): string => props.theme.fonts.body};
  font-size: ${(props): string => props.theme.fontSizes.pageDescription};
  min-height: inherit;

  .user-details__header {
    background-color: ${(props): string => props.theme.colors.lightGray};
    position: sticky;
    top: -128px; // header - breadcrumb = 176 - 48
    z-index: 97;
  }

  .user-details__header-content {
    max-width: 1056px;
    margin: auto;
    padding: 38px 24px 15px;
  }

  .user-details__delete-user {
    margin-bottom: 28px;
  }

  .user-details__title {
    font-size: ${(props): string => props.theme.fontSizes.pageTitle};
    color: ${(props): string => props.theme.colors.text};
    font-family: ${(props): string => props.theme.fonts.pageTitle};
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  .user-details__info-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 150px 0 0;
  }

  .reassign__button {
    text-transform: uppercase;
  }

  .user-details__main-info-label {
    font-family: ${(props): string => props.theme.fonts.filterLabel};
    font-size: ${(props): string => props.theme.fontSizes.filterLabel};
    color: ${(props): string => props.theme.colors.border};
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .user-details__main-info-val {
    font-family: ${(props): string => props.theme.fonts.filterLabel};
    font-size: ${(props): string => props.theme.fontSizes.filterLabel};
    color: ${(props): string => props.theme.colors.black};
    text-transform: uppercase;
  }

  .user-details__body-content {
    font-family: ${(props): string => props.theme.fonts.formBody};
    color: ${(props): string => props.theme.colors.text};
    max-width: 1056px;
    margin: 26px auto 0;
    padding: 0 24px;

    .user-details__title {
      font-family: ${(props): string => props.theme.fonts.heading};
      font-size: ${(props): string => props.theme.fontSizes.formSubheading};
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    .user-details__info-title {
      font-family: ${(props): string => props.theme.fonts.heading};
      font-size: ${(props): string => props.theme.fontSizes.formLabel};
      text-transform: uppercase;
    }

    .user-details__user-status-title {
      font-family: ${(props): string => props.theme.fonts.heading};
      font-size: ${(props): string => props.theme.fontSizes.formLabel};
      text-transform: uppercase;
      margin: 10px 0 5px 0;
    }

    .user-details__edit-user-btn {
      text-transform: uppercase;
    }

    .user-details__delete-user-btn {
      text-transform: uppercase;
    }

    .user-details__resendInvitation-user-btn {
      text-transform: uppercase;
    }

    .user-details__user-status {
      margin: 0 0 20px 0;
    }

    .user-details__main-info-tag {
      font-family: ${(props): string => props.theme.fonts.body};
      color: ${(props): string => props.theme.colors.gray};
      background-color: ${(props): string => props.theme.colors.semiGray};
      font-size: ${(props): string => props.theme.fontSizes.formBody};
      border-radius: 10px;
      border-width: 0;
      line-height: 25px;
    }

    .user-details__main-info-tag-active {
      background-color: ${(props): string => props.theme.colors.userStatusColor};
      color: ${(props): string => props.theme.colors.white};
    }

    .user-details__info-content {
      word-wrap: break-word;
    }
  }

  @media screen and (max-width: 1406px) {
    .user-details__header-content,
    .user-details__body-content {
      margin-left: 175px;
    }
  }
`;
