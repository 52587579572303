import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isNil } from 'lodash-es';
import { Col, Row } from 'antd';
import { customerModalsActions } from '../../../state/customerModalsSlice';
import { customerModalSelector } from '../../../state/customerModalsSelectors';
import { StyledCustomerDeleteModal } from './CustomerDeleteModal.styles';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';

export interface CustomerDeleteModalProps {
  customerName: string;
}
export const CustomerDeleteModal = (props: CustomerDeleteModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const isOpen = !!useSelector(customerModalSelector.selectIsDeleteCustomerModalOpen);
  const isLoading = !!useSelector(customerModalSelector.selectIsDeleteCustomerModalLoading);

  const handleCancel = useCallback(() => {
    dispatch(customerModalsActions.closeDeleteCustomerModal());
  }, [dispatch]);

  const handleDelete = useCallback(() => {
    if (isNil(id)) return;

    dispatch(customerModalsActions.deleteCustomerModalRequest({ customerId: id }));
  }, [id, dispatch]);

  return (
    <StyledCustomerDeleteModal
      className="customer-delete-modal"
      title={t('customerDetails.modals.delete.title')}
      width={600}
      centered
      closable={false}
      open={isOpen}
      footer={[
        <SecondaryButton size="m" key="cancel-button" className="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          loading={isLoading}
          onClick={handleDelete}
        >
          {t('customerDetails.body.button.deleteCustomer')}
        </PrimaryButton>,
      ]}
    >
      <Row justify="center" align="middle">
        <Col span={24}>
          <p className="customer-delete-modal__description">{t('customerDetails.modals.delete.description')}</p>
          {props.customerName && <p className="customer-delete-modal__customer-name">{props.customerName || ''}</p>}
        </Col>
      </Row>
    </StyledCustomerDeleteModal>
  );
};
