export const getInitialNames = (fullName: string): string => {
  const splitName = fullName.split(' ');

  if (splitName.length === 1) {
    return splitName[0].charAt(0).toUpperCase();
  }

  const firstCharOfFirstName = splitName[0].charAt(0);
  const firstCharOfLastName = splitName[splitName.length - 1].charAt(0);

  return firstCharOfFirstName.toUpperCase() + firstCharOfLastName.toUpperCase();
};
