/* eslint-disable max-len */
export interface IJsonWebKey {
  alg: string;
  e: string;
  kid: string;
  kty: 'RSA';
  n: string;
  use: string;
}
export interface IUserPoolConfig {
  [key: string]: {
    env: string;
    keys: IJsonWebKey[];
  };
}
/**
 * Cognitp user pool configuration and JSON Web Key sets
 *
 * @see https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-verifying-a-jwt.html
 */
export const userPoolConfig: IUserPoolConfig = {
  'eu-west-1_KdPQyZKER': {
    env: 'dev',
    // Downloaded from https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_n3VzTh1TV/.well-known/jwks.json
    keys: [
      {
        alg: 'RS256',
        e: 'AQAB',
        kid: '+RQpSNJmjFE0a91QfuAlMG358yD6jnl1F2tneCaI+5E=',
        kty: 'RSA',
        n: 'w6hLGgFzpd4WQ4z0gEMr4ocQYEIZmSZYiKUdpBfxSCgvQErMdQOo-S2P3qNvFOUbHBLh8qImaGh6i-JnWjeLgAcSrBjYhImFozQ3wxX-xRwXy4u3gLHhAD1BXJhlzEFZwEqTZ_kAeKQlcmheGYaMqcYEi3Iluk7kOl8o0-86RAYOCOBF-IOT2RhRaL2Xp1LJfztT-d0JRFFJxDDNm_cFCcZfgRv-xpO9LO-A-gSinn2kBjJM6tc5Njc0ilL_H9xFzzy-r5oEZDcAtuK-7s_4YcZAxHDJSQrgTbkgKf2FwdxAMcOZUVDk7bro7T08IW_9Oql-_SodvsYjPBd1MTyufQ',
        use: 'sig',
      },
      {
        alg: 'RS256',
        e: 'AQAB',
        kid: 'Ct7sPXomOeTjbwbgTRmt+eTX809MsAzHf2fy0CHV5aU=',
        kty: 'RSA',
        n: 'q7dm6SyNYIzjkBU7r2GTdG1Dcq0l1_sy4df8lDTJ42W-xRT6pdxgvnCdhGxZZBpNa14lP4Zx1x38P2qwFnEjgu2DpKmUhxhz5MpJ1v9_mYp2gm753tHnhW9zq7XiwjSC2x5Sw0gH90sHp4cFwmwKKP5e-WCveQzG1_-H8uPlMIvge9Qhnhi5qMBIRiR9tniO89L4haPIrLE6vtawKyxPv8RaJ4Z8_yDxu1pkTbwuJh2QGQEmJdOnEoJPBs_zShCo0SETKNkPWvmL8BVXneKHyyiLY164-NkZKUQ_c6mgEcYLWutnlwZyaZkJ2xfvQUKCdvjRANGo_2NrSFM9o5gFiQ',
        use: 'sig',
      },
    ],
  },
  'eu-west-1_G7pgMuSEO': {
    env: 'stage',
    // Downloaded from https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_lTjXyG5sf/.well-known/jwks.json
    keys: [
      {
        alg: 'RS256',
        e: 'AQAB',
        kid: 'x97/Wi2GVv0G9uqFMtATm2ydNaMMUaovRxuLHoLie44=',
        kty: 'RSA',
        n: 'mC0jR6owxvTyPlt5yBkUEzqXnxxffa2utqL5LSYo3AiItfleSbENzmYjRX5s3SlMlDKJBh_JqG3n4qxS_qVaAGfd601Z9vNfFr9K0R1umA1401ewHOgf6Rj4wsMy1I18uDKbToF0cz24syDck2dUIk1AGelzdtUDfpoGfL9gP7pqxNOsISdeMM33ePPeD2V2lEYFjcGdo4VHo8Q9cy6pYLmIPYxU2mJdpMNXbBPkwh0fXP9V0Gg6tDe02HJy1IhuKyPUNli0nVEG9fMorGytsSGOu6Sy24k_Vq4UlcZJ8G2P-avruN9qYojfNo64ocTanzZemfAfbixCDTr9QOpzZw',
        use: 'sig',
      },
      {
        alg: 'RS256',
        e: 'AQAB',
        kid: 'O3WRWKFmFxiqptzUjQlknjl/AjE89C55r5KT8EVoHFc=',
        kty: 'RSA',
        n: 'tiO2nsY-a_oJ8EV1pBmxu6GUjZHPw30g2o5taYbq1Lhu36ipU9-71eBQAVLTCvDGSfLmNAebXKD4dK6CrUznN4iuS4MIWs0V_NUqlRRBDXgLlSnUJbJ9c1H5J4igy-Fqpxec9RsxU2L5L6-Divr3MAFitBan0Um2GPyk8xuU5mQk58PyF370_uyIJiGi9a2V6kDMjT1T0bA-sn_SytEbvbwHRx10r_IN_bUj1fw2QLjLifB87J00AvdNBqGAuAgP5S2DtkhcD4mGKn4h69whcGABbt0B758zrQXPD2pvKNdNLLbZVnNQClaB3kx9aSfZ5-e1CSVEgisRr1XdqQIgBw',
        use: 'sig',
      },
    ],
  },
  'eu-west-1_8hsslQvF8': {
    env: 'prod',
    // Downloaded from https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_nmPHsyt4o/.well-known/jwks.json
    keys: [
      {
        alg: 'RS256',
        e: 'AQAB',
        kid: 'ooW7zIcgEuUpc4va9DfqIWo7ZcBT7ZPN7X7G4p039ds=',
        kty: 'RSA',
        n: 'whvp5C_LFLjWA56VKQjmd9oG_LhWGJseZGnb7rtrUWTte-zMWIEA3KJcKzoz8CRI4mUrJtKM3qlr0fSUSIdIisFEYMiirEdHnL-cyorbOKUW2HlmJOGzYW1oKt_jh_oIjHLdOgHM0pLeBvCeXeP3aDUw-k5ftC3Ovjgbf9m4nd9dcvjq3pBYH5PW3FFbOmRNHdwUO3t4p_eoHQOi8h2MvIa3Mx3VA5tjSkga_pMH8fFyc2h3dnX9nB4YaYxZ1d8hxWZ31rDlnHJarh3PySFHswgCv4QXD_Yxm7PuRoJI4FNSEEoVAmeQsfV2VnaMSg9m5MCtlrK-WMls0E79yxI7gw',
        use: 'sig',
      },
      {
        alg: 'RS256',
        e: 'AQAB',
        kid: 'aUz+W9/P1K/VKfpLJKS76N1zpRCpMRjo/3PXS4Gh234=',
        kty: 'RSA',
        n: 'taoKrhSOvmJFej7bObRW2cqhm8iA_-tQT0IF9eNK8_sucQ0OjMZdWbKMtINNljn4AXwYsqgTRHTilUckSexKXIPdAJdX50AAQ_fftKFdkzYrkIwCNAOjn5w5rYi6IIEFeo-TbW-S_C80wSUGSfmgaqU8DkTNeTui9LkXAL98Qj5B0DnSD5Dwb72iKIH5MNX7jb5sw2UTJ7o6p5HE-Q263JeGrkB5DLOj_u5GchqA3LgQ872Ky0tDvZIk5zeumr-6N16_rnVSkK9WSr8HZ2zsqd8QJ-esQclPnCVGagn2efrPFeMhLof2OovGyJIA50HLJH-BqAfLbaJotUqN8Jf_9Q',
        use: 'sig',
      },
    ],
  },
};
