import styled from 'styled-components';

export const StyledCustomerDeleteDescription = styled.div`
  .customer-delete-modal__description {
    font-family: ${(props): string => props.theme.fonts.formBody};
    font-size: ${(props): string => props.theme.fontSizes.p0};
    font-weight: ${(props): number => props.theme.fontWeights.captionSmall};
    line-height: ${(props): string => props.theme.lineHeights.p};

    b {
      font-family: ${(props): string => props.theme.fonts.formSubheading};
      font-weight: ${(props): number => props.theme.fontWeights.captionBold};
    }
  }
`;
