import styled from 'styled-components';

export const StyledMachineClaimingPairing = styled.div`
  font-family: ${(props): string => props.theme.fonts.body};
  font-size: ${(props): string => props.theme.fontSizes.pageDescription};
  min-height: inherit;

  .machine-claiming-pairing__header {
    background-color: ${(props): string => props.theme.colors.lightGray};
    position: sticky;
    top: -94px;
    z-index: 97;
  }

  .machine-claiming-pairing__header-content {
    max-width: 1056px;
    margin: auto;
    padding: 38px 24px 15px;
  }

  .machine-claiming-pairing__body-content {
    max-width: 1056px;
    margin: 26px auto 0;
    padding: 0 24px;
  }

  .machine-claiming-pairing__title {
    font-size: ${(props): string => props.theme.fontSizes.pageTitle};
    color: ${(props): string => props.theme.colors.text};
    font-family: ${(props): string => props.theme.fonts.pageTitle};
    text-transform: uppercase;
    margin-bottom: 7px;
  }

  @media screen and (max-width: 1406px) {
    .machine-claiming-pairing__header-content,
    .machine-claiming-pairing__body-content {
      margin-left: 175px;
    }
  }

  .auto-complete__invalid-message {
    margin: 0;
    color: ${(props): string => props.theme.colors.errorExplainColor};
  }
`;
