import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { debounce, noop } from 'lodash-es';
import { SelectValue } from 'antd/lib/select';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { useDispatch, useSelector } from 'react-redux';
// // eslint-disable-next-line import/no-extraneous-dependencies
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Permission } from '../../../../../../config/permissions';
import { UserListActions } from '../../state/userListActions';
import { userListSelectors } from '../../state/userListSelectors';
import { ReactComponent as DownloadIcon } from '../../../../../assets/images/users/download.svg';
import { StyledUserList } from './UserList.styles';
import { getUserListColumns } from './columns/UserList.columns';
import { Select } from 'lib/components/Select/Select';
import { Table } from 'lib/components/Table/Table';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { AutoCompleteInput } from 'lib/components/AutoCompleteInput/AutoCompleteInput';
import { SortOrders, User } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { isFeatureEnabled } from 'lib/utils/feature-flags/isFeatureEnabled';
import { RoutePaths } from 'config/route-paths';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { Optional } from 'lib/types/Optional';

const { Option } = Select;

export const UserList = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const data = useSelector(userListSelectors.selectData) || [];

  const totalCount = useSelector(userListSelectors.selectTotalCount) || 0;
  const paginationTokens = useSelector(userListSelectors.selectPaginationTokens);
  const isLoading = !!useSelector(userListSelectors.selectIsLoading);
  const page = useSelector(userListSelectors.selectPage);
  const pageSize = useSelector(userListSelectors.selectPageSize);
  const algs = useSelector(userListSelectors.selectFilters);
  const areFiltersLoading = useSelector(userListSelectors.selectAreFiltersLoading);
  const activeAlgFilter = useSelector(userListSelectors.selectActiveAlgFilter);
  const sortField = useSelector(userListSelectors.selectSortField) || 'createdAt';
  const sortOrder = useSelector(userListSelectors.selectSortOrder) || SortOrders.Desc;
  const searchText = useSelector(userListSelectors.selectSearchText);

  const isSortingAndFilteringEnabled = isFeatureEnabled(
    process.env.REACT_APP_FEATURE_ENABLE_USER_LIST_FILTERS_AND_SORTING
  );
  const isFilterAlgEnabled = isFeatureEnabled(process.env.REACT_APP_FEATURE_ENABLE_USER_LIST_FILTERS_ALG);
  const isUploadUsersEnabled = isFeatureEnabled(process.env.REACT_APP_FEATURE_ENABLE_USER_LIST_UPLOAD_USERS);
  const isSearchUsersEnabled = isFeatureEnabled(process.env.REACT_APP_FEATURE_ENABLE_USER_LIST_SEARCH_USERS);

  useEffect(() => {
    if (!isSortingAndFilteringEnabled) return;

    dispatch(UserListActions.getUserListFiltersRequest());
  }, [dispatch, isSortingAndFilteringEnabled]);

  useEffect(() => {
    dispatch(UserListActions.userListLoading());

    return (): void => {
      dispatch(UserListActions.resetState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading) {
      dispatch(
        UserListActions.getUserListRequest({
          filter: {
            alg: activeAlgFilter,
          },
          search: searchText,
          sortOptions: {
            field: sortField,
            order: sortOrder,
          },
          paginationOptions: {
            limit: pageSize,
            paginationToken: paginationTokens[page] || '',
          },
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, sortOrder, sortField, searchText, activeAlgFilter]);

  const handleAlgFilterChange = (value: SelectValue): void => {
    dispatch(UserListActions.setActiveAlgFilter(value?.toString()));
  };

  const handleClickTableRow = (user: User): void => {
    navigate(RoutePaths.USER_DETAILS.replace(':id', user.internalUserId as string));
  };

  const onPageChange = (value: number): void => {
    dispatch(UserListActions.changeTablePage({ page: value }));
  };

  const handleInvitation = (): void => {
    navigate(RoutePaths.INVITE_USER);
  };

  const handleUploadUsers = (): void => noop();

  const onPageLimitChange = (value: number): void => {
    dispatch(UserListActions.changeTablePageSize({ pageSize: value }));
  };

  const onChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    let sorterOrder = SortOrders.Asc;
    if (sorter.order === 'descend') sorterOrder = SortOrders.Desc;
    dispatch(UserListActions.setActiveSortField(sorter.field as Optional<string>));
    dispatch(UserListActions.setActiveSortOrder(sorterOrder as Optional<SortOrders>));
  };

  const handleSearchUser = debounce((e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(UserListActions.setActiveSearchText(e.target.value));
  }, 500);

  const tableColumns = getUserListColumns({ t, isSortingAndFilteringEnabled });

  return (
    <StyledUserList className="user-list">
      <div className="user-list__header">
        <div className="user-list__header-content">
          <div className="user-list__page-info">
            <h1 className="user-list__title">{t('userList.title').toLocaleUpperCase()}</h1>
          </div>

          <div className="user-list__filter-wrapper">
            {isSortingAndFilteringEnabled && (
              <div className="user-list__filter">
                {isFilterAlgEnabled && (
                  <div className="user-list__select-wrapper">
                    <span className="user-list__filter-label">{t('userList.filter.ALG')}</span>
                    <Select
                      defaultValue={activeAlgFilter || ''}
                      className="user-list__select"
                      loading={areFiltersLoading}
                      onChange={handleAlgFilterChange}
                      showSearch
                    >
                      <Option value="">{t('common.all')}</Option>
                      {algs?.map((alg, i) => (
                        <Option value={alg} key={`${alg} - ${i}`}>
                          {alg}
                        </Option>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
            )}
            <div className="user-list__action-buttons-wrapper">
              {isUploadUsersEnabled && (
                <PermissionGuard requiredPermissions={[Permission.Customer.User.SEND_INVITATION]}>
                  <PrimaryButton
                    size="m"
                    className="user-list__upload-users-btn"
                    onClick={handleUploadUsers}
                    icon={<DownloadIcon />}
                  >
                    {t('userList.uploadUsers')}
                  </PrimaryButton>
                </PermissionGuard>
              )}
              <PermissionGuard requiredPermissions={[Permission.Customer.User.SEND_INVITATION]}>
                <PrimaryButton size="m" className="user-list__invitation-btn" onClick={handleInvitation}>
                  {t('userList.newUserInvitation')}
                </PrimaryButton>
              </PermissionGuard>
            </div>
          </div>
        </div>
      </div>
      <div className="user-list__body">
        <div className="user-list__body-content">
          <Row gutter={[26, 26]}>
            <Col xs={24} sm={24} md={24} lg={24}>
              {isSearchUsersEnabled && (
                <div className="user-list__search-input">
                  <AutoCompleteInput
                    placeholder={t('userList.searchInputHolder')}
                    className="user-list__search-custom-input"
                    prefix={<SearchOutlined />}
                    onChange={handleSearchUser}
                  />
                </div>
              )}
              <Table
                pagination={{
                  total: totalCount,
                  pageSize,
                  page,
                  onPageChange,
                  onPageLimitChange,
                  isLoading,
                }}
                dataSource={data}
                loading={isLoading}
                className="user-list__table"
                columns={tableColumns}
                rowKey="email"
                onRow={(userRowData: User): { onClick(): void } => ({
                  onClick: (): void => {
                    handleClickTableRow(userRowData);
                  },
                })}
                onChange={onChange}
                sortDirections={['ascend', 'descend', 'ascend']}
              />
            </Col>
          </Row>
        </div>
      </div>
    </StyledUserList>
  );
};
