import styled from 'styled-components';

export const StyledMachineUnclaimModal = styled.div`
  .machine-selection__header-content {
    margin: 26px auto 0;

    .machine-selection__subTitle {
      font-size: ${(props): string => props.theme.fontSizes.formSubheading};
      font-family: ${(props): string => props.theme.fonts.heading};
      color: ${(props): string => props.theme.colors.text};
      text-transform: uppercase;
    }
  }

  .ant-table-footer {
    display: none;
  }

  .machine-selection__table {
    .ant-table-thead {
      .ant-table-cell {
        font-family: ${(props): string => props.theme.fonts.heading};
        font-size: ${(props): string => props.theme.fontSizes.formTitle};
        color: ${(props): string => props.theme.colors.text};
        background-color: ${(props): string => props.theme.colors.semiGray};
        text-transform: uppercase;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        cursor: pointer;

        .ant-table-cell {
          font-family: ${(props): string => props.theme.fonts.modalBody};
          font-size: ${(props): string => props.theme.fontSizes.pageDescription};
          vertical-align: top;
          background-color: inherit !important;
        }
      }
    }

    // TODO: uncomment later whenever the api reclaim changed
    /* .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: ${(props): string => props.theme.colors.text};
        background-color: ${(props): string => props.theme.colors.text};
      }

      &::after {
        border-color: ${(props): string => props.theme.colors.text};
      }
    }

    .ant-checkbox {
      .ant-checkbox-inner {
        border: 2px solid ${(props): string => props.theme.colors.text};
        border-radius: 0;
        width: 20px;
        height: 20px;
      }
    } */
  }

  .machine-list__avatar-column {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .machine-list__avatar-image {
    width: 48px;
    height: 48px;
  }
`;
