import { find, isNil } from 'lodash-es';
import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { call, getContext, put, retry, select, takeLatest } from 'typed-redux-saga';
import { IDependencies } from '../../../../cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import {
  CustomerListAlgNumbersResponse,
  CustomerListResponse,
  PollCustomerListOperation,
} from '../../interfaces/Customer.types';
import { Optional } from '../../../../../lib/types/Optional';
import {
  CustomerManagementEntity,
  SortOrders,
} from '../../../../cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { POLL_INTERVAL, POLL_MAX_RETRIES } from '../../../../../config/constants';
import { customerModalsActions } from '../../modals/state/customerModalsSlice';
import * as customerListActionTypes from './customerListActions.types';
import { CustomerListActions } from './customerListSlice';
import { customerListSelector } from './customerListSelectors';

export function* getCustomerListSaga(
  action: customerListActionTypes.CustomerListRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<CustomerListResponse>
  | PutEffect<customerListActionTypes.CustomerListSuccessAction>
  | PutEffect<customerListActionTypes.CustomerListErrorAction>,
  void,
  IDependencies
> {
  const { customerService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(customerService.list, action.payload);
    yield* put(CustomerListActions.customerListSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      CustomerListActions.customerListError({
        error,
      })
    );
  }
}

export function* getCustomerListAlgsSaga(): Generator<
  | GetContextEffect
  | CallEffect<Optional<CustomerListAlgNumbersResponse>>
  | PutEffect<customerListActionTypes.CustomerListAlgsSuccessAction>
  | PutEffect<customerListActionTypes.CustomerListAlgsErrorAction>,
  void,
  IDependencies
> {
  const { customerService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(customerService.listAlgs);
    yield* put(CustomerListActions.customerListAlgsSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(
      CustomerListActions.customerListAlgsError({
        error,
      })
    );
  }
}

export function* pollCustomerListSaga(
  action: customerListActionTypes.CustomerListPollRequestAction
): Generator<
  | GetContextEffect
  | SelectEffect
  | CallEffect<CustomerListResponse>
  | PutEffect<customerListActionTypes.CustomerListSuccessAction>
  | PutEffect<customerListActionTypes.CustomerListErrorAction>,
  void,
  IDependencies
> {
  const { customerService } = yield* getContext<IDependencies>('dependencies');
  const { customerId, operation } = action.payload;
  const previousCustomerList: CustomerManagementEntity[] = yield* select(customerListSelector.selectData);
  const alg = yield* select(customerListSelector.selectActiveAlgFilter);
  const search = yield* select(customerListSelector.selectSearchText);
  const sortOptions = yield* select(customerListSelector.selectSortOrder);
  const sortField = yield* select(customerListSelector.selectSortField);
  const paginationToken = yield* select(customerListSelector.selectPaginationTokens);
  const page = yield* select(customerListSelector.selectPage);

  const getCustomerListAfterMutation = async (): Promise<CustomerListResponse> => {
    let predicate: () => boolean;

    const checkCustomer: CustomerListResponse = await customerService.list({
      search: customerId,
    });

    const newCustomerList: CustomerManagementEntity[] =
      (checkCustomer.searchCustomers.data as CustomerManagementEntity[]) || [];

    switch (operation) {
      case PollCustomerListOperation.CREATE:
        predicate = (): boolean => newCustomerList.some(customer => customer.customerId === customerId);
        break;
      case PollCustomerListOperation.UPDATE: {
        predicate = (): boolean => {
          const modifiedCustomerPrevious = find(previousCustomerList, { customerId });
          const modifiedCustomerNew = find(newCustomerList, { customerId });

          if (isNil(modifiedCustomerPrevious) || isNil(modifiedCustomerNew)) {
            return false;
          }

          if (
            modifiedCustomerPrevious.customerName === modifiedCustomerNew.customerName &&
            modifiedCustomerPrevious.alg === modifiedCustomerNew.alg &&
            modifiedCustomerPrevious.city === modifiedCustomerNew.city &&
            modifiedCustomerPrevious.country === modifiedCustomerNew.country &&
            modifiedCustomerPrevious.district === modifiedCustomerNew.district &&
            modifiedCustomerPrevious.houseNumber === modifiedCustomerNew.houseNumber &&
            modifiedCustomerPrevious.region === modifiedCustomerNew.region &&
            modifiedCustomerPrevious.street === modifiedCustomerNew.street &&
            modifiedCustomerPrevious.zipCode === modifiedCustomerNew.zipCode
          ) {
            return false;
          }

          return true;
        };
        break;
      }

      case PollCustomerListOperation.DELETE:
        predicate = (): boolean => newCustomerList.every(customer => customer.customerId !== customerId);
        break;

      default: {
        throw new Error(`Invalid operation: ${operation}`);
      }
    }

    if (!predicate()) {
      throw new Error('List Customer not updated');
    }

    const response: CustomerListResponse = await customerService.list({
      filter: {
        alg: alg || '',
      },
      search: search || '',
      sortOptions: {
        field: (sortField as string) || 'createdAt',
        order: (sortOptions as SortOrders) || SortOrders.Desc,
      },
      paginationOptions: {
        limit: 50,
        paginationToken: paginationToken[page] || '',
      },
    });

    return response;
  };

  try {
    const response: CustomerListResponse = yield* retry(POLL_MAX_RETRIES, POLL_INTERVAL, getCustomerListAfterMutation);

    yield* put(CustomerListActions.customerListSuccess(response));
  } catch (error) {
    console.error(error);
    yield* put(
      CustomerListActions.customerListError({
        error,
      })
    );
  } finally {
    yield* put(customerModalsActions.resetDeleteCustomerModal());
  }
}

export function* customerListSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(CustomerListActions.customerListRequest, getCustomerListSaga);
  yield* takeLatest(CustomerListActions.customerListAlgsRequest, getCustomerListAlgsSaga);
  yield* takeLatest(CustomerListActions.customerListPollRequest, pollCustomerListSaga);
}
