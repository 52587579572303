import { TableProps as AntdTableProps, Table as AntdTable } from 'antd';
import * as React from 'react';
import { ReactNode } from 'react';
import { IPaginationProps, Pagination } from './Pagination/Pagination';
import { StyledTable } from './Table.styles';

type TableProps = Omit<AntdTableProps<any>, 'pagination' | 'footer'> & {
  pagination?: Omit<IPaginationProps, 'additionalComponents'>;
  footerComponents?: React.ReactNode;
};

export const Table = (props: TableProps): JSX.Element => {
  const { className, pagination, footerComponents, ...rest } = props;
  const tableClassName = [className, 'table'].join(' ');

  return (
    <StyledTable className={tableClassName}>
      <AntdTable
        pagination={false}
        {...rest}
        footer={(): ReactNode => pagination && <Pagination {...pagination} additionalComponents={footerComponents} />}
      />
    </StyledTable>
  );
};
