import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';
import { selectIsMachineReclaimingModalVisible } from '../../state/machineModalSelectors';
import { MachineModalActions } from '../../state/machineModalActions';
import { MachineSelectedTable } from '../../../../../components/MachineSelectedTable/MachineSelectedTable';
import { StyledMachineReclaimingModal } from './MachineReclaimingModal.styles';
import { CustomerSelectionForm } from 'app/components/CustomerSelectionForm/CustomerSelectionForm';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import {
  IReclaimMachineRequestOptions,
  MachineDirectoryActions,
} from 'app/modules/machine-directory/state/machineDirectoryActions';
import { CustomerSearchSelectors } from 'app/modules/user-management/customer-search/state/customerSearchSelectors';
import { CustomerSearchActions } from 'app/modules/user-management/customer-search/state/customerSearchAction';
import { MachineDirectorySelectors } from 'app/modules/machine-directory/state/machineDirectorySelectors';
import { MachineOwnership } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { CustomerSearchFormValues } from 'app/modules/user-management/interfaces/Customer.types';
import { CustomerSelectedTable } from 'app/components/CustomerSelectedTable/CustomerSelectedTable';

export interface MachineReclaimingModalProps {
  selectedMachines: MachineOwnership[];
}

export const MachineReclaimingModal = ({ selectedMachines }: MachineReclaimingModalProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formCustomerSelection] = Form.useForm();
  const [selectedMachinesModal, setSelectedMachinesModal] = useState<MachineOwnership[]>([]);
  const [selectedMachinesId, setSelectedMachinesId] = useState<React.Key[]>([]);
  const [customerSelectedId, setCustomerSelectedId] = useState<string>('');
  const [algNumber, setAlgNumber] = useState<string>('');

  const isVisible = useSelector(selectIsMachineReclaimingModalVisible);
  const isReclaimMachineLoading = useSelector(MachineDirectorySelectors.isReclaimMachineLoading);
  const customerList = useSelector(CustomerSearchSelectors.selectCustomerSearchList);

  useEffect(() => {
    if (isVisible) {
      setSelectedMachinesModal(selectedMachines);
      setSelectedMachinesId(selectedMachines.map(machine => machine.id) as React.Key[]);
      setCustomerSelectedId('');
      dispatch(CustomerSearchActions.customerSearchClearList());
    }
  }, [dispatch, selectedMachines, isVisible]);

  useEffect(() => {
    dispatch(MachineDirectoryActions.getAlgsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (customerList.length > 0 && customerSelectedId !== '') {
      const isCustomerInList = customerList.some(customer => customer.customerId === customerSelectedId);
      if (isCustomerInList) {
        return;
      }
    }
    setCustomerSelectedId('');
  }, [customerList, customerSelectedId]);

  const handleCancel = useCallback(() => dispatch(MachineModalActions.hideMachineReclaimingModal()), [dispatch]);

  const handleSearch = useCallback(() => {
    formCustomerSelection.submit();
  }, [formCustomerSelection]);

  const onSearchFinish = useCallback(
    (values: CustomerSearchFormValues) => {
      const customerSelectionInput: CustomerSearchFormValues = values;

      if (values.customerNameOrNumber === undefined) {
        customerSelectionInput.customerNameOrNumber = '';
      }

      dispatch(
        CustomerSearchActions.customerSearchRequest({
          algNumber: values.algNumber,
          customerName: values.customerNameOrNumber,
          customerNumber: values.customerNameOrNumber,
        })
      );
      setAlgNumber(formCustomerSelection.getFieldValue('algNumber'));
    },
    [formCustomerSelection, dispatch]
  );

  const handleReclaim = useCallback(() => {
    const requestOptions: IReclaimMachineRequestOptions = {
      input: {
        algNumber,
        customerId: customerSelectedId,
        materialNumber: selectedMachines[0].materialNumber as string,
        serialNumber: selectedMachines[0].serialNumber as string,
      },
    };

    dispatch(MachineDirectoryActions.reClaimMachineRequest(requestOptions));
  }, [dispatch, customerSelectedId, algNumber, selectedMachines]);

  const onSelectChangeModal = (selectedRowKeys: React.Key[]): void => {
    if (selectedRowKeys.length > 0) {
      setSelectedMachinesId(selectedRowKeys);
    }
  };

  const onSelectCustomer = (selectedRowKeys: React.Key[]): void => {
    setCustomerSelectedId(selectedRowKeys[0] as string);
  };

  return (
    <StyledMachineReclaimingModal
      className="machine-reclaiming__modal"
      title={
        <>
          {t('machineReclaiming.title')}
          <p className="machine-reclaiming__description">{t('machineReclaiming.description')}</p>
        </>
      }
      centered
      open={isVisible}
      closable={false}
      destroyOnClose
      footer={[
        <SecondaryButton size="m" className="cancel-button" key="cancel-button" onClick={handleCancel}>
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton
          size="m"
          key="submit"
          className="submit-button"
          type="primary"
          loading={isReclaimMachineLoading}
          disabled={!(customerSelectedId !== '')}
          onClick={handleReclaim}
        >
          {t('machineReclaiming.reClaimButton')}
        </PrimaryButton>,
      ]}
    >
      <CustomerSelectionForm
        formCustomerSelection={formCustomerSelection}
        handleSearch={handleSearch}
        onSearchFinish={onSearchFinish}
      />

      {customerList.length > 0 && (
        <CustomerSelectedTable customerList={customerList} onSelectCustomer={onSelectCustomer} />
      )}

      <MachineSelectedTable
        selectedMachinesModal={selectedMachinesModal}
        selectedMachinesId={selectedMachinesId}
        onSelectChangeModal={onSelectChangeModal}
      />
    </StyledMachineReclaimingModal>
  );
};
