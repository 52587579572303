import { getContext, put, takeLatest, call, select } from 'typed-redux-saga';
import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import {
  CustomerCreateResponse,
  CustomerDeleteResponse,
  CustomerUpdateResponse,
  CustomerValidateCreateResponse,
  PollCustomerListOperation,
} from '../../interfaces/Customer.types';
import * as customerListActionTypes from '../../customer-list/state/customerListActions.types';
import { customerDetailsActions } from '../../customer-details/state/customerDetailsSlice';
import { CustomerDetailsSelectors } from '../../customer-details/state/customerDetailsSelectors';
import { CustomerListActions } from '../../customer-list/state/customerListSlice';
import { CustomerDetailsGetRequestAction } from '../../customer-details/state/customerDetailsActions.types';
import { customerModalsActions } from './customerModalsSlice';
import * as customerModalActionTypes from './customerModalsActions.types';
import * as machineListSelectors from 'app/modules/machine-directory/machine-list/state/machineListSelectors';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { RoutePaths } from 'config/route-paths';
import { PollUserListOperation, UserInviteResponse } from 'app/modules/user-management/interfaces/User.types';
import { Optional } from 'lib/types/Optional';
import {
  IGetUserListRequestAction,
  IPollUserListRequestAction,
  UserListActions,
} from 'app/modules/user-management/user-list/state/userListActions';
import { userListSelectors } from 'app/modules/user-management/user-list/state/userListSelectors';
import { SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import {
  MachineClaimResponse,
  MachineUnclaimResponse,
  PollMachineListOperation,
} from 'app/modules/machine-directory/interfaces/Machine.types';
import {
  IGetMachineListRequestAction,
  IPollMachineListRequestAction,
  MachineListActions,
} from 'app/modules/machine-directory/machine-list/state/machineListActions';

export function* createCustomerSaga(
  action: customerModalActionTypes.CustomerCreateModalCreateRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<CustomerCreateResponse>>
  | CallEffect<void>
  | PutEffect<customerListActionTypes.CustomerListPollRequestAction>
  | PutEffect<customerModalActionTypes.CustomerCreateModalCreateSuccessAction>
  | PutEffect<customerModalActionTypes.CustomerCreateModalCreateErrorAction>
  | PutEffect<customerModalActionTypes.CustomerCreateModalCloseAction>,
  void,
  IDependencies
> {
  const { customerService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(customerService.create, action.payload);

    yield* put(customerModalsActions.createCustomerSuccess(response));

    yield* call(toastService.success, {
      message: t('customerCreate.toasts.success.message'),
      description: t('customerCreate.toasts.success.description'),
    });

    yield* put(customerModalsActions.closeCreateCustomerModal());
    yield* put(customerModalsActions.closeConfirmCustomerModal());

    if (!response?.createCustomer.data?.customerId) {
      throw new Error('Customer ID is missing');
    }

    yield* put(
      CustomerListActions.customerListPollRequest({
        customerId: response?.createCustomer.data?.customerId,
        operation: PollCustomerListOperation.CREATE,
      })
    );
  } catch (error) {
    yield* put(customerModalsActions.createCustomerError({ error }));
    yield* call(toastService.error, {
      message: t('customerCreate.form.toasts.error.message'),
      description: (error as Error).message,
    });
  }
}

export function* validateCreateSaga(
  action: customerModalActionTypes.CustomerCreateModalValidateCreateRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<Optional<CustomerValidateCreateResponse>>
  | CallEffect<void>
  | PutEffect<customerModalActionTypes.CustomerCreateModalValidateCreateSuccessAction>
  | PutEffect<customerModalActionTypes.CustomerCreateModalValidateCreateErrorAction>,
  void,
  IDependencies
> {
  const { customerService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(customerService.validateCreate, action.payload);

    yield* put(customerModalsActions.validateCreateCustomerSuccess(response));
  } catch (error) {
    yield* put(customerModalsActions.validateCreateCustomerError({ error }));
    yield* call(toastService.error, {
      message: t('customerCreate.form.toasts.error.message'),
      description: (error as Error).message,
    });
  }
}

export function* deleteCustomerSaga(
  action: customerModalActionTypes.CustomerDeleteModalDeleteRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<void>
  | CallEffect<Optional<CustomerDeleteResponse>>
  | PutEffect<customerListActionTypes.CustomerListPollRequestAction>
  | PutEffect<customerModalActionTypes.CustomerDeleteModalDeleteSuccessAction>
  | PutEffect<customerModalActionTypes.CustomerDeleteModalDeleteErrorAction>
  | PutEffect<customerModalActionTypes.CustomerDeleteModalCloseAction>,
  void,
  IDependencies
> {
  const { customerService, toastService, t, history } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(customerService.delete, action.payload);

    yield* put(customerModalsActions.deleteCustomerModalSuccess(response));

    if (response) {
      yield* call(toastService.success, {
        message: t('customerDetails.toasts.delete.success.message'),
        description: t('customerDetails.toasts.delete.success.description'),
      });

      history.push(RoutePaths.CUSTOMERS);

      yield* put(customerModalsActions.closeDeleteCustomerModal());

      yield* put(
        CustomerListActions.customerListPollRequest({
          customerId: action.payload.customerId,
          operation: PollCustomerListOperation.DELETE,
        })
      );
    }
  } catch (error) {
    console.error(error);

    yield* call(toastService.error, {
      message: t('customerDetails.toasts.delete.error.message'),
      description: (error as Error).message,
    });

    yield* put(customerModalsActions.deleteCustomerModalError({ error }));
  }
}

export function* updateCustomerSaga(
  action: customerModalActionTypes.CustomerEditModalEditRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<void>
  | CallEffect<Optional<CustomerUpdateResponse>>
  | PutEffect<CustomerDetailsGetRequestAction>
  | PutEffect<customerModalActionTypes.CustomerEditModalEditSuccessAction>
  | PutEffect<customerModalActionTypes.CustomerEditModalEditErrorAction>
  | PutEffect<customerModalActionTypes.CustomerEditModalCloseAction>,
  void,
  IDependencies
> {
  const { customerService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(customerService.update, action.payload);

    yield* put(customerModalsActions.editCustomerModalSuccess(response));

    if (response) {
      yield* put(customerModalsActions.closeEditCustomerModal());

      yield* call(toastService.success, {
        message: t('customerDetails.toasts.edit.success.message'),
        description: t('customerDetails.toasts.edit.success.description'),
      });

      yield* put(customerDetailsActions.getCustomerDetailsRequest({ id: action.payload.customerId }));
    }
  } catch (error) {
    console.error(error);

    yield* call(toastService.error, {
      message: t('customerDetails.toasts.edit.error.message'),
      description: (error as Error).message,
    });

    yield* put(customerModalsActions.editCustomerModalError({ error }));
  }
}

export function* inviteUserCustomerSaga(
  action: customerModalActionTypes.CustomerInviteUserModalRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<void>
  | CallEffect<Optional<UserInviteResponse>>
  | PutEffect<CustomerDetailsGetRequestAction>
  | PutEffect<customerModalActionTypes.CustomerInviteUserModalSuccessAction>
  | PutEffect<IPollUserListRequestAction>
  | PutEffect<customerModalActionTypes.CustomerInviteUserModalErrorAction>
  | PutEffect<customerModalActionTypes.CustomerInviteUserModalCloseAction>
  | PutEffect<IGetUserListRequestAction>
  | SelectEffect,
  void,
  IDependencies
> {
  const { userService, toastService, t } = yield* getContext<IDependencies>('dependencies');
  const searchText = yield* select(userListSelectors.selectSearchText);
  const paginationTokens = yield* select(userListSelectors.selectPaginationTokens);
  const page = yield* select(userListSelectors.selectPage);
  const pageSize = yield* select(userListSelectors.selectPageSize);
  const sortField = yield* select(userListSelectors.selectSortField);
  const sortOrder = yield* select(userListSelectors.selectSortOrder);
  const customerDetailsData = yield* select(CustomerDetailsSelectors.selectData);
  const filterOptions = {
    filter: {
      customerId: customerDetailsData?.customerId,
    },
    search: searchText,
    sortOptions: {
      field: sortField || 'createdAt',
      order: sortOrder || SortOrders.Desc,
    },
    paginationOptions: {
      limit: pageSize,
      paginationToken: paginationTokens[page] || '',
    },
  };

  try {
    const response = yield* call(userService.invite, action.payload);

    yield* put(customerModalsActions.inviteUserModalSuccess(response));

    if (response) {
      yield* put(customerModalsActions.closeInviteUserModal());

      yield* call(toastService.success, {
        message: t('userInvite.toasts.success.message'),
        description: t('userInvite.toasts.success.description'),
      });

      yield* put(
        UserListActions.pollUserListRequest({
          email: action.payload.email,
          operation: PollUserListOperation.INVITE_NEWUSER_IN_CUSTOMER_DETAIL,
          filterOptions,
        })
      );
    }
  } catch (error) {
    console.error(error);

    if ((error as Error).message === '0199: User exists with input email') {
      yield* call(toastService.error, {
        message: t('userInvite.toasts.error.emailAlreadyExistsMessage'),
        description: t('userInvite.toasts.error.emailALreadyExistsDescription'),
      });
    } else {
      yield* call(toastService.error, {
        message: t('userInvite.toasts.error.message'),
        description: (error as Error).message,
      });
    }

    yield* put(customerModalsActions.inviteUserModalError({ error }));
  }
}

export function* reclaimMachineCustomerSaga(
  action: customerModalActionTypes.CustomerReclaimMachineModalRequestAction
): Generator<
  | GetContextEffect
  | IDependencies
  | CallEffect<Optional<MachineClaimResponse>>
  | CallEffect<void>
  | PutEffect<customerModalActionTypes.CustomerReclaimMachineModalSuccessAction>
  | PutEffect<IPollMachineListRequestAction>
  | PutEffect<customerModalActionTypes.CustomerReclaimMachineModalErrorAction>
  | PutEffect<customerModalActionTypes.CustomerReclaimMachineModalCloseAction>
  | PutEffect<IGetMachineListRequestAction>
  | SelectEffect,
  void
> {
  const { machineService, toastService, t } = yield* getContext<IDependencies>('dependencies');
  const searchText = yield* select(machineListSelectors.selectSearchText);
  const algFilter = yield* select(machineListSelectors.selectAlg);
  const customerDetailsData = yield* select(CustomerDetailsSelectors.selectData);
  const sortField = yield* select(machineListSelectors.selectSortField);
  const sortOrder = yield* select(machineListSelectors.selectSortOrder);
  const paginationTokens = yield* select(machineListSelectors.selectPaginationTokens);
  const page = yield* select(machineListSelectors.selectPage);
  const pageSize = yield* select(machineListSelectors.selectPageSize);

  const filterOptions = {
    search: searchText,
    filter: {
      algNumber: algFilter || '',
      customerId: customerDetailsData?.customerId,
    },
    sortOptions: {
      field: sortField || 'dateOfClaim',
      order: sortOrder || SortOrders.Desc,
    },
    paginationOptions: {
      limit: pageSize,
      paginationToken: paginationTokens[page] || '',
    },
  };

  try {
    const response = yield* call(machineService.reClaim, action.payload);

    yield* put(customerModalsActions.reclaimMachineModalSuccess(response));

    yield* put(
      MachineListActions.pollMachineListRequest({
        materialNumber: action.payload.input?.materialNumber || '',
        serialNumber: action.payload.input?.serialNumber || '',
        operation: PollMachineListOperation.RECLAIM,
        filterOption: filterOptions,
      })
    );

    if (response?.adminMachineClaim) {
      yield* put(customerModalsActions.closeReclaimMachineModal());

      yield* call(toastService.success, {
        message: t('machineReclaiming.toasts.machineReclaiming.success.message'),
        description: t('machineReclaiming.toasts.machineReclaiming.success.description'),
      });
    }
  } catch (error) {
    yield* put(customerModalsActions.reclaimMachineModalError({ error }));

    yield* call(toastService.error, {
      message: t('machineReclaiming.toasts.machineReclaiming.error.message'),
      description: (error as Error).message,
    });
  }
}

export function* unclaimMachineCustomerSaga(
  action: customerModalActionTypes.CustomerUnclaimMachineModalRequestAction
): Generator<
  | GetContextEffect
  | IDependencies
  | CallEffect<Optional<MachineUnclaimResponse>>
  | CallEffect<void>
  | PutEffect<customerModalActionTypes.CustomerUnclaimMachineModalSuccessAction>
  | PutEffect<IPollMachineListRequestAction>
  | PutEffect<customerModalActionTypes.CustomerUnclaimMachineModalErrorAction>
  | PutEffect<customerModalActionTypes.CustomerUnclaimMachineModalCloseAction>
  | PutEffect<IGetMachineListRequestAction>
  | SelectEffect,
  void
> {
  const { machineService, toastService, t } = yield* getContext<IDependencies>('dependencies');
  const searchText = yield* select(machineListSelectors.selectSearchText);
  const algFilter = yield* select(machineListSelectors.selectAlg);
  const customerDetailsData = yield* select(CustomerDetailsSelectors.selectData);
  const sortField = yield* select(machineListSelectors.selectSortField);
  const sortOrder = yield* select(machineListSelectors.selectSortOrder);
  const paginationTokens = yield* select(machineListSelectors.selectPaginationTokens);
  const page = yield* select(machineListSelectors.selectPage);
  const pageSize = yield* select(machineListSelectors.selectPageSize);
  const filterOptions = {
    search: searchText,
    filter: {
      algNumber: algFilter || '',
      customerId: customerDetailsData?.customerId,
    },
    sortOptions: {
      field: sortField || 'dateOfClaim',
      order: sortOrder || SortOrders.Desc,
    },
    paginationOptions: {
      limit: pageSize,
      paginationToken: paginationTokens[page] || '',
    },
  };

  try {
    const response = yield* call(machineService.unClaim, action.payload);

    yield* put(customerModalsActions.unclaimMachineModalSuccess(response));

    yield* put(
      MachineListActions.pollMachineListRequest({
        materialNumber: action.payload.input?.materialNumber || '',
        serialNumber: action.payload.input?.serialNumber || '',
        operation: PollMachineListOperation.RECLAIM,
        filterOption: filterOptions,
      })
    );

    if (response?.unclaimMachine.data) {
      yield* put(customerModalsActions.closeUnclaimMachineModal());

      yield* call(toastService.success, {
        message: t('machineUnclaim.toasts.success.message'),
        description: t('machineUnclaim.toasts.success.description'),
      });
    }
  } catch (error) {
    yield* put(customerModalsActions.unclaimMachineModalError({ error }));

    yield* call(toastService.error, {
      message: t('machineUnclaiming.toasts.machineUnclaiming.error.message'),
      description: (error as Error).message,
    });
  }
}

export function* claimMachineCustomerSaga(
  action: customerModalActionTypes.CustomerClaimNewDeviceMachineModalRequestAction
): Generator<
  | GetContextEffect
  | IDependencies
  | CallEffect<Optional<MachineClaimResponse>>
  | CallEffect<void>
  | PutEffect<customerModalActionTypes.CustomerClaimNewDeviceMachineModalSuccessAction>
  | PutEffect<IPollMachineListRequestAction>
  | PutEffect<customerModalActionTypes.CustomerClaimNewDeviceMachineModalErrorAction>
  | PutEffect<customerModalActionTypes.CustomerClaimNewDeviceModalCloseAction>
  | PutEffect<IGetMachineListRequestAction>
  | SelectEffect,
  void
> {
  const { machineService, toastService, t } = yield* getContext<IDependencies>('dependencies');
  const searchText = yield* select(machineListSelectors.selectSearchText);
  const algFilter = yield* select(machineListSelectors.selectAlg);
  const sortField = yield* select(machineListSelectors.selectSortField);
  const sortOrder = yield* select(machineListSelectors.selectSortOrder);
  const paginationTokens = yield* select(machineListSelectors.selectPaginationTokens);
  const page = yield* select(machineListSelectors.selectPage);
  const pageSize = yield* select(machineListSelectors.selectPageSize);
  const customerDetailsData = yield* select(CustomerDetailsSelectors.selectData);
  const filterOptions = {
    search: searchText,
    filter: {
      algNumber: algFilter || '',
      customerId: customerDetailsData?.customerId,
    },
    sortOptions: {
      field: sortField || 'dateOfClaim',
      order: sortOrder || SortOrders.Desc,
    },
    paginationOptions: {
      limit: pageSize,
      paginationToken: paginationTokens[page] || '',
    },
  };

  try {
    const response = yield* call(machineService.reClaim, action.payload);

    yield* put(customerModalsActions.claimNewDeviceModalSuccess(response));

    yield* put(
      MachineListActions.pollMachineListRequest({
        materialNumber: action.payload.input?.materialNumber || '',
        serialNumber: action.payload.input?.serialNumber || '',
        operation: PollMachineListOperation.CLAIM_NEWDEVICE,
        filterOption: filterOptions,
      })
    );

    if (response?.adminMachineClaim) {
      yield* put(customerModalsActions.closeClaimNewDeviceModal());

      yield* call(toastService.success, {
        message: t('customerDetails.modals.claimNewDevice.toast.success.message'),
        description: t('customerDetails.modals.claimNewDevice.toast.success.description'),
      });
    }
  } catch (error) {
    yield* put(customerModalsActions.claimNewDeviceModalError({ error }));

    yield* call(toastService.error, {
      message: t('machineReclaiming.toasts.machineReclaiming.error.message'),
      description: (error as Error).message,
    });
  }
}

export function* customerModalsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(customerModalsActions.createCustomerRequest, createCustomerSaga);
  yield* takeLatest(customerModalsActions.validateCreateCustomerRequest, validateCreateSaga);
  yield* takeLatest(customerModalsActions.editCustomerModalRequest, updateCustomerSaga);
  yield* takeLatest(customerModalsActions.deleteCustomerModalRequest, deleteCustomerSaga);
  yield* takeLatest(customerModalsActions.inviteUserModalRequest, inviteUserCustomerSaga);
  yield* takeLatest(customerModalsActions.reclaimMachineModalRequest, reclaimMachineCustomerSaga);
  yield* takeLatest(customerModalsActions.unclaimMachineModalRequest, unclaimMachineCustomerSaga);
  yield* takeLatest(customerModalsActions.claimNewDeviceModalRequest, claimMachineCustomerSaga);
}
