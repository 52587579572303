import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { FilterValue, SorterResult, TablePaginationConfig } from 'antd/lib/table/interface';
import { debounce } from 'lodash-es';
import { getCustomerDetailsUsersColumns } from './columns/CustomerDetailsUsers.columns';
import { SortOrders, User } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { AutoCompleteInput } from 'lib/components/AutoCompleteInput/AutoCompleteInput';
import { isFeatureEnabled } from 'lib/utils/feature-flags/isFeatureEnabled';
import { UserListActions } from 'app/modules/user-management/user-list/state/userListActions';
import { userListSelectors } from 'app/modules/user-management/user-list/state/userListSelectors';
import { Table } from 'lib/components/Table/Table';
import { Optional } from 'lib/types/Optional';
import { RoutePaths } from 'config/route-paths';
import { CustomerInviteUserModal } from 'app/modules/customer-management/modals/components/CustomerInviteUserModal/CustomerInviteUserModal';

export const CustomerDetailsUsers = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: customerId } = useParams();

  const data = useSelector(userListSelectors.selectData) || [];
  const totalCount = useSelector(userListSelectors.selectTotalCount) || 0;
  const paginationTokens = useSelector(userListSelectors.selectPaginationTokens);
  const isLoading = !!useSelector(userListSelectors.selectIsLoading);
  const page = useSelector(userListSelectors.selectPage);
  const pageSize = useSelector(userListSelectors.selectPageSize);
  const sortField = useSelector(userListSelectors.selectSortField) || 'createdAt';
  const sortOrder = useSelector(userListSelectors.selectSortOrder) || SortOrders.Desc;
  const searchText = useSelector(userListSelectors.selectSearchText);

  const isSortingAndFilteringEnabled = isFeatureEnabled(
    process.env.REACT_APP_FEATURE_ENABLE_USER_LIST_FILTERS_AND_SORTING
  );

  useEffect(() => {
    if (!isSortingAndFilteringEnabled) return;

    dispatch(UserListActions.getUserListFiltersRequest());
  }, [dispatch, isSortingAndFilteringEnabled]);

  useEffect(() => {
    if (!customerId) return;

    dispatch(
      UserListActions.getUserListRequest({
        filter: {
          customerId,
        },
        search: searchText,
        sortOptions: {
          field: sortField,
          order: sortOrder,
        },
        paginationOptions: {
          limit: pageSize,
          paginationToken: paginationTokens[page] || '',
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, pageSize, sortOrder, sortField, searchText, customerId]);

  const handleClickTableRow = (user: User): void => {
    navigate(RoutePaths.USER_DETAILS.replace(':id', user.internalUserId as string));
  };

  const onPageChange = (value: number): void => {
    dispatch(UserListActions.changeTablePage({ page: value }));
  };

  const onPageLimitChange = (value: number): void => {
    dispatch(UserListActions.changeTablePageSize({ pageSize: value }));
  };

  const onChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<User> | SorterResult<User>[]
  ): void => {
    if (Array.isArray(sorter)) return;
    let sorterOrder = SortOrders.Asc;
    if (sorter.order === 'descend') sorterOrder = SortOrders.Desc;
    dispatch(UserListActions.setActiveSortField(sorter.field as Optional<string>));
    dispatch(UserListActions.setActiveSortOrder(sorterOrder as Optional<SortOrders>));
  };

  const handleSearchUser = debounce((e: React.ChangeEvent<HTMLInputElement>): void => {
    dispatch(UserListActions.setActiveSearchText(e.target.value));
  }, 500);

  const tableColumns = getCustomerDetailsUsersColumns({ t, isSortingAndFilteringEnabled });

  return (
    <Col xs={24} sm={24} md={24} lg={24}>
      <div className="customer-list__search-input">
        <AutoCompleteInput
          placeholder={t('userList.searchInputHolder')}
          className="customer-list__search-custom-input"
          prefix={<SearchOutlined />}
          onChange={handleSearchUser}
        />
      </div>
      <Table
        pagination={{
          total: totalCount,
          pageSize,
          page,
          onPageChange,
          onPageLimitChange,
          isLoading,
        }}
        dataSource={data}
        loading={isLoading}
        className="customer-list__table"
        columns={tableColumns}
        rowKey="email"
        onRow={(userRowData: User): { onClick(): void } => ({
          onClick: (): void => {
            handleClickTableRow(userRowData);
          },
        })}
        onChange={onChange}
        sortDirections={['ascend', 'descend', 'ascend']}
      />

      {customerId && <CustomerInviteUserModal customerId={customerId} />}
    </Col>
  );
};
