import { TFunction } from 'react-i18next';
import { splitLangPrefix } from './splitLangPrefix';
import { Optional } from 'lib/types/Optional';

export const convertCodeToLanguage = (code: Optional<string>, t: TFunction<'translation', undefined>): string => {
  const codeRegex = /[a-z]{2}(-[A-Z]{2})?/;

  if (code && codeRegex.test(code)) {
    const languageNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'language' });

    const getPrefixLang = splitLangPrefix(code);

    return languageNamesInEnglish.of(getPrefixLang)?.split('(')[0] as string;
  }

  return code || t('userDetails.noneData');
};
