import { AnyAction } from 'redux';
import { produce } from 'immer';
import { MachineModalActions } from './machineModalActions';

export interface MachineModalState {
  machineReclaiming: {
    visible: boolean;
    isLoading: boolean;
  };
  machinePairing: {
    visible: boolean;
    isLoading: boolean;
    pairedMsg: string;
  };
  machinePairReclaim: {
    visible: boolean;
    isLoading: boolean;
  };
  machineUnclaim: {
    visible: boolean;
    isLoading: boolean;
  };
}

export const initialState: MachineModalState = {
  machineReclaiming: {
    visible: false,
    isLoading: false,
  },
  machinePairing: {
    visible: false,
    isLoading: false,
    pairedMsg: '',
  },
  machinePairReclaim: {
    visible: false,
    isLoading: false,
  },
  machineUnclaim: {
    visible: false,
    isLoading: false,
  },
};

export const machineModalReducer = (state = initialState, action: AnyAction): MachineModalState =>
  produce(state, draft => {
    switch (action.type) {
      case MachineModalActions.SHOW_MACHINE_RECLAIMING_MODAL: {
        draft.machineReclaiming.visible = true;
        return draft;
      }

      case MachineModalActions.HIDE_MACHINE_RECLAIMING_MODAL: {
        draft.machineReclaiming.visible = false;
        return draft;
      }

      case MachineModalActions.SHOW_MACHINE_PAIRING_MODAL: {
        draft.machinePairing.visible = true;

        const { pairedMsg } = action.payload;
        draft.machinePairing.pairedMsg = pairedMsg;
        return draft;
      }

      case MachineModalActions.HIDE_MACHINE_PAIRING_MODAL: {
        draft.machinePairing.visible = false;
        return draft;
      }

      case MachineModalActions.SHOW_MACHINE_PAIR_RECLAIM_MODAL: {
        draft.machinePairReclaim.visible = true;
        return draft;
      }

      case MachineModalActions.HIDE_MACHINE_PAIR_RECLAIM_MODAL: {
        draft.machinePairReclaim.visible = false;
        return draft;
      }

      case MachineModalActions.SHOW_MACHINE_UNCLAIM_MODAL: {
        draft.machineUnclaim.visible = true;
        return draft;
      }

      case MachineModalActions.HIDE_MACHINE_UNCLAIM_MODAL: {
        draft.machineUnclaim.visible = false;
        return draft;
      }

      default:
        return draft;
    }
  });
