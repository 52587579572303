import { Breadcrumb } from 'antd';
import { useMemo } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { Link, useMatch, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { StyledAppBreadcrumbs } from './AppBreadcrumbs.styles';
import { RoutePaths } from 'config/route-paths';
import { userDetailsSelectors } from 'app/modules/user-management/user-details/state/userDetailsSelector';
import { CustomerManagementEntity, User } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { Optional } from 'lib/types/Optional';
import { CustomerDetailsSelectors } from 'app/modules/customer-management/customer-details/state/customerDetailsSelectors';

interface IBreadcrumb {
  text: string;
  path: string;
}

const generateBreadcrumbs = (
  currentRoute: string,
  t: TFunction<'translation', undefined>,
  params: { id?: string; user: Optional<User> | null; customer: Optional<CustomerManagementEntity> | null }
): IBreadcrumb[] => {
  const BREADCRUMBS: Record<string, string> = {
    machines: t('pages.machineDirectory'),
    'machines-claiming': t('pages.manualClaiming'),
    users: t('pages.userManagement'),
    userDetails: t('pages.userDetails'),
    userCreate: t('pages.userCreate'),
    userInvite: t('pages.userInvite'),
    'machine-claiming-pairing': t('pages.machineClaimingPairing'),
    'user-register-success': t('pages.userRegisterSuccess'),
    userInviteSuccess: t('pages.userInviteSuccess'),
    'access-denied': t('pages.accessDenied'),
    customers: t('pages.customerManagement'),
  };
  const routes = currentRoute.split('/').slice(1);
  const breadcrumbs = routes.reduce((acc, cur, idx) => {
    const path = acc[idx - 1]?.path ? `${acc[idx - 1].path}/${cur}` : `/${cur}`;
    let text = BREADCRUMBS[cur] || cur;

    if (acc[idx - 1]?.path === RoutePaths.USERS) {
      text = BREADCRUMBS.userInvite;
    }

    if (acc[idx - 1]?.path === RoutePaths.USERS && params.id) {
      if (params.user) {
        text = params.user.fullName || params.id;
      } else text = '';
    }

    if (acc[idx - 1]?.path === RoutePaths.MACHINES && RoutePaths.CLAIM_MACHINE.includes(cur)) {
      text = BREADCRUMBS['machines-claiming'];
      acc.pop();
    }

    if (acc[idx - 1]?.path === RoutePaths.USERS && RoutePaths.CREATE_USER.includes(cur)) {
      text = BREADCRUMBS.userCreate;
      acc.pop();
    }

    if (acc[idx - 1]?.path === RoutePaths.MACHINES && RoutePaths.MACHINE_CLAIM_PAIR.includes(cur)) {
      text = BREADCRUMBS['machine-claiming-pairing'];
      acc.pop();
    }

    if (acc[idx - 1]?.path === RoutePaths.CREATE_USER_SUCCESS) {
      text = BREADCRUMBS['user-register-success'];
      acc.pop();
    }

    if (acc[idx - 1]?.path === RoutePaths.CUSTOMERS && params.id) {
      if (params.customer) {
        text = params.customer.customerName || params.id;
      } else text = '';
    }

    acc.push({ path, text });
    return acc;
  }, [] as IBreadcrumb[]);

  return breadcrumbs;
};

const BreadcrumbLink = ({
  idx,
  breadcrumbs,
  currentBreadcrumb,
}: {
  idx: number;
  breadcrumbs: IBreadcrumb[];
  currentBreadcrumb: IBreadcrumb;
}): JSX.Element => {
  if (idx === breadcrumbs.length - 1) {
    return <span className="breadcrumb-link--disabled">{currentBreadcrumb.text}</span>;
  }

  return <Link to={currentBreadcrumb.path}>{currentBreadcrumb.text}</Link>;
};

export const AppBreadcrumbs = (): JSX.Element => {
  const { t } = useTranslation();
  const match = useMatch('/:path/*');
  const params = useParams();
  const currentRoute = match ? match.pathname : '/overview';

  const user = useSelector(userDetailsSelectors.selectUserDetails);
  const customer = useSelector(CustomerDetailsSelectors.selectData);

  const breadcrumbs = useMemo(
    () => generateBreadcrumbs(currentRoute, t, { ...params, user, customer }),
    [currentRoute, t, params, user, customer]
  );

  return (
    <StyledAppBreadcrumbs>
      <div className="header__breadcrumb">
        <Breadcrumb separator=">">
          {breadcrumbs.map((breadcrumb, idx) => (
            <Breadcrumb.Item key={breadcrumb.path}>
              <BreadcrumbLink idx={idx} breadcrumbs={breadcrumbs} currentBreadcrumb={breadcrumb} />
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    </StyledAppBreadcrumbs>
  );
};
