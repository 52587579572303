import produce from 'immer';
import { AnyAction } from 'redux';
import { UserDetailsActions } from './userDetailsActions';
import { Optional } from 'lib/types/Optional';
import { User } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export interface UserDetailsState {
  data: Optional<User>;
  isLoading: boolean;
  invitationRevokedOn: Optional<string>;
  reassign: Optional<User>;
  errorMessage: Optional<string>;
  deleteUsers: {
    [id: string]: {
      loading: boolean;
      error: Optional<string>;
      completed: boolean;
    };
  };
}

export const initialState: UserDetailsState = {
  data: null,
  isLoading: false,
  invitationRevokedOn: null,
  reassign: null,
  errorMessage: null,
  deleteUsers: {},
};

export const userDetailsReducer = (state = initialState, action: AnyAction): UserDetailsState =>
  produce(state, draft => {
    switch (action.type) {
      case UserDetailsActions.USER_DETAILS_REQUEST: {
        draft.isLoading = true;
        draft.data = null;
        return draft;
      }

      case UserDetailsActions.USER_DETAILS_SUCCESS: {
        const { getUser } = action.payload;

        draft.isLoading = false;
        draft.data = getUser?.data || {};
        return draft;
      }

      case UserDetailsActions.USER_DETAILS_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case UserDetailsActions.CLEAR_DELETE_USER: {
        draft.deleteUsers = {};
        return draft;
      }

      case UserDetailsActions.USER_DELETE_REQUEST: {
        draft.deleteUsers[action.payload?.input.id] = {
          loading: true,
          error: null,
          completed: false,
        };

        return draft;
      }

      case UserDetailsActions.USER_DELETE_SUCCESS: {
        draft.deleteUsers[action.payload.id] = {
          loading: false,
          error: null,
          completed: true,
        };

        draft.isLoading = false;
        return draft;
      }

      case UserDetailsActions.USER_DELETE_ERROR: {
        draft.isLoading = false;
        draft.deleteUsers[action?.id] = {
          loading: false,
          error: action.error,
          completed: false,
        };
        return draft;
      }

      case UserDetailsActions.USER_UPDATE_REQUEST: {
        return draft;
      }

      case UserDetailsActions.USER_UPDATE_SUCCESS: {
        const { updateUser } = action.payload;

        draft.data = updateUser?.data || draft.data;
        draft.isLoading = false;
        return draft;
      }

      case UserDetailsActions.USER_UPDATE_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case UserDetailsActions.USER_RESEND_INVITATION_REQUEST: {
        return draft;
      }

      case UserDetailsActions.USER_RESEND_INVITATION_SUCCESS: {
        const { resendInviteEmail } = action.payload;
        draft.invitationRevokedOn = resendInviteEmail?.invitationRevokedOn;
        return draft;
      }

      case UserDetailsActions.USER_RESEND_INVITATION_ERROR: {
        return draft;
      }

      case UserDetailsActions.USER_REASSIGN_REQUEST: {
        return draft;
      }

      case UserDetailsActions.USER_REASSIGN_SUCCESS: {
        const { reassignUserToCustomer } = action.payload;
        draft.reassign = reassignUserToCustomer?.data;
        return draft;
      }

      case UserDetailsActions.USER_REASSIGN_ERROR: {
        return draft;
      }

      default:
        return draft;
    }
  });
