import styled from 'styled-components';

export const StyledLoginHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  background-color: ${(props): string => props.theme.colors.primary};
  height: 120px;
  padding-left: 216px;

  .login-header__title {
    margin-bottom: 0;
    font-family: ${(props): string => props.theme.fonts.pageTitle};
    font-size: ${(props): string => props.theme.fontSizes.pageTitle};
    text-transform: uppercase;
  }

  .login-header__description {
    margin-bottom: 0;
    font-family: ${(props): string => props.theme.fonts.body};
    font-size: 1rem;
  }
`;
