import { CallEffect, ForkEffect, GetContextEffect, PutEffect, SelectEffect } from 'redux-saga/effects';
import { call, getContext, put, select, takeLatest } from 'typed-redux-saga';
import {
  PollUserListOperation,
  ReassignUserToCustomerResponse,
  UserDeleteResponse,
  UserDetailsResponse,
  UserResendInvitationResponse,
  UserStatusValue,
  UserUpdateResponse,
} from '../../interfaces/User.types';
import {
  IHideDeleteUserModalAction,
  IIsDeleteUserModalLoadingAction,
  IIsDeleteUserModalNotLoadingAction,
  IHideEditUserModalAction,
  IIsEditUserModalLoadingAction,
  IIsEditUserModalNotLoadingAction,
  UserModalsActions,
  IHideResendInvitationModalAction,
  IIsResendInvitationModalNotLoadingAction,
  IIsResendInvitationModalLoadingAction,
  IHideChangeCustomerModalAction,
  IIsChangeCustomerModalLoadingAction,
  IIsChangeCustomerModalNotLoadingAction,
} from '../../modals/state/userModalsActions';
import {
  CustomerSearchActions,
  ICustomerSearchClearListAction,
} from '../../customer-search/state/customerSearchAction';
import { IPollUserListRequestAction, UserListActions } from '../../user-list/state/userListActions';
import { userListSelectors } from '../../user-list/state/userListSelectors';
import {
  IUserDeleteErrorAction,
  IUserDeleteRequestAction,
  IUserDeleteSuccessAction,
  IUserDetailsErrorAction,
  IUserDetailsRequestAction,
  IUserDetailsSuccessAction,
  IUserReassignErrorAction,
  IUserReassignRequestAction,
  IUserReassignSuccessAction,
  IUserResendInvitationErrorAction,
  IUserResendInvitationRequestAction,
  IUserResendInvitationSuccessAction,
  IUserUpdateErrorAction,
  IUserUpdateRequestAction,
  IUserUpdateSuccessAction,
  UserDetailsActions,
} from './userDetailsActions';
import { userDetailsSelectors } from './userDetailsSelector';
import { Optional } from 'lib/types/Optional';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';
import { RoutePaths } from 'config/route-paths';
import { SortOrders } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export function* userDetails(
  action: IUserDetailsRequestAction
): Generator<
  | IDependencies
  | GetContextEffect
  | CallEffect<Optional<UserDetailsResponse>>
  | PutEffect<IUserDetailsSuccessAction>
  | PutEffect<IUserDetailsErrorAction>
  | void
> {
  const { userService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(userService.details, action.payload);

    yield* put(UserDetailsActions.userDetailsSuccess(response));
  } catch (error) {
    yield* put(UserDetailsActions.userDetailsError({ error }));
  }
}

export function* userDelete(
  action: IUserDeleteRequestAction
): Generator<
  | IDependencies
  | GetContextEffect
  | CallEffect<Optional<UserDeleteResponse>>
  | CallEffect<void>
  | CallEffect<void>
  | PutEffect<IIsDeleteUserModalLoadingAction>
  | PutEffect<IUserDeleteSuccessAction>
  | PutEffect<IUserDeleteErrorAction>
  | PutEffect<IIsDeleteUserModalNotLoadingAction>
  | PutEffect<IHideDeleteUserModalAction>
  | PutEffect<IPollUserListRequestAction>
  | SelectEffect
  | void
> {
  const { userService, toastService, t, history } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(UserModalsActions.isDeleteUserModalLoading());
    const { input } = action.payload;
    const response = yield* call(userService.delete, { input });

    if (action.payload.input?.id && response?.deleteUser) {
      yield* put(
        UserDetailsActions.userDeleteSuccess({
          id: action.payload.input?.id,
          deleteUser: response?.deleteUser,
        })
      );
    }

    const user = yield* select(userDetailsSelectors.selectUserDetails);

    // Delete user
    if (user?.status !== UserStatusValue.IN_PROGRESS && response?.deleteUser) {
      yield* call(toastService.success, {
        message: t('userDetails.deleteUserModal.toasts.success.message'),
        description: t('userDetails.deleteUserModal.toasts.success.description'),
      });
    }

    // Delete invitation
    if (user?.status === UserStatusValue.IN_PROGRESS && response?.deleteUser) {
      yield* call(toastService.success, {
        message: t('userDetails.deleteInvitationModal.toasts.success.message'),
        description: t('userDetails.deleteInvitationModal.toasts.success.description'),
      });
    }

    const activeAlgFilter = yield* select(userListSelectors.selectActiveAlgFilter);
    const sortField = yield* select(userListSelectors.selectSortField);
    const sortOrder = yield* select(userListSelectors.selectSortOrder);
    const searchText = yield* select(userListSelectors.selectSearchText);
    const paginationTokens = yield* select(userListSelectors.selectPaginationTokens);
    const page = yield* select(userListSelectors.selectPage);
    const pageSize = yield* select(userListSelectors.selectPageSize);
    const filterOptions = {
      filter: {
        alg: activeAlgFilter,
      },
      search: searchText,
      sortOptions: {
        field: sortField || 'createdAt',
        order: sortOrder || SortOrders.Desc,
      },
      paginationOptions: {
        limit: pageSize,
        paginationToken: paginationTokens[page] || '',
      },
    };

    yield* put(
      UserListActions.pollUserListRequest({
        email: action.payload.email,
        operation: PollUserListOperation.DELETE,
        filterOptions,
      })
    );

    history.push(RoutePaths.USERS);
  } catch (error) {
    yield* put(
      UserDetailsActions.userDeleteError({ error: (error as Error).message, id: action.payload.input?.id as string })
    );

    yield* call(toastService.error, {
      message: t('userDetails.deleteUserModal.toasts.error.message'),
      description: (error as Error).message,
    });
  } finally {
    yield* put(UserModalsActions.isDeleteUserModalNotLoading());
    yield* put(UserModalsActions.hideDeleteUserModal());
  }
}

export function* userUpdate(
  action: IUserUpdateRequestAction
): Generator<
  | IDependencies
  | GetContextEffect
  | CallEffect<Optional<UserUpdateResponse>>
  | CallEffect<void>
  | PutEffect<IIsEditUserModalLoadingAction>
  | PutEffect<IUserUpdateSuccessAction>
  | PutEffect<IUserDetailsRequestAction>
  | PutEffect<IHideEditUserModalAction>
  | PutEffect<IIsEditUserModalNotLoadingAction>
  | PutEffect<IUserUpdateErrorAction>
  | void
> {
  const { userService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(UserModalsActions.isEditUserModalLoading());

    const response = yield* call(userService.update, action.payload);
    const userId: string = response?.updateUser.data?.internalUserId as string;

    yield* put(UserDetailsActions.userUpdateSuccess(response));

    if (response?.updateUser) {
      yield* call(toastService.success, {
        message: t('userDetails.editUserModal.toasts.success.message'),
        description: t('userDetails.editUserModal.toasts.success.description'),
      });

      yield* put(UserDetailsActions.userDetailsRequest(userId));
    }
  } catch (error) {
    yield* put(UserDetailsActions.userUpdateError({ error }));

    yield* call(toastService.error, {
      message: t('userDetails.editUserModal.toasts.error.message'),
      description: (error as Error).message,
    });
  } finally {
    yield* put(UserModalsActions.isEditUserModalNotLoading());
    yield* put(UserModalsActions.hideEditUserModal());
  }
}

export function* userResendInvitation(
  action: IUserResendInvitationRequestAction
): Generator<
  | IDependencies
  | GetContextEffect
  | CallEffect<Optional<UserResendInvitationResponse>>
  | CallEffect<void>
  | PutEffect<IUserResendInvitationSuccessAction>
  | PutEffect<IUserDetailsRequestAction>
  | PutEffect<IUserResendInvitationErrorAction>
  | PutEffect<IIsResendInvitationModalNotLoadingAction>
  | PutEffect<IIsResendInvitationModalLoadingAction>
  | PutEffect<IHideResendInvitationModalAction>
  | void
> {
  const { userService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(UserModalsActions.setResendInvitationModalLoading());
    const response = yield* call(userService.resend, action.payload);

    if (response?.resendInviteEmail) {
      yield* put(UserDetailsActions.userResendInvitationSuccess(response));
      yield* put(UserModalsActions.hideResendInvitationModal());

      yield* call(toastService.success, {
        message: t('userDetails.resendInvitationModal.toasts.success.message'),
        description: t('userDetails.resendInvitationModal.toasts.success.description'),
      });
    }
  } catch (error) {
    yield* put(UserDetailsActions.userResendInvitationError({ error }));
    yield* call(toastService.error, {
      message: t('userDetails.resendInvitationModal.toasts.error.message'),
      description: (error as Error).message,
    });
  } finally {
    yield* put(UserModalsActions.setResendInvitationModalNotLoading());
  }
}

export function* userReassign(
  action: IUserReassignRequestAction
): Generator<
  | IDependencies
  | GetContextEffect
  | CallEffect<Optional<ReassignUserToCustomerResponse>>
  | CallEffect<void>
  | PutEffect<IUserReassignSuccessAction>
  | PutEffect<IUserReassignErrorAction>
  | PutEffect<IHideChangeCustomerModalAction>
  | PutEffect<IIsChangeCustomerModalLoadingAction>
  | PutEffect<IIsChangeCustomerModalNotLoadingAction>
  | PutEffect<IUserDetailsRequestAction>
  | PutEffect<ICustomerSearchClearListAction>
  | void
> {
  const { userService, toastService, t } = yield* getContext<IDependencies>('dependencies');

  try {
    yield* put(UserModalsActions.isChangeCustomerModalLoading());
    const response = yield* call(userService.reassign, action.payload);

    if (action.payload.input?.userId) {
      yield* put(UserDetailsActions.userDetailsRequest(action.payload.input.userId));
    } else {
      throw new Error('User ID is missing');
    }

    if (response?.reassignUserToCustomer) {
      yield* put(CustomerSearchActions.customerSearchClearList());
      yield* put(UserDetailsActions.userReassignSuccess(response));

      yield* call(toastService.success, {
        message: t('userDetails.changeCustomerModal.toasts.success.message'),
        description: t('userDetails.changeCustomerModal.toasts.success.description'),
      });
    }
  } catch (error) {
    yield* put(UserDetailsActions.userReassignError({ error }));
    yield* call(toastService.error, {
      message: t('userDetails.changeCustomerModal.toasts.error.message'),
      description: (error as Error).message,
    });
  } finally {
    yield* put(UserModalsActions.isChangeCustomerModalNotLoading());

    yield* put(UserModalsActions.hideChangeCustomerModal());
  }
}
export function* userDetailsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(UserDetailsActions.USER_DETAILS_REQUEST, userDetails);
  yield* takeLatest(UserDetailsActions.USER_DELETE_REQUEST, userDelete);
  yield* takeLatest(UserDetailsActions.USER_UPDATE_REQUEST, userUpdate);
  yield* takeLatest(UserDetailsActions.USER_RESEND_INVITATION_REQUEST, userResendInvitation);
  yield* takeLatest(UserDetailsActions.USER_REASSIGN_REQUEST, userReassign);
}
