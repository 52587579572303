import { useTranslation } from 'react-i18next';
import { Result } from 'antd';
import { StyledUserCreateSuccess } from './UserCreateSuccess.styles';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';

export const UserCreateSuccess = (): JSX.Element => {
  const { t } = useTranslation();
  const redirectEmPath = process.env.REACT_APP_REDIRECT_EM_PATH;

  return (
    <StyledUserCreateSuccess>
      <Result
        status="success"
        title={t('userCreate.toasts.success.message')}
        subTitle={t('userCreate.toasts.success.description')}
        extra={[
          <PrimaryButton key="login" size="m" href={redirectEmPath} className="user-create-success__login-em-btn">
            {t('userCreate.button.loginEM')}
          </PrimaryButton>,
        ]}
      />
    </StyledUserCreateSuccess>
  );
};
