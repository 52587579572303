import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  selectIsResendInvitationModalVisible,
  selectIsResendInvitationModalLoading,
} from '../../state/userModalsSelectors';
import { UserModalsActions } from '../../state/userModalsActions';
import { StyledResendInvitationModal } from './ResendInvitationModal.styles';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import {
  IUserResendInvitationRequestOptions,
  UserDetailsActions,
} from 'app/modules/user-management/user-details/state/userDetailsActions';

export const ResendInvitationModal = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const isVisible = useSelector(selectIsResendInvitationModalVisible);
  const isLoading = useSelector(selectIsResendInvitationModalLoading);

  const handleOk = useCallback(() => {
    const input: IUserResendInvitationRequestOptions = { input: { internalUserId: id as string } };
    dispatch(UserDetailsActions.userResendInvitationRequest(input));
  }, [dispatch, id]);

  const handleCancel = useCallback(() => dispatch(UserModalsActions.hideResendInvitationModal()), [dispatch]);

  return (
    <StyledResendInvitationModal
      className="resend-invitation-modal"
      title={t('userDetails.resendInvitationModal.resend')}
      centered
      open={isVisible}
      closable={false}
      footer={[
        <SecondaryButton size="s" onClick={handleCancel} className="cancel-button" key="cancel-button">
          {t('common.cancel')}
        </SecondaryButton>,
        <PrimaryButton key="submit" className="submit-button" type="primary" loading={isLoading} onClick={handleOk}>
          {t('userDetails.resendInvitationModal.resend')}
        </PrimaryButton>,
      ]}
    >
      <p className="modal__body">{t('userDetails.resendInvitationModal.modalBody')}</p>
    </StyledResendInvitationModal>
  );
};
