import { Optional } from '../../../../lib/types/Optional';
import { IState } from '../../state-management/interfaces/IState';
import { IPermission, IUserInfo } from '../interfaces/Authentication.types';
import { IAuthenticationTokens } from '../interfaces/IAuthenticationTokens';

export const selectIsCheckingAuthentication = (state: IState): boolean =>
  state['cross-cutting-concerns']?.authentication?.isCheckingAuthentication;
export const selectAuthenticationTokens = (state: IState): Optional<IAuthenticationTokens> =>
  state['cross-cutting-concerns']?.authentication?.tokens;
export const selectIsLoggedIn = (state: IState): boolean => state['cross-cutting-concerns']?.authentication?.isLoggedIn;
export const selectUserInfo = (state: IState): IUserInfo => state['cross-cutting-concerns']?.authentication?.userInfo;
export const selectPermissions = (state: IState): IPermission[] =>
  state['cross-cutting-concerns']?.authentication?.permissions;
