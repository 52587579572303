import { Auth } from 'aws-amplify';
import { negate, isNil, uniq } from 'lodash-es';
import { inject, singleton } from 'tsyringe';
import { RoutePaths } from '../../../config/route-paths';
import { Optional } from '../../../lib/types/Optional';
import { UserPoolUtils } from '../utils/user-pool/UserPoolUtils';
import {
  IAccessTokenPayload,
  IAuthenticationTokens,
  IIdTokenPayload,
  OidcTokenType,
} from './interfaces/IAuthenticationTokens';
import { CognitoError } from './errors/CognitoError';
import { IPermission, IUserInfo } from './interfaces/Authentication.types';
import { Permission, PermissionsByRole } from 'config/permissions';

@singleton()
export class AuthenticationService {
  constructor(
    @inject('UserPoolProviderName') private providerName: string,
    // Typescript is reporting the error "Attempted import error: 'BrowserHistory' is not exported from 'redux'"
    // Set to any type as a workaround
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @inject('History') private history: any,
    private userPoolUtils: UserPoolUtils
  ) {}

  public signIn = async (): Promise<void> => {
    try {
      await Auth.federatedSignIn({ customProvider: this.providerName });
    } catch (error) {
      console.error(error);
    }
  };

  public triggerTokenRefresh = async (): Promise<void> => {
    try {
      await Auth.currentSession();
    } catch (error) {
      if (error === CognitoError.REFRESH_TOKEN_EXPIRED) {
        await this.signOut();
        this.history.push(RoutePaths.LOGIN);
        return;
      }
      // No refresh needed when not logged in
      if (error !== CognitoError.NO_CURRENT_USER) {
        console.error(error);
      }
    }
  };

  public signOut = async (): Promise<any> => Auth.signOut();

  public checkAuthentication = async (): Promise<Optional<IAuthenticationTokens>> => {
    const tokens = await this.getTokens();

    return tokens;
  };

  public getTokens = async (): Promise<Optional<IAuthenticationTokens>> => {
    let tokens: Optional<IAuthenticationTokens>;

    try {
      const currentSession = await Auth.currentSession();

      const idToken = currentSession.getIdToken();
      const accessToken = currentSession.getAccessToken();
      const refreshToken = currentSession.getRefreshToken();

      tokens = {
        jwt: {
          idToken: idToken.getJwtToken(),
          accessToken: accessToken.getJwtToken(),
          refreshToken: refreshToken.getToken(),
        },
        decoded: {
          idToken: idToken.decodePayload() as IIdTokenPayload,
          accessToken: accessToken.decodePayload() as IAccessTokenPayload,
        },
      };
    } catch (error) {
      if (error === CognitoError.NO_CURRENT_USER) {
        tokens = null;
      } else {
        console.error(error);
      }
    }

    if (tokens !== null) {
      try {
        this.userPoolUtils.verifyOidcToken(tokens?.jwt.idToken, OidcTokenType.ID);
        this.userPoolUtils.verifyOidcToken(tokens?.jwt.accessToken, OidcTokenType.ACCESS);
      } catch (error) {
        console.error(error);
        await this.signOut();
        this.history.push(RoutePaths.LOGIN);
      }
    }

    return tokens;
  };

  public getPermissions = async (): Promise<IPermission[]> => {
    const tokens = await this.getTokens();
    const roles = tokens?.decoded.accessToken?.['cognito:groups'] ?? [];

    // TODO: [0.9] Remove once role dropdown is implemented or BE sends roles
    // Possible role values:
    // roles.push(
    //   'eu-west-1_n3VzTh1TV_kaercher-sso-dev',
    //   Role.Internal.ADMIN,
    //   Role.Customer.ADMIN,
    //   Role.Customer.STRATEGIC_MANAGER,
    //   Role.Customer.OPERATOR
    // );

    const permissions = roles.flatMap(role => PermissionsByRole[role]).filter(negate(isNil));

    // TODO: [0.9] Hardcode permissions until role dropdown is implemented or BE sends roles
    // Comment / uncomment for testing
    // TODO: Create unit test to check user permissions once hardcoded permission are removed.
    permissions.push(
      Permission.Customer.Account.UPDATE,
      Permission.Customer.Role.UPDATE,
      Permission.Customer.User.AUTHORIZE,
      Permission.Customer.User.UNAUTHORIZE,
      Permission.Customer.User.SEND_INVITATION,
      Permission.Customer.User.DELETE,
      Permission.Internal.Role.UPDATE,
      Permission.Machine.CLAIM,
      Permission.Machine.UPDATE,
      Permission.Machine.ActivationStatus.UPDATE,
      Permission.Machine.Comment.CREATE,
      Permission.Site.CREATE,
      Permission.Site.READ,
      Permission.Site.UPDATE,
      Permission.Site.DELETE,
      Permission.Site.Machine.UPDATE,
      Permission.Site.StrategicManager.UPDATE,
      Permission.Site.WorkInterval.UPDATE,
      Permission.Permissions.READ,
      Permission.Machine.UNCLAIM
    );

    return uniq(permissions);
  };

  public getUserInfo = async (): Promise<IUserInfo> => {
    try {
      const currentSession = await Auth.currentSession();
      const email = currentSession?.getIdToken()?.decodePayload()?.email;

      return {
        email,
      };
    } catch (error) {
      return {
        email: undefined,
      };
    }
  };
}
