import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledUserStatus } from './UserStatus.styles';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { Icon } from 'lib/components/Icon/Icon';
import { getUserStatus } from 'app/modules/user-management/utils/getUserStatus';
import { IUserStatus } from 'app/modules/user-management/interfaces/User.types';

interface UserStatusProps {
  status: string;
}
export const UserStatus = ({ status }: UserStatusProps): JSX.Element => {
  const { t } = useTranslation();

  const userStatus = useMemo((): IUserStatus => getUserStatus(status), [status]);

  return (
    <StyledUserStatus className="user-status" $statusColor={userStatus.color}>
      <Tooltip title={t(userStatus.description)} className="user-status__content">
        &nbsp;
        <Icon name="icon-b_148_dot_for_graph" className="user-status__icon" />
      </Tooltip>
    </StyledUserStatus>
  );
};
