import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AuthenticationActions, ISignInAction } from '../../state/authenticationActions';
import { StyledLogin } from './Login.styles';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';

export const Login = (): JSX.Element => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onLogin = useCallback((): ISignInAction => dispatch(AuthenticationActions.signIn()), [dispatch]);

  return (
    <StyledLogin className="login">
      <PrimaryButton size="m" className="login__login-button" onClick={onLogin}>
        {t('login.loginButton')}
      </PrimaryButton>
    </StyledLogin>
  );
};
