import produce from 'immer';
import { AnyAction } from 'redux';
import { SortOrders, User } from '../../../../cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { UserListActions } from './userListActions';
import { Optional } from 'lib/types/Optional';
import { PaginationTokens } from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';
import { DEFAULT_PAGE_SIZE_VALUE, DEFAULT_PAGE_VALUE } from 'config/constants';

export interface UserListState {
  data: User[];
  totalCount: Optional<number>;
  isLoading: Optional<boolean>;
  paginationTokens: PaginationTokens;
  page: number;
  pageSize: number;
  searchText: Optional<string>;
  filters: {
    available: {
      algs: string[];
    };
    active: {
      alg: Optional<string>;
    };
    isLoading: boolean;
  };
  sortOptions: {
    field: Optional<string>;
    order: Optional<SortOrders>;
  };
}

export const initialState: UserListState = {
  data: [],
  totalCount: null,
  isLoading: null,
  paginationTokens: {},
  page: DEFAULT_PAGE_VALUE,
  pageSize: DEFAULT_PAGE_SIZE_VALUE,
  searchText: null,
  filters: {
    available: {
      algs: [],
    },
    active: {
      alg: null,
    },
    isLoading: false,
  },
  sortOptions: {
    field: null,
    order: null,
  },
};

export const userListReducer = (state = initialState, action: AnyAction): UserListState =>
  produce(state, draft => {
    switch (action.type) {
      case UserListActions.GET_USER_LIST_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case UserListActions.USER_LIST_LOADING_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case UserListActions.POLL_USER_LIST_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case UserListActions.GET_USER_LIST_SUCCESS: {
        const { listUsers } = action.payload;

        draft.isLoading = false;
        draft.data = listUsers?.data;
        draft.totalCount = listUsers?.metadata?.totalCount;

        if (listUsers?.metadata?.paginationToken) {
          draft.paginationTokens[draft.page + 1] = listUsers?.metadata?.paginationToken;
        }

        return draft;
      }

      case UserListActions.GET_USER_LIST_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      case UserListActions.CHANGE_TABLE_PAGE: {
        const { page } = action.payload;

        draft.page = page;
        return draft;
      }

      case UserListActions.CHANGE_TABLE_PAGE_SIZE: {
        const { pageSize } = action.payload;

        draft.paginationTokens = {};
        draft.page = DEFAULT_PAGE_VALUE;
        draft.pageSize = pageSize;
        return draft;
      }

      case UserListActions.SET_ACTIVE_ALG_FILTER: {
        const { alg } = action.payload;

        draft.filters.active.alg = alg;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      case UserListActions.SET_ACTIVE_SORT_FIELD: {
        const { field } = action.payload;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        draft.sortOptions.field = field;
        return draft;
      }

      case UserListActions.SET_ACTIVE_SORT_ORDER: {
        const { order } = action.payload;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        draft.sortOptions.order = order;
        return draft;
      }

      case UserListActions.RESET_STATE: {
        return initialState;
      }

      case UserListActions.GET_USER_LIST_FILTERS_REQUEST: {
        draft.filters.isLoading = true;
        return draft;
      }

      case UserListActions.GET_USER_LIST_FILTERS_SUCCESS: {
        draft.filters.isLoading = false;
        draft.filters.available.algs = action.payload?.listAlgNumber?.data || [];

        return draft;
      }

      case UserListActions.GET_USER_LIST_FILTERS_ERROR: {
        draft.filters.isLoading = false;
        return draft;
      }

      case UserListActions.SET_ACTIVE_SEARCH_TEXT: {
        const { searchText } = action.payload;
        draft.searchText = searchText;
        draft.page = DEFAULT_PAGE_VALUE;
        draft.paginationTokens = {};
        return draft;
      }

      default:
        return draft;
    }
  });
