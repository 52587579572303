import { getContext, put, takeLatest, call } from 'typed-redux-saga';
import { CallEffect, ForkEffect, GetContextEffect, PutEffect } from 'redux-saga/effects';
import { CustomerGetResponse } from '../../interfaces/Customer.types';
import { customerDetailsActions } from './customerDetailsSlice';
import {
  CustomerDetailsGetErrorAction,
  CustomerDetailsGetRequestAction,
  CustomerDetailsGetSuccessAction,
} from './customerDetailsActions.types';
import { Optional } from 'lib/types/Optional';
import { IDependencies } from 'app/cross-cutting-concerns/dependency-injection/interfaces/IDependencies';

export function* getCustomerDetailsSaga(
  action: CustomerDetailsGetRequestAction
): Generator<
  | GetContextEffect
  | CallEffect<void>
  | CallEffect<Optional<CustomerGetResponse>>
  | PutEffect<CustomerDetailsGetSuccessAction>
  | PutEffect<CustomerDetailsGetErrorAction>,
  void,
  IDependencies
> {
  const { customerService } = yield* getContext<IDependencies>('dependencies');

  try {
    const response = yield* call(customerService.get, action.payload);

    yield* put(customerDetailsActions.getCustomerDetailsSuccess(response));
  } catch (error) {
    console.error(error);

    yield* put(customerDetailsActions.getCustomerDetailsError({ error }));
  }
}

export function* customerDetailsSaga(): Generator<ForkEffect<never>, void> {
  yield* takeLatest(customerDetailsActions.getCustomerDetailsRequest, getCustomerDetailsSaga);
}
