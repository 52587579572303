import { Action } from 'redux';
import { CustomerSearchResponse } from '../../interfaces/Customer.types';
import { CustomerClientSearchOptions } from '../../UserClient';
import {
  IActionCreatorErrorOptions,
  IErrorAction,
} from 'app/cross-cutting-concerns/state-management/interfaces/StateManagement.types';

export type ICustomerSearchRequestOptions = CustomerClientSearchOptions;
export type ICustomerSearchRequestAction = Action<typeof CustomerSearchActions.CUSTOMER_SEARCH_REQUEST> & {
  payload: CustomerClientSearchOptions;
};

export type ICustomerSearchSuccessOptions = CustomerSearchResponse;
export type ICustomerSearchSuccessAction = Action<typeof CustomerSearchActions.CUSTOMER_SEARCH_SUCCESS> & {
  payload: ICustomerSearchSuccessOptions;
};

export type ICustomerSearchErrorAction = IErrorAction<typeof CustomerSearchActions.CUSTOMER_SEARCH_ERROR>;

export type ICustomerSearchClearListAction = Action<typeof CustomerSearchActions.CUSTOMER_SEARCH_CLEAR_LIST>;
export class CustomerSearchActions {
  public static CUSTOMER_SEARCH_REQUEST = 'CUSTOMER_SEARCH::CUSTOMER_SEARCH_REQUEST';
  public static CUSTOMER_SEARCH_SUCCESS = 'CUSTOMER_SEARCH::CUSTOMER_SEARCH_SUCCESS';
  public static CUSTOMER_SEARCH_ERROR = 'CUSTOMER_SEARCH::CUSTOMER_SEARCH_ERROR';

  public static CUSTOMER_SEARCH_CLEAR_LIST = 'CUSTOMER_SEARCH::CUSTOMER_SEARCH_CLEAR_LIST';

  public static customerSearchClearList(): ICustomerSearchClearListAction {
    return { type: this.CUSTOMER_SEARCH_CLEAR_LIST };
  }

  public static customerSearchRequest({
    algNumber,
    customerName,
    customerNumber,
  }: ICustomerSearchRequestOptions): ICustomerSearchRequestAction {
    return {
      type: this.CUSTOMER_SEARCH_REQUEST,
      payload: {
        algNumber,
        customerName,
        customerNumber,
      },
    };
  }

  public static customerSearchSuccess(response: ICustomerSearchSuccessOptions): ICustomerSearchSuccessAction {
    return {
      type: this.CUSTOMER_SEARCH_SUCCESS,
      payload: response,
    };
  }

  public static customerSearchError({ error }: IActionCreatorErrorOptions): ICustomerSearchErrorAction {
    return {
      type: this.CUSTOMER_SEARCH_ERROR,
      error,
    };
  }
}
