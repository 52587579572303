import styled from 'styled-components';
import { Modal } from 'lib/components/Modal/Modal';

export const StyledCustomerDeleteDevicesReclaimingModal = styled(Modal)`
  .customer-delete-device-reclaiming__description {
    font-size: ${(props): string => props.theme.fontSizes.pageDescription};
    font-family: ${(props): string => props.theme.fonts.body};
    color: ${(props): string => props.theme.colors.text};
    overflow: unset;
    text-transform: none;
    margin: 18px auto 0;
  }

  .customer-delete-device-reclaiming__customer-options {
    display: flex;
    justify-content: center;
    margin-top: 33px;
  }

  .ant-form-item-label {
    padding-bottom: 0;
    > label {
      font-size: ${(props): string => props.theme.fontSizes.formLabel};
      font-family: ${(props): string => props.theme.fonts.formLabel};
      color: ${(props): string => props.theme.colors.text};
      text-transform: uppercase;
    }
  }

  .customer-delete-device-reclaiming__customer-selection-submit-button {
    width: 100%;
  }

  .ant-table-footer {
    display: none;
  }

  .customer-delete-device-reclaiming__customer-selection-input-group {
    width: 216px;
  }

  @media screen and (max-width: 1406px) {
    .machine-list__header-content,
    .machine-list__body-content {
      margin-left: 175px;
    }
  }

  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
    border-color: ${(props): string => props.theme.colors.red};
    box-shadow: none;
    border-right-width: 2px !important;
  }

  .ant-form-item-explain-error {
    font-size: ${(props): string => props.theme.fontSizes.formTitle};
    font-family: ${(props): string => props.theme.fonts.formTitle};
    color: ${(props): string => props.theme.colors.red};
  }
`;
