import { useTranslation } from 'react-i18next';
import { StyledMachineIsPairableTooltip } from './MachineIsPairableTooltip.styles';

export const MachineIsPairableTooltip = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <StyledMachineIsPairableTooltip className="machine-pairable-tooltip">
      <div className="machine-pairable-tooltip__description">{t('machineClaimingPairing.nonPairInfoDescription')}</div>
    </StyledMachineIsPairableTooltip>
  );
};
