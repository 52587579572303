import { StepsProps as AntdStepsProps } from 'antd';
import classNames from 'classnames';
import { StyledSteps } from './Steps.styles';

type StepsProps = AntdStepsProps;

export const Steps = ({ className, ...props }: StepsProps): JSX.Element => {
  const stepClassName = classNames(className, 'steps');
  return <StyledSteps className={stepClassName} {...props} />;
};
