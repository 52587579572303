import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useForm } from 'antd/lib/form/Form';
import { RadioChangeEvent } from 'antd/lib/radio';
import { MaterialNumberAutoComplete } from '../MaterialNumberAutoComplete/MaterialNumberAutoComplete';
import { MachineIsPairableTooltip } from '../MachineSummary/MachineIsPairableTooltip/MachineIsPairableTooltip';
import { StyledMachineIsPairableGlobalStyles } from '../MachineSummary/MachineIsPairableTooltip/MachineIsPairable.global.styles';
import { StyledMachineFind } from './MachineFind.styles';
import { Radio } from 'lib/components/Radio/Radio';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'lib/components/Button/SecondaryButton/SecondaryButton';
import { Input } from 'lib/components/Input/Input';
import { ValidateMachineInput } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';
import { TextButton } from 'lib/components/Button/TextButton/TextButton';
import { MachineModalActions } from 'app/modules/machine-directory/modals/state/machineModalActions';
import { MachineReclaimModal } from 'app/modules/machine-directory/modals/components/MachinePairReclaimModal/MachineReclaimModal';
import { MachineClaimPairSelectors } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairSelectors';
import { MachineClaimPairActions } from 'app/modules/machine-directory/machine-claiming-pairing/state/machineClaimPairActions';
import { getMachineClassification } from 'app/modules/machine-directory/utils/getMachineClassification';
import { MachineClassification } from 'app/modules/machine-directory/enum/MachineClassification';
import { MachineManufacturerType } from 'app/modules/machine-directory/enum/MachineManufacturerType';
import { SvgIcon } from 'lib/components/SvgIcon/SvgIcon';
import { Tooltip } from 'lib/components/Tooltip/Tooltip';
import { getMachineIsPairable } from 'app/modules/machine-directory/utils/getMachineIsPairable';

export const MachineFind = (): JSX.Element => {
  const [machineType, setMachineType] = useState(MachineManufacturerType.KARCHER_MACHINE);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formSearch] = useForm();
  const [materialNumberInput, setMaterialNumberInput] = useState('');
  const [serialNumberInput, setSerialNumberInput] = useState('');

  const handleOnFieldsChanged = (): void => {
    setMaterialNumberInput(formSearch.getFieldValue('materialNumber'));
    setSerialNumberInput(formSearch.getFieldValue('serialNumber'));
  };

  const selectMachineFindData = useSelector(MachineClaimPairSelectors.selectMachineFindData);
  const selectIsLoadingSearchMachine = useSelector(MachineClaimPairSelectors.selectIsMachineFindLoading);
  const selectMaterialNumberData = useSelector(MachineClaimPairSelectors.selectMaterialNumberData);
  const machineClassification = getMachineClassification(
    selectMachineFindData?.materialNumber,
    selectMaterialNumberData
  );

  const isMachinePairable =
    getMachineIsPairable(selectMachineFindData?.materialNumber, selectMaterialNumberData) || false;

  const isMaterialNumberFound = selectMaterialNumberData?.some(
    materialNumberInfo => materialNumberInfo?.materialNumber === materialNumberInput
  );

  const inputValidation = useMemo(
    () => materialNumberInput && serialNumberInput && isMaterialNumberFound,
    [materialNumberInput, serialNumberInput, isMaterialNumberFound]
  );

  const handleChangeMachineType = (e: RadioChangeEvent): void => {
    setMachineType(e.target.value);
    formSearch.resetFields();
    dispatch(MachineClaimPairActions.searchMaterialNumberResetState());
  };

  const handleSearch = useCallback(
    ({ materialNumber, serialNumber }: ValidateMachineInput) => {
      dispatch(MachineClaimPairActions.findMachineRequest({ materialNumber, serialNumber }));
    },
    [dispatch]
  );

  const handleSearchButtonClick = useCallback(() => {
    formSearch.submit();
  }, [formSearch]);

  const handleReclaim = useCallback(() => {
    dispatch(MachineModalActions.showMachinePairReclaimModal());
  }, [dispatch]);

  const handleContinueClaim = useCallback(() => {
    dispatch(MachineClaimPairActions.setStepClaimMachineActive());
    dispatch(MachineClaimPairActions.claimMachineResetState());
  }, [dispatch]);

  const handleContinuePair = useCallback(() => {
    dispatch(MachineClaimPairActions.setStepPairMachineActive());
    dispatch(MachineClaimPairActions.pairMachineResetState());
    dispatch(MachineClaimPairActions.pairMachineSearchClearData());
    dispatch(MachineClaimPairActions.searchMaterialNumberResetState());
  }, [dispatch]);

  const handleDeleteSelectionClick = useCallback(() => {
    formSearch.resetFields();
    setMachineType(MachineManufacturerType.KARCHER_MACHINE);
    dispatch(MachineClaimPairActions.findMachineResetState());
    dispatch(MachineClaimPairActions.searchMaterialNumberResetState());
  }, [dispatch, formSearch]);

  useEffect(() => {
    formSearch.resetFields();
    setMachineType(MachineManufacturerType.KARCHER_MACHINE);
    dispatch(MachineClaimPairActions.findMachineResetState());
    dispatch(MachineClaimPairActions.searchMaterialNumberResetState());
  }, [dispatch, formSearch, setMachineType]);

  return (
    <StyledMachineFind>
      <StyledMachineIsPairableGlobalStyles />
      <div className="machine-find">
        <div className="machine-find__body">
          <Form
            form={formSearch}
            layout="vertical"
            name="machine-find"
            onFinish={handleSearch}
            validateTrigger="onChange"
            autoComplete="false"
            onFieldsChange={handleOnFieldsChanged}
          >
            {!selectMachineFindData && (
              <Radio.Group
                className="machine-find__radio-group"
                defaultValue={machineType}
                onChange={handleChangeMachineType}
              >
                <Radio value={MachineManufacturerType.KARCHER_MACHINE}>
                  {t('findMachine.typeOfMachine.karcherMachine')}
                </Radio>
                <Radio value={MachineManufacturerType.THIRD_PARTY_MANUFACTURER}>
                  {t('findMachine.typeOfMachine.thirdPartyManufacturer')}
                </Radio>
              </Radio.Group>
            )}
            <Row gutter={24}>
              <Col>
                <Form.Item
                  name="materialNumber"
                  label={
                    machineType === MachineManufacturerType.KARCHER_MACHINE
                      ? t('machineClaiming.forms.materialNumber')
                      : t('findMachine.forms.manufacturer')
                  }
                  className="machine-find__form-item"
                  rules={[
                    {
                      required: true,
                      message: t('findMachine.forms.errors.materialNumberRequired'),
                    },
                  ]}
                >
                  <MaterialNumberAutoComplete
                    className="machine-find__input"
                    placeholder={
                      machineType === MachineManufacturerType.THIRD_PARTY_MANUFACTURER
                        ? t('findMachine.forms.placeholderManufacturer')
                        : t('machineClaiming.forms.placeholderMaterialNumber')
                    }
                    classifications={[
                      MachineClassification.STAND_ALONE,
                      MachineClassification.GATEWAY_CONNECTED_DEVICE,
                    ]}
                    isNonKaercherDevice={machineType === MachineManufacturerType.THIRD_PARTY_MANUFACTURER}
                    disabled={!!selectMachineFindData}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="serialNumber"
                  label={t('machineClaiming.forms.serialNumber')}
                  className="machine-find__form-item"
                  rules={[
                    {
                      required: true,
                      message: t('findMachine.forms.errors.serialNumberRequired'),
                    },
                    {
                      min: 6,
                      message: t('findMachine.forms.errors.serialNumberMinLength'),
                    },
                  ]}
                >
                  <Input
                    className="machine-find__input "
                    placeholder={t('machineClaiming.forms.placeholderSerialNumber')}
                    disabled={!!selectMachineFindData}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          {!selectMachineFindData ? (
            <PrimaryButton
              size="m"
              className="machine-find__button"
              onClick={handleSearchButtonClick}
              loading={selectIsLoadingSearchMachine}
              disabled={!inputValidation}
            >
              {t('machineClaiming.searchButton')}
            </PrimaryButton>
          ) : (
            <div className="machine-find__result">
              <div className="machine-find__table-result">
                <Row gutter={24} justify="space-between">
                  <Col className="machine-find__result">
                    <h4 className="machine-find__title">{t('findMachine.table.machineType')}</h4>
                    <p className="machine-find__info">{selectMachineFindData?.type || '-'}</p>
                  </Col>
                  <Col className="machine-find__result">
                    <h4 className="machine-find__title">{t('findMachine.table.materialNo')}</h4>
                    <p className="machine-find__info">{selectMachineFindData?.materialNumber}</p>
                  </Col>
                  <Col className="machine-find__result">
                    <h4 className="machine-find__title">{t('findMachine.table.serialNo')}</h4>
                    <p className="machine-find__info">{selectMachineFindData?.serialNumber}</p>
                  </Col>
                  <Col className="machine-find__result">
                    <h4 className="machine-find__title">{t('findMachine.table.alg')}</h4>
                    <p className="machine-find__info">{selectMachineFindData?.algNumber || '-'}</p>
                  </Col>
                  <Col className="machine-find__result">
                    <h4 className="machine-find__title">{t('findMachine.table.customer')}</h4>
                    <p className="machine-find__info">{selectMachineFindData?.customerName || '-'}</p>
                    <p className="machine-find__info">{selectMachineFindData?.customerNumber}</p>
                  </Col>
                </Row>
                {selectMachineFindData?.machineIsClaimed && (
                  <Row justify="end">
                    <SecondaryButton
                      size="s"
                      className="machine-find__button machine-find__reclaim-button"
                      key="reclaim-button"
                      onClick={handleReclaim}
                    >
                      {t('findMachine.reclaimButton')}
                    </SecondaryButton>
                  </Row>
                )}
              </div>
              <Row gutter={24}>
                {selectMachineFindData?.machineIsClaimed &&
                  machineClassification !== MachineClassification.STAND_ALONE && (
                    <Col className="machine-find__col">
                      <PrimaryButton
                        disabled={!isMachinePairable}
                        size="m"
                        className="machine-find__button"
                        onClick={handleContinuePair}
                      >
                        {t('findMachine.continueWithPairingButton')}
                      </PrimaryButton>
                      {!isMachinePairable && (
                        <Tooltip
                          title={MachineIsPairableTooltip}
                          overlayClassName="machine-pairable-tooltip-overlay"
                          placement="bottomLeft"
                        >
                          <div className="machine-find__info-icon">
                            <SvgIcon name="info"></SvgIcon>
                          </div>
                        </Tooltip>
                      )}
                    </Col>
                  )}
                {!selectMachineFindData?.machineIsClaimed && (
                  <Col>
                    <PrimaryButton size="m" className="machine-find__button" onClick={handleContinueClaim}>
                      {t('findMachine.continueWithClaimingButton')}
                    </PrimaryButton>
                  </Col>
                )}
                <Col>
                  <TextButton
                    size="m"
                    className="machine-find__button"
                    key="delete-button"
                    onClick={handleDeleteSelectionClick}
                  >
                    {t('findMachine.deleteSelectionButton')}
                  </TextButton>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
      <MachineReclaimModal />
    </StyledMachineFind>
  );
};
