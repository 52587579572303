import { createSlice } from '@reduxjs/toolkit';
import { Optional } from 'lib/types/Optional';

export interface DeleteCustomerState {
  deleteUsers: {
    [id: string]: {
      loading: boolean;
      error: Optional<string>;
      completed: boolean;
    };
  };
  reassignUsers: {
    [id: string]: {
      loading: boolean;
      error: Optional<string>;
      completed: boolean;
    };
  };
  reclaimDevices: {
    [id: string]: {
      loading: boolean;
      error: Optional<string>;
      completed: boolean;
    };
  };
  unclaimDevices: {
    [id: string]: {
      loading: boolean;
      error: Optional<string>;
      completed: boolean;
    };
  };
  errorMessage: Optional<string>;
}

export const initialState: DeleteCustomerState = {
  reassignUsers: {},
  errorMessage: null,
  deleteUsers: {},
  reclaimDevices: {},
  unclaimDevices: {},
};

const deleteCustomerSlice = createSlice({
  name: 'deleteCustomer',
  initialState,
  reducers: {
    deleteUserRequest: (state, action) => {
      state.deleteUsers[action.payload.input.id] = {
        loading: true,
        error: null,
        completed: false,
      };
      return state;
    },
    deleteUserSuccess: (state, action) => {
      state.deleteUsers[action.payload.id] = {
        loading: false,
        error: null,
        completed: true,
      };
      return state;
    },
    deleteUserError: (state, action) => {
      const { id, error } = action.payload;
      state.deleteUsers[id] = {
        loading: false,
        error,
        completed: false,
      };
      return state;
    },
    clearDeleteUser: (state, _action) => {
      state.deleteUsers = {};
      return state;
    },
    deleteMultiUsersRequest: (state, action) => {
      action.payload.map(({ input }: { input: { id: string } }) => {
        state.deleteUsers[input.id] = {
          loading: true,
          error: null,
          completed: false,
        };
        return input;
      });
      return state;
    },

    reassignUserRequest: (state, action) => {
      state.reassignUsers[action.payload.input.userId] = {
        loading: true,
        error: null,
        completed: false,
      };
      return state;
    },
    reassignUserSuccess: (state, action) => {
      state.reassignUsers[action.payload?.id] = {
        loading: false,
        error: null,
        completed: true,
      };
      return state;
    },
    reassignUserError: (state, action) => {
      const { id, error } = action.payload;
      state.reassignUsers[id] = {
        loading: false,
        error,
        completed: false,
      };
      return state;
    },
    reassignMultiUsersRequest: (state, action) => {
      action.payload.map(({ input }: { input: { userId: string } }) => {
        state.reassignUsers[input.userId] = {
          loading: true,
          error: null,
          completed: false,
        };
        return input;
      });
      return state;
    },

    reclaimDevicesRequest: (state, action) => {
      state.reclaimDevices[action.payload.input.deviceId] = {
        loading: true,
        error: null,
        completed: false,
      };
      return state;
    },

    reclaimDevicesSuccess: (state, action) => {
      const data = action.payload?.adminMachineClaim?.data;
      const deviceId = `${data?.materialNumber}-${data?.serialNumber}`;
      state.reclaimDevices[deviceId] = {
        loading: false,
        error: null,
        completed: true,
      };
      return state;
    },

    reclaimDevicesError: (state, action) => {
      const { id, error } = action.payload;
      state.reclaimDevices[id] = {
        loading: false,
        error,
        completed: false,
      };
      return state;
    },

    reclaimMultiDevicesRequest: (state, action) => {
      action.payload.map(({ input }: { input: { materialNumber: string; serialNumber: string } }) => {
        const deviceId = `${input?.materialNumber}-${input?.serialNumber}`;
        state.reclaimDevices[deviceId] = {
          loading: true,
          error: null,
          completed: false,
        };
        return input;
      });
      return state;
    },

    unclaimDevicesRequest: (state, action) => {
      const input = action.payload.input;
      const deviceId = `${input?.materialNumber}-${input?.serialNumber}`;
      state.unclaimDevices[deviceId] = {
        loading: true,
        error: null,
        completed: false,
      };
      return state;
    },

    unclaimDevicesSuccess: (state, action) => {
      const data = action.payload?.unclaimMachine?.data;
      const deviceId = `${data?.materialNumber}-${data?.serialNumber}`;
      state.unclaimDevices[deviceId] = {
        loading: false,
        error: null,
        completed: true,
      };
      return state;
    },

    unclaimDevicesError: (state, action) => {
      const { id, error } = action.payload;
      state.unclaimDevices[id] = {
        loading: false,
        error,
        completed: false,
      };
      return state;
    },

    unclaimMultiDevicesRequest: (state, action) => {
      action.payload.map(({ input }: { input: { materialNumber: string; serialNumber: string } }) => {
        const deviceId = `${input?.materialNumber}-${input?.serialNumber}`;
        state.unclaimDevices[deviceId] = {
          loading: true,
          error: null,
          completed: false,
        };
        return input;
      });
      return state;
    },

    clearDeleteUsers: (state, _action) => {
      state.deleteUsers = {};
      return state;
    },

    clearReassignUsers: (state, _action) => {
      state.reassignUsers = {};
      return state;
    },

    clearReclaimDevices: (state, _action) => {
      state.reclaimDevices = {};
      return state;
    },

    clearUnclaimDevices: (state, _action) => {
      state.unclaimDevices = {};
      return state;
    },

    resetState: () => initialState,
  },
});

export const deleteCustomerActions = deleteCustomerSlice.actions;
export const customerDeleteReducer = deleteCustomerSlice.reducer;
