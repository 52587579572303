import React from 'react';
import logo from '../../../../../../assets/images/kaercher-logo.png';
import { StyledAppHeader } from 'app/components/app-layout/AppHeader/AppHeader.styles';

export const UserCreateHeader = (): JSX.Element => (
  <StyledAppHeader>
    <div className="header__top">
      <img src={logo} alt="logo" />
    </div>
  </StyledAppHeader>
);
