import { AutoComplete } from 'antd';
import styled from 'styled-components';

export const StyledAutoComplete = styled(AutoComplete)`
  width: 243px;
  height: 44px;

  .ant-input-affix-wrapper {
    box-shadow: none !important;
    border-right-width: 2px !important;
    border-color: ${(props): string => props.theme.colors.border};

    :hover {
      border-color: ${(props): string => props.theme.colors.border};
      border-right-width: 2px !important;
    }
  }
`;
