import { Button, ButtonProps as AntdButtonProps } from 'antd';

interface IButtonBaseProps {
  size?: 's' | 'm';
}

export type ButtonBaseProps = Partial<Omit<AntdButtonProps, 'size'> & IButtonBaseProps>;

export const ButtonBase = (props: ButtonBaseProps): JSX.Element => {
  const { size = 's', className = '', ...rest } = props;
  const buttonClassName = [className, 'button-base', `button__size-${size}`].join(' ');

  return <Button className={buttonClassName} {...rest} />;
};
