import React, { useCallback, useEffect } from 'react';
import { Col, Row, Tag } from 'antd';
import { TFunction, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { userDetailsSelectors } from '../../state/userDetailsSelector';
import { IUserDeleteRequestOptions, UserDetailsActions } from '../../state/userDetailsActions';
import { StyledUserDetails } from './UserDetails.styles';
import { PrimaryButton } from 'lib/components/Button/PrimaryButton/PrimaryButton';
import { DeleteUserModal } from 'app/modules/user-management/modals/components/DeleteUserModal/DeleteUserModal';
import { Permission } from 'config/permissions';
import { PermissionGuard } from 'app/cross-cutting-concerns/authentication/components/PermissionGuard/PermissionGuard';
import { EditUserModal } from 'app/modules/user-management/modals/components/EditUserModal/EditUserModal';
import { UserModalsActions } from 'app/modules/user-management/modals/state/userModalsActions';
import { LoadingPage } from 'lib/components/LoadingPage/LoadingPage';
import { convertCodeToLanguage } from 'app/modules/user-management/utils/convertCodeToLanguage';
import { convertCodeToCountry } from 'app/modules/user-management/utils/convertCodeToCountry';
import { ResendInvitationModal } from 'app/modules/user-management/modals/components/ResendInvitationModal/ResendInvitationModal';
import { convertUTCToLocal } from 'app/modules/user-management/utils/convertUTCToLocal';
import { UserStatusValue } from 'app/modules/user-management/interfaces/User.types';
import { Optional } from 'lib/types/Optional';
import { ChangeCustomerModal } from 'app/modules/user-management/modals/components/ChangeCustomerModal/ChangeCustomerModal';

interface UserDisplayInfoProps {
  title: string;
  infoDisplay: Optional<string>;
  t: TFunction;
}

const UserDisplayInfo = ({ title, infoDisplay, t }: UserDisplayInfoProps): JSX.Element => (
  <>
    <p className="user-details__info-title">{t(`userDetails.${title}`)}</p>
    <p className="user-details__info-content">{infoDisplay || t('userDetails.noneData')}</p>
  </>
);

export const UserDetails = (): JSX.Element => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();

  const user = useSelector(userDetailsSelectors.selectUserDetails);
  const isLoadingUser = useSelector(userDetailsSelectors.selectIsUserDetailLoading);
  const selectInvokeResendInviteData = useSelector(userDetailsSelectors.selectResendInvitationData);

  const STATUS_VALUE = [
    {
      title: 'userDetails.unauthorized',
      value: UserStatusValue.UNAUTHORIZED,
    },
    {
      title: 'userDetails.inProgress',
      value: UserStatusValue.IN_PROGRESS,
    },
    {
      title: 'userDetails.authorized',
      value: UserStatusValue.AUTHORIZED,
    },
  ];

  const handleEditUser = (): void => {
    dispatch(UserModalsActions.showEditUserModal());
  };

  const handleDeleteUser = (): void => {
    if (!id) return;
    dispatch(UserModalsActions.showDeleteUserModal({ userId: id }));
  };

  const handleResendInvitation = (): void => {
    dispatch(UserModalsActions.showResendInvitationModal());
  };

  const handleDeleteUserCallback = useCallback(() => {
    if (!id) return;
    const input: IUserDeleteRequestOptions = { input: { id }, email: user?.email as string };

    dispatch(UserDetailsActions.userDeleteRequest(input));
  }, [dispatch, id, user?.email]);

  const openChangeCustomerModal = (): void => {
    if (!id) return;
    dispatch(UserModalsActions.showChangeCustomerModal({ userId: id }));
  };

  useEffect(() => {
    if (id) {
      dispatch(UserDetailsActions.userDetailsRequest(id));
    }
  }, [dispatch, id]);

  const getInvokedResendDisplay = useCallback(() => {
    if (selectInvokeResendInviteData) {
      return convertUTCToLocal(selectInvokeResendInviteData, t);
    }
    return convertUTCToLocal(user?.invitationRevokedOn, t);
  }, [selectInvokeResendInviteData, user?.invitationRevokedOn, t]);

  return (
    <StyledUserDetails className="user-details">
      {isLoadingUser ? (
        <LoadingPage />
      ) : (
        <>
          <div className="user-details__header">
            <div className="user-details__header-content">
              <div className="user-details__page-info">
                <h1 className="user-details__title">{user?.fullName || t('userDetails.noneData')}</h1>
              </div>

              <Row gutter={24} align="middle">
                <Col className="user-details__info-wrapper">
                  <p className="user-details__main-info-label">{t('userDetails.customerName')}</p>
                  <p className="user-details__main-info-val">{user?.customerName || t('userDetails.noneData')}</p>
                </Col>
                <Col className="user-details__info-wrapper">
                  <p className="user-details__main-info-label">{t('userDetails.customerNr')}</p>
                  <p className="user-details__main-info-val">{user?.customerNumber || t('userDetails.noneData')}</p>
                </Col>
                <Col className="user-details__info-wrapper">
                  <p className="user-details__main-info-label">{t('userDetails.country')}</p>
                  <p className="user-details__main-info-val">{convertCodeToCountry(user?.country, t)}</p>
                </Col>

                <Col>
                  <PrimaryButton size="m" className="reassign__button" onClick={openChangeCustomerModal}>
                    {t('userDetails.reassignUser')}
                  </PrimaryButton>
                </Col>
              </Row>
            </div>
          </div>
          <div className="user-details__body">
            <div className="user-details__body-content">
              <Row gutter={24}>
                <Col span={12}>
                  <h3 className="user-details__title">{t('userDetails.userInformation')}</h3>
                  <Row gutter={24} justify="space-between">
                    <Col>
                      <UserDisplayInfo
                        title="name"
                        infoDisplay={`${user?.title !== 'None' && user?.title ? `${user?.title}. ` : ''}${
                          user?.fullName
                        }`}
                        t={t}
                      />
                      <UserDisplayInfo title="phoneNumber" infoDisplay={user?.phoneNumber} t={t} />
                      <UserDisplayInfo title="email" infoDisplay={user?.email} t={t} />
                      <UserDisplayInfo title="language" infoDisplay={convertCodeToLanguage(user?.language, t)} t={t} />
                    </Col>
                  </Row>

                  <div className="user-details__user-status">
                    <p className="user-details__user-status-title">{t('userDetails.userStatus')}</p>
                    {STATUS_VALUE.map(({ title, value }) => {
                      const classNameTag = classNames('user-details__main-info-tag', {
                        'user-details__main-info-tag-active': user?.status === value,
                      });

                      return (
                        <Tag className={classNameTag} key={`user-${title}`}>
                          <span>{t(title)}</span>
                        </Tag>
                      );
                    })}
                  </div>
                  <PrimaryButton
                    size="m"
                    className="user-details__edit-user-btn"
                    onClick={handleEditUser}
                    disabled={user?.status === STATUS_VALUE[1].value}
                  >
                    {t('userDetails.editUserInformation')}
                  </PrimaryButton>
                </Col>
                <Col span={12} className="user-details__options">
                  {user?.status === STATUS_VALUE[1].value ? (
                    <PermissionGuard requiredPermissions={[Permission.Customer.User.DELETE]}>
                      <Col className="user-details__delete-user">
                        <h3 className="user-details__title">{t('userDetails.deleteInvitation')}</h3>
                        <p>{t('userDetails.deleteInvitationDescription')}</p>
                        <PrimaryButton
                          size="m"
                          className="user-details__delete-user-btn"
                          onClick={handleDeleteUser}
                          disabled={user?.status !== STATUS_VALUE[1].value}
                        >
                          {t('userDetails.deleteInvitation')}
                        </PrimaryButton>
                      </Col>
                      <DeleteUserModal
                        handleOk={handleDeleteUserCallback}
                        title="userDetails.deleteInvitationModal.deleteInvitation"
                        submitButtonLabel="userDetails.deleteInvitationModal.deleteInvitation"
                        body="userDetails.deleteInvitationModal.modalBody"
                      />
                    </PermissionGuard>
                  ) : (
                    <PermissionGuard requiredPermissions={[Permission.Customer.User.DELETE]}>
                      <Col className="user-details__delete-user">
                        <h3 className="user-details__title">{t('userDetails.deleteUser')}</h3>
                        <p>{t('userDetails.deleteGuild')}</p>
                        <PrimaryButton
                          size="m"
                          className="user-details__delete-user-btn"
                          onClick={handleDeleteUser}
                          disabled={user?.status === STATUS_VALUE[1].value}
                        >
                          {t('userDetails.deleteUser')}
                        </PrimaryButton>
                      </Col>
                      <DeleteUserModal
                        handleOk={handleDeleteUserCallback}
                        title="userDetails.deleteUserModal.deleteUser"
                        submitButtonLabel="userDetails.deleteUserModal.deleteUser"
                        body="userDetails.deleteUserModal.modalBody"
                      />
                    </PermissionGuard>
                  )}
                  <PermissionGuard requiredPermissions={[Permission.Customer.User.SEND_INVITATION]}>
                    <Col className="user-details__resendInvitation-user">
                      <h3 className="user-details__title">{t('userDetails.resendInvitation')}</h3>
                      <p className="user-details__info-title">{t('userDetails.dateRevoked')}</p>
                      <p>{getInvokedResendDisplay()}</p>
                      <PrimaryButton
                        size="m"
                        className="user-details__resendInvitation-user-btn"
                        onClick={handleResendInvitation}
                        disabled={user?.status !== STATUS_VALUE[1].value}
                      >
                        {t('userDetails.resendInvitation')}
                      </PrimaryButton>
                    </Col>
                  </PermissionGuard>
                </Col>
              </Row>
            </div>
          </div>

          <EditUserModal />
          <ResendInvitationModal />
          <ChangeCustomerModal />
        </>
      )}
    </StyledUserDetails>
  );
};
