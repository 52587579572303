import produce from 'immer';
import { AnyAction } from 'redux';
import { UserInviteActions } from './userInviteActions';
import { Optional } from 'lib/types/Optional';
import { User } from 'app/cross-cutting-concerns/communication/interfaces/am-sp-api-graphql';

export interface UserInviteState {
  data: Optional<User>;
  isLoading: boolean;
}

export const initialState: UserInviteState = {
  data: null,
  isLoading: false,
};

export const userInviteReducer = (state = initialState, action: AnyAction): UserInviteState =>
  produce(state, draft => {
    switch (action.type) {
      case UserInviteActions.INVITE_USER_REQUEST: {
        draft.isLoading = true;
        return draft;
      }

      case UserInviteActions.INVITE_USER_SUCCESS: {
        const { inviteUser } = action.payload;

        draft.isLoading = false;
        draft.data = inviteUser?.data || {};
        return draft;
      }

      case UserInviteActions.INVITE_USER_ERROR: {
        draft.isLoading = false;
        return draft;
      }

      default:
        return draft;
    }
  });
